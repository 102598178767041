<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items2" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-select
          :items="yearItems"
          v-model="yearItem"
          label="基準日年度選択"
          suffix="年"
        ></v-select>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId"/>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired"/>
      </v-flex>
      <v-spacer></v-spacer>

      <v-btn dark
        color="primary"
        class="mb-2"
        @click="$router.push('/manager/leave-application-management')"
      >有給取得管理</v-btn>

    </v-layout>
    <v-card class="ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >休暇付与</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on }">
            <v-btn color="#6ac67b" dark v-on="on">追加</v-btn>
          </template>

          <v-card>
            <v-card-title class="headline" primary-title>休暇付与</v-card-title>

            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.staff_id"
                    label="スタッフ"
                    :items="staffItems"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="applicationDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.begin_date"
                        label="付与有効期限（開始）"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.begin_date"
                      locale="ja-jp"
                      @input="applicationDateMenu = false"
                      :day-format="date => new Date(date).getDate()"
                      ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-menu lazy offset-y full-width
                    v-model="applicationDateMenu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.end_date"
                        label="付与有効期限（終了）"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.end_date"
                      locale="ja-jp"
                      @input="applicationDateMenu2 = false"
                      :day-format="date => new Date(date).getDate()"
                      ></v-date-picker>
                  </v-menu>
                </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedItem.leave_days"
                      label="有給休暇の付与日数"
                      type="number"
                      suffix="日"
                    />
                  </v-flex>
              </v-layout>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" flat @click="closeDialog">cancel</v-btn>
              <v-btn color="orange" flat @click="save">ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table :headers="headers" :items="processedItems" hide-actions>
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="leave-application-td">{{ props.item.name }}</td>
          <td class="leave-application-td">{{ props.item.begin_date }}</td>
          <td class="leave-application-td" style="font-size: 1.7em">~</td>
          <td class="leave-application-td">{{ props.item.end_date }}</td>
          <td class="leave-application-td">{{ props.item.leave_days }}</td>
          <td class="leave-application-td">{{ props.item.used_days }}</td>
          <td class="text-xs-center leave-application-td">
            <v-icon color="primary" class="mr-4" @click="editItem(props.item)">
              edit
            </v-icon>
            <v-icon color="accent" @click="confirmDelete(props.item)">
              delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import itemTools from '@/mixins/item_tools.js'

let defaultEditedItem = {
  id: 0,
  staff_id: 0,
  begin_date: moment().tz('Asia/Tokyo').format('YYYY-MM-DD'),
  end_date: moment().add(12, 'months').subtract(1, 'd').tz('Asia/Tokyo').format('YYYY-MM-DD'),
  leave_days: 0
}

export default {
  mixins: [itemTools],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  data: () => ({
    isRetired: 0,
    retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }, { id: 3, name: '指定なし' }],
    group: '指定なし',
    groupId: 0,
    items2: [
      {
        text: '休暇・申請',
        disabled: false,
        href: '/#/manager/leave-application'
      },
      {
        text: '休暇付与',
        disabled: true
      }
    ],
    yearItem: moment().format('YYYY'),
    yearItems: [],
    dialog: false,
    endDateMenu: false,
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    leaveDays: 0,
    headers: [
      {
        text: '氏名',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '付与有効期限（開始）',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header',
        width: '70'
      },
      {
        text: '付与有効期限（終了）',
        align: 'center',
        value: 'name',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '付与日数',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '取得',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      }
    ],
    items: [],
    editedItem: cloneDeep(defaultEditedItem),
    applicationDateMenu: false,
    applicationDateMenu2: false
  }),
  computed: {
    processedItems () {
      let items = []
      this.items.forEach((item) => {
        if (this.group !== '指定なし') {
          if (this.group !== '') {
            if (item.group_id !== this.groupId) {
              return
            }
          }
        }
        if (this.staff !== '指定なし') {
          if (this.staff !== '') {
            if (item.name !== this.staff) {
              return
            }
          }
        }
        items.push(item)
      })
      return items
    }
  },
  mounted () {
    this.getLeaveGrant()
    this.getStaff()
    this.yearArrayCreation()
  },
  watch: {
    yearItem () {
      this.getLeaveGrant()
    },
    staff () {
      this.getLeaveGrant()
    },
    group () {
      this.getLeaveGrant()
    },
    isRetired () {
      this.getLeaveGrant()
    }
  },
  methods: {
    editItem (item) {
      let tmpItem = cloneDeep(item)
      this.editedItem = tmpItem
      this.dialog = true
    },
    yearArrayCreation () {
      let currentYear = moment().year()
      for (let i = currentYear - 4; i <= currentYear + 12; i++) {
        this.yearItems.push(String(i))
      }
    },
    confirmDelete (item) {
      let confirmText = item.name + ' \n' + '付与有効期限' +
      item.begin_date + '~' + item.end_date + '\n' +
      '付与日数' + item.leave_days + '日\n\n' + ' を削除しますか？'
      if (confirm(confirmText)) {
        this.deleteItem(item)
      }
    },
    getLeaveGrant () {
      this.items = []
      axios.get('/api/manager/leave-grant?year=' + this.yearItem)
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.items.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.items.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.items.push(item)
              }
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    getStaff () {
      this.staffItems = []
      axios.get('/api/manager/staff')
        .then((res) => {
          res.data.forEach((item) => {
            if (item.is_retired === 0) {
              this.staffItems.push(item)
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getStaff]')
        })
    },
    save () {
      if (this.editedItem.id === 0) {
        axios.post('/api/manager/leave-grant', this.editedItem)
          .then((res) => {
            this.dialog = false
            this.editedItem = cloneDeep(defaultEditedItem)
            this.getLeaveGrant()
          })
          .catch(() => {
            alert('登録エラー')
          })
      } else {
        axios.put('/api/manager/leave-grant/' + this.editedItem.id, this.editedItem)
          .then((res) => {
            this.dialog = false
            this.editedItem = cloneDeep(defaultEditedItem)
            this.getLeaveGrant()
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    deleteItem (item) {
      axios.delete('/api/manager/leave-grant/' + item.id)
        .then(() => {
          this.getLeaveGrant()
        })
        .catch(() => {
          alert('削除エラー')
        })
    },
    closeDialog () {
      this.dialog = false
      this.editedItem = cloneDeep(defaultEditedItem)
    }
  }
}
</script>

<style>
.leave-application-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.leave-application-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
