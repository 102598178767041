<template>
  <v-card :style="heightSize2">
    <v-card-title>
      <v-layout justify-center>
        <v-menu
          v-model="monthMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-item-group>
              <v-item>
                <v-btn flat icon :class="fontSize" @click="prevMonth" style="color: #616161;">
                  <v-icon>navigate_before</v-icon>
                </v-btn>
              </v-item>
              <v-item>
                <v-btn flat v-on="on" :class="fontSize" large style="color: #616161;">
                  {{ formatedCalendarStart }}
                </v-btn>
              </v-item>
              <v-item>
                <v-btn flat icon :class="fontSize" @click="nextMonth" style="color: #616161;">
                  <v-icon>navigate_next</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
          </template>
          <v-date-picker
            v-model="startDate"
            type="month"
            no-title
            scrollable
            locale="ja-jp"
            @input="monthMenu = false"
          />
        </v-menu>
      </v-layout>
    </v-card-title>
    <v-card-text :style="heightSize">
      <v-calendar
        ref="calendar"
        type="month"
        :start="`${startDate}-01`"
        color="primary"
        locale="ja-jp"
        :day-format="date => date.day"
        :show-month-on-first="false"
      >
        <template v-slot:day="{ date }">
          <div
            v-if="highlightDays[date]"
            :key="`${date}-check`"
            style="position: absolute; top: 3px; left: 3px"
          >
            <v-icon
              color="success"
              left
            >
              check_circle
            </v-icon>
          </div>
          <div
            :key="`${date}-click`"
            style="position: absolute; top: 0; width: 94%; height: 100%;"
            @click="clickedDay(date)"
          >
            <v-layout
              fill-height
              column
              justify-end
            >
              <template v-for="event in eventItems[date]">
                <v-tooltip
                  top
                  color="#616161"
                  :key="`${event.type}-${event.start}-2`"
                >
                  <template v-slot:activator="{ on }">
                    <span v-if="$route.path === '/manager/daily-worktime'" class="work-shift" v-on="on" @click="clickedEvent(event)">{{ event.title }}</span>
                    <span v-else :class="[event.type]" v-on="on" @click="clickedEvent(event)">{{ event.title }}</span>
                  </template>
                  <span>&#10003;</span>
                </v-tooltip>
              </template>
            </v-layout>
          </div>
        </template>
      </v-calendar>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'MyCalendar',
  props: {
    eventItems: Object,
    calendarStart: String,
    highlightDays: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    heightSize: 'height: 60vh',
    heightSize2: 'height: 72vh',
    fontSize: 'display-1',
    monthMenu: false
  }),
  computed: {
    startDate: {
      get: function () {
        return this.calendarStart
      },
      set: function (ym) {
        this.$emit('update:calendarStart', ym)
      }
    },
    formatedCalendarStart () {
      let ym = this.startDate.split('-')
      return ym[0] + '年 ' + ym[1] + '月'
    }
  },
  watch: {
    startDate () {
      this.$emit('month-changed')
    }
  },
  mounted () {
    this.getFontSize()
    this.getHeightSize()
  },
  methods: {
    getHeightSize () {
      if (this.$route.path === '/manager/daily-worktime') {
        this.heightSize = 'height: 35vh'
        this.heightSize2 = 'height: 48vh'
      } else {
        this.heightSize = 'height: 60vh'
        this.heightSize2 = 'height: 72vh'
      }
    },
    getFontSize () {
      if (this.$route.path === '/shift-application') {
        this.fontSize = 'subheading'
      } else if (this.$route.path === '/manager/daily-worktime') {
        this.fontSize = 'body-1'
      } else {
        this.fontSize = 'display-1'
      }
    },
    clickedDay (date) {
      this.$emit('day-clicked', date)
    },
    clickedEvent (event) {
      this.$emit('event-clicked', event)
    },
    prevMonth () {
      this.startDate = moment(this.calendarStart + '-01').subtract(1, 'months').format('YYYY-MM')
    },
    nextMonth () {
      this.startDate = moment(this.calendarStart + '-01').add(1, 'months').format('YYYY-MM')
    }
  }
}
</script>

<style scoped>
.v-tooltip__content {
  border-radius: 20px;
}
.work {
  padding: 0.3em;
  text-align: center;
  height: auto;
  border: solid 1px #00809e;
  border-radius: 0.5em;
  background-color: #EEE;
}
.work-shift {
  padding: 0.5em;
  width: 2.2em;
  text-align: center;
  height: auto;
  border: solid 1px #00809e;
  border-radius: 0.5em;
  background-color: #EEE;
}
.leave {
  padding: 0.3em;
  text-align: center;
  height: auto;
  border: solid 1px #EF9A9A;
  border-radius: 0.5em;
  background-color: #E57373;
  color: white;
}
.workable {
  padding: 0.5em;
  width: 2.2em;
  text-align: center;
  height: auto;
  border-radius: 0.5em;
  background-color: #1E88E5;
}
.specify_another {
  padding: 0.5em;
  width: 2.2em;
  text-align: center;
  height: auto;
  border-radius: 0.5em;
  background: linear-gradient(90deg,#1E88E5 0%,#1E88E5 50%,#81C784 50%,#81C784 100%);
}
.rough_specified_time, .specified_time {
  padding: 0.5em;
  width: 2.2em;
  text-align: center;
  height: auto;
  border-radius: 0.5em;
  background-color: #81C784;
}
.undecided {
  padding: 0.5em;
  width: 2.2em;
  text-align: center;
  height: auto;
  border-radius: 0.5em;
  background-color: #DCE775;
}
.not_possible {
  padding: 0.5em;
  width: 2.2em;
  text-align: center;
  height: auto;
  border-radius: 0.5em;
  background-color: #EF5350;
}
</style>

<style>
.v-calendar-monthly {
  border-top: #e0e0e0 1px solid;
  border-left: #e0e0e0 1px solid;
}
.v-calendar-weekly__head {
  border-bottom: #e0e0e0 1px solid;
}
.theme--light.v-calendar-weekly
.v-calendar-weekly__head-weekday {
  text-align: center;
}
.v-calendar-weekly__day-label {
  left: auto;
  right: 0;
}
</style>
