<template>
  <v-layout style="background-color: white" column fill-height>
    <v-toolbar flat color="white" class="pt-2">
      <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">勤務可能日</v-toolbar-title>
    </v-toolbar>
    <v-layout row wrap>
      <v-flex xs12 class="text-xs-right">
        <v-btn v-if="isCompleted === 0" color="#6ac67b" dark class="mb-2" @click="showWorktimePlan">作成</v-btn>
        <v-btn v-else depressed disabled class="mb-2" @click="showWorktimePlan">送信済み</v-btn>
      </v-flex>

      <v-flex xs12 class="pa-1">
        <MyCalendar
          :eventItems="newEvents"
          :calendarStart.sync="calendarStart"
          @event-clicked="eventClicked"
          @month-changed="monthChanged"
        />
      </v-flex>
      <v-flex xs12 class="pa-1">
        <v-card>
          <v-card-text class="pa-1">
            <v-list>
              <v-subheader>{{ eventDate }}</v-subheader>
              <template v-for="item in eventItems">
                <div :key="item.id">
                  <v-list-tile :key="item.id">
                    <v-list-tile-content>
                      <v-list-tile-sub-title v-if="item.category === 'workable'">
                        全日勤務可能
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else-if="item.category === 'rough_specified_time'">
                        {{ item.begin_time }} ~ 時間指定なし
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else-if="item.category === 'specified_time'">
                        {{ item.begin_time }} ~ {{ item.end_time }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else-if="item.category === 'specify_another'">
                        葬儀： {{ item.funeral_category === 'workable' ? '勤務可能' : item.funeral_category === 'rough_specified_time' ? item.begin_time + '~' : '勤務不可' }}<br>
                        通夜： {{ item.wake_category === 'workable' ? '勤務可能' : '休み' }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else-if="item.category === 'undecided'">
                        未定
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else-if="item.category === 'not_possible'">
                        休み
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </div>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-btn color="grey lighten-1" @click="$router.push('worktime-record')">戻る</v-btn>
      </v-flex>
      <v-flex xs12>
        <v-btn
          v-if="isCompleted === 0"
          dark
          color="#1976D2"
          @click="putWorkableShift"
        >
          {{ formatedCalendarStart }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="worktimePlanDialog"
      persistent
      fullscreen
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="title">勤務可能日編集</span>
        </v-card-title>

        <template v-if="worktimePlanDialogPage === 1">

          <v-stepper vertical value="1">
            <v-stepper-header>
              <v-stepper-step step="1" class="pa-1"><span class="caption">日付選択</span></v-stepper-step>
              <v-stepper-step step="2" class="pa-1"><span class="caption">時間・種別指定</span></v-stepper-step>
              <v-stepper-step step="3" class="pa-1"><span class="caption">確認</span></v-stepper-step>
              <v-stepper-step step="4" class="pa-1"><span class="caption">完了</span></v-stepper-step>
            </v-stepper-header>
          </v-stepper>

          <v-card-text class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <MyCalendar
                  :eventItems="newEvents"
                  :calendarStart.sync="calendarStart"
                  :highlightDays="highlightDays"
                  @day-clicked="dayClicked"
                  @month-changed="monthChanged"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="worktimePlanDialog = false">キャンセル</v-btn>
            <v-alert
              :value="dayAlertJudgment"
              color="error"
              outline
              icon="error_outline"
            >
              日付が未選択です
            </v-alert>
            <v-btn
              v-if="dayAlertJudgment !== true"
              color="success"
              flat
              @click="toSelect"
            >
              時間・種別を指定
            </v-btn>
          </v-card-actions>
        </template>

        <template v-if="worktimePlanDialogPage === 2">

          <v-stepper vertical value="2" class="pa-0">
            <v-stepper-header>
              <v-stepper-step step="1" complete class="pa-1" ><span class="caption">日付選択</span></v-stepper-step>
              <v-stepper-step step="2" class="pa-1"><span class="caption">時間・種別指定</span></v-stepper-step>
              <v-stepper-step step="3" class="pa-1"><span class="caption">確認</span></v-stepper-step>
              <v-stepper-step step="4" class="pa-1"><span class="caption">完了</span></v-stepper-step>
            </v-stepper-header>
          </v-stepper>

          <v-card-text style="height: 70vh;">
            <v-layout row wrap>
              <v-flex xs12>
                <v-radio-group v-model="category" :mandatory="false">
                  <v-radio label="全日可能" value="workable" color="#6ac67b"></v-radio>
                  <v-radio label="時間指定1" value="rough_specified_time" color="#6ac67b"></v-radio>
                  <v-radio label="時間指定2" value="specified_time" color="#6ac67b"></v-radio>
                  <v-radio label="葬儀・通夜別指定" value="specify_another" color="#6ac67b"></v-radio>
                  <v-radio label="未定" value="undecided" color="#6ac67b"></v-radio>
                  <v-radio label="休み" value="not_possible" color="#6ac67b"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex v-if="category === 'rough_specified_time' || category === 'specified_time'" xs7>
                <v-dialog
                  ref="beginTimeDialog"
                  v-model="beginTimeModal"
                  :return-value.sync="beginTime"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="beginTime"
                      label="開始時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="beginTimeModal"
                    v-model="beginTime"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="beginTimeModal = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.beginTimeDialog.save(beginTime)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex v-if="category === 'rough_specified_time'" xs5 class="mt-4">
               ～ 時間指定なし
              </v-flex>
              <v-flex v-if="category === 'specified_time'" xs7>
                <v-dialog
                  ref="endTimeDialog"
                  v-model="endTimeModal"
                  :return-value.sync="endTime"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endTime"
                      label="終了時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="endTimeModal"
                    v-model="endTime"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="endTimeModal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.endTimeDialog.save(endTime)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-layout row wrap v-if="category === 'specify_another'">
                <v-flex xs2>
                  <v-subheader class="px-0">葬儀</v-subheader>
                </v-flex>
                <v-flex xs5>
                  <v-select
                    v-model="select"
                    :items="items"
                    item-text="state"
                    item-value="abbr"
                    single-line
                  ></v-select>
                </v-flex>
                <v-flex xs5 v-if="select === 'rough_specified_time'">
                  <v-dialog
                    ref="beginTimeDialog"
                    v-model="beginTimeModal"
                    :return-value.sync="beginTime"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="beginTime"
                        label="開始時間"
                        prepend-icon="access_time"
                        suffix="~"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="beginTimeModal"
                      v-model="beginTime"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="beginTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="success" @click="$refs.beginTimeDialog.save(beginTime)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
                <v-flex v-else xs5></v-flex>
                <v-flex xs2>
                  <v-subheader class="px-0">通夜</v-subheader>
                </v-flex>
                <v-flex xs5>
                  <v-select
                    v-model="select2"
                    :items="items2"
                    item-text="state"
                    item-value="abbr"
                    single-line
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="worktimePlanDialogPage = 1">戻る</v-btn>
            <v-btn color="success" flat @click="toConfirm">確認する</v-btn>
          </v-card-actions>
        </template>

        <template v-if="worktimePlanDialogPage === 3">

          <v-stepper vertical value="3" class="pa-0">
            <v-stepper-header>
              <v-stepper-step step="1" complete class="pa-1" ><span class="caption">日付選択</span></v-stepper-step>
              <v-stepper-step step="2" complete class="pa-1"><span class="caption">時間・種別指定</span></v-stepper-step>
              <v-stepper-step step="3" class="pa-1"><span class="caption">確認</span></v-stepper-step>
              <v-stepper-step step="4" class="pa-1"><span class="caption">完了</span></v-stepper-step>
            </v-stepper-header>
          </v-stepper>

          <v-card-text style="height: 70vh;">
            <v-card>
              <v-card-title>
                <h3 class="title mb-0">日付</h3>
              </v-card-title>
              <v-card-text class="subheading">
                <v-chip v-for="(day, idx) in dayList" :key="idx">{{ day }}</v-chip>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>
                <h3 class="title mb-0">時間・種別</h3>
              </v-card-title>
              <v-card-text v-if="category === 'workable'" class="subheading">
                <v-chip>全日可能</v-chip>
              </v-card-text>
              <v-card-text v-else-if="category === 'rough_specified_time'" class="subheading">
                <v-chip>{{ beginTime }} ～ 時間指定なし</v-chip>
              </v-card-text>
              <v-card-text v-else-if="category === 'specified_time'" class="subheading">
                <v-chip>{{ beginTime }}</v-chip>
                -
                <v-chip>{{ endTime }}</v-chip>
              </v-card-text>
              <v-card-text v-else-if="category === 'specify_another'" class="subheading">
                <v-chip>葬儀：{{ select === 'workable' ? '勤務可能' : select === 'rough_specified_time' ? beginTime + '～' : '休み' }}</v-chip><br>
                <v-chip>通夜：{{ select2 === 'workable' ? '勤務可能' : '休み' }}</v-chip>
              </v-card-text>
              <v-card-text v-else-if="category === 'undecided'" class="subheading">
                <v-chip>未定</v-chip>
              </v-card-text>
              <v-card-text v-else-if="category === 'not_possible'" class="subheading">
                <v-chip>休み</v-chip>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="worktimePlanDialogPage = 2">戻る</v-btn>
            <v-btn color="success" flat @click="workableSave">登録</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      bottom
      :timeout="2000"
      color="#6ac67b"
    >
      登録しました
    </v-snackbar>
    <v-snackbar
      v-model="snackbar1"
      bottom
      :timeout="2000"
      color="#6ac67b"
    >
      送信しました
    </v-snackbar>
  </v-layout>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import MyCalendar from '@/components/MyCalendar'
import '@/assets/vue2-simple-calendar.css'
import moment from 'moment-timezone'

export default {
  name: 'your-component',
  components: {
    MyCalendar
  },
  data: () => ({
    isCompleted: 0,
    snackbar: false,
    snackbar1: false,
    select: '',
    items: [
      { state: '勤務可能', abbr: 'workable' },
      { state: '時間指定', abbr: 'rough_specified_time' },
      { state: '休み', abbr: 'not_possible' }
    ],
    select2: '',
    items2: [
      { state: '勤務可能', abbr: 'workable' },
      { state: '休み', abbr: 'not_possible' }
    ],
    category: 'radio-1',
    countDisplay: '',
    calendarStart: moment().format('YYYY-MM'),
    selectedDefaultWorktime: {},
    selectedDefaultWorktime2: {},
    defaultWorktimeSelectMenu: false,
    defaultWorktimeSelectMenu2: false,
    eventItems: [],
    staffSelectMenu: false,
    dayAlertJudgment: true,
    staffAlertJudgment: true,
    beginTimeModal: false,
    beginTimeModalForEdit: false,
    endTimeModal: false,
    endTimeModalForEdit: false,
    selectedStaffIds: [],
    beginTime: '00:00',
    endTime: '00:00',
    editedWorktimeItem: {},
    editDialog: false,
    worktimePlanDialog: false,
    worktimePlanDialogPage: 1,
    workableShiftItems: [],
    highlightDays: {}
  }),
  watch: {
    worktimePlanDialog (value) {
      if (value === false) {
        this.staffSelectMenu = false
      }
      this.$emit('month-changed', { start: '2019-12-01', end: '2019-12-31' })
    },
    selectedStaffIds () {
      if (this.selectedStaffIds.length === 0) {
        this.staffAlertJudgment = true
      } else {
        this.staffAlertJudgment = false
      }
    },
    highlightDays: {
      handler: function (val) {
        if (Object.keys(this.highlightDays).length === 0) {
          this.dayAlertJudgment = true
        } else {
          this.dayAlertJudgment = false
        }
      },
      deep: true
    }
  },
  computed: {
    formatedCalendarStart () {
      let ym = this.calendarStart.split('-')
      return ym[0] + '年 ' + ym[1] + '月の勤務可能日を送信する'
    },
    eventDate () {
      if (this.eventItems.length === 0) {
        return ''
      } else {
        if (this.eventItems[0].workable_date) {
          return moment(this.eventItems[0].workable_date).format('YYYY/MM/DD(ddd)')
        }
        return ''
      }
    },
    dayObj () {
      let dayObj = {}
      this.workableShiftItems.forEach((item) => {
        if (!dayObj[moment(item.workable_date).format('YYYY-MM-DD')]) {
          dayObj[moment(item.workable_date).format('YYYY-MM-DD')] = []
        }
        dayObj[moment(item.workable_date).format('YYYY-MM-DD')].push(item)
      })

      Object.keys(dayObj).forEach((dayItem) => {
        dayObj[dayItem].sort(function (a, b) {
          if (a.begin_time < b.begin_time) return -1
          if (a.begin_time > b.begin_time) return 1
          return 0
        })
      })
      return dayObj
    },
    newEvents () {
      let obj = {}
      Object.keys(this.dayObj).forEach((day) => {
        let type = this.dayObj[day][0].category

        if (!obj[moment(day).format('YYYY-MM-DD')]) {
          obj[moment(day).format('YYYY-MM-DD')] = []
        }
        obj[moment(day).format('YYYY-MM-DD')].push({
          title: '',
          start: moment(day).toDate(),
          end: null,
          type: type
        })
      })
      return obj
    },
    events () {
      let items = []
      Object.keys(this.dayObj).forEach((day) => {
        items.push({
          title: this.dayObj[day].length + this.countDisplay,
          start: moment(day).toDate(),
          end: null,
          type: 'work'
        })
      })
      return items
    },
    dayList () {
      let list = []
      Object.keys(this.highlightDays).forEach((date) => {
        list.push(date)
      })
      return list
    }
  },
  mounted () {
    this.getWorkAbleShift()
  },
  methods: {
    putWorkableShift () {
      let ym = this.calendarStart.split('-')
      let confirmText = '作成された「 ' + ym[0] + '年 ' + ym[1] + '月 」の勤務可能日を管理者へ送信します。\nよろしいですか？'

      if (confirm(confirmText)) {
        axios.put('/api/send-workable-shift', { 'calendar_start': this.calendarStart })
          .then((res) => {
            this.getWorkAbleShift()
            this.snackbar1 = true
          })
          .catch((x) => {
            alert(x.response.data.error)
          })
      }
    },
    showWorktimePlan () {
      this.highlightDays = {}
      this.selectedStaffIds = []
      this.endTime = '00:00'
      this.beginTime = '00:00'
      this.worktimePlanDialog = true
    },
    monthChanged (event) {
      this.eventItems = []
      this.getWorkAbleShift()
    },
    editWorktime (item) {
      let tmpItem = cloneDeep(item)
      tmpItem.date = moment(this.editedWorktimeItem.worktime_date).format('YYYY-MM-DD')
      this.editedWorktimeItem = tmpItem
      this.editDialog = true
    },
    eventClicked (event) {
      let clickedDayObj = this.dayObj[moment(event.start).format('YYYY-MM-DD')]
      if (clickedDayObj) {
        this.eventItems = cloneDeep(clickedDayObj)
      }
    },
    dayClicked (date) {
      let days = cloneDeep(this.highlightDays)
      if (days[date]) {
        delete days[date]
      } else {
        days[date] = true
      }
      this.highlightDays = cloneDeep(days)
    },
    deleteWorktime (item) {
      let confirmText = item.staff_name + ' の\n' +
        item.begin_time + '-' + item.end_time + ' を削除します。'
      if (confirm(confirmText)) {
        axios.delete('/api/manager/worktime/' + item.id)
          .then(() => {
            this.getWorktime()
            setTimeout(() => {
              let tmpItems = []
              this.eventItems.forEach((eventItem) => {
                if (eventItem.id !== item.id) {
                  tmpItems.push(eventItem)
                }
                this.eventItems = tmpItems
              })
            }, 300)
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    workableSave () {
      let postDataObj = {}
      postDataObj['date_list'] = this.dayList
      postDataObj['begin_time'] = this.beginTime
      postDataObj['end_time'] = this.endTime
      postDataObj['category'] = this.category
      postDataObj['funeral_category'] = this.select
      postDataObj['wake_category'] = this.select2

      axios.post('/api/workable-shift', postDataObj)
        .then((res) => {
          this.worktimePlanDialog = false
          this.getWorkAbleShift()
          this.snackbar = true
          setTimeout(() => {
            this.beginTime = '00:00'
            this.endTime = '00:00'
            this.category = ''
            this.select = ''
            this.select2 = ''
            this.worktimePlanDialogPage = 1
            this.highlightDays = {}
          }, 300)
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    getWorkAbleShift () {
      axios.get('/api/workable-shift?ym=' + this.calendarStart)
        .then((res) => {
          this.isCompleted = 0
          this.workableShiftItems = res.data

          if (res.data.length > 0) {
            if (res.data[0].is_completed === 0) {
              this.isCompleted = 0
            } else {
              this.isCompleted = 1
            }
          }
        })
        .catch(() => {
          alert('取得エラー')
        })
    },
    toSelect () {
      this.worktimePlanDialogPage = 2
    },
    toConfirm () {
      this.worktimePlanDialogPage = 3
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
