<template>
  <v-layout style="background-color: white" column fill-height>
    <MobileHader />
    <v-flex xs12 class="pa-5">
      <v-card>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              label="email"
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="パスワード"
              type="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn large outline flat @click="login()" color="primary" style="width: 100%">ログイン</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios'
import MobileHader from '@/components/MobileHeader'

export default {
  name: 'MobileLogin',
  components: {
    MobileHader
  },
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    login () {
      let postData = {
        email: this.email,
        password: this.password
      }
      axios.post('/api/email-login', postData)
        .then((res) => {
          localStorage.clear()
          this.$store.commit('setStaffName', res.data.name)
          localStorage.setItem('staffName', res.data.name)
          localStorage.setItem('isRecordBodyTemperature', res.data.is_record_body_temperature)
          localStorage.setItem('qrValue', `${res.data.id}:${this.password}`)
          this.$router.push('/worktime-record')
        })
        .catch(() => {
          alert('認証エラー')
        })
    }
  }
}
</script>
