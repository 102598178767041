<template>
  <v-combobox
    v-model="comboValue"
    :items="processedItems"
    label="スタッフ"
    prepend-icon="perm_identity"
  ></v-combobox>
</template>

<script>
import axios from 'axios'

export default {
  props: [ 'value', 'groupId', 'isRetired' ],
  data: () => ({
    items: []
  }),
  computed: {
    comboValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('update:value', newValue)
      }
    },
    processedItems () {
      let items = [ '指定なし' ]
      this.items.forEach((item) => {
        if (this.groupId !== '' && this.groupId !== 0) {
          if (item.group_id === this.groupId) {
            items.push(item.name)
          }
        } else {
          if (this.isRetired === 3) {
            items.push(item.name)
          } else if (this.isRetired === 1) {
            if (item.is_retired === 1) {
              items.push(item.name)
            }
          } else {
            if (item.is_retired === 0) {
              items.push(item.name)
            }
          }
        }
      })
      return items
    }
  },
  mounted () {
    this.getStaff()
  },
  methods: {
    getStaff () {
      axios.get('/api/manager/staff')
        .then((res) => {
          this.items = res.data
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/manager')
          } else {
            alert('データエラー')
          }
        })
    }
  }
}
</script>
