<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" @click="newEdit">設定</v-btn>
    </template>
    <v-card>
      <v-dialog v-model="editDialog" persistent max-width="700px">
        <v-card>
          <v-card-title>
            <span class="headline">アラート設定</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-checkbox
                    v-model.number="is_attendance_none_detection_shift_system_do"
                    label="打刻なし検出を、シフト登録がされている場合だけにする"
                    :true-value="1"
                    :false-value="0"
                    color="success"
                  />
                </v-flex>
                <!-- <v-flex xs12>
                  <v-checkbox
                    color="success"
                  >
                    <template v-slot:label>
                      <div style="display:inline-flex">
                        <span class="mt-4">
                          残業時間が1ヶ月、
                        </span>
                        <v-text-field
                          style="width: 5em"
                        ></v-text-field>
                        <span class="mt-4">
                          時間超過した場合、通知する
                        </span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex> -->
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="editDialog = false">Cancel</v-btn>
            <v-btn color="success" flat @click="save">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'

export default {
  data: () => ({
    companyId: 0,
    is_attendance_none_detection_shift_system_do: 0,
    dialog: false,
    editDialog: false,
    editedItem: {}
  }),
  mounted () {
    this.getCompanyItems()
  },
  methods: {
    getCompanyItems () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyId = res.data['id']
          this.is_attendance_none_detection_shift_system_do = res.data['is_attendance_none_detection_shift_system_do']
          this.$emit('child-event', res.data['is_attendance_none_detection_shift_system_do'])
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompany]')
        })
    },
    save () {
      axios.put('/api/manager/company/',
        { is_attendance_none_detection_shift_system_do: this.is_attendance_none_detection_shift_system_do })
        .then((res) => {
          this.getCompanyItems()
          this.editDialog = false
        })
        .catch(() => {
          alert('追加登録エラー')
        })
    },
    newEdit () {
      this.editDialog = true
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.editDialog = true
    }
  }
}
</script>

<style>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line) .v-select__selections {
  padding-top: 0;
}
</style>
