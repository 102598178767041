<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
    <audio id="open-sound" preload="auto">
      <source src="/vue/sounds/open.mp3" type="audio/mp3" />
    </audio>
    <audio id="click-sound" preload="auto">
      <source src="/vue/sounds/click.mp3" type="audio/mp3" />
    </audio>
    <audio id="attendance-sound" preload="auto">
      <source src="/vue/sounds/attendance.mp3" type="audio/mp3" />
    </audio>
    <audio id="leave-sound" preload="auto">
      <source src="/vue/sounds/leave.mp3" type="audio/mp3" />
    </audio>
    <audio id="confirm-sound" preload="auto">
      <source src="/vue/sounds/confirm.mp3" type="audio/mp3" />
    </audio>
    <audio id="logout-sound" preload="auto">
      <source src="/vue/sounds/logout.mp3" type="audio/mp3" />
    </audio>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      //
    }
  }
}
</script>

<style>
.theme--light.v-table thead th {
  font-weight: bold;
  color: black;
  font-size: 15px;
}
table.v-table tbody td {
  font-size: 15px;
}
</style>
