<template>
  <v-dialog v-model="printDialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">
        <v-icon>print</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        印刷
      </v-card-title>

      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              lazy
              full-width
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  label="年月度"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                locale="ja-jp"
                :day-format="date => new Date(date).getDate()"
                type="month"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                <v-btn flat color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs12 class="subheading">
            日付範囲：{{ beginDate }}~{{ endDate }}
          </v-flex>
          <v-flex xs12 class="subheading">
            スタッフ：{{ this.staff === '' ? '選択なし' : this.staff }}
          </v-flex>
          <v-flex xs12 class="subheading mt-3">
            {{ date.substr(0, 4) }}年{{ date.substr(5, 2) }}月分として印刷します。
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-1" flat @click="printDialog = false">キャンセル</v-btn>
        <v-btn v-if="staff" color="success" flat @click="printTable">
          印刷する
        </v-btn>
        <v-btn v-else disabled flat>
          スタッフを選択してください。
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'AggregatePrintTable',
  props: [
    'beginDate',
    'endDate',
    'processedItems',
    'wdayHolidayItems',
    'publicHolidayWdayItems',
    'isActivateJapanageHoliday',
    'holidayItems',
    'wDayList',
    'changeNotationToHourTime',
    'staff'
  ],
  data: () => ({
    modal: false,
    printDialog: false,
    date: new Date().toISOString().substr(0, 7)
  }),
  mounted () {
    let loaded = false
    const scriptTags = Array.from(document.getElementsByTagName('script'))
    scriptTags.forEach((tag) => {
      if (/pdfmake/.test(tag.src)) {
        loaded = true
      }
    })
    if (loaded === false) {
      let pdfMakeScript = document.createElement('script')
      pdfMakeScript.src = '/pdfmake/pdfmake.min.js'
      document.body.appendChild(pdfMakeScript)
      let vfsFontsScript = document.createElement('script')
      vfsFontsScript.src = '/pdfmake/vfs_fonts.js'
      document.body.appendChild(vfsFontsScript)
    }
  },
  methods: {
    printTable () {
      this.printDialog = false
      const items = []
      let items2 = []
      let date = ''
      let date1 = ''
      let date2 = ''
      let targetDate = this.beginDate

      const totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.processedItems.forEach((dayItem) => {
        while (targetDate < dayItem.date) {
          let holidayText = ''
          this.wdayHolidayItems.forEach((item) => {
            if (moment(targetDate, 'YYYY-MM-DD').day() === item.wday) {
              holidayText = '法定休日'
            }
          })
          if (!holidayText) {
            this.publicHolidayWdayItems.forEach((item) => {
              if (moment(targetDate, 'YYYY-MM-DD').day() === item.wday) {
                holidayText = '公休日'
              }
            })
          }
          if (!holidayText && this.isActivateJapanageHoliday === 1) {
            this.holidayItems.forEach((item) => {
              if (targetDate === item.date) {
                holidayText = '公休日'
              }
            })
          }
          items2 = [
            { text: targetDate + '(' + moment(targetDate, 'YYYY-MM-DD').format('ddd') + ')', style: 'th', alignment: 'center' },
            { text: holidayText, style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' },
            { text: '', style: 'th', alignment: 'center' }
          ]
          items.push(items2)
          targetDate = moment(targetDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
        }

        date1 = dayItem.date

        dayItem.staffItems.forEach((staffItem) => {
          if (date1 === date2 && date2 !== '') {
            date = ''
          } else {
            date = { text: dayItem.date + '(' + this.wDayList[dayItem.wDay] + ')' }
          }

          let holidayWorkMinutes = 0

          if (staffItem.is_holiday === 1) {
            holidayWorkMinutes = staffItem.work_minute
          } else if (staffItem.work_minute === '') {
            holidayWorkMinutes = ''
          }

          if (staffItem.attendance.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
            items2 = [
              date,
              staffItem.name,
              staffItem.attendance,
              staffItem.leave,
              this.changeNotationToHourTime(staffItem.rest_minutes),
              this.changeNotationToHourTime(staffItem.work_minute),
              this.changeNotationToHourTime(staffItem.overtime_workday_minutes),
              this.changeNotationToHourTime(staffItem.overtime_workday_midnight_minutes),
              this.changeNotationToHourTime(staffItem.legal_overtime_working_minutes),
              this.changeNotationToHourTime(staffItem.non_statutory_working_minutes),
              this.changeNotationToHourTime(holidayWorkMinutes),
              this.changeNotationToHourTime(staffItem.overtime_holiday_workday_midnight_minutes)
            ]
            totals[3] += staffItem.rest_minutes
            totals[4] += staffItem.work_minute
            totals[5] += staffItem.overtime_workday_minutes
            totals[6] += staffItem.overtime_workday_midnight_minutes
            totals[7] += staffItem.legal_overtime_working_minutes
            totals[8] += staffItem.non_statutory_working_minutes
            totals[9] += holidayWorkMinutes
            totals[10] += staffItem.overtime_holiday_workday_midnight_minutes
          } else {
            items2 = [
              { text: date, style: 'th', alignment: 'center' },
              { text: staffItem.name, style: 'th', alignment: 'center' },
              { text: staffItem.attendance, style: 'th', alignment: 'center' },
              { text: staffItem.leave, style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.rest_minutes), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.work_minute), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.overtime_workday_minutes), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.overtime_workday_midnight_minutes), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.legal_overtime_working_minutes), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.non_statutory_working_minutes), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(holidayWorkMinutes), style: 'th', alignment: 'center' },
              { text: this.changeNotationToHourTime(staffItem.overtime_holiday_workday_midnight_minutes), style: 'th', alignment: 'center' }
            ]
          }

          if (this.staff && this.staff !== '指定なし') {
            items2.splice(1, 1)
          }

          date2 = dayItem.date

          items.push(items2)
          targetDate = moment(dayItem.date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
        })
      })

      while (targetDate <= this.endDate) {
        items2 = [
          { text: targetDate + '(' + moment(targetDate, 'YYYY-MM-DD').format('ddd') + ')', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' },
          { text: '', style: 'th', alignment: 'center' }
        ]
        items.push(items2)
        targetDate = moment(targetDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }

      const content = [
        {
          text: '出  勤  簿',
          fontSize: 14,
          alignment: 'center'
        },
        {
          columns: [
            {
              fontSize: 10,
              width: 'auto',
              alignment: 'left',
              table: {
                widths: ['auto', 'auto'],
                body: [
                  ['会社名', localStorage.getItem('companyName')],
                  ['従業員氏名', this.staff]
                ]
              }
            },
            {
              width: '*',
              text: ''
            },
            {
              width: 'auto',
              alignment: 'right',
              layout: 'noBorders',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    text: this.date.substr(0, 4) + '年' + this.date.substr(5, 2) + '月分',
                    fontSize: 12
                  }],
                  [{
                    text: '(' + this.beginDate + ' 〜 ' + this.endDate + ')',
                    fontSize: 10
                  }]
                ]
              }
            }
          ]
        },
        {
          style: 'marginTop10',
          layout: this.layoutTable(),
          fontSize: 7,
          alignment: 'center',
          table: {
            headerRows: 1,
            widths: [
              '*', '*', '*', '*', 40,
              'auto', 40, 32, 31, 31,
              35, 40
            ],
            body: [
              [
                { text: '日付', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '氏名', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '出勤', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '退勤', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '休憩', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '労働時間', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '時間外', style: 'th', alignment: 'center', margin: [0, 11] },
                { text: '  　深夜     　時間外', style: 'th', alignment: 'center', margin: [0, 5] },
                { text: '　　  法内       時間外', style: 'th', alignment: 'center', margin: [0, 5] },
                { text: '　　  法外       時間外', style: 'th', alignment: 'center', margin: [0, 5] },
                { text: '休日　　　　労働時間', style: 'th', alignment: 'center', margin: [0, 5] },
                { text: '休日　　　　深夜時間外', style: 'th', alignment: 'center', margin: [0, 5] }
              ],
              ...items,
              [
                ...totals.map((item, idx) => {
                  if (idx === 2) {
                    return {
                      text: '合計',
                      alignment: 'center'
                    }
                  }
                  if (item) {
                    return {
                      text: this.changeNotationToHourTime(item),
                      alignment: 'center'
                    }
                  }
                  return { text: '' }
                })
              ]
            ]
          }
        }
      ]
      if (this.staff && this.staff !== '指定なし') {
        content[2]['table']['body'][0].splice(1, 1)
        content[2]['table']['widths'].splice(1, 1)
      } else {
        content[1]['columns'][0]['table']['body'].splice(1, 1)
      }

      this.printPDF(content, 'landscape')
    },
    layoutTable () {
      return {
        hLineWidth: function () {
          return 0.5
        },
        vLineWidth: function () {
          return 0.5
        }
      }
    },
    printPDF: function (content, orientation) {
      window.pdfMake.fonts = {
        GenShin: {
          normal: 'GenShinGothic-P-Regular.ttf',
          bold: 'GenShinGothic-P-Regular.ttf',
          italics: 'GenShinGothic-P-Regular.ttf',
          bolditalics: 'GenShinGothic-P-Regular.ttf'
        }
      }
      window.pdfMake.createPdf({
        pageOrientation: orientation,
        pageMargins: [50, 10, 50, 10],
        content: content,
        styles: {
          th: {
            fillColor: '#eeeeee',
            alignment: 'left'
          },
          marginTop10: {
            margin: [0, 10, 0, 0]
          }
        },
        defaultStyle: {
          font: 'GenShin',
          fontSize: 8
        }
      }).open()
    }
  }
}
</script>
