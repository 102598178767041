<template>
  <div>
    <MyToolbar />
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="beginDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="beginDate2"
              label="日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="beginDate2"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="beginDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate2"
              label="日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate2"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="endDateMenu = false"
            ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs3 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId" />
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs2 class="pl-2 pr-2 pb-2">
        <v-btn color="success" @click="aggregate()">日付範囲で表示する</v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="ma-2" @click="$router.push('/manager/aggregate/closed')">
        日別データ締め処理
      </v-btn>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">一覧データ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="companyItem.id === 11 || companyItem.id === 12" color="primary" dark class="ma-2" @click="$router.push('/manager/aggregate/soul')">
          葬儀用一覧データ
        </v-btn>
        <AggregatePrintTable
          :beginDate="beginDate"
          :endDate="endDate"
          :processedItems="processedItems"
          :wdayHolidayItems="wdayHolidayItems"
          :publicHolidayWdayItems="publicHolidayWdayItems"
          :isActivateJapanageHoliday="isActivateJapanageHoliday"
          :holidayItems="holidayItems"
          :wDayList="wDayList"
          :changeNotationToHourTime="changeNotationToHourTime"
          :staff="staff"
        />
        <AggregateCsv :items="csvItems" />
      </v-toolbar>

      <div style="display: block">
        <v-layout row wrap class="timetable aggregate-header">
          <v-flex
            xs2
            class="pa-2 text-xs-center"
            style="font-weight: bold; padding-top: 1.2em !important"
          >
            日付
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <v-flex xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                氏名
              </v-flex>
              <v-flex v-if="companyItem.is_record_body_temperature === 0" xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                出勤
                <p v-if="projectItems.length > 0" style="margin-bottom: 0; font-size: 0.8em; color: #546E7A">作業</p>
              </v-flex>
              <v-flex v-else xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                出勤
                <p style="margin-bottom: 0; font-size: 0.8em; color: #546E7A">体温</p>
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                退勤
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th"></v-flex>
              <v-flex xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                休憩
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                労働時間
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th" style="padding-top: 1.2em !important">
                時間外
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th">
                深夜<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th">
                法内<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th">
                法外<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th">
                休日<br>労働時間
              </v-flex>
              <v-flex xs1 class="pa-2 aggregate-th">
                休日深夜<br>時間外
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
        </v-layout>
        <v-progress-linear v-if="isWait === 1" :indeterminate="true"></v-progress-linear>
        <v-alert v-else :value="isNoData === 1" color="error" icon="warning" outline>
          データがありません
        </v-alert>
      </div>
      <div class="tbody" style="font-size: 15px">
      <v-layout v-for="(item, processedItemsIdx) in processedItems" :key="item.date" row wrap>
        <v-flex
          xs2
          :key="`date-${processedItemsIdx}`"
          class="pl-2 pr-2 pb-2"
          style="text-align: center;"
          :style="{'background-color': bgg === processedItemsIdx ? '#eeeeee' : '', 'padding-top': companyItem.is_record_body_temperature !== 0 ? '1.2em' : '0.7em'}"
        >
          <span class="subheading">{{ item.date }}</span>
          <span class="caption">&lpar;{{ wDayList[item.wDay] }}&rpar;</span>
        </v-flex>
        <v-flex xs10>
          <v-layout row wrap>
            <template v-for="(staffItem, idx) in item.staffItems">

              <!-- 氏名 -->
              <v-flex
                xs1
                :key="`name-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ staffItem.name }}
              </v-flex>
              <!-- 氏名 ここまで -->

              <!-- 出勤時間 -->
              <v-flex
                xs1
                :key="`attendance-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  color: staffItem.attendance.match(/:/) === null ? 'red' : '',
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': companyItem.is_record_body_temperature !== 0 ? '0.7em' : '0.7em'
                }"
              >
                {{ staffItem.attendance }}<br>
                <span v-if="staffItem.project_id !== 0" style="font-size: 0.9em; color: #78909C">{{ projectNameObj[staffItem.project_id] }}</span>
                <span v-if="staffItem.body_temperature > '35' && staffItem.body_temperature < '37'" style="font-size: 0.9em; color: #78909C">{{ staffItem.body_temperature }}℃</span>
                <span v-if="staffItem.body_temperature > '37' && staffItem.body_temperature < '38'" style="font-size: 0.9em; color: #F9A825">{{ staffItem.body_temperature }}℃</span>
                <span v-if="staffItem.body_temperature >= '38'" style="font-size: 0.9em; color: red">{{ staffItem.body_temperature }}℃</span>
              </v-flex>
              <!-- 出勤時間 ここまで -->

              <!-- 退勤時間 -->
              <v-flex
                xs1
                v-if="staffItem.is_leaving_spanning_date === 1"
                :key="`leave-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ add24Hours(staffItem.leave) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`leave-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ staffItem.leave }}
              </v-flex>
              <!-- 退勤時間 ここまで -->

              <!-- 警告エリア -->
              <v-flex
                xs1
                :key="`leave-alert-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                <v-tooltip v-if="showAlert(staffItem)" top>
                  <template v-slot:activator="{ on }">
                    <v-icon color="accent" v-on="on">warning</v-icon>
                  </template>
                  <span
                    v-if="staffItem.tardiness_time !== 0"
                    v-html="`${staffItem.tardiness_time}分 遅刻<br>`"
                  />
                  <span
                    v-if="staffItem.early_withdrawal_time !== 0"
                    v-html="`${staffItem.early_withdrawal_time}分 早退<br>`"
                  />
                  <span v-if="staffItem.hasError" v-html="staffItem.errorText" />
                </v-tooltip>
              </v-flex>
              <!-- 警告エリア ここまで -->

              <!-- 休憩 -->
              <v-flex
                xs1
                :key="`restHours-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.rest_minutes) }}
              </v-flex>
              <!-- 休憩 ここまで -->

              <!-- 労働時間 -->
              <v-flex
                xs1
                v-if="staffItem.is_holiday === 0 || staffItem.is_base_transfer_leave === 1"
                :key="`workHours-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'}"
              >
                {{ changeNotationToHourTime(staffItem.work_minute) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`workHours-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >0</v-flex>
              <!-- 労働時間 ここまで -->

              <!-- 時間外 -->
              <v-flex
                xs1
                v-if="!staffItem.hasError"
                :key="`offHours100-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.overtime_workday_minutes) }}
                <template v-if="requestOvertimeWorkObj[item.date]">
                  <div v-if="requestOvertimeWorkObj[item.date][staffItem.staff_id]">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon color="orange" v-on="on">message</v-icon>
                      </template>
                      <div
                        v-for="(requestOvertimeItem, requestOvertimeIdx) in requestOvertimeWorkObj[item.date][staffItem.staff_id]"
                        :key="`request-overtime-${item.date}-${staffItem.staff_id}-${requestOvertimeIdx}`"
                      >
                        {{ requestTypeToText(requestOvertimeItem) }}
                        {{ requestTimeToText(requestOvertimeItem) }}
                        {{ requestStatusToText(requestOvertimeItem) }}
                      </div>
                    </v-tooltip>
                  </div>
                </template>
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`offHours100-else${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                0
                <template v-if="requestOvertimeWorkObj[item.date]">
                  <div v-if="requestOvertimeWorkObj[item.date][staffItem.staff_id]">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon color="orange" v-on="on">message</v-icon>
                      </template>
                      <div
                        v-for="(requestOvertimeItem, requestOvertimeIdx) in requestOvertimeWorkObj[item.date][staffItem.staff_id]"
                        :key="`request-overtime-${item.date}-${staffItem.staff_id}-${requestOvertimeIdx}`"
                      >
                        {{ requestTypeToText(requestOvertimeItem) }}
                        {{ requestTimeToText(requestOvertimeItem) }}
                        {{ requestStatusToText(requestOvertimeItem) }}
                      </div>
                    </v-tooltip>
                  </div>
                </template>
              </v-flex>
              <!-- 時間外 ここまで -->

              <!-- 深夜時間外 -->
              <v-flex
                xs1
                v-if="!staffItem.hasError"
                :key="`offHours2-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.overtime_workday_midnight_minutes) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`offHours2-else${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >0</v-flex>
              <!-- 深夜時間外 ここまで -->

              <!-- 法内時間外 -->
              <v-flex
                xs1
                v-if="!staffItem.hasError"
                :key="`offHours1-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.legal_overtime_working_minutes) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`offHours1-else${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >0</v-flex>
              <!-- 法内時間外 ここまで -->

              <!-- 法外時間外 -->
              <v-flex
                xs1
                v-if="!staffItem.hasError"
                :key="`offHours10-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.non_statutory_working_minutes) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`offHours10-else${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >0</v-flex>
              <!-- 法外時間外 ここまで -->

              <!-- 休日労働時間 -->
              <v-flex
                xs1
                v-if="!staffItem.hasError && staffItem.is_holiday === 1 && staffItem.is_base_transfer_leave === 0"
                :key="`offHours3-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.work_minute) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`offHours3-else${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ staffItem.work_minute === '' ? '' : '0' }}
              </v-flex>
              <!-- 休日労働時間 ここまで -->

              <!-- 休日深夜時間外 -->
              <v-flex
                xs1
                v-if="!staffItem.hasError"
                :key="`offHours4-${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >
                {{ changeNotationToHourTime(staffItem.overtime_holiday_workday_midnight_minutes) }}
              </v-flex>
              <v-flex
                xs1
                v-else
                :key="`offHours4-else${idx}`"
                class="pl-2 pr-2 pb-2"
                :class="staffItem.class_name"
                v-on:mouseover="mouseover(processedItemsIdx, idx)"
                v-on:mouseleave="mouseleave"
                :style="{
                  'background-color': bg === (String(processedItemsIdx) + String(idx)) ? '#eeeeee' : '',
                  'padding-top': (companyItem.is_record_body_temperature !== 0 || projectItems.length > 0) ? '1.2em' : '0.7em'
                }"
              >0</v-flex>
              <!-- 休日深夜時間外 ここまで -->

              <v-flex
                xs12
                v-if="idx !== item.staffItems.length - 1"
                :key="`staff-divider-${idx}`"
                :class="staffItem.class_name"
              >
                <v-divider/>
              </v-flex>

            </template>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="processedItemsIdx !== processedItems.length - 1 || processedItemsIdx === processedItems.length -1"
          xs12
        >
          <v-divider style="border-color:#78909C;border-top-width: 1px"/>
        </v-flex>
      </v-layout>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import itemTools from '@/mixins/item_tools.js'
import AggregatePrintTable from '@/components/AggregatePrintTable'
import AggregateCsv from '@/components/AggregateCsv'

export default {
  name: 'Aggregate',
  mixins: [itemTools],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo,
    AggregatePrintTable,
    AggregateCsv
  },
  data: () => ({
    holidayItems: [],
    publicHolidayWdayItems: [],
    wdayHolidayItems: [],
    isActivateJapanageHoliday: 0,
    japaneseHolidayItems: [],
    processedItems: [],
    isRetired: 0,
    retireItems: [
      { id: 0, name: '在職' },
      { id: 1, name: '退職' },
      { id: 3, name: '指定なし' }
    ],
    menu: false,
    isNoData: 0,
    isWait: 1,
    projectNameObj: {},
    projectItems: [],
    groupId: 0,
    group: '指定なし',
    bgg: '',
    bg: '',
    companyItem: {},
    leaveApplicationItems: [],
    leaveApplicationItems2: [],
    leaveApplicationDataItems: {
      paid_leave: '有給休暇',
      am_paid_leave: '午前有給休暇',
      pm_paid_leave: '午後有給休暇',
      time_paid_leave: '時間有給',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      general_absence: '一般欠勤',
      disease_scratch_absence: '病傷欠勤',
      closing: '休業',
      time_closing: '時間休業',
      public_holiday: '公休日'
    },
    csvEndDate: moment().add(1, 'months').date(15).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDate2: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDate2: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    headers: [
      { text: '氏名', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '出勤', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '退勤', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '休憩', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '労働時間', value: 'name', sortable: false, class: 'aggregate-header' }
    ],
    worktimeRecordItems: [],
    worktimeRecordItems2: [],
    requestOvertimeWorkObj: {}
  }),
  computed: {
    csvItems () {
      const result = [
        [
          '日付', '氏名', '出勤', '退勤', '休憩', '労働時間', '時間外',
          '深夜時間外', '法内時間外', '法外時間外', '休日労働時間', '休日深夜労働時間',
          '申告早出開始時刻', '申告早出終了時刻',
          '申告残業開始時刻', '申告残業終了時刻',
          '申告休日勤務開始時刻', '申告休日勤務終了時刻', '週間勤務日数', '公休日'
        ]
      ]
      const holidaySet = new Set()
      this.japaneseHolidayItems.forEach((item) => {
        holidaySet.add(item.date)
      })
      this.holidayItems.forEach((item) => {
        holidaySet.add(item.date)
      })

      this.processedItems.forEach((item) => {
        item.staffItems.forEach((staffItem) => {
          const leave = staffItem.is_leaving_spanning_date === 1
            ? this.add24Hours(staffItem.leave) : staffItem.leave

          const work = (
            staffItem.is_holiday === 0 || staffItem.is_base_transfer_leave === 1
          ) ? this.changeNotationToHourTime(staffItem.work_minute) : 0

          const outWork = staffItem.hasError
            ? 0 : this.changeNotationToHourTime(staffItem.overtime_workday_minutes)

          const nightOutWord = staffItem.hasError
            ? 0 : this.changeNotationToHourTime(
              staffItem.overtime_workday_midnight_minutes
            )

          const inRulesWork = staffItem.hasError
            ? 0 : this.changeNotationToHourTime(staffItem.legal_overtime_working_minutes)

          const outRulesWork = staffItem.hasError
            ? 0 : this.changeNotationToHourTime(staffItem.non_statutory_working_minutes)

          let holidayWork = 0
          if (
            !staffItem.hasError &&
            staffItem.is_holiday === 1 &&
            staffItem.is_base_transfer_leave === 0
          ) {
            holidayWork = this.changeNotationToHourTime(staffItem.work_minute)
          }

          const nightHolidayWork = staffItem.hasError
            ? 0 : this.changeNotationToHourTime(
              staffItem.overtime_holiday_workday_midnight_minutes
            )

          const requestOvertimeWork = {
            earlyAttendantBegin: '',
            earlyAttendantEnd: '',
            overtimeWorkBegin: '',
            overtimeWorkEnd: '',
            holidayWorkBegin: '',
            holidayWorkEnd: ''
          }
          if (this.requestOvertimeWorkObj[item.date]) {
            if (this.requestOvertimeWorkObj[item.date][staffItem.staff_id]) {
              const requestOvertimeWorkItems =
                this.requestOvertimeWorkObj[item.date][staffItem.staff_id]
              requestOvertimeWorkItems.forEach((requestOvertimeWorkItem) => {
                if (requestOvertimeWorkItem.request_status !== 1) {
                  return
                }
                if (requestOvertimeWorkItem.request_type === 'early_attendant') {
                  requestOvertimeWork.earlyAttendantBegin =
                    requestOvertimeWorkItem.request_time_start
                  requestOvertimeWork.earlyAttendantEnd =
                    requestOvertimeWorkItem.request_time_end
                  return
                }
                if (requestOvertimeWorkItem.request_type === 'overtime_work') {
                  requestOvertimeWork.overtimeWorkBegin =
                    requestOvertimeWorkItem.request_time_start
                  requestOvertimeWork.overtimeWorkEnd =
                    requestOvertimeWorkItem.request_time_end
                  return
                }
                if (requestOvertimeWorkItem.request_type === 'holiday_work') {
                  requestOvertimeWork.holidayWorkBegin =
                    requestOvertimeWorkItem.request_time_start
                  requestOvertimeWork.holidayWorkEnd =
                    requestOvertimeWorkItem.request_time_end
                  return
                }
                console.log(`request_type: error, [${requestOvertimeWorkItem.request_type}]`)
              })
            }
          }

          result.push([
            item.date,
            staffItem.name,
            staffItem.attendance,
            leave,
            this.changeNotationToHourTime(staffItem.rest_minutes),
            work,
            outWork,
            nightOutWord,
            inRulesWork,
            outRulesWork,
            holidayWork,
            nightHolidayWork,
            requestOvertimeWork.earlyAttendantBegin,
            requestOvertimeWork.earlyAttendantEnd,
            requestOvertimeWork.overtimeWorkBegin,
            requestOvertimeWork.overtimeWorkEnd,
            requestOvertimeWork.holidayWorkBegin,
            requestOvertimeWork.holidayWorkEnd,
            staffItem.countWeekyWork,
            holidaySet.has(item.date) ? '公休' : ''
          ])
        })
      })
      return result
    }
  },
  watch: {
    processedItems () {
      if (this.processedItems.length === 0) {
        this.isNoData = 1
      } else {
        this.isNoData = 0
      }
    },
    isRetired () {
      this.getWorktimeRecord()
      this.getProject()
    },
    staff () {
      this.getWorktimeRecord()
      this.getProject()
    }
  },
  mounted () {
    this.getCompanyActivateJapanageHoliday()
    this.getCompanyHoliday()
    this.getProject()
    this.getCompany()
    this.getWorktimeRecord()
  },
  methods: {
    requestTypeToText (item) {
      if (item.request_type === 'overtime_work') {
        return '残業'
      } else if (item.request_type === 'early_attendant') {
        return '早出'
      } else if (item.request_type === 'holiday_work') {
        return '休日出勤'
      }
      return '--'
    },
    requestTimeToText (item) {
      return `${item.request_time_start} - ${item.request_time_end}`
    },
    requestStatusToText (item) {
      if (item.request_status === 0) {
        return '未承認'
      } else if (item.request_status === 1) {
        return '承認'
      } else if (item.request_status === 2) {
        return '再申請'
      } else if (item.request_status === 3) {
        return '承認拒否'
      } else if (item.request_status === 4) {
        return '承認取り下げ'
      }
      return '--'
    },
    showAlert (staffItem) {
      if (staffItem.tardiness_time > 0 || staffItem.early_withdrawal_time > 0) {
        if (
          staffItem.is_early_withdrawal === 1 ||
          staffItem.is_tardiness === 1
        ) {
          return true
        }
      }
      if (staffItem.hasError) {
        return true
      }
      return false
    },
    getProcessedItems () {
      let pItems1 = []
      let pItems = []

      pItems1 = this.processItems(
        this.worktimeRecordItems, this.staff, this.companyItem, '', this.projectItems
      )

      const weeklyWorkObj = {}
      pItems1.forEach((item) => {
        item.staffItems.forEach((staffItem, idx) => {
          if (staffItem.work_minute !== '') {
            const nextStepDate = moment(item.date)
              .day(this.companyItem.starting_weekday_overtime_weekly + 7)
              .format('YYYY-MM-DD') // これで「次の週開始日」を設定
            if (weeklyWorkObj[staffItem.staff_id] === undefined) {
              weeklyWorkObj[staffItem.staff_id] = {
                count: 1,
                nextStepDate
              }
            } else {
              if (item.date >= weeklyWorkObj[staffItem.staff_id].nextStepDate) {
                weeklyWorkObj[staffItem.staff_id].count = 1
                weeklyWorkObj[staffItem.staff_id].nextStepDate = nextStepDate
              } else {
                weeklyWorkObj[staffItem.staff_id].count++
              }
            }
            item.staffItems[idx].countWeekyWork = weeklyWorkObj[staffItem.staff_id].count
          } else {
            item.staffItems[idx].countWeekyWork = 0
          }
        })
        if (item.date >= this.beginDate) {
          pItems.push(item)
        }
      })

      let items = []
      this.leaveApplicationItems2.forEach((item) => {
        if (this.staff === '' || this.staff === '指定なし' || this.staff === item.name) {
          let notExistsDate = true
          pItems.forEach((pItem) => {
            if (moment(item.application_date).format('YYYY-MM-DD') === pItem.date) {
              pItem.staffItems.unshift(item)
              notExistsDate = false
            }
          })
          if (notExistsDate && item.is_approved === 1) {
            items.push({
              date: moment(item.application_date).format('YYYY-MM-DD'),
              staffItems: [item],
              wDay: moment(item.application_date).format('d')
            })
          }
        }
      })
      pItems = pItems.concat(items).sort((a, b) => {
        if (a.date > b.date) {
          return 1
        } else {
          return -1
        }
      })

      let count = 0
      pItems.forEach((item) => {
        item.staffItems.forEach((item2) => {
          if (count % 2 === 0) {
            item2['class_name'] = 'whiteCursor'
          } else {
            item2['class_name'] = 'grayCursor'
          }
          count++
        })
      })

      this.processedItems = pItems
    },
    aggregate () {
      this.beginDate = this.beginDate2
      this.endDate = this.endDate2
      this.getWorktimeRecord()
    },
    changeNotationToHourTime (minute) {
      if (minute === 'leave-application') {
        return ''
      } else if (minute === '') {
        return ''
      } else if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    },
    getRequestOvertimeWork () {
      const uri = '/api/manager/request-overtimework-all?' +
        `begin_date=${this.beginDate}&end_date=${this.endDate}`
      axios.get(uri)
        .then((res) => {
          const obj = {}
          res.data.forEach((item) => {
            if (!obj[item.request_date]) {
              obj[item.request_date] = {}
            }
            if (!obj[item.request_date][item.staff_id]) {
              obj[item.request_date][item.staff_id] = []
            }
            obj[item.request_date][item.staff_id].push(item)
          })
          this.requestOvertimeWorkObj = obj
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.projectItems = []
          this.projectNameObj = {}

          this.projectItems = res.data

          res.data.forEach((item) => {
            if (!this.projectNameObj[item.id]) {
              this.projectNameObj[item.id] = item.name
            }
          })
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    mouseleave () {
      this.bg = ''
      this.bgg = ''
    },
    mouseover (processedItemsIdx, idx) {
      this.bgg = processedItemsIdx
      this.bg = String(processedItemsIdx) + String(idx)
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    add24Hours (leaveTime) {
      return Number(leaveTime.slice(0, 2)) + 24 + leaveTime.slice(2, 5)
    },
    convAtTime (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm')
    },
    convAtDate (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getLeaveApplication () {
      this.leaveApplicationItems2 = []
      axios.get('/api/manager/leave-application?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          let editedItem = {}
          let defaultEditedItem = {
            staff_id: 0,
            name: '',
            application_date: '',
            attendance: '',
            leave: '',
            is_holiday: 0,
            is_approved: 0,
            is_early_withdrawal: 0,
            early_withdrawal_time: '',
            rest_minutes: '',
            work_minute: '',
            record_at: '',
            is_retired: 0,
            legal_overtime_working_minutes: 'leave-application',
            non_statutory_working_minutes: 'leave-application',
            overtime_workday_minutes: 'leave-application',
            overtime_workday_midnight_minutes: 'leave-application',
            overtime_holiday_workday_midnight_minutes: 'leave-application',
            hasError: false,
            errorText: ''
          }
          res.data.forEach((item) => {
            editedItem = JSON.parse(JSON.stringify(defaultEditedItem))
            editedItem.attendance = this.leaveApplicationDataItems[item.category]

            if (item.time_paid_leave !== 0) {
              editedItem.attendance += '(' + item.time_paid_leave / 10 + ')'
            }
            if (item.time_closing !== 0) {
              editedItem.attendance += '(' + item.time_closing + 'h)'
            }

            editedItem.staff_id = item.staff_id
            editedItem.name = item.staff_name
            editedItem.application_date = item.application_date
            editedItem.category = item.category
            editedItem.is_approved = item.is_approved
            editedItem.is_retired = item.is_retired

            if (this.isRetired === 3) {
              this.leaveApplicationItems2.push(editedItem)
            }
            if (this.isRetired === 0 && item.is_retired === 0) {
              this.leaveApplicationItems2.push(editedItem)
            }
            if (this.isRetired === 1 && item.is_retired === 1) {
              this.leaveApplicationItems2.push(editedItem)
            }
          })
          this.getProcessedItems()
        })
        .catch((error) => {
          console.log(error)
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    getWorktimeRecord () {
      this.leaveApplicationItems2 = []
      this.worktimeRecordItems2 = []

      this.leaveApplicationItems = []
      this.worktimeRecordItems = []
      this.isWait = 1
      // 週40時間を超えた場合の時間外の計算のために、日付指定（開始）より6日前から取得するようにする
      // 表示は日付指定（開始）より表示されるように制御する
      let beginDate = moment(this.beginDate).subtract(6, 'd').format('YYYY-MM-DD')
      axios.get('/api/manager/worktime-record?begin_date=' + beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.worktimeRecordItems2.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.worktimeRecordItems2.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.worktimeRecordItems2.push(item)
              }
            }
          })
          this.worktimeRecordItems = this.worktimeRecordItems2
          this.getRequestOvertimeWork()
          this.getLeaveApplication()

          this.$nextTick(() => {
            this.isWait = 0
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    },
    getCompanyActivateJapanageHoliday () {
      axios.get('/api/manager/company-japanage-holiday')
        .then((res) => {
          this.isActivateJapanageHoliday = res.data[0]
          if (res.data[0] === 1) {
            axios.get('/api/manager/japanese-holiday')
              .then((res) => {
                this.japaneseHolidayItems = res.data
              })
              .catch((error) => {
                this.errorDecision(
                  error,
                  '/manager',
                  '取得エラー[getCompanyActivateJapanageHoliday-japaneseHoliday]'
                )
              })
          }
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompanyActivateJapanageHoliday]')
        })
    },
    getCompanyHoliday () {
      axios.get('/api/manager/company-holiday?type=date')
        .then((res) => {
          this.holidayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompanyHoliday]')
        })
      axios.get('/api/manager/company-holiday?type=wday')
        .then((res) => {
          this.wdayHolidayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompanyHoliday]')
        })
      axios.get('/api/manager/company-holiday?type=publicHolidayWday')
        .then((res) => {
          this.publicHolidayWdayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompanyHoliday]')
        })
    }
  }
}
</script>

<style>
.aggregate-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  font-size: 15px;
}
.aggregate-th {
  font-weight: bold;
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>

<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.tbody::-webkit-scrollbar {
    display:none;
}
.grayCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.whiteCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
