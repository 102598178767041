<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items2" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout>
      <v-flex xs4 class="pa-2">
        <v-menu
          v-model="dialog"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="yearMonth"
              label="年月選択"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="yearMonth"
            locale="ja-jp"
            type="month"
            :day-format="date => new Date(date).getDate()"
            @input="dialog = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-card class="mx-3 m-2">
       <v-progress-linear v-if="isWait === 1" :indeterminate="true"></v-progress-linear>
       <v-toolbar  flat color="white">
         <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">
           勤務可能日管理
           <span class="title">/{{ yearMonth.substr(0, 4) }}年{{ yearMonth.substr(5, 2) }}月度</span>
        </v-toolbar-title>
       </v-toolbar>
       <v-data-table
        :headers="headers"
        :items="items"
        hide-actions
      >
        <template v-slot:no-data>
          <v-alert v-if="isWait === 0" :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="staff-td">{{ props.item.name }}</td>
          <td class="staff-td justify-center layout px-0" :class="props.item.class_name">
            <v-chip
              v-if="props.item.is_completed === 0"
              dark
              color="red"
              class="mx-5 mt-2"
            >
              未提出
            </v-chip>
            <v-chip
              v-else
              dark
              color="green"
              class="mx-5 mt-2"
              @click="unlock(props.item)"
            >
              提出済み
            </v-chip>
          </td>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import MyToolbar from '@/components/MyToolbar'
import moment from 'moment-timezone'

export default {
  components: {
    MyToolbar
  },
  data: () => {
    return {
      staffItems: [],
      yearMonth: moment().add(1, 'months').tz('Asia/Tokyo').format('YYYY-MM'),
      items2: [
        {
          text: '休暇・申請',
          disabled: false,
          href: '/#/manager/leave-application'
        },
        {
          text: '勤務可能日管理',
          disabled: true
        }
      ],
      isWait: 0,
      items: [],
      headers: [
        {
          text: 'スタッフ名',
          align: 'center',
          sortable: false,
          value: 'name',
          width: 300,
          class: 'staff-header'
        },
        { text: '', sortable: false, value: 'name', class: 'staff-header' }
      ],
      dialog: false,
      editedItem: {}
    }
  },
  watch: {
    yearMonth () {
      this.getWorkAbleShift()
    }
  },
  mounted () {
    this.getStaff()
  },
  methods: {
    getStaff () {
      axios.get('/api/manager/staff')
        .then((res) => {
          this.staffItems = res.data
          this.getWorkAbleShift()
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaff]')
        })
    },
    unlock (item) {
      if (confirm('「提出済み」が「未提出」となり、スタッフ側で勤務可能日を再編集出来るようなります。\nよろしいですか？')) {
        axios.put('/api/manager/workable-shift', { 'year_month': this.yearMonth, 'staff_id': item.staff_id })
          .then((res) => {
            this.getWorkAbleShift()
          })
          .catch(() => {
            alert('更新エラー')
          })
      }
    },
    getWorkAbleShift () {
      this.items = []
      let staffItems = []

      axios.get('/api/manager/workable-shift?ym=' + this.yearMonth)
        .then((res) => {
          res.data.forEach((item) => {
            if (!staffItems.includes(item.staff_id)) {
              staffItems.push(item.staff_id)
              this.items.push({ 'name': item.name, 'is_completed': item.is_completed, 'staff_id': item.staff_id })
            }
          })
          this.staffItems.forEach((item) => {
            if (!staffItems.includes(item.id) && item.is_shift_application === 1) {
              staffItems.push(item.staff_id)
              this.items.unshift({ 'name': item.name, 'is_completed': 0, 'staff_id': item.id })
            }
          })
        })
        .catch(() => {
          alert('取得エラー')
        })
    }
  }
}
</script>

<style>
.staff-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.staff-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
