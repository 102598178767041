import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  theme: {
    primary: '#00809e', // #E53935#1976D2
    secondary: '#FFCDD2', // #FFCDD2
    accent: '#E57373' // #3F51B5
  },
  iconfont: 'md'
})
