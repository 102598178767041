<template>
  <div>
    <v-btn
      v-if="val && val.is_second_approved === 1"
      color='#90A4AE'
      dark
      class="pa-2"
      @click="holidayWorkDialog = true"
    >
      休暇申請処理済み
    </v-btn>
    <v-btn
      v-else-if="val && val.is_first_approved === 0"
      color='orange'
      dark
      class="pa-2"
      @click="holidayWorkDialog = true"
    >
      申請済み
    </v-btn>
    <v-btn
      v-else-if="val && val.is_working_report === 1"
      color='orange'
      dark
      class="pa-2"
      @click="holidayWorkDialog = true"
    >
      休日勤務報告済み（修正受付可）
    </v-btn>
    <v-btn
      v-else-if="val && val.is_first_approved === 1"
      color='orange'
      dark
      class="pa-2"
      @click="holidayWorkDialog = true"
    >
      休日勤務報告をする
    </v-btn>
    <v-btn
      v-else
      color="#6ac67b"
      dark
      class="mb-2"
      @click="holidayWorkDialog = true"
    >
      新規休日勤務申請
    </v-btn>
    <v-dialog v-model="holidayWorkDialog">
      <v-card>
        <v-card-title>
          <span class="headline">休日勤務申請</span>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  取得希望日
                </v-subheader>
              </v-flex>
              <v-flex xs3>
                <v-menu
                  :disabled="disabled"
                  ref="applicationDateMenu"
                  v-model="applicationDateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem2.application_date"
                      :disabled="disabled"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker2"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    v-model="editedItem2.application_date"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs5></v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  種別
                </v-subheader>
              </v-flex>
              <v-flex xs5>
                <v-select
                  v-model="editedItem2.category"
                  :disabled="disabled"
                  :items="categoryItems"
                  item-text="name"
                  item-value="category"
                ></v-select>
              </v-flex>
              <v-flex xs3></v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  予定時間
                </v-subheader>
              </v-flex>
              <v-flex xs4>
                <v-dialog
                  ref="beginTimeDialog"
                  v-model="beginTimeDialog"
                  :disabled="disabled"
                  :return-value.sync="editedItem2.plan_begin_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem2.plan_begin_time"
                      label="開始時間"
                      prepend-icon="access_time"
                      :disabled="disabled"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="beginTimeDialog"
                    v-model="editedItem2.plan_begin_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="beginTimeDialog = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.beginTimeDialog.save(editedItem2.plan_begin_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs4>
                <v-dialog
                  ref="endTimeDialog"
                  :disabled="disabled"
                  v-model="endTimeDialog"
                  :return-value.sync="editedItem2.plan_end_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem2.plan_end_time"
                      label="終了時間"
                      prepend-icon="access_time"
                      :disabled="disabled"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="endTimeDialog"
                    v-model="editedItem2.plan_end_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="endTimeDialog = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.endTimeDialog.save(editedItem2.plan_end_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  予定休憩時間
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-checkbox
                  :disabled="disabled"
                  v-model="inputPlanRestTime"
                  label="予定休憩時間を登録する"
                ></v-checkbox>
              </v-flex>
              <v-flex offset-xs4 xs4 v-if="inputPlanRestTime || val">
                <v-dialog
                  ref="planRestBeginTimeDialog"
                  v-model="planRestBeginTimeDialog"
                  :return-value.sync="editedItem2.plan_rest_begin_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="disabled"
                      v-model="editedItem2.plan_rest_begin_time"
                      label="休憩開始時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="planRestBeginTimeDialog"
                    v-model="editedItem2.plan_rest_begin_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="planRestBeginTimeDialog = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.planRestBeginTimeDialog.save(editedItem2.plan_rest_begin_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs4 v-if="inputPlanRestTime || val">
                <v-dialog
                  ref="planRestEndTimeDialog"
                  v-model="planRestEndTimeDialog"
                  :return-value.sync="editedItem2.plan_rest_end_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="disabled"
                      v-model="editedItem2.plan_rest_end_time"
                      label="休憩終了時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="planRestEndTimeDialog"
                    v-model="editedItem2.plan_rest_end_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="planRestEndTimeDialog = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.planRestEndTimeDialog.save(editedItem2.plan_rest_end_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  業務内容
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  :disabled="disabled"
                  v-model="editedItem2.plan_job_content"
                ></v-text-field>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  申請理由
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  :disabled="disabled"
                  v-model="editedItem2.application_reason"
                ></v-text-field>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  承認欄
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-chip color="primary" v-if="editedItem2.is_first_approved === 1" text-color="white">承認</v-chip>
                <v-chip color="primary" v-else text-color="white">未承認</v-chip>
              </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="editedItem2.is_first_approved === 0 && !val">
            <v-btn color="grey lighten-1" flat @click="holidayWorkDialog = false">キャンセル</v-btn>
            <v-btn color="success" flat @click="save">申請する</v-btn>
          </div>
          <div v-if="editedItem2.is_first_approved === 0 && val">
            <v-btn color="grey lighten-1" flat @click="holidayWorkDialog = false">キャンセル</v-btn>
            <v-btn
              color="accent"
              flat
              @click="deleteHolidayWorkingItem(editedItem2)"
            >
              申請を削除する
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>

      <v-card v-if="editedItem2.is_first_approved === 1">
        <v-card-title>
          <span class="headline">休日勤務報告</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  実績勤務報告日
                </v-subheader>
              </v-flex>
              <v-flex xs3>
                <v-menu
                  disabled
                  ref="applicationDateMenu3"
                  v-model="applicationDateMenu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      disabled
                      v-model="editedItem2.real_working_report_date"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker2"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    v-model="editedItem2.real_working_report_date"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs5></v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  実績時間
                </v-subheader>
              </v-flex>
              <v-flex xs4>
                <v-dialog
                  :disabled="editedItem2.is_second_approved === 1"
                  ref="beginTimeDialog2"
                  v-model="beginTimeDialog2"
                  :return-value.sync="editedItem2.work_begin_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="editedItem2.is_second_approved === 1"
                      v-model="editedItem2.work_begin_time"
                      label="開始時間"
                      prepend-icon="access_time"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="beginTimeDialog2"
                    v-model="editedItem2.work_begin_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="beginTimeDialog2 = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.beginTimeDialog2.save(editedItem2.work_begin_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs4>
                <v-dialog
                  :disabled="editedItem2.is_second_approved === 1"
                  ref="endTimeDialog2"
                  v-model="endTimeDialog2"
                  :return-value.sync="editedItem2.work_end_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="editedItem2.is_second_approved === 1"
                      v-model="editedItem2.work_end_time"
                      label="終了時間"
                      prepend-icon="access_time"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="endTimeDialog2"
                    v-model="editedItem2.work_end_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="endTimeDialog2 = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.endTimeDialog2.save(editedItem2.work_end_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  休憩時間
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-checkbox
                  :disabled="editedItem2.is_second_approved === 1"
                  v-model="inputPlanRestTime2"
                  label="休憩時間を登録する"
                ></v-checkbox>
              </v-flex>
              <v-flex offset-xs4 xs4 v-if="inputPlanRestTime2 || editedItem2.rest_begin_time || editedItem2.rest_end_time">
                <v-dialog
                  :disabled="editedItem2.is_second_approved === 1"
                  ref="planRestBeginTimeDialog2"
                  v-model="planRestBeginTimeDialog2"
                  :return-value.sync="editedItem2.rest_begin_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="editedItem2.is_second_approved === 1"
                      v-model="editedItem2.rest_begin_time"
                      label="休憩開始時間"
                      prepend-icon="access_time"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="planRestBeginTimeDialog2"
                    v-model="editedItem2.rest_begin_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="planRestBeginTimeDialog2 = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.planRestBeginTimeDialog2.save(editedItem2.rest_begin_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs4 v-if="inputPlanRestTime2 || editedItem2.rest_begin_time || editedItem2.rest_end_time">
                <v-dialog
                  :disabled="editedItem2.is_second_approved === 1"
                  ref="planRestEndTimeDialog2"
                  v-model="planRestEndTimeDialog2"
                  :return-value.sync="editedItem2.rest_end_time"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="editedItem2.is_second_approved === 1"
                      v-model="editedItem2.rest_end_time"
                      label="休憩終了時間"
                      prepend-icon="access_time"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="planRestEndTimeDialog2"
                    v-model="editedItem2.rest_end_time"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="planRestEndTimeDialog2 = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.planRestEndTimeDialog2.save(editedItem2.rest_end_time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  業務内容
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  :disabled="editedItem2.is_second_approved === 1"
                  v-model="editedItem2.real_job_content"
                ></v-text-field>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  予定と実績時間の乖離理由
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  :disabled="editedItem2.is_second_approved === 1"
                  v-model="editedItem2.difference_reason"
                ></v-text-field>
              </v-flex>

              <v-flex xs4>
                <v-subheader class="font-weight-bold">
                  承認欄
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-chip color="primary" v-if="editedItem2.is_second_approved === 1" text-color="white">承認</v-chip>
                <v-chip color="primary" v-else text-color="white">未承認</v-chip>
              </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" flat @click="holidayWorkDialog = false">キャンセル</v-btn>
          <v-btn   v-if="editedItem2.is_first_approved === 1 && editedItem2.is_second_approved === 0" color="success" flat @click="save">勤務報告する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'
import { required } from 'vuelidate/lib/validators'

let defaultEditedItem2 = {
  id: 0,
  staff_id: 0,
  category: null,
  application_date: moment().format('YYYY-MM-DD'),
  plan_working_report_date: moment().format('YYYY-MM-DD'),
  real_working_report_date: moment().format('YYYY-MM-DD'),
  plan_begin_time: '09:00',
  plan_end_time: '18:00',
  plan_rest_begin_time: '',
  plan_rest_end_time: '',
  work_begin_time: '09:00',
  work_end_time: '18:00',
  rest_begin_time: '',
  rest_end_time: '',
  plan_job_content: '',
  real_job_content: '申請内容と同じ',
  application_reason: '',
  difference_reason: '乖離なし',
  is_first_approved: 0,
  is_second_approved: 0,
  closed_id: 0
}

export default {
  props: ['val'],
  validations: {
    editedItem: {
      name: { required },
      email: {
        checkMailNotation () {
          if (this.editedItem.email !== undefined && this.editedItem.email !== '') {
            let email = (this.editedItem.email).match(/^[0-9a-z_./?-\\+]+@([0-9a-z-]+\.)+[0-9a-z-]+$/)
            if (!(email)) {
              return false
            }
          }
          return true
        }
      },
      tel: {
        checkTelNotation () {
          if (this.editedItem.tel !== undefined && this.editedItem.tel !== '') {
            let tel = (this.editedItem.tel).match(/^[0-9]{10,11}$/)
            if (!(tel)) {
              return false
            }
          }
          return true
        }
      },
      memo: {
        checkMemoNotation () {
          if (this.editedItem.memo !== undefined && this.editedItem.memo !== '') {
            if (this.editedItem.memo.length > 300) {
              return false
            }
          }
          return true
        }
      },
      address: {
        checkAddressNotation () {
          if (this.editedItem.address !== undefined && this.editedItem.address !== '') {
            if (this.editedItem.address.length > 100) {
              return false
            }
          }
          return true
        }
      },
      password: {
        requiredNewEditPassword () {
          if (this.editedItem.id === 0) {
            if (this.editedItem.password === '') {
              return false
            }
          }
          return true
        },
        minLengthNewEditPassword () {
          if (this.editedItem.id === 0) {
            if (this.editedItem.password.length < 8) {
              return false
            }
          }
          return true
        }
      },
      new_password: {
        requiredEditItemPassword () {
          if (this.editedItem.id !== 0) {
            if (this.changePassword) {
              if (this.editedItem.new_password === '') {
                return false
              }
            }
          }
          return true
        },
        minLengthEditItemPassword () {
          if (this.editedItem.id !== 0) {
            if (this.changePassword) {
              if (this.editedItem.new_password.length < 8) {
                return false
              }
            }
          }
          return true
        }
      }
    }
  },
  data: () => ({
    disabled: false,
    calendarStart: moment().format('YYYY'),
    categoryItems: [
      { category: 'legal_overtime_working', name: '法内残業' },
      { category: 'non_statutory_working', name: '法外残業' },
      { category: 'holiday_working', name: '休日勤務' }
    ],
    inputPlanRestTime: false,
    planRestBeginTimeDialog: false,
    planRestEndTimeDialog: false,
    beginTimeDialog: false,
    endTimeDialog: false,
    inputPlanRestTime2: false,
    planRestBeginTimeDialog2: false,
    planRestEndTimeDialog2: false,
    beginTimeDialog2: false,
    endTimeDialog2: false,
    applicationDateMenu2: false,
    applicationDateMenu3: false,
    holidayWorkDialog: false,
    editedItem2: cloneDeep(defaultEditedItem2)
  }),
  mounted () {
    this.determineDisabled()
    this.getHolidayWorkingItems()
    if (this.val) {
      this.editedItem2 = cloneDeep(this.val)
      this.editedItem2.application_date = moment(this.editedItem2.application_date).format('YYYY-MM-DD')
      this.editedItem2.plan_working_report_date = moment(this.editedItem2.plan_working_report_date).format('YYYY-MM-DD')
      this.editedItem2.real_working_report_date = moment(this.editedItem2.real_working_report_date).format('YYYY-MM-DD')
    }
  },
  methods: {
    determineDisabled () {
      if (this.val) {
        this.disabled = true
        return this.disabled
      }
      this.disabled = false
      return this.disabled
    },
    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    deleteHolidayWorkingItem (item) {
      let confirmText = '【 取得希望日 】' + this.date(item.application_date) +
        'の休日申請を削除しますか？'
      if (confirm(confirmText)) {
        axios.delete('/api/holiday-work-application/' + item.id)
          .then(() => {
            this.holidayWorkDialog = false
            this.getHolidayWorkingItems()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    getHolidayWorkingItems () {
      axios.get('/api/holiday-work-application?select_year=' + this.calendarStart)
        .then((res) => {
          this.$emit('getHolidayWorking', res.data)
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    save () {
      if (this.editedItem2.id === 0) {
        axios.post('/api/holiday-work-application', this.editedItem2)
          .then((res) => {
            this.editedItem2 = cloneDeep(defaultEditedItem2)
            this.holidayWorkDialog = false
            this.getHolidayWorkingItems()
          })
          .catch(() => {
            alert('登録エラー')
          })
      } else {
        axios.put('/api/holiday-work-application/' + this.editedItem2.id, this.editedItem2)
          .then((res) => {
            this.holidayWorkDialog = false
            this.getHolidayWorkingItems()
          })
          .catch(() => {
            alert('登録更新エラー')
          })
      }
    },
    newEdit () {
      this.editDialog = true
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.editDialog = true
    }
  }
}
</script>
