import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLimitManager: false,
    staffName: ''
  },
  mutations: {
    setStaffName (state, payload) {
      state.staffName = payload
    },
    setLimitManager (state, payload) {
      state.isLimitManager = payload
    }
  },
  getters: {
    isLimitManager: state => state.isLimitManager
  },
  actions: {
  }
})
