<template>
  <div>
    <MyToolbar />
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="beginDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="beginDate"
              label="日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="beginDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="beginDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="endDateMenu = false"
            ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId" />
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired"/>
      </v-flex>
      <v-spacer />
      <v-btn
        dark
        color="primary"
        class="mb-2"
        @click="$router.push('/manager/leave-application-management')"
      >有給取得管理</v-btn>
      <v-btn dark
        v-if="$store.state.isLimitManager !== true"
        color="primary"
        class="mb-2"
        @click="$router.push('/manager/grant-leave')"
      >
        休暇付与
      </v-btn>
    </v-layout>
    <v-layout>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="$router.push('/manager/holiday-work-application')"
      >休日出勤管理</v-btn>
      <v-btn
        v-if="companyItem.id === 11 || companyItem.id === 12"
        dark
        color="primary"
        class="mb-2"
        @click="$router.push('/manager/workable-shift')"
      >勤務可能日管理</v-btn>
    </v-layout>
    <v-card class="ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title class="toolbar">休暇申請</v-toolbar-title>
        <v-spacer />
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on }">
            <v-btn dark color="#6ac67b" v-on="on">追加</v-btn>
          </template>

          <v-card>
            <v-card-title class="headline" primary-title>休暇申請</v-card-title>

            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.staff_id"
                    label="スタッフ"
                    :items="processedStaffItems"
                    item-text="name"
                    item-value="id"
                    @change="getRemainigDays"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="applicationDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.application_date"
                        label="取得希望日"
                        readonly
                        v-on="on"
                        @change="getRemainigDays"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.application_date"
                      locale="ja-jp"
                      @input="applicationDateMenu = false"
                      @change="getRemainigDays"
                      :day-format="date => new Date(date).getDate()"
                      ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.category"
                    label="種別"
                    :items="categoryItems"
                    item-text="v"
                    item-value="k"
                  />
                  <span>{{ leaveDaysHint }}</span>
                </v-flex>
                <v-flex xs12 v-if="editedItem.category === 'time_paid_leave'">
                  <v-select
                    v-model="editedItem.time_paid_leave"
                    label="時間有給"
                    :items="timePaidLeaveItems"
                  />
                </v-flex>
                <v-flex xs12 v-if="editedItem.category === 'time_closing'">
                  <v-select
                    v-model="editedItem.time_closing"
                    label="時間休業"
                    suffix="h"
                    :items="timeClosingItems"
                  />
                </v-flex>
                <v-flex v-if="needsBasisDate" xs12>
                  <v-menu
                    v-model="basisDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.basis_date"
                        :label="basisLabel"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.basis_date"
                      locale="ja-jp"
                      :day-format="date => new Date(date).getDate()"
                      @input="basisDateMenu = false"
                      ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex v-if="isNeedApprove(editedItem.category)" xs12>
                  <v-checkbox
                    v-model.number="editedItem.is_approved"
                    label="承認済み"
                    :true-value="1"
                    :false-value="0"
                    color="success"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="editedItem.leave_reason"
                    maxlength="100"
                    box
                    label="休暇理由（任意）"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" flat @click="closeDialog">cancel</v-btn>
              <v-chip v-if="remainingDaysAlert" label>
                有給休暇の取得日数が上限に達しています。
              </v-chip>
              <v-btn v-else color="success" flat @click="save">ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table :headers="headers" :items="processedItems" hide-actions>
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="leave-application-td">{{ props.item.staff_name }}</td>
          <td class="leave-application-td">{{ convAtDate(props.item.application_date) }}</td>
          <td class="leave-application-td">
            {{ leaveApplicationDataItems[props.item.category] }}
            {{ props.item.time_paid_leave !== 0 ? props.item.time_paid_leave / 10 : '' }}
            {{ props.item.time_closing !== 0 ? '(' + props.item.time_closing + 'h' + ')' : '' }}
          </td>
          <td class="leave-application-td">
            {{ props.item.category === 'transfer_leave' ? convAtDate(props.item.basis_date) :
            props.item.category === 'alternative_leave' ? convAtDate(props.item.basis_date) : '' }}
          </td>
          <td class="leave-application-td">{{ props.item.leave_reason }}</td>
          <td class="text-xs-center leave-application-td">
            <v-icon v-if="props.item.is_approved === 1" color="primary">done</v-icon>
            <v-icon v-else color="success" @click="confirmApprove(props.item)">error_outline</v-icon>
          </td>
          <td class="text-xs-center leave-application-td">
            <v-icon
              color="accent"
              @click="confirmDelete(props.item)"
              :disabled="determineClosing(props.item)"
            >delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import itemTools from '@/mixins/item_tools.js'

let defaultEditedItem = {
  id: 0,
  staff_id: 0,
  application_date: moment().format('YYYY-MM-DD'),
  category: 'paid_leave',
  basis_date: moment().format('YYYY-MM-DD'),
  is_approved: 0,
  leave_reason: '',
  time_paid_leave: 0,
  time_closing: 0
}

export default {
  mixins: [itemTools],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  data: () => ({
    companyItem: {},
    isRetired: 0,
    retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }, { id: 3, name: '指定なし' }],
    timePaidLeaveItems: [],
    timeClosingItems: [],
    groupId: 0,
    groupNameObj: {},
    group: '指定なし',
    dialog: false,
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().tz('Asia/Tokyo').endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().tz('Asia/Tokyo').startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    remainingDays: 0,
    lastYearLeaveDays: 0,
    headers: [
      {
        text: '氏名',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '取得希望日',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '種別',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '振替または代替の基日',
        align: 'center',
        value: 'name',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '理由',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header',
        width: '200'
      },
      {
        text: '承認',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      }
    ],
    items: [],
    editedItem: cloneDeep(defaultEditedItem),
    applicationDateMenu: false,
    basisDateMenu: false,
    categoryItems: [
      { k: 'paid_leave', v: '有給休暇' },
      { k: 'am_paid_leave', v: '午前有給休暇' },
      { k: 'pm_paid_leave', v: '午後有給休暇' },
      { k: 'time_paid_leave', v: '有給休暇(時間休)' },
      { k: 'transfer_leave', v: '振替休暇' },
      { k: 'alternative_leave', v: '代替休暇' },
      { k: 'general_absence', v: '一般欠勤' },
      { k: 'disease_scratch_absence', v: '病傷欠勤' },
      { k: 'closing', v: '休業' },
      { k: 'time_closing', v: '時間休業' },
      { k: 'public_holiday', v: '公休日' }
    ],
    leaveApplicationDataItems: {
      paid_leave: '有給休暇',
      am_paid_leave: '午前有給休暇',
      pm_paid_leave: '午後有給休暇',
      time_paid_leave: '有給休暇(時間休)',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      general_absence: '一般欠勤',
      disease_scratch_absence: '病傷欠勤',
      closing: '休業',
      time_closing: '時間休業',
      public_holiday: '公休日'
    }
  }),
  computed: {
    remainingDaysAlert () {
      if (this.editedItem.staff_id) {
        if (this.remainingDays <= 0) {
          if (this.isPaidLeave) {
            return true
          }
        }
      }
      return false
    },
    needsBasisDate () {
      if (this.editedItem.category === 'transfer_leave') {
        return true
      }
      if (this.editedItem.category === 'alternative_leave') {
        return true
      }
      return false
    },
    basisLabel () {
      return this.editedItem.category === 'transfer_leave' ? '振替の基日' : '代替の基日'
    },
    processedItems () {
      let items = []
      this.items.forEach((item) => {
        if (this.group !== '指定なし') {
          if (this.group !== '') {
            if (!this.groupNameObj[item.group_id].includes(this.group)) {
              return
            }
          }
        }

        if ((this.staff) && (this.staff !== '指定なし')) {
          if (!item.staff_name.includes(this.staff)) {
            return
          }
        }
        if (this.isRetired === 0) {
          if (item.is_retired !== 0) {
            return
          }
        }
        if (this.isRetired === 1) {
          if (item.is_retired !== 1) {
            return
          }
        }
        items.push(item)
      })
      return items
    },
    processedStaffItems () {
      const result = []
      this.staffItems.forEach((item) => {
        if (this.isRetired === 0) {
          if (item.is_retired === 0) {
            result.push(item)
          }
        } else if (this.isRetired === 1) {
          if (item.is_retired === 1) {
            result.push(item)
          }
        } else {
          result.push(item)
        }
      })
      return result
    },
    isPaidLeave () {
      if (this.editedItem.category === 'paid_leave') {
        return true
      }
      if (this.editedItem.category === 'am_paid_leave') {
        return true
      }
      if (this.editedItem.category === 'pm_paid_leave') {
        return true
      }
      if (this.editedItem.category === 'time_paid_leave') {
        return true
      }
      return false
    },
    leaveDaysHint () {
      if (this.isPaidLeave) {
        return `残り有給休暇: ${this.remainingDays}日`
      }
      return ''
    }
  },
  mounted () {
    this.getCompany()
    this.getLeaveApplication()
    this.getStaff()
    this.getGroup()

    for (let i = 1; i <= 9; i++) {
      this.timePaidLeaveItems.push(i / 10)
    }
    for (let i = 1; i <= 8; i++) {
      this.timeClosingItems.push(i)
    }
  },
  watch: {
    beginDate () {
      this.getLeaveApplication()
    },
    endDate () {
      this.getLeaveApplication()
    }
  },
  methods: {
    isNeedApprove (category) {
      const approveItem = {
        paid_leave: '有給休暇',
        am_paid_leave: '午前有給休暇',
        pm_paid_leave: '午後有給休暇',
        time_paid_leave: '有給休暇(時間休)',
        transfer_leave: '振替休暇',
        alternative_leave: '代替休暇',
        general_absence: '一般欠勤'
      }
      return approveItem[category]
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    getGroup () {
      axios.get('/api/manager/group')
        .then((res) => {
          this.groupNameObj = {}

          res.data.forEach((item) => {
            if (!this.groupNameObj[item.id]) {
              this.groupNameObj[item.id] = {}
              this.groupNameObj[item.id] = item.name
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getGroup]')
        })
    },
    determineClosing (item) {
      if (item.closed_id !== 0) {
        return true
      } else {
        return false
      }
    },
    confirmApprove (item) {
      let category = {
        paid_leave: '有給休暇',
        am_paid_leave: '午前有給休暇',
        pm_paid_leave: '午後有給休暇',
        time_paid_leave: '有給休暇(時間休)',
        transfer_leave: '振替休暇',
        alternative_leave: '代替休暇',
        general_absence: '一般欠勤',
        disease_scratch_absence: '病傷欠勤'
      }
      let confirmText = item.staff_name + ' の ' +
      this.convAtDate(item.application_date) + ' ' + category[item.category] + ' を承認しますか？'
      if (confirm(confirmText)) {
        this.editedItem = item
        this.save()
      }
    },
    confirmDelete (item) {
      let category = {
        paid_leave: '有給休暇',
        am_paid_leave: '午前有給休暇',
        pm_paid_leave: '午後有給休暇',
        time_paid_leave: '有給休暇(時間休)',
        transfer_leave: '振替休暇',
        alternative_leave: '代替休暇',
        general_absence: '一般欠勤',
        disease_scratch_absence: '病傷欠勤',
        closing: '休業',
        time_closing: '時間休業',
        public_holiday: '公休日'
      }
      let confirmText = item.staff_name + ' の ' +
      this.convAtDate(item.application_date) + ' ' + category[item.category] + ' を削除しますか？'
      if (confirm(confirmText)) {
        this.deleteItem(item)
      }
    },
    convAtDate (date) {
      return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getLeaveApplication () {
      const uri = '/api/manager/leave-application?begin_date=' +
        `${this.beginDate}&end_date=${this.endDate}`
      axios.get(uri)
        .then((res) => {
          this.items = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    getStaff () {
      this.staffItems = []
      axios.get('/api/manager/staff')
        .then((res) => {
          this.staffItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getStaff]')
        })
    },
    save () {
      if (this.editedItem.id === 0) {
        if (!this.isNeedApprove(this.editedItem.category)) {
          this.editedItem.is_approved = 1
        }
        axios.post('/api/manager/leave-application', this.editedItem)
          .then((res) => {
            this.dialog = false
            this.editedItem = cloneDeep(defaultEditedItem)
            this.getLeaveApplication()
          })
          .catch(() => {
            alert('登録エラー')
          })
      } else {
        axios.put('/api/manager/leave-application/' + this.editedItem.id, this.editedItem)
          .then((res) => {
            this.dialog = false
            this.editedItem = cloneDeep(defaultEditedItem)
            this.getLeaveApplication()
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    deleteItem (item) {
      axios.delete('/api/manager/leave-application/' + item.id)
        .then(() => {
          this.getLeaveApplication()
        })
        .catch(() => {
          alert('削除エラー')
        })
    },
    closeDialog () {
      this.dialog = false
      this.editedItem = cloneDeep(defaultEditedItem)
    },
    getRemainigDays () {
      if (this.editedItem.staff_id === 0) {
        this.remainingDays = 0
        return
      }
      const uri = '/api/manager/remaining-paid-leave?staff_id=' +
        `${this.editedItem.staff_id}&date=${this.editedItem.application_date}`
      axios.get(uri)
        .then((res) => {
          this.remainingDays = res.data.days
        })
        .catch(() => {
          alert('有給休暇数取得エラー')
        })
    }
  }
}
</script>

<style>
.leave-application-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.leave-application-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.toolbar {
 font-weight: bold;
 border-left: solid 5px #456ab8;
 padding-left: 0.3em;
}
</style>
