<template>
  <div>
    <MyToolbar />
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="日付"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId"/>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired"/>
      </v-flex>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">日別データ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="#6ac67b" dark class="mb-2" @click="newEdit">追加</v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">勤怠データ編集</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-dialog
                      ref="editTimeDialog"
                      v-model="editedTimeModal"
                      :return-value.sync="editedItem.time"
                      persistent
                      lazy
                      full-width
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.time"
                          label="時刻"
                          prepend-icon="access_time"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="editedTimeModal"
                        v-model="editedItem.time"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="editedTimeModal = false">Cancel</v-btn>
                        <v-btn flat color="success" @click="$refs.editTimeDialog.save(editedItem.time)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="editedItem.staff_id"
                      :items="staffItems"
                      item-text="name"
                      item-value="id"
                      label="スタッフ"
                      :disabled="editedItem.id !== 0"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="editedItem.category"
                      :items="categoryForSelect"
                      item-text="value"
                      item-value="name"
                      label="種別"
                      :disabled="projectItems.length > 1 && editedItem.category === 'attendance'"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 v-if="isRecordBodyTemperature === '1'">
                    <v-text-field
                      v-model="editedItem.body_temperature"
                      placeholder="例：36.5"
                      suffix="℃"
                      label="体温"
                      @blur="$v.bodyTemperature.$touch()"
                      :error-messages="bodyTemperatureErrors"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-if="projectItems.length > 1"
                      v-model="editedItem.project_id"
                      :disabled="editedItem.category !== 'attendance'"
                      :items="projectItems"
                      item-text="name"
                      item-value="id"
                      label="作業"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      :disabled="editedItem.category !== 'leave'"
                      v-model.number="editedItem.is_leaving_spanning_date"
                      label="前日から連続した勤務で退勤を登録する"
                      :true-value="1"
                      :false-value="0"
                      color="success"
                    />
                </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="dialog = false">Cancel</v-btn>
              <v-btn v-if="projectItems.length > 1 && editedItem.category === 'attendance'" color="success" flat @click="shiftConfirm">OK</v-btn>
              <v-btn v-else color="success" flat @click="save">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-progress-linear v-if="isWait === 1" :indeterminate="true"></v-progress-linear>
      <v-data-table
        hide-actions
        :headers="headers"
        :items="processedItems"
        class="elevation-1"
        :rows-per-page-items="[50, 100, 300]"
      >
        <template v-slot:no-data>
          <v-alert v-if="isWait === 0" :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="" >{{ props.item.name }}</td>
          <td v-if="company.is_activate_gps" class="text-xs-center">
            <span
              v-if="props.item.latitude !== '' &&
                props.item.category !== 'paid_leave' &&
                props.item.category !== 'am_paid_leave' &&
                props.item.category !== 'general_absence' &&
                props.item.category !== 'time_paid_leave' &&
                props.item.category !== 'pm_paid_leave' &&
                props.item.category !== 'closing' &&
                props.item.category !== 'time_closing'"
            >
              <v-icon @click="showMap(props.item.latitude, props.item.longitude)" color="accent">room</v-icon>
            </span>
          </td>
          <td
            class="text-xs-center"
            :style="{color: props.item.category === 'attendance' ? '#00809e' :
            props.item.category === 'leave' ? '#ff7f61' :
            props.item.category === 'outOfOffice' ? '#6ac67b' :
            props.item.category === 'returnToOffice' ? '#6ac67b' :
            props.item.category === 'paid_leave' ? 'red' :
            props.item.category === 'am_paid_leave' ? 'red' :
            props.item.category === 'pm_paid_leave' ? 'red' :
            props.item.category === 'time_paid_leave' ? 'red' :
            props.item.category === 'transfer_leave' ? 'red' :
            props.item.category === 'disease_scratch_absence' ? 'red' :
            props.item.category === 'general_absence' ? 'red' :
            props.item.category === 'closing' ? 'red' :
            props.item.category === 'time_closing' ? 'red' :
            props.item.category === 'alternative_leave' ? 'red' : ''}"
          >{{ category[props.item.category] }}
           <span
             v-if="props.item.time_paid_leave && props.item.time_paid_leave !== 0"
             style="color: red"
           >
             ({{ props.item.time_paid_leave / 10 }})
           </span>
           <span
             v-if="props.item.time_closing && props.item.time_closing !== 0"
             style="color: red"
           >
             ({{ props.item.time_closing }}h)
           </span>
           <br>
           <span
             v-if="props.item.project_id !== 0"
             style="color: #78909C"
           >
             {{ projectNameObj[props.item.project_id] }}
           </span>
           <span
             v-if="props.item.body_temperature > '35' &&
             props.item.body_temperature < '37' &&
             props.item.category === 'attendance'"
             style="color: #78909C"
           >{{ props.item.body_temperature }}℃</span>
           <span
             v-if="props.item.body_temperature > '37' &&
             props.item.body_temperature < '38' &&
             props.item.category === 'attendance'"
             style="color: #F9A825"
             >{{ props.item.body_temperature }}℃</span>
           <span
             v-if="props.item.body_temperature >= '38' &&
             props.item.category === 'attendance'"
             style="color: red">{{ props.item.body_temperature }}℃</span>
          </td>
          <td class="text-xs-center">
            <v-tooltip v-if="props.item.is_tardiness === 1" top>
              <template v-slot:activator="{ on }">
                <v-icon color="accent" v-on="on">warning</v-icon>
              </template>
              <span v-html="minutesConvert(props.item.tardiness_time) + '分 遅刻'"></span>
            </v-tooltip>
            <v-tooltip v-if="props.item.is_early_withdrawal === 1" top>
              <template v-slot:activator="{ on }">
                <v-icon color="accent" v-on="on">warning</v-icon>
              </template>
              <span v-html="minutesConvert(props.item.early_withdrawal_time) + '分 早退'"></span>
            </v-tooltip>
          </td>
          <td v-if="props.item.is_leaving_spanning_date === 1" class="text-xs-center" >
            {{ add24Hours(convAtTime(props.item.without_change_record_at)) }}</td>
          <td v-else-if="props.item.record_at === ''" class="text-xs-center" ></td>
          <td v-else-if="props.item.without_change_record_at === null" class="text-xs-center" >
            {{ convAtTime(props.item.without_change_record_at) }}</td>
          <td v-else class="text-xs-center" >
            {{ convAtTime(props.item.without_change_record_at) }}</td>
          <td class="text-xs-center" >{{ props.item.input_type === 'manager' ? 'マネージャー' : props.item.input_type === 'normal' ? '本人' : ''}}</td>
          <td class="text-xs-center" >
            <v-tooltip v-if="props.item.hasError" top>
              <template v-slot:activator="{ on }">
                <v-icon color="accent" v-on="on">error_outline</v-icon>
              </template>
              <span v-html="props.item.errorText"></span>
            </v-tooltip>
          </td>
          <td style="text-align: center">
            <span
              v-if="
                props.item.category === 'attendance' ||
                props.item.category === 'leave' ||
                props.item.category === 'rest' ||
                props.item.category === 'restart' ||
                props.item.category === 'amRest' ||
                props.item.category === 'amRestart' ||
                props.item.category === 'pmRest' ||
                props.item.category === 'pmRestart' ||
                props.item.category === 'outOfOffice' ||
                props.item.category === 'returnToOffice'"
            >
              <v-icon
                small
                color="primary"
                class="mr-2"
                @click="editItem(props.item)"
              >
                edit
              </v-icon>
              <v-icon
                small
                color="accent"
                @click="deleteItem(props.item)"
              >
                delete
              </v-icon>
            </span>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-xs-center">
      <v-dialog
        v-model="alertDialog"
        width="800"
      >
        <v-card>
          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            <v-icon color="yellow darken-3 " class="mr-2">warning</v-icon>登録日に「{{ staffName }}」の休憩打刻がありません。
          </v-card-title>

          <v-card-text>
            休憩登録が必要な場合は、続けて休憩の登録が必要です。
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              flat
              @click="alertDialog = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import cloneDeep from 'lodash/cloneDeep'
import itemTools from '@/mixins/item_tools.js'
import { validationMixin } from 'vuelidate'

export default {
  mixins: [itemTools, validationMixin],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  validations: {
    bodyTemperature: {
      checkNotation () {
        if (
          this.editedItem.body_temperature !== undefined &&
          this.editedItem.body_temperature !== '' &&
          this.editedItem.category === 'attendance'
        ) {
          let bodyTemperature = (this.editedItem.body_temperature).match(/^[3][5-9]+.+[0-9]+$/)
          if (!(bodyTemperature)) {
            return false
          }
        }
        return true
      },
      checkNotation2 () {
        if (
          (
            this.editedItem.body_temperature === undefined ||
            this.editedItem.body_temperature === ''
          ) &&
          this.editedItem.category === 'attendance'
        ) {
          return false
        }
        return true
      }
    }
  },
  data: () => ({
    isRetired: 0,
    retireItems: [
      { id: 0, name: '在職' },
      { id: 1, name: '退職' },
      { id: 3, name: '指定なし' }
    ],
    isWait: 1,
    projectNameObj: {},
    staffName: '',
    alertDialog: false,
    groupId: 0,
    groupNameObj: {},
    group: '指定なし',
    isRecordBodyTemperature: 0,
    latitude: '',
    longitude: '',
    mapDialog: false,
    editedTimeModal: false,
    dafaltItem: {
      id: 0,
      staff_id: 0,
      category: '',
      input_type: 'manager',
      project_id: 0,
      record_at: moment().tz('Asia/Tokyo').format(),
      time: moment().tz('Asia/Tokyo').format('HH:mm')
    },
    showTimePicker: false,
    category: {
      attendance: '出勤',
      leave: '退勤',
      rest: '休入',
      restart: '休戻',
      amRest: '午前休入',
      amRestart: '午前休戻',
      pmRest: '午後休入',
      pmRestart: '午後休戻',
      outOfOffice: '外出',
      returnToOffice: '外戻',
      paid_leave: '有休休暇',
      am_paid_leave: '午前有休休暇',
      pm_paid_leave: '午後有休休暇',
      time_paid_leave: '時間有給',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      general_absence: '一般欠勤',
      disease_scratch_absence: '病傷欠勤',
      closing: '休業',
      time_closing: '時間休業'
    },
    editedItem: {},
    worktimeRecordItems: [],
    dialog: false,
    date: moment().format('YYYY-MM-DD'),
    dateMenu: false,
    staff: '指定なし',
    staffItems: [],
    leaveApplicationItems: [],
    projectItems: [],
    company: {
      is_activate_gps: 0
    }
  }),
  computed: {
    headers () {
      let result = [
        {
          text: 'スタッフ',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        }
      ]
      if (this.company.is_activate_gps) {
        result.push(
          {
            text: 'MAP',
            align: 'center',
            sortable: false,
            value: 'name',
            class: 'worktime-record-header'
          }
        )
      }
      return [ ...result,
        {
          text: '種別',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '時間',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '登録者',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        }
      ]
    },
    bodyTemperatureErrors () {
      const errors = []
      if (!this.$v.bodyTemperature.$dirty) return errors
      !this.$v.bodyTemperature.checkNotation && errors.push('形式が正しくありません')
      !this.$v.bodyTemperature.checkNotation2 && errors.push('入力は必須です')
      return errors
    },
    processedItems () {
      let items = []
      let staffObj = {}

      this.worktimeRecordItems.forEach((item) => {
        if (!staffObj[item.staff_id]) {
          staffObj[item.staff_id] = []
        }
        staffObj[item.staff_id].push(item)
      })
      this.worktimeRecordItems.forEach((item) => {
        if (this.group !== '指定なし') {
          if (this.group !== '') {
            if (item.group_id !== this.groupId) {
              return
            }
          }
        }
        if (this.staff !== '指定なし') {
          if (this.staff !== '') {
            if (item.name !== this.staff) {
              return
            }
          }
        }
        item.time = moment(item.record_at).tz('Asia/Tokyo').format('HH:mm')
        item.hasError = false
        let errorItems = []
        let hasAttendance = false
        let hasLeave = false
        let countOutOfOffice = 0
        let countReturnToOffice = 0
        let countRest = 0
        let countRestart = 0
        let countAmRest = 0
        let countAmRestart = 0
        let countPmRest = 0
        let countPmRestart = 0

        staffObj[item.staff_id].forEach((staffItem) => {
          if (staffItem.category === 'attendance') {
            hasAttendance = true
          } else if (staffItem.category === 'leave') {
            hasLeave = true
          } else if (staffItem.category === 'outOfOffice') {
            countOutOfOffice++
          } else if (staffItem.category === 'returnToOffice') {
            countReturnToOffice++
          } else if (staffItem.category === 'rest') {
            countRest++
          } else if (staffItem.category === 'restart') {
            countRestart++
          } else if (staffItem.category === 'amRest') {
            countAmRest++
          } else if (staffItem.category === 'amRestart') {
            countAmRestart++
          } else if (staffItem.category === 'pmRest') {
            countPmRest++
          } else if (staffItem.category === 'pmRestart') {
            countPmRestart++
          }
        })
        if (hasAttendance === false) {
          item.hasError = true
          errorItems.push('出勤の登録がありません。')
        }
        if (hasLeave === false) {
          item.hasError = true
          errorItems.push('退勤の登録がありません。')
        }
        if (countOutOfOffice > countReturnToOffice) {
          item.hasError = true
          errorItems.push('外戻の登録がありません。')
        } else if (countOutOfOffice < countReturnToOffice) {
          item.hasError = true
          errorItems.push('外出の登録がありません。')
        }
        if (countRest > countRestart) {
          item.hasError = true
          errorItems.push('休戻の登録がありません。')
        } else if (countRest < countRestart) {
          item.hasError = true
          errorItems.push('休入の登録がありません。')
        }
        if (countAmRest > countAmRestart) {
          item.hasError = true
          errorItems.push('午前休戻の登録がありません。')
        } else if (countAmRest < countAmRestart) {
          item.hasError = true
          errorItems.push('午後休入の登録がありません。')
        }
        if (countPmRest > countPmRestart) {
          item.hasError = true
          errorItems.push('午後休戻の登録がありません。')
        } else if (countPmRest < countPmRestart) {
          item.hasError = true
          errorItems.push('午後休入の登録がありません。')
        }
        item.errorText = errorItems.join('<br>')
        items.push(item)
      })
      this.leaveApplicationItems.forEach((item) => {
        if (item.is_approved === 1) {
          if ((this.group === '指定なし' || this.group === '') && (this.staff === '指定なし' || this.staff === '')) {
            items.unshift(item)
          } else if (this.group !== '指定なし' && this.group !== '') {
            if (this.staff !== '指定なし' && this.staff !== '') {
              if (this.groupNameObj[item.group_id].includes(this.group) && item.name.includes(this.staff)) {
                items.unshift(item)
              }
            } else {
              if (this.groupNameObj[item.group_id].includes(this.group)) {
                items.unshift(item)
              }
            }
          } else if (this.staff !== '指定なし' && this.staff !== '') {
            if (item.name.includes(this.staff)) {
              items.unshift(item)
            }
          }
        }
      })
      return items
    },
    categoryForSelect () {
      let items = []
      Object.keys(this.category).forEach((key) => {
        if (key === 'attendance' || key === 'leave' || key === 'rest' || key === 'restart') {
          items.push({ name: key, value: this.category[key] })
        }
      })
      return items
    }
  },
  mounted () {
    this.isRecordBodyTemperature = localStorage.getItem('isRecordBodyTemperature')
    /*
    if (this.isRecordBodyTemperature === '1') {
      this.headers[2].text = '<div>種別<br/><span style="font-size: 0.8em; color: #546E7A">体温</span></div>'
    }
    */

    if (this.$route.params.specifiedDate) {
      this.date = this.$route.params.specifiedDate
    }
    this.getCompany().then(() => {
      this.getGroup()
      this.getWorktimeRecord()
      this.getStaff()
      this.getProject()
      this.getLeaveApplication()
    })
  },
  watch: {
    date () {
      this.isWait = 1
      this.getWorktimeRecord()
      this.getLeaveApplication()
      this.getProject()
    },
    // 'editedItem.category' (newCategory) {
    //   if (newCategory !== 'atendance') {
    //     this.editedItem.project_id = 0
    //   }
    // },
    staff () {
      this.getWorktimeRecord()
      this.getLeaveApplication()
      this.getProject()
    },
    group () {
      this.getWorktimeRecord()
      this.getLeaveApplication()
      this.getProject()
    },
    isRetired () {
      this.getWorktimeRecord()
      this.getLeaveApplication()
      this.getProject()
    }
  },
  methods: {
    shiftConfirm () {
      if (this.editedItem.project_id === 0) {
        if (confirm('作業を選択してください。')) {
        }
      } else {
        let timeHM = this.editedItem.time.split(':')
        let newRecordAt = moment(this.editedItem.record_at)
        newRecordAt = newRecordAt.hours(Number(timeHM[0])).minutes(Number(timeHM[1]))
        newRecordAt = newRecordAt.utc().format()

        axios.get('/api/manager/shift-confirm?category=' + this.editedItem.category + '&staff_id=' + this.editedItem.staff_id + '&record_at=' + newRecordAt + '&project_id=' + this.editedItem.project_id)
          .then((res) => {
            if (res.data.decision) {
              this.save()
            } else {
              if (confirm(res.data.message + '\nそれでも出勤登録しますか？')) {
                this.save()
              }
            }
          })
          .catch((error) => {
            this.errorDecision(error, '/manager', 'データ取得エラー[shiftConfirm]')
          })
      }
    },
    getCompany () {
      return new Promise((resolve, reject) => {
        axios.get('/api/manager/company')
          .then((res) => {
            this.company = res.data
            resolve()
          })
          .catch((error) => {
            this.errorDecision(error, '/manager', 'データエラー[getCompany]')
            reject(new Error('データエラー[getCompany]'))
          })
      })
    },
    getGroup () {
      axios.get('/api/manager/group')
        .then((res) => {
          this.groupNameObj = {}

          res.data.forEach((item) => {
            if (!this.groupNameObj[item.id]) {
              this.groupNameObj[item.id] = {}
              this.groupNameObj[item.id] = item.name
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getGroup]')
        })
    },
    determineClosing (item) {
      if (item.closed_id !== 0) {
        return true
      } else {
        return false
      }
    },
    showMap (latitude, longitude) {
      window.open('https://www.google.co.jp/maps?q=' + latitude + ',' + longitude, 'newwindow', 'width=1000,height=800')
    },
    add24Hours (leaveTime) {
      return Number(leaveTime.slice(0, 2)) + 24 + leaveTime.slice(2, 5)
    },
    newEdit () {
      this.dialog = true
      this.editedItem = {
        id: 0,
        staff_id: 0,
        category: '',
        input_type: 'manager',
        is_leaving_spanning_date: 0,
        project_id: 0,
        body_temperature: '',
        record_at: moment(this.date).tz('Asia/Tokyo').format(),
        time: moment().tz('Asia/Tokyo').format('HH:mm')
      }
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.dialog = true
    },
    convAtTime (datetime) {
      if (datetime === '') {
        return ''
      }
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm')
    },
    minutesConvert (minutes) {
      if (Math.floor(minutes) === 0) {
        return 1
      } else {
        return Math.floor(minutes)
      }
    },
    getStaff () {
      this.staffItems = []
      axios.get('/api/manager/staff')
        .then((res) => {
          res.data.forEach((item) => {
            if (item.is_retired === 0) {
              this.staffItems.push(item)
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaff]')
        })
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.projectItems = [{ id: 0, name: '--' }, ...res.data]
          this.projectNameObj = {}

          res.data.forEach((item) => {
            if (!this.projectNameObj[item.id]) {
              this.projectNameObj[item.id] = item.name
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getProject]')
        })
    },
    getWorktimeRecord () {
      let items1 = []
      let items2 = []
      this.worktimeRecordItems = []
      axios.get('/api/manager/worktime-record?begin_date=' + this.date + '&end_date=' + this.date)
        .then((res) => {
          res.data.forEach((item) => {
            if (item.is_leaving_spanning_date === 1) {
              items1.push(item)
            } else {
              items2.push(item)
            }
          })
          items1.forEach((item) => {
            items2.push(item)
          })

          items2.forEach((item) => {
            if (this.isRetired === 3) {
              this.worktimeRecordItems.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.worktimeRecordItems.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.worktimeRecordItems.push(item)
              }
            }
          })

          this.$nextTick(() => {
            this.isWait = 0
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    },
    getLeaveApplication () {
      this.leaveApplicationItems = []
      axios.get('/api/manager/leave-application?begin_date=' + this.date + '&end_date=' + this.date)
        .then((res) => {
          let editedItem = []
          let defaultEditedItem = {
            name: '',
            category: '',
            group_id: 0,
            is_approved: 0,
            is_tardiness: 0,
            tardiness_time: 0,
            is_early_withdrawal: 0,
            early_withdrawal_time: 0,
            time_paid_leave: 0,
            time_closing: 0,
            is_retired: 0,
            record_at: '',
            input_type: '',
            hasError: false,
            errorText: ''
          }
          res.data.forEach((item) => {
            editedItem = cloneDeep(defaultEditedItem)
            editedItem.name = item.staff_name
            editedItem.category = item.category
            editedItem.is_approved = item.is_approved
            editedItem.group_id = item.group_id
            editedItem.time_paid_leave = item.time_paid_leave
            editedItem.time_closing = item.time_closing
            editedItem.is_retired = item.is_retired

            if (this.isRetired === 3) {
              this.leaveApplicationItems.push(cloneDeep(editedItem))
            }
            if (this.isRetired === 0 && item.is_retired === 0) {
              this.leaveApplicationItems.push(cloneDeep(editedItem))
            }
            if (this.isRetired === 1 && item.is_retired === 1) {
              this.leaveApplicationItems.push(cloneDeep(editedItem))
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid || this.isRecordBodyTemperature !== '1') {
        let timeHM = this.editedItem.time.split(':')
        let newRecordAt = moment(this.editedItem.record_at)
        newRecordAt = newRecordAt.hours(Number(timeHM[0])).minutes(Number(timeHM[1]))
        this.editedItem.record_at = newRecordAt.utc().format()
        if (this.editedItem.id === 0) {
          axios.post('/api/manager/worktime-record', this.editedItem)
            .then((res) => {
              this.getWorktimeRecord()
              this.dialog = false
              if (res.data.alert === 1) {
                // this.alertDialog = true
                this.staffName = res.data.staff_name
              }
            })
            .catch((x) => {
              alert(x.response.data.error)
            })
        } else {
          // this.editedItem.record_at = moment(this.record_at).utc().format()
          axios.put('/api/manager/worktime-record/' + this.editedItem.id, this.editedItem)
            .then((res) => {
              this.getWorktimeRecord()
              this.dialog = false
            })
            .catch(() => {
              alert('登録エラー')
            })
        }
      }
    },
    deleteItem (item) {
      let confirmText = item.name + 'の\n' + this.category[item.category] + 'を削除します。'

      if (item.category === 'attendance' && this.projectItems.length > 1) {
        confirmText += '\n削除する「出勤データ」に関係する、他の勤務データも削除します。'
      }

      if (confirm(confirmText)) {
        axios.delete('/api/manager/worktime-record/' + item.id)
          .then(() => {
            this.getWorktimeRecord()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>

<style>
.worktime-record-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
</style>
<style scoped>
.datetimepicker > .datepicker {
  height: 200px;
}
table.v-table tbody td {
  border-left: 1px solid rgba(199, 127, 127, 0.12) !important;
}
/* thead, tbody {
  width: 100%;
  display: block;
}
tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
} */
</style>
