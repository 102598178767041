<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" @click="newEdit">定時</v-btn>
    </template>
    <v-card>
      <v-dialog v-model="editDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">定時編集</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-checkbox
                    v-model.number="is_activate_company_shift"
                    label="シフト登録がない場合、設定した定時を適用する"
                    :true-value="1"
                    :false-value="0"
                    color="success"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-dialog
                    :disabled="is_activate_company_shift === 0"
                    ref="beginTimeDialog"
                    v-model="editedBeginTimeModal"
                    :return-value.sync="ordinary_time_in"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="ordinary_time_in"
                        :disabled="is_activate_company_shift === 0"
                        label="仕事開始時間"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="editedBeginTimeModal"
                      v-model="ordinary_time_in"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="editedBeginTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="success" @click="$refs.beginTimeDialog.save(ordinary_time_in)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
                <v-flex xs12>
                  <v-dialog
                    :disabled="is_activate_company_shift === 0"
                    ref="endTimeDialog"
                    v-model="editedEndTimeModal"
                    :return-value.sync="ordinary_time_out"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="is_activate_company_shift === 0"
                        v-model="ordinary_time_out"
                        label="仕事終了時間"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="editedEndTimeModal"
                      v-model="ordinary_time_out"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="editedEndTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="success" @click="$refs.endTimeDialog.save(ordinary_time_out)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="editDialog = false">Cancel</v-btn>
            <v-btn color="success" flat @click="save">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'

export default {
  data: () => ({
    companyId: 0,
    is_activate_company_shift: 0,
    ordinary_time_in: '',
    ordinary_time_out: '',
    editedBeginTimeModal: false,
    editedEndTimeModal: false,
    dialog: false,
    editDialog: false,
    editedItem: {}
  }),
  mounted () {
    this.getCompanyItems()
  },
  methods: {
    getCompanyItems () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyId = res.data['id']
          this.is_activate_company_shift = res.data['is_activate_company_shift']
          this.ordinary_time_in = res.data['ordinary_time_in']
          this.ordinary_time_out = res.data['ordinary_time_out']
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompany]')
        })
    },
    save () {
      axios.put('/api/manager/company/',
        { is_activate_company_shift: this.is_activate_company_shift,
          ordinary_time_in: this.ordinary_time_in,
          ordinary_time_out: this.ordinary_time_out })
        .then((res) => {
          this.getCompanyItems()
          this.editDialog = false
        })
        .catch(() => {
          alert('追加登録エラー')
        })
    },
    newEdit () {
      this.editDialog = true
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.editDialog = true
    }
  }
}
</script>

<style>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line) .v-select__selections {
  padding-top: 0;
}
</style>
