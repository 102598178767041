<template>
  <div>
    <MyToolbar />
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="dateMenu_start"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_start"
              label="申請日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_start"
            locale="ja-jp"
            :day-format="date_start => new Date(date_start).getDate()"
            @input="dateMenu_start = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="dateMenu_end"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_end"
              label="申請日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_end"
            locale="ja-jp"
            :day-format="date_end => new Date(date_end).getDate()"
            @input="dateMenu_end = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          label="申請状況"
          :items="requestStatusItems"
          v-model="isStatus"
          item-value="id"
          item-text="status"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId"/>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired"/>
      </v-flex>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">時間外勤務申請データ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="approvalDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">承認</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs2>
                    <span>
                      <v-chip
                        class="ma-2 white--text font-weight-bold"
                        :color="requestStatusItems[beforeRequestStatus + 1].color"
                        outlined
                      >
                        {{ requestStatusItems[beforeRequestStatus + 1].status }}
                      </v-chip>
                    </span>
                  </v-flex>
                  <v-flex xs4>
                    <div
                      style="font-weight:bold;font-size:1.25rem;padding-top:10px;"
                    >
                      {{ overWorkEditedItem.name }}
                    </div>
                  </v-flex>
                  <v-flex xs5>
                    <span>{{ groupNameObj[overWorkEditedItem.group_id] }}</span>
                  </v-flex>
                  <v-flex xs1>
                  </v-flex>
                  <v-flex xs11>
                    <div
                      style="float:left;width:100px;font-weight:bold;font-size:1.1rem;text-align:center;"
                    >
                      <span>{{ requestTypeName[beforeRequestType] }}</span>
                    </div>
                    <div
                      style="float:left;width:100px;font-size:1.1rem;"
                    >
                      <span>{{ overWorkEditedItem.request_date }}</span>
                    </div>
                    <div
                      style="float:left;width:200px;font-size:1.1rem;"
                    >
                      <span>{{ overWorkEditedItem.request_time_start + '　～　' + overWorkEditedItem.request_time_end + '　' + calculationWorktime(overWorkEditedItem) }}</span>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div>
                      <span
                        v-if="isOvernightWork"
                        class="red--text"
                      >
                        <v-icon
                          color="red"
                          dark
                        >
                          warning
                        </v-icon>
                        ※日をまたぐ時間外勤務の申請です。
                      </span>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div
                      v-if="overWorkEditedItem.request_comment"
                    >
                      <v-textarea
                        v-model="overWorkEditedItem.request_comment"
                        label="申請コメント"
                        solo
                        readonly
                        dense
                      ></v-textarea>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="overWorkEditedItem.approval_comment"
                      box
                      label="承認コメント（任意）"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="approvalDialog = false">キャンセル</v-btn>
              <v-btn color="success" flat @click="approval">承認</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="rejectDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">申請拒否</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs2>
                    <span>
                      <v-chip
                        class="ma-2 white--text font-weight-bold"
                        :color="requestStatusItems[beforeRequestStatus + 1].color"
                        outlined
                      >
                        {{ requestStatusItems[beforeRequestStatus + 1].status }}
                      </v-chip>
                    </span>
                  </v-flex>
                  <v-flex xs4>
                    <div
                      style="font-weight:bold;font-size:1.25rem;padding-top:10px;"
                    >
                      {{ overWorkEditedItem.name }}
                    </div>
                  </v-flex>
                  <v-flex xs5>
                    <span>{{ groupNameObj[overWorkEditedItem.group_id] }}</span>
                  </v-flex>
                  <v-flex xs1>
                  </v-flex>
                  <v-flex xs11>
                    <div
                      style="float:left;width:100px;font-weight:bold;font-size:1.1rem;text-align:center;"
                    >
                      <span>{{ requestTypeName[beforeRequestType] }}</span>
                    </div>
                    <div
                      style="float:left;width:100px;font-size:1.1rem;"
                    >
                      <span>{{ overWorkEditedItem.request_date }}</span>
                    </div>
                    <div
                      style="float:left;width:200px;font-size:1.1rem;"
                    >
                      <span>{{ overWorkEditedItem.request_time_start + '　～　' + overWorkEditedItem.request_time_end + '　' + calculationWorktime(overWorkEditedItem) }}</span>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div>
                      <span
                        v-if="isOvernightWork"
                        class="red--text"
                      >
                        <v-icon
                          color="red"
                          dark
                        >
                          warning
                        </v-icon>
                        ※日をまたぐ時間外勤務の申請です。
                      </span>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div
                      v-if="overWorkEditedItem.request_comment"
                    >
                      <v-textarea
                        v-model="overWorkEditedItem.request_comment"
                        label="申請コメント"
                        solo
                        readonly
                        dense
                      ></v-textarea>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="overWorkEditedItem.approval_comment"
                      box
                      label="申請拒否コメント（任意）"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="rejectDialog = false">キャンセル</v-btn>
              <v-btn color="pink" flat @click="reject">申請拒否</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-progress-linear v-if="isWait === 1" :indeterminate="true"></v-progress-linear>
      <v-data-table
        hide-actions
        :headers="headers"
        :items="overWorkItems"
        class="elevation-1"
        :rows-per-page-items="[50, 100, 300]"
      >
        <template v-slot:no-data>
          <v-alert v-if="isWait === 0" :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="" >{{ props.item.name }}</td>
          <td class="" >{{ groupNameObj[props.item.group_id] }}</td>
          <td style="text-align: center" class="" >
            <v-chip
              class="ma-2 white--text font-weight-bold"
              :color="requestStatusItems[props.item.request_status + 1].color"
              outlined
            >
              {{ requestStatusItems[props.item.request_status + 1].status }}
            </v-chip>
          </td>
          <td style="text-align: center" class="" >{{ requestTypeName[props.item.request_type] }}</td>
          <td style="text-align: center" class="" >
            {{ props.item.request_date }}{{ weekday(props.item.request_date) }}<br>
            <span v-if="afterRequeast(props.item)">
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    warning
                  </v-icon>
                </template>
                <span>事後申請</span>
              </v-tooltip>
            </span>
          </td>
          <td style="text-align: center" class="" >
            {{ props.item.request_time_start }}～{{ props.item.request_time_end }}<br>
            <span
              v-if="overnightWork(props.item)"
              class="red--text"
            >
              {{ calculationWorktime(props.item) }}
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    warning
                  </v-icon>
                </template>
                <span>日をまたぐ時間外勤務</span>
              </v-tooltip>
            </span>
            <span
              v-else
            >
              {{ calculationWorktime(props.item) }}
            </span>
          </td>
          <td style="text-align: center" class="" >
            <span v-if="props.item.request_comment !== ''">
              <v-tooltip
                top
                max-width="400px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="orange"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    message
                  </v-icon>
                </template>
                <span>{{ props.item.request_comment }}</span>
              </v-tooltip>
            </span>
          </td>
          <td style="text-align: center" class="" >
            <span v-if="props.item.approval_comment !== ''">
              <v-tooltip
                top
                max-width="400px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="props.item.request_status === 3 ? 'red' : 'cyan'"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    message
                  </v-icon>
                </template>
                <span>{{ props.item.approval_comment }}</span>
              </v-tooltip>
            </span>
          </td>
          <td style="text-align: center" class="" >
            {{ props.item.submission_date }}<br>{{ props.item.submission_time }}
          </td>
          <td style="text-align: center">
            <div v-if="props.item.request_status === 0 || props.item.request_status === 2">
              <v-btn
                class="ma-2 white--text font-weight-bold"
                color="cyan"
                @click="approvalEdit(props.item)"
              >
                承認
              </v-btn>
              <v-btn
                class="ma-2 white--text font-weight-bold"
                color="pink"
                @click="rejectRequestEdit(props.item)"
              >
                拒否
              </v-btn>
            </div>
            <div v-else>
              <span
                :class="requestStatusItems[props.item.request_status + 1].color + '--text'"
              >
                {{ requestStatusItems[props.item.request_status + 1].status }}
              </span>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import cloneDeep from 'lodash/cloneDeep'
import itemTools from '@/mixins/item_tools.js'
import { validationMixin } from 'vuelidate'

export default {
  mixins: [itemTools, validationMixin],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  data: () => ({
    isRetired: 0,
    isStatus: 5,
    retireItems: [
      { id: 0, name: '在職' },
      { id: 1, name: '退職' },
      { id: 3, name: '指定なし' }
    ],
    requestStatusItems: [
      { id: 5, status: '指定なし', color: 'blue-grey' },
      { id: 0, status: '申請中', color: 'orange' },
      { id: 1, status: '承認済み', color: 'cyan' },
      { id: 2, status: '再申請中', color: 'deep-orange' },
      { id: 3, status: '申請拒否', color: 'pink' },
      { id: 4, status: '申請取下げ', color: 'blue-grey' }
    ],
    isWait: 1,
    staffName: '',
    groupId: 0,
    groupNameObj: {},
    group: '指定なし',
    editedItem: {},
    overWorkEditedItem: {},
    worktimeRecordItems: [],
    approvalDialog: false,
    rejectDialog: false,
    date_start: moment().startOf('month').format('YYYY-MM-DD'),
    date_end: moment().endOf('month').format('YYYY-MM-DD'),
    dateMenu_start: false,
    dateMenu_end: false,
    staff: '指定なし',
    staffItems: [],
    leaveApplicationItems: [],
    overWorkItems: [],
    requestTypeName: {
      overtime_work: '残業',
      early_attendant: '早出',
      holiday_work: '休日勤務'
    },
    company: {
      is_activate_gps: 0
    },
    beforeRequestStatus: 0,
    beforeRequestType: 'overtime_work',
    isOvernightWork: false,
    wDayList: ['日', '月', '火', '水', '木', '金', '土']
  }),
  computed: {
    headers () {
      return [
        {
          text: 'スタッフ',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: 'グループ',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: 'ステータス',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '種別',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '勤務日',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '勤務時間(h)',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '申請コメント',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '可否コメント',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '提出日時',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        },
        {
          text: '',
          sortable: false,
          value: 'name',
          class: 'worktime-record-header'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.specifiedDate) {
      this.date = this.$route.params.specifiedDate
    }
    this.getCompany().then(() => {
      this.getGroup()
      this.getStaff()
      this.getRequestOvertime()
      this.newEdit()
    })
  },
  watch: {
    date_start () {
      this.isWait = 1
      this.getRequestOvertime()
    },
    date_end () {
      this.isWait = 1
      this.getRequestOvertime()
    },
    staff () {
      this.getRequestOvertime()
    },
    group () {
      this.getRequestOvertime()
    },
    isRetired () {
      this.getRequestOvertime()
    },
    isStatus () {
      this.getRequestOvertime()
    }
  },
  methods: {
    getCompany () {
      return new Promise((resolve, reject) => {
        axios.get('/api/manager/company')
          .then((res) => {
            this.company = res.data
            resolve()
          })
          .catch((error) => {
            this.errorDecision(error, '/manager', 'データエラー[getCompany]')
            reject(new Error('データエラー[getCompany]'))
          })
      })
    },
    getGroup () {
      axios.get('/api/manager/group')
        .then((res) => {
          this.groupNameObj = {}

          res.data.forEach((item) => {
            if (!this.groupNameObj[item.id]) {
              this.groupNameObj[item.id] = {}
              this.groupNameObj[item.id] = item.name
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getGroup]')
        })
    },
    newEdit () {
      this.overWorkEditedItem = {
        request_status: 0
      }
    },
    approvalEdit (item) {
      this.overWorkEditedItem = cloneDeep(item)
      this.approvalDialog = true
      this.beforeRequestStatus = this.overWorkEditedItem.request_status
      this.beforeRequestType = this.overWorkEditedItem.request_type
      this.overWorkEditedItem.request_status = 1
    },
    rejectRequestEdit (item) {
      this.overWorkEditedItem = cloneDeep(item)
      this.rejectDialog = true
      this.beforeRequestStatus = this.overWorkEditedItem.request_status
      this.beforeRequestType = this.overWorkEditedItem.request_type
      this.overWorkEditedItem.request_status = 3
    },
    getStaff () {
      this.staffItems = []
      axios.get('/api/manager/staff')
        .then((res) => {
          res.data.forEach((item) => {
            if (item.is_retired === 0) {
              this.staffItems.push(item)
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaff]')
        })
    },
    getRequestOvertime () {
      axios.get('/api/manager/request-overtimework-all?begin_date=' + this.date_start + '&end_date=' + this.date_end)
        .then((res) => {
          let displayFilterStr = ''
          // 画面側のフィルター
          displayFilterStr = displayFilterStr + (this.isStatus !== 5 ? String(this.isStatus) : '-') + displayFilterStr + (this.isRetired !== 3 ? String(this.isRetired) : '-') + (this.group !== '指定なし' ? String(this.groupId) : '-') + (this.staff !== '指定なし' ? this.staff : '-')
          this.overWorkItems = []
          res.data.forEach((item) => {
            let itemFilterStr = ''
            // データ
            itemFilterStr = itemFilterStr + (this.isStatus !== 5 ? item.request_status : '-') + (this.isRetired !== 3 ? item.is_retired : '-') + (this.group !== '指定なし' ? item.group_id : '-') + (this.staff !== '指定なし' ? item.name : '-')
            if (displayFilterStr === itemFilterStr) {
              this.overWorkItems.push(item)
            }
          })
        })
      this.isWait = 0
    },
    approval () {
      this.overWorkEditedItem.request_status = 1
      axios.put('/api/manager/approval-overtimework', this.overWorkEditedItem)
        .then((res) => {
          this.getRequestOvertime()
          this.approvalDialog = false
        })
        .catch(() => {
          alert('承認エラー')
        })
    },
    reject () {
      console.log('申請拒否！')
      this.overWorkEditedItem.request_status = 3
      axios.put('/api/manager/approval-overtimework', this.overWorkEditedItem)
        .then((res) => {
          this.getRequestOvertime()
          this.rejectDialog = false
        })
        .catch(() => {
          alert('申請拒否エラー')
        })
    },
    afterRequeast (item) {
      let requestDate = moment(item.request_date + ' ' + item.request_time_start, 'YYYY-MM-DD H:mm')
      let submissionDate = moment(item.submission_date + ' ' + item.submission_time, 'YYYY-MM-DD H:mm')
      return submissionDate.isSameOrAfter(requestDate)
    },
    calculationWorktime (item) {
      let dateTo = moment(item.request_date + ' ' + item.request_time_start, 'YYYY-MM-DD H:mm')
      let dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm')
      // 残業終了時間が開始時間より前の場合
      if (dateFrom.isBefore(dateTo)) {
        dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm').add(1, 'd')
        this.isOvernightWork = true
        return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
      } else {
        this.isOvernightWork = false
      }
      return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
    },
    overnightWork (item) {
      let dateTo = moment(item.request_date + ' ' + item.request_time_start, 'YYYY-MM-DD H:mm')
      let dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm')
      // 残業終了時間が開始時間より前の場合
      if (dateFrom.isBefore(dateTo)) {
        return true
      }
      return false
    },
    weekday (date) {
      let wDay = moment(date, 'YYYY-MM-DD').format('d')
      return ' (' + this.wDayList[wDay] + ')'
    }
  }
}
</script>

<style>
.worktime-record-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
</style>
<style scoped>
.datetimepicker > .datepicker {
  height: 200px;
}
table.v-table tbody td {
  border-left: 1px solid rgba(199, 127, 127, 0.12) !important;
}
/* thead, tbody {
  width: 100%;
  display: block;
}
tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
} */
</style>
