<template>
  <v-card v-if="item">
    <v-card-title class="headline" primary-title>
      <span>{{ item.staffName }}</span>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="printTable" class="ma-2">
        <v-icon>print</v-icon>印刷する
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <table border="1" style="border-collapse: collapse" width="100%">
        <tr>
          <th rowspan="6" width="200">年次有給休暇取得日数</th>
          <th width="110">基準日</th>
          <td colspan="10" class="subheading pa-2">
            {{ item.beginDate }}
          </td>
        </tr>
        <tr>
          <th>取得日数</th>
          <td colspan="10" class="subheading pa-2">
            {{ item.countDays }}日
          </td>
        </tr>

        <tr v-for="m in 4" :key="`m-${m}`">
          <th v-if="m === 1" rowspan="4" >年次有給休暇を取得した日付</th>
          <td v-for="n in 10" :key="n" width="80"  height="80" class="pa-2">
            <span
              v-if="item.dayItems[n - 1 + ((m - 1) * 10)]"
              v-html="item.dayItems[n - 1 + ((m - 1) * 10)]"
            />
              <span v-else>&nbsp;</span>
          </td>
        </tr>

      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: [ 'item' ],
  methods: {
    convAtDate (date) {
      return moment(date).format('YYYY/') + '\n' + moment(date).format('MM/DD(ddd)')
    },
    convAtDate2 (date) {
      return moment(date).format('YYYY/MM/DD')
    },
    printPDF: function (content, orientation) {
      window.pdfMake.fonts = {
        GenShin: {
          normal: 'GenShinGothic-P-Regular.ttf',
          bold: 'GenShinGothic-P-Regular.ttf',
          italics: 'GenShinGothic-P-Regular.ttf',
          bolditalics: 'GenShinGothic-P-Regular.ttf'
        }
      }
      window.pdfMake.createPdf({
        pageOrientation: orientation,
        pageMargins: [25, 10, 25, 10],
        content: content,
        styles: {
          th: {
            fillColor: '#eeeeee',
            alignment: 'left'
          },
          marginTop10: {
            margin: [0, 10, 0, 0]
          }
        },
        defaultStyle: {
          font: 'GenShin',
          fontSize: 8
        }
      }).open()
    },
    layoutTable () {
      return {
        hLineWidth: function () {
          return 0.5
        },
        vLineWidth: function () {
          return 0.5
        }
      }
    },
    printTable () {
      // this.dialog = false
      // console.log(this.item)
      const content = [
        {
          text: '年次有給休暇管理簿',
          fontSize: 17,
          alignment: 'center',
          margin: [10, 0, 0, 10]
        },
        {
          fontSize: 12,
          width: 'auto',
          alignment: 'left',
          margin: [0, 0, 0, 10],
          table: {
            widths: ['auto', 'auto'],
            body: [
              ['会社名', localStorage.getItem('companyName')],
              ['従業員氏名', this.item.staffName]
            ]
          }
        },
        {
          style: 'marginTop10',
          layout: this.layoutTable(),
          fontSize: 11,
          alignment: 'center',
          table: {
            widths: [
              'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',
              'auto', 'auto'
            ],
            body: [
              [
                {
                  text: '年次有給休暇取得日数',
                  style: 'th',
                  rowSpan: 6,
                  alignment: 'center',
                  margin: [5, 155, 5, 155]
                },
                { text: '基準日', style: 'th', alignment: 'center', margin: [0, 16] },
                {
                  text: this.item.beginDate,
                  style: 'td',
                  colSpan: 10,
                  alignment: 'left',
                  margin: [0, 16],
                  fontSize: 12
                },
                {}, {}, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                {},
                { text: '取得日数', style: 'th', alignment: 'center', margin: [0, 16] },
                {
                  text: this.item.countDays + '日',
                  style: 'td',
                  colSpan: 10,
                  alignment: 'left',
                  margin: [0, 16],
                  fontSize: 12
                },
                {}, {}, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                {},
                {
                  text: '年次有給休暇を取得した日付',
                  style: 'th',
                  rowSpan: 4,
                  alignment: 'center',
                  margin: [5, 110, 5, 110]
                },
                this.objectCreation(0),
                this.objectCreation(1),
                this.objectCreation(2),
                this.objectCreation(3),
                this.objectCreation(4),
                this.objectCreation(5),
                this.objectCreation(6),
                this.objectCreation(7),
                this.objectCreation(8),
                this.objectCreation(9)
              ],
              [
                {},
                {},
                this.objectCreation(10),
                this.objectCreation(11),
                this.objectCreation(12),
                this.objectCreation(13),
                this.objectCreation(14),
                this.objectCreation(15),
                this.objectCreation(16),
                this.objectCreation(17),
                this.objectCreation(18),
                this.objectCreation(19)
              ],
              [
                {},
                {},
                this.objectCreation(20),
                this.objectCreation(21),
                this.objectCreation(22),
                this.objectCreation(23),
                this.objectCreation(24),
                this.objectCreation(25),
                this.objectCreation(26),
                this.objectCreation(27),
                this.objectCreation(28),
                this.objectCreation(29)
              ],
              [
                {},
                {},
                this.objectCreation(30),
                this.objectCreation(31),
                this.objectCreation(32),
                this.objectCreation(33),
                this.objectCreation(34),
                this.objectCreation(35),
                this.objectCreation(36),
                this.objectCreation(37),
                this.objectCreation(38),
                this.objectCreation(39)
              ]
            ]
          }
        }
      ]
      console.log(content)
      this.printPDF(content, 'landscape')
    },
    objectCreation (i) {
      if (this.item.dayItems[i]) {
        return {
          text: this.item.dayItems[i].replace(/<br \/>/, '\n').replace(/\[/, '\n['),
          style: 'td',
          alignment: 'center',
          margin: [0, 12]
        }
      } else {
        return { text: '', style: 'td', alignment: 'center', margin: [25, 30] }
      }
    }
  }
}
</script>
