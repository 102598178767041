<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items2" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-select
          :items="yearItems"
          v-model="yearItem"
          label="基準日年度選択"
          suffix="年"
        ></v-select>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <!-- <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId"/>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" />
      </v-flex> -->

    </v-layout>
    <v-card class="ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">休日出勤申請</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="holidayWorkingItems"
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="leave-application-td">{{ props.item.name }}</td>
          <td class="leave-application-td">{{ date(props.item.application_date) }}</td>
          <td class="leave-application-td">{{ categoryItems[props.item.category] }}</td>
          <td class="leave-application-td">{{ props.item.plan_begin_time }}~{{ props.item.plan_end_time }}</td>

          <td v-if="props.item.plan_rest_begin_time" class="leave-application-td">{{ props.item.plan_rest_begin_time }}~{{ props.item.plan_rest_end_time }}</td>
          <td v-else class="leave-application-td">なし</td>

          <td class="leave-application-td">{{ props.item.plan_job_content }}</td>
          <td class="leave-application-td">{{ props.item.application_reason }}</td>

          <td v-if="props.item.is_working_report === 0" class="leave-application-td"></td>
          <td v-else class="leave-application-td">{{ props.item.work_begin_time }}~{{ props.item.work_end_time }}</td>

          <td v-if="props.item.is_working_report === 0" class="leave-application-td"></td>
          <td v-else-if="props.item.rest_begin_time" class="leave-application-td">{{ props.item.rest_begin_time }}~{{ props.item.rest_end_time }}</td>
          <td v-else class="leave-application-td">なし</td>

          <td class="leave-application-td">
            {{ props.item.is_working_report === 1 ? props.item.real_job_content : '' }}
          </td>
          <td class="leave-application-td">
            {{ props.item.is_working_report === 1 ? props.item.difference_reason : '' }}
          </td>

          <td class="leave-application-td"
            :style="[props.item.is_first_approved === 0 ? unprocessed : props.item.is_second_approved === 0 ? unsubmitted : completed]"
          >
            {{ props.item.is_first_approved === 0 ? '申請未承認' : props.item.is_working_report === 0 ? '未勤務報告' : props.item.is_second_approved === 1 ? '処理完了' : '勤務報告済み' }}
          </td>

          <td class="text-xs-center leave-application-td">
            <v-icon
              small
              v-if="props.item.is_first_approved === 0"
              color="primary"
              class="mr-5"
              @click="putHolidayWorkingItem(props.item)"
            >
              thumb_up_alt
            </v-icon>
            <v-icon
              small
              v-else-if="props.item.is_second_approved === 0"
              color="primary"
              class="mr-5"
              @click="putHolidayWorkingItem2(props.item)"
            >
              thumb_up_alt
            </v-icon>
            <v-icon
              small
              :disabled="props.item.is_second_approved === 1"
              color="accent"
              @click="deleteHolidayWorkingItem(props.item)"
            >
              delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
// import StaffCombo from '@/components/StaffCombo'
// import GroupCombo from '@/components/GroupCombo'
import itemTools from '@/mixins/item_tools.js'

export default {
  mixins: [itemTools],
  components: {
    MyToolbar
    // StaffCombo,
    // GroupCombo
  },
  data: () => ({
    isRetired: 0,
    retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }, { id: 3, name: '指定なし' }],
    unsubmitted: {
      color: 'orange'
    },
    unprocessed: {
      color: 'red'
    },
    completed: {
      color: 'green'
    },
    categoryItems: {
      'legal_overtime_working': '法内残業',
      'non_statutory_working': '法外残業',
      'holiday_working': '休日勤務'
    },
    holidayWorkingItems: [],
    group: '指定なし',
    groupId: 0,
    items2: [
      {
        text: '休暇・申請',
        disabled: false,
        href: '/#/manager/leave-application'
      },
      {
        text: '休日出勤申請',
        disabled: true
      }
    ],
    yearItem: moment().format('YYYY'),
    yearItems: [],
    dialog: false,
    endDateMenu: false,
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    leaveDays: 0,
    headers: [
      { text: '氏名', value: 'name', align: 'center', sortable: false, class: 'leave-application-header' },
      { text: '取得希望日', value: 'name', align: 'center', sortable: false, class: 'leave-application-header', width: '100' },
      { text: '種別', value: 'name', align: 'center', sortable: false, class: 'leave-application-header', width: '70' },
      { text: '予定時間', align: 'center', value: 'name', sortable: false, class: 'leave-application-header' },
      { text: '予定休憩時間', align: 'center', value: 'name', sortable: false, class: 'leave-application-header' },
      { text: '業務内容(予定)', value: 'name', align: 'center', sortable: false, class: 'leave-application-header' },
      { text: '申請理由', value: 'name', align: 'center', sortable: false, class: 'leave-application-header' },
      { text: '実績時間', align: 'center', value: 'name', sortable: false, class: 'leave-application-header' },
      { text: '実績休憩時間', align: 'center', value: 'name', sortable: false, class: 'leave-application-header' },
      { text: '　業務内容(実績)　', value: 'name', align: 'center', sortable: false, class: 'leave-application-header' },
      { text: '予定と実績の乖離の理由', value: 'name', align: 'center', sortable: false, class: 'leave-application-header' },
      { text: '　　状態　　', value: 'name', align: 'center', sortable: false, class: 'leave-application-header', width: '200' },
      { text: '', value: 'name', align: 'center', sortable: false, class: 'leave-application-header' }
    ],
    items: [],
    applicationDateMenu: false,
    applicationDateMenu2: false
  }),
  computed: {
    processedItems () {
      let items = []
      this.items.forEach((item) => {
        if (this.group !== '指定なし') {
          if (this.group !== '') {
            if (item.group_id !== this.groupId) {
              return
            }
          }
        }
        if (this.staff !== '指定なし') {
          if (this.staff !== '') {
            if (item.name !== this.staff) {
              return
            }
          }
        }
        items.push(item)
      })
      return items
    }
  },
  mounted () {
    this.getStaff()
    this.yearArrayCreation()
    this.getHolidayWorkingItems()
  },
  watch: {
    yearItem () {
      this.getHolidayWorkingItems()
    },
    isRetired () {
      this.getHolidayWorkingItems()
    }
  },
  methods: {
    putHolidayWorkingItem2 (item) {
      let confirmText = '【スタッフ名】' + item.name + '\n' + '【 取得希望日 】' + this.date(item.application_date) +
        'の作業報告を許可しますか？'
      if (confirm(confirmText)) {
        axios.put('/api/manager/holiday-work-application/' + item.id, item)
          .then((res) => {
            this.getHolidayWorkingItems()
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    putHolidayWorkingItem (item) {
      let confirmText = '【スタッフ名】' + item.name + '\n' + '【 取得希望日 】' + this.date(item.application_date) +
        'の休日申請を許可しますか？'
      if (confirm(confirmText)) {
        axios.put('/api/manager/holiday-work-application/' + item.id, item)
          .then((res) => {
            this.getHolidayWorkingItems()
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    getHolidayWorkingItems () {
      this.holidayWorkingItems = []
      axios.get('/api/manager/holiday-work-application?select_year=' + this.yearItem)
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.holidayWorkingItems.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.holidayWorkingItems.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.holidayWorkingItems.push(item)
              }
            }
          })
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    yearArrayCreation () {
      let currentYear = moment().year()
      for (let i = currentYear - 4; i <= currentYear + 2; i++) {
        this.yearItems.push(String(i))
      }
    },
    getStaff () {
      axios.get('/api/manager/staff')
        .then((res) => {
          this.staffItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getStaff]')
        })
    },
    deleteHolidayWorkingItem (item) {
      let confirmText = '【スタッフ名】' + item.name + '\n' + '【 取得希望日 】' + this.date(item.application_date) +
        'の休日申請を削除しますか？'
      if (confirm(confirmText)) {
        axios.delete('/api/manager/holiday-work-application/' + item.id)
          .then(() => {
            this.getHolidayWorkingItems()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>

<style>
.leave-application-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.leave-application-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
