<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" v-on="on">パターン</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">勤務パターン編集</span>
        <v-spacer></v-spacer>
        <v-btn flat icon @click="dialog = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-title>
      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="newEdit">追加</v-btn>
      </v-toolbar>
      <v-dialog v-model="editDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">勤務パターン編集</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.name"
                    label="パターン名"
                    required
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                    :error-messages="nameErrors"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-dialog
                    ref="beginTimeDialog"
                    v-model="editedBeginTimeModal"
                    :return-value.sync="editedItem.begin_time"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.begin_time"
                        label="開始時刻"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="editedBeginTimeModal"
                      v-model="editedItem.begin_time"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="editedBeginTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="success" @click="$refs.beginTimeDialog.save(editedItem.begin_time)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
                <v-flex xs12>
                  <v-dialog
                    ref="endTimeDialog"
                    v-model="editedEndTimeModal"
                    :return-value.sync="editedItem.end_time"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.end_time"
                        label="終了時刻"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="editedEndTimeModal"
                      v-model="editedItem.end_time"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="editedEndTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="success" @click="$refs.endTimeDialog.save(editedItem.end_time)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="editDialog = false">Cancel</v-btn>
            <v-btn color="success" flat @click="save">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-text>
        <v-list two-line>
          <template v-for="item in items">
            <v-list-tile
              :key="item.id"
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                <v-list-tile-sub-title>{{ item.begin_time }} - {{ item.end_time }}</v-list-tile-sub-title>
              </v-list-tile-content>

              <v-list-tile-action>
                <v-btn icon ripple>
                  <v-icon color="primary"
                    @click="editItem(item)"
                  >
                    edit
                  </v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn icon ripple>
                  <v-icon
                    color="accent"
                    @click="deleteItem(item)"
                  >
                    delete
                  </v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="`divider-${item.id}`" />
          </template>

        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  components: {
  },
  validations: {
    editedItem: {
      name: { required }
    }
  },
  props: [
    'items'
  ],
  data: () => ({
    editedBeginTimeModal: false,
    editedEndTimeModal: false,
    dialog: false,
    editDialog: false,
    editedItem: {}
  }),
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('パターン名は必須です')
      return errors
    }
  },
  methods: {
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedItem.id === 0) {
          axios.post('/api/manager/default-worktime', this.editedItem)
            .then((res) => {
              this.$emit('updateDefaultWorktime')
              this.editDialog = false
              this.$v.$reset()
            })
            .catch(() => {
              alert('登録エラー')
            })
        } else {
          axios.put('/api/manager/default-worktime/' + this.editedItem.id, this.editedItem)
            .then((res) => {
              this.$emit('updateDefaultWorktime')
              this.editDialog = false
              this.$v.$reset()
            })
            .catch(() => {
              alert('追加登録エラー')
            })
        }
      }
    },
    newEdit () {
      this.editedItem = {
        id: 0,
        name: '',
        begin_time: '',
        end_time: ''
      }
      this.editDialog = true
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.editDialog = true
    },
    deleteItem (item) {
      if (confirm(item.name + ' を削除します。')) {
        axios.delete('/api/manager/default-worktime/' + item.id)
          .then(() => {
            this.$emit('updateDefaultWorktime')
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>
