<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items3" divider=">" class="ma-2"></v-breadcrumbs>
    <v-layout style="background-color: white" column fill-height>
      <v-toolbar flat color="white" class="pt-2">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">日別勤務シフト</v-toolbar-title>
        <v-spacer/>
        <v-flex xs4>
          <GroupCombo :value.sync="group" :groupId="groupId" />
        </v-flex>
      </v-toolbar>
      <v-layout row wrap>
        <v-flex xs4 class="pa-1">
          <MyCalendar
            :eventItems="newEvents"
            :calendarStart.sync="calendarStart"
            @event-clicked="eventClicked"
            @month-changed="monthChanged"
          />
        </v-flex>
        <v-flex xs8 class="pa-1">
          <v-card style="height: 48vh">

            <v-card-title>
              <v-icon
                left
              >
                fact_check
              </v-icon>
              <span class="title font-weight-light">
                <v-chip color="orange" text-color="white" v-if="confirmEventDate === tomorrow" class="headline">明日</v-chip>
                『{{ confirmEventDate }}』の勤務編集
              </span>
              <v-spacer />

              <v-dialog
                v-model="confirmDialog"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="blue darken-2"
                    dark
                    v-on="on"
                  >
                    全体の勤務シフト確認
                  </v-btn>
                </template>

                <v-card>
                  <v-toolbar dark color="blue darken-2">
                    <v-toolbar-title><v-chip color="orange" v-if="tomorrow === confirmEventDate">明日</v-chip>全体の勤務シフト確認『{{ confirmEventDate }}』</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn icon dark @click="confirmDialog = false">
                        <v-icon medium>close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <div style="display: block">
                      <v-layout row wrap class="timetable aggregate-header">
                        <v-flex xs1 class="pa-2 text-xs-center daily-worktime-th" style="border-left: 1px solid rgba(0,0,0,0.12);">
                          地区
                        </v-flex>
                        <v-flex xs11>
                          <v-layout row wrap>
                            <v-flex xs1 class="pa-2 text-xs-center daily-worktime-th">
                              宗派
                            </v-flex>

                            <v-flex xs11>
                              <v-layout row wrap>
                                <v-flex xs2 class="pa-2 text-xs-center daily-worktime-th">
                                  会館名
                                </v-flex>

                                <v-flex xs10>
                                  <v-layout row wrap>
                                    <v-flex xs2 class="pa-2 text-xs-center daily-worktime-th">
                                      告別式
                                    </v-flex>
                                    <v-flex xs2 class="pa-2 text-xs-center daily-worktime-th">
                                      火葬場（会）
                                    </v-flex>
                                    <v-flex xs2 class="pa-2 text-xs-center daily-worktime-th">
                                      火葬場（直）
                                    </v-flex>
                                    <v-flex xs2 class="pa-2 text-xs-center daily-worktime-th">
                                      精進
                                    </v-flex>
                                    <v-flex xs2 class="pa-2 text-xs-center daily-worktime-th" style="border-right: 1px solid rgba(0,0,0,0.12);">
                                      通夜
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12>
                          <v-divider />
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="tbody" style="font-size: 15px">
                      <v-layout v-for="(areaItem) in confirmshiftTotal" :key="`area-${areaItem.id}`" row wrap>
                        <v-flex xs1 class="pa-2 text-xs-center border">
                          {{ areaItem.area }}
                        </v-flex>

                        <v-flex xs11>
                          <v-layout v-for="(denomination) in areaItem.denomination" :key="`denomination-${denomination.denomination}`" row wrap>
                            <v-flex xs1 class="pa-2 text-xs-center border">
                              {{ denomination.denomination }}
                            </v-flex>

                            <v-flex xs11>
                              <v-layout v-for="(hallItem) in denomination.hallNameItems" :key="`hallItem-${hallItem.hallName}`" row wrap>
                                <v-flex xs2 class="pa-2 text-xs-center border">
                                  {{ hallItem.hallName }}
                                </v-flex>

                                <v-flex xs10>
                                  <v-layout row wrap>
                                    <template v-for="(staffItem, idx3) in hallItem.staffItems">

                                      <v-flex xs2 v-if="staffItem.type === '告別式'" :key="`type1-${idx3}`" class="pa-2 text-xs-center border">
                                        {{ staffItem.staff_name }}<br>
                                        {{ staffItem.begin_time }}~{{ staffItem.end_time }}
                                      </v-flex>
                                      <v-flex xs2 v-else :key="`typeno1-${idx3}`" class="pa-2 text-xs-center border">
                                      </v-flex>

                                      <v-flex xs2 v-if="staffItem.type === '火葬場（会）'" :key="`type2-${idx3}`" class="pa-2 text-xs-center border">
                                        {{ staffItem.staff_name }}<br>
                                        {{ staffItem.begin_time }}~{{ staffItem.end_time }}
                                      </v-flex>
                                      <v-flex xs2 v-else :key="`typeno2-${idx3}`" class="pa-2 text-xs-center border">
                                      </v-flex>

                                      <v-flex xs2 v-if="staffItem.type === '火葬場（直）'" :key="`type3-${idx3}`" class="pa-2 text-xs-center border">
                                        {{ staffItem.staff_name }}<br>
                                        {{ staffItem.begin_time }}~{{ staffItem.end_time }}
                                      </v-flex>
                                      <v-flex xs2 v-else :key="`typeno3-${idx3}`" class="pa-2 text-xs-center border">
                                      </v-flex>

                                      <v-flex xs2 v-if="staffItem.type === '精進'" :key="`type4-${idx3}`" class="pa-2 text-xs-center border">
                                        {{ staffItem.staff_name }}<br>
                                        {{ staffItem.begin_time }}~{{ staffItem.end_time }}
                                      </v-flex>
                                      <v-flex xs2 v-else :key="`typeno4-${idx3}`" class="pa-2 text-xs-center border">
                                      </v-flex>

                                      <v-flex xs2 v-if="projectNameObj[staffItem.project_id] === '通夜'" :key="`type4-${idx3}`" class="pa-2 text-xs-center border2">
                                        {{ staffItem.staff_name }}<br>
                                        {{ staffItem.begin_time }}~{{ staffItem.end_time }}
                                      </v-flex>
                                      <v-flex xs2 v-else :key="`typeno5-${idx3}`" class="pa-2 text-xs-center border2">
                                      </v-flex>

                                      <v-flex v-if="staffItem.is_contacted" xs2 :key="`typeno6-${idx3}`" class="pa-2 text-xs-center border2">
                                        <v-chip color="orange" text-color="white">確定</v-chip>
                                      </v-flex>
                                      <v-flex v-else xs2 :key="`typeno6-${idx3}`" class="pa-2 text-xs-center border2">
                                      </v-flex>

                                    </template>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>

            </v-card-title>

            <v-card-text class="pa-1" style="overflow: scroll;height: 37vh">
              <v-list two-line>
                <template v-for="item in confirmEventItems">
                  <div :key="`${item.id}-tomorrow`">
                    <v-list-tile :key="`${item.id}-tomorrow`">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ item.name }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title v-if="item.category === 'workable'">
                          〇<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'rough_specified_time'">
                          {{ item.begin_time }} ~ 指定なし<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'specified_time'">
                          {{ item.begin_time }} ~ {{ item.end_time }}<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'specify_another'">
                          葬儀： {{ item.funeral_category === 'workable' ? '〇' : item.funeral_category === 'rough_specified_time' ? item.begin_time + '~' : '✕' }}<br>
                          通夜： {{ item.wake_category === 'workable' ? '〇' : '✕' }}
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'undecided'">
                          ？<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'not_possible'">
                          ✕<br>&nbsp;
                        </v-list-tile-sub-title>
                      </v-list-tile-content>

                      <v-chip color="deep-orange lighten-1" text-color="white">{{ item.continuous_working_days }}連勤</v-chip>
                      <v-chip v-if="item.is_previous_day_vigil === 1" color="indigo darken-2" text-color="white">前日通夜</v-chip>
                      <v-chip v-if="checkIfContacted(item.staff_id)" color="orange" text-color="white">確定</v-chip>

                      <v-chip v-if="checkIfContacted(item.staff_id) && checkIfRead(item.staff_id)" color="orange" text-color="white">既読</v-chip>
                      <v-chip v-if="checkIfContacted(item.staff_id) && !checkIfRead(item.staff_id)" color="red" text-color="white">未読</v-chip>
                      <v-list-tile-action>
                        <v-btn icon ripple>
                          <v-icon
                            color="primary"
                            @click="editWorkableShift(item)"
                          >
                            edit
                          </v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider :key="`divider-${item.id}`" />
                  </div>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
          <v-dialog
            v-model="workShiftDialog"
            width="100%"
          >
            <v-card>
              <v-card-title
                class="headline grey lighten-2"
                primary-title
              >
                「{{ editedWorkableShiftItem.name }}」<span class="subheading">の勤務シフト編集</span>
              </v-card-title>

              <v-card-text>
                <v-layout>
                  <v-spacer></v-spacer>
                  <v-btn v-if="staffByWorktimeItems.length > 0 && staffByWorktimeItems[0].is_contacted === 1" disabled class="mb-2">確定済み</v-btn>
                  <v-btn v-if="staffByWorktimeItems.length > 0 && staffByWorktimeItems[0].is_contacted === 1" @click="cancelWorkShift" dark color="#6ac67b" class="mb-2">勤務シフトを再編集する</v-btn>
                  <v-btn v-else color="#6ac67b" dark class="mb-2" @click="addWorkShift">勤務シフト追加</v-btn>
                  <v-btn v-if="staffByWorktimeItems.length > 0 && staffByWorktimeItems[0].is_contacted === 0" color="orange" dark class="mb-2" @click="fixWorkShift">勤務シフトを確定する</v-btn>
                </v-layout>

                  <v-data-table
                    :items="staffByWorktimeItems"
                    class="elevation-1"
                    :headers="headers"
                    hide-actions
                  >
                    <template v-slot:no-data>
                      <v-alert v-if="staffByWorktimeItems.length === 0" :value="true" color="error" icon="warning" outline>
                        データがありません
                      </v-alert>
                    </template>

                    <template v-slot:items="props">
                      <td>{{ props.item.begin_time }}～{{ props.item.end_time }}</td>
                      <td>{{ projectNameObj[props.item.project_id] }}</td>
                      <td>{{ props.item.area }}</td>
                      <td>{{ props.item.denomination }}</td>
                      <td>{{ props.item.hall_name }}</td>
                      <td>{{ props.item.hall_name_detail }}</td>
                      <td>{{ props.item.type }}</td>
                      <td>{{ props.item.memo }}</td>
                      <td class="justify-center layout px-0">
                        <v-icon
                          v-if="props.item.is_contacted === 0"
                          color="primary"
                          class="mr-4"
                          @click="editItem(props.item)"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          v-else
                          disabled
                        >
                          edit
                        </v-icon>
                        <v-icon
                          v-if="props.item.is_contacted === 0"
                          color="accent"
                          @click="deleteItem(props.item)"
                        >
                          delete
                        </v-icon>
                        <v-icon
                          v-else
                          disabled
                        >
                          delete
                        </v-icon>
                      </td>
                    </template>
                  </v-data-table>

              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  flat
                  @click="closeWorkShiftDialog"
                >
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="addWorkShiftDialog"
            width="800"
          >
            <v-card>
              <v-card-title
                class="headline grey lighten-2"
                primary-title
              >
                「{{ editedWorkableShiftItem.name }}」<span class="subheading">の勤務シフト追加</span>
              </v-card-title>

              <v-card-text>
                <v-container fluid grid-list-xl>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                  <v-layout wrap align-center>
                    <v-flex xs4>
                      <v-subheader>地域</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                      <v-select
                        :items="areaItems"
                        v-model="editedWorktimeItem.area"
                        :rules="areaRules"
                        outline
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-subheader>宗派</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                      <v-text-field
                        v-model="editedWorktimeItem.denomination"
                        :rules="denominationRules"
                        outline
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-subheader>会館名</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                      <v-select
                        :items="selectHallNameItems"
                        v-model="editedWorktimeItem.hall_name"
                        :rules="hallNameRules"
                        outline
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-subheader>会館名（詳細）</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                      <v-text-field
                        outline
                        v-model="editedWorktimeItem.hall_name_detail"
                        placeholder="任意"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-subheader>種別</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                      <v-select
                        :items="typeItems"
                        v-model="editedWorktimeItem.type"
                        :rules="typeRules"
                        outline
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-subheader>勤務予定時間</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                    <v-card>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>
                        <DefaultWorktimeMenu
                          :defaultWorktimeItems="defaultWorktimeItems"
                          :beginTime.sync="editedWorktimeItem.begin_time"
                          :endTime.sync="editedWorktimeItem.end_time"
                        />
                      </v-toolbar>
                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-dialog
                              ref="beginTimeDialog"
                              v-model="beginTimeModal"
                              :return-value.sync="editedWorktimeItem.begin_time"
                              persistent
                              lazy
                              full-width
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedWorktimeItem.begin_time"
                                  label="開始時間"
                                  prepend-icon="access_time"
                                  readonly
                                  outline
                                  :rules="beginTimeRules"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="beginTimeModal"
                                v-model="editedWorktimeItem.begin_time"
                                full-width
                                format="24hr"
                              >
                                <v-spacer></v-spacer>
                                <v-btn flat color="primary" @click="beginTimeModal = false">Cancel</v-btn>
                                <v-btn flat color="success" @click="$refs.beginTimeDialog.save(editedWorktimeItem.begin_time)">OK</v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-flex>
                          <v-flex xs12>
                            <v-dialog
                              ref="endTimeDialog"
                              v-model="endTimeModal"
                              :return-value.sync="editedWorktimeItem.end_time"
                              persistent
                              lazy
                              full-width
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedWorktimeItem.end_time"
                                  label="終了時間"
                                  prepend-icon="access_time"
                                  readonly
                                  outline
                                  :rules="endTimeRules"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="endTimeModal"
                                v-model="editedWorktimeItem.end_time"
                                full-width
                                format="24hr"
                              >
                                <v-spacer></v-spacer>
                                <v-btn flat color="primary" @click="endTimeModal = false">Cancel</v-btn>
                                <v-btn flat color="primary" @click="$refs.endTimeDialog.save(editedWorktimeItem.end_time)">OK</v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                    </v-flex>
                    <v-flex xs4>
                      <v-subheader>備考</v-subheader>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea
                        outline
                        label="任意"
                        v-model="editedWorktimeItem.memo"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  flat
                  @click="addWorkShiftDialog = false"
                >
                  閉じる
                </v-btn>
                <v-btn
                  color="success"
                  flat
                  @click="postWorktimeShift"
                >
                  登録する
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
        <v-flex xs2 class="pa-1" v-for="(eventItems, index) in eventItems2" :key="index">
          <v-card :style="[ eventDate(eventItems) === tomorrow ? { 'background-color': '#00809e' } : '']">
            <v-card-text class="pa-1">
              <v-list two-line>
                <v-subheader>
                  <v-chip v-if="eventDate(eventItems) === tomorrow" color="primary" text-color="white" small class="ml-0">明日</v-chip>
                  {{ eventDate(eventItems) }}
                </v-subheader>
                <template v-for="item in eventItems">
                  <div :key="item.id">
                    <v-list-tile :key="item.id">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ item.name }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title v-if="item.category === 'workable'">
                          〇<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'rough_specified_time'">
                          {{ item.begin_time }} ~ 指定なし<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'specified_time'">
                          {{ item.begin_time }} ~ {{ item.end_time }}<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'specify_another'">
                          葬儀： {{ item.funeral_category === 'workable' ? '〇' : item.funeral_category === 'rough_specified_time' ? item.begin_time + '~' : '✕' }}<br>
                          通夜： {{ item.wake_category === 'workable' ? '〇' : '✕' }}
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'undecided'">
                          ？<br>&nbsp;
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-else-if="item.category === 'not_possible'">
                          ✕<br>&nbsp;
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </div>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog
        v-model="worktimePlanDialog"
        persistent
        fullscreen
        scrollable
      >
        <v-card>
          <v-card-title>
            <span class="title">勤務可能日編集</span>
          </v-card-title>

          <template v-if="worktimePlanDialogPage === 1">

            <v-stepper vertical value="1">
              <v-stepper-header>
                <v-stepper-step step="1" class="pa-1"><span class="caption">日付選択</span></v-stepper-step>
                <v-stepper-step step="2" class="pa-1"><span class="caption">時間・種別指定</span></v-stepper-step>
                <v-stepper-step step="3" class="pa-1"><span class="caption">確認</span></v-stepper-step>
                <v-stepper-step step="4" class="pa-1"><span class="caption">完了</span></v-stepper-step>
              </v-stepper-header>
            </v-stepper>

            <v-card-text class="pa-0">
              <v-layout row wrap>
                <v-flex xs12>
                  <MyCalendar
                    :eventItems="newEvents"
                    :calendarStart.sync="calendarStart"
                    :highlightDays="highlightDays"
                    @day-clicked="dayClicked"
                    @month-changed="monthChanged"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="worktimePlanDialog = false">キャンセル</v-btn>
              <v-alert
                :value="dayAlertJudgment"
                color="error"
                outline
                icon="error_outline"
              >
                日付が未選択です
              </v-alert>
              <v-btn
                v-if="dayAlertJudgment !== true"
                color="success"
                flat
                @click="toSelect"
              >
                時間・種別を指定
              </v-btn>
            </v-card-actions>
          </template>

          <template v-if="worktimePlanDialogPage === 2">

            <v-stepper vertical value="2" class="pa-0">
              <v-stepper-header>
                <v-stepper-step step="1" complete class="pa-1" ><span class="caption">日付選択</span></v-stepper-step>
                <v-stepper-step step="2" class="pa-1"><span class="caption">時間・種別指定</span></v-stepper-step>
                <v-stepper-step step="3" class="pa-1"><span class="caption">確認</span></v-stepper-step>
                <v-stepper-step step="4" class="pa-1"><span class="caption">完了</span></v-stepper-step>
              </v-stepper-header>
            </v-stepper>

            <v-card-text style="height: 70vh;">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-radio-group v-model="category" :mandatory="false">
                    <v-radio label="全日可能" value="workable" color="#6ac67b"></v-radio>
                    <v-radio label="時間指定1" value="rough_specified_time" color="#6ac67b"></v-radio>
                    <v-radio label="時間指定2" value="specified_time" color="#6ac67b"></v-radio>
                    <v-radio label="葬儀・通夜別指定" value="specify_another" color="#6ac67b"></v-radio>
                    <v-radio label="未定" value="undecided" color="#6ac67b"></v-radio>
                    <v-radio label="休み" value="not_possible" color="#6ac67b"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex v-if="category === 'rough_specified_time' || category === 'specified_time'" xs7>
                  <v-dialog
                    ref="beginTimeDialog"
                    v-model="beginTimeModal"
                    :return-value.sync="beginTime"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="beginTime"
                        label="開始時間"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="beginTimeModal"
                      v-model="beginTime"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="beginTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="success" @click="$refs.beginTimeDialog.save(beginTime)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
                <v-flex v-if="category === 'rough_specified_time'" xs5 class="mt-4">
                 ～ 時間指定なし
                </v-flex>
                <v-flex v-if="category === 'specified_time'" xs7>
                  <v-dialog
                    ref="endTimeDialog"
                    v-model="endTimeModal"
                    :return-value.sync="endTime"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endTime"
                        label="終了時間"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="endTimeModal"
                      v-model="endTime"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="endTimeModal = false">Cancel</v-btn>
                      <v-btn flat color="primary" @click="$refs.endTimeDialog.save(endTime)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
                <v-layout row wrap v-if="category === 'specify_another'">
                  <v-flex xs2>
                    <v-subheader class="px-0">葬儀</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-select
                      v-model="select"
                      :items="items"
                      item-text="state"
                      item-value="abbr"
                      single-line
                    ></v-select>
                  </v-flex>
                  <v-flex xs5 v-if="select === 'specified_time'">
                    <v-dialog
                      ref="endTimeDialog"
                      v-model="endTimeModal"
                      :return-value.sync="endTime"
                      persistent
                      lazy
                      full-width
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="endTime"
                          label="終了時間"
                          prepend-icon="access_time"
                          readonly
                          v-on="on"
                          suffix="～"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="endTimeModal"
                        v-model="endTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="endTimeModal = false">Cancel</v-btn>
                        <v-btn flat color="primary" @click="$refs.endTimeDialog.save(endTime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex v-else xs5></v-flex>
                  <v-flex xs2>
                    <v-subheader class="px-0">通夜</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-select
                      v-model="select2"
                      :items="items2"
                      item-text="state"
                      item-value="abbr"
                      single-line
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="worktimePlanDialogPage = 1">戻る</v-btn>
              <v-btn color="success" flat @click="toConfirm">確認する</v-btn>
            </v-card-actions>
          </template>

          <template v-if="worktimePlanDialogPage === 3">

            <v-stepper vertical value="3" class="pa-0">
              <v-stepper-header>
                <v-stepper-step step="1" complete class="pa-1" ><span class="caption">日付選択</span></v-stepper-step>
                <v-stepper-step step="2" complete class="pa-1"><span class="caption">時間・種別指定</span></v-stepper-step>
                <v-stepper-step step="3" class="pa-1"><span class="caption">確認</span></v-stepper-step>
                <v-stepper-step step="4" class="pa-1"><span class="caption">完了</span></v-stepper-step>
              </v-stepper-header>
            </v-stepper>

            <v-card-text style="height: 70vh;">
              <v-card>
                <v-card-title>
                  <h3 class="title mb-0">日付</h3>
                </v-card-title>
                <v-card-text class="subheading">
                  <v-chip v-for="(day, idx) in dayList" :key="idx">{{ day }}</v-chip>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>
                  <h3 class="title mb-0">時間・種別</h3>
                </v-card-title>
                <v-card-text v-if="category === 'workable'" class="subheading">
                  <v-chip>全日可能</v-chip>
                </v-card-text>
                <v-card-text v-else-if="category === 'rough_specified_time'" class="subheading">
                  <v-chip>{{ beginTime }} ～ 時間指定なし</v-chip>
                </v-card-text>
                <v-card-text v-else-if="category === 'specified_time'" class="subheading">
                  <v-chip>{{ beginTime }}</v-chip>
                  -
                  <v-chip>{{ endTime }}</v-chip>
                </v-card-text>
                <v-card-text v-else-if="category === 'specify_another'" class="subheading">
                  <v-chip>葬儀：{{ select === 'workable' ? '勤務可能' : select === 'specified_time' ? beginTime + '～' : '休み' }}</v-chip><br>
                  <v-chip>通夜：{{ select2 === 'workable' ? '勤務可能' : '休み' }}</v-chip>
                </v-card-text>
                <v-card-text v-else-if="category === 'undecided'" class="subheading">
                  <v-chip>未定</v-chip>
                </v-card-text>
                <v-card-text v-else-if="category === 'not_possible'" class="subheading">
                  <v-chip>休み</v-chip>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="worktimePlanDialogPage = 2">戻る</v-btn>
              <v-btn color="success" flat @click="workableSave">登録</v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        bottom
        :timeout="1500"
        color="#6ac67b"
      >
        登録しました
      </v-snackbar>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import MyToolbar from '@/components/MyToolbar'
import MyCalendar from '@/components/MyCalendar'
import '@/assets/vue2-simple-calendar.css'
import moment from 'moment-timezone'
import DefaultWorktimeMenu from '@/components/DefaultWorktimeMenu'
import GroupCombo from '@/components/GroupCombo'

export default {
  name: 'your-component',
  components: {
    MyToolbar,
    MyCalendar,
    DefaultWorktimeMenu,
    GroupCombo
  },
  data: () => ({
    groupId: 0,
    group: '指定なし',
    confirmshiftTotal: [],
    confirmDialog: false,
    projectNameObj: {},
    projectItems: [],
    headers: [
      { text: '勤務時間', value: 'time', sortable: false },
      { text: '作業', value: 'project', sortable: false },
      { text: '地区', value: 'area', sortable: false },
      { text: '宗派', value: 'denomination', sortable: false },
      { text: '会館名', value: 'hall_name', sortable: false },
      { text: '会館名（詳細）', value: 'hall_name_detail', sortable: false },
      { text: '種別', value: 'type', sortable: false },
      { text: 'メモ', value: 'memo', sortable: false },
      { text: '', value: 'memo', sortable: false }
    ],
    worktimeItems: [],
    projectRules: [
      v => !!v || '作業を選択して下さい。'
    ],
    areaRules: [
      v => !!v || '地域を選択して下さい。'
    ],
    denominationRules: [
      v => !!v || '宗派を入力して下さい。'
    ],
    hallNameRules: [
      v => !!v || '会館を選択して下さい。'
    ],
    typeRules: [
      v => !!v || '種別を選択して下さい。'
    ],
    beginTimeRules: [
      v => !!v || '開始時間を選択して下さい。'
    ],
    endTimeRules: [
      v => !!v || '終了時間を選択して下さい。'
    ],
    valid: true,
    staffByWorktimeItems: [],
    defaultWorktimeItems: [],
    typeItems: ['告別式', '火葬場（会）', '火葬場（直）', '精進', '通夜'],
    selectHallNameItems: [],
    matsuyamaHallNameItems: ['天山', '枝松', '道後', '空港通', '井門', '鷹子', '三津', '吉藤', '和気', '重信', '川内', '松前', '伊予', '土居田', 'プライベートホール縁（吉藤）', 'プライベートホール縁（枝松）'],
    toyoHallNameItems: ['坂井', '菊本', '萩生', '郷', '西条', '川之江', '川之江別館', '三島', '三島別館', 'プライベートホール縁（坂井）', '法要会館 萩生'],
    areaItems: ['東予', '松山', '徳島'],
    addWorkShiftDialog: false,
    workShiftDialog: false,
    tomorrow: moment().add(1, 'days').format('YYYY/MM/DD(ddd)'),
    confirmEventDate: moment().add(1, 'days').format('YYYY/MM/DD(ddd)'),
    items3: [
      {
        text: '勤務時間',
        disabled: false,
        href: '/#/manager/worktime'
      },
      {
        text: '日別勤務シフト',
        disabled: true
      }
    ],
    isCompleted: 0,
    snackbar: false,
    select: '',
    items: [
      { state: '勤務可能', abbr: 'workable' },
      { state: '時間指定', abbr: 'specified_time' },
      { state: '休み', abbr: 'not_possible' }
    ],
    select2: '',
    items2: [
      { state: '勤務可能', abbr: 'workable' },
      { state: '休み', abbr: 'not_possible' }
    ],
    confirmEventItems: [],
    category: 'radio-1',
    countDisplay: '',
    calendarStart: moment().format('YYYY-MM'),
    selectedDefaultWorktime: {},
    selectedDefaultWorktime2: {},
    defaultWorktimeSelectMenu: false,
    defaultWorktimeSelectMenu2: false,
    eventItems2: [],
    staffSelectMenu: false,
    dayAlertJudgment: true,
    staffAlertJudgment: true,
    beginTimeModal: false,
    beginTimeModalForEdit: false,
    endTimeModal: false,
    endTimeModalForEdit: false,
    selectedStaffIds: [],
    beginTime: '00:00',
    endTime: '00:00',
    editedWorkableShiftItem: {},
    editedWorktimeItem: {},
    editDialog: false,
    worktimePlanDialog: false,
    worktimePlanDialogPage: 1,
    workableShiftItems: [],
    highlightDays: {}
  }),
  watch: {
    'editedWorktimeItem.area' () {
      this.selectHallNameItems = []
      if (this.editedWorktimeItem.area === '東予') {
        this.selectHallNameItems = this.toyoHallNameItems
      } else if (this.editedWorktimeItem.area === '松山') {
        this.selectHallNameItems = this.matsuyamaHallNameItems
      }
    },
    group () {
      this.getWorkAbleShift()
    },
    'editedWorktimeItem.type' () {
      let wakeId = 0 // 通夜id
      let dedicationId = 0 // 献茶id

      Object.keys(this.projectNameObj).forEach((id) => {
        if (this.projectNameObj[id] === '通夜') {
          wakeId = id
        } else if (this.projectNameObj[id] === '献茶') {
          dedicationId = id
        }
      })
      if (this.editedWorktimeItem.type === '通夜') {
        this.editedWorktimeItem.project_id = wakeId
      } else {
        this.editedWorktimeItem.project_id = dedicationId
      }
    },
    worktimePlanDialog (value) {
      if (value === false) {
        this.staffSelectMenu = false
      }
      this.$emit('month-changed', { start: '2019-12-01', end: '2019-12-31' })
    },
    selectedStaffIds () {
      if (this.selectedStaffIds.length === 0) {
        this.staffAlertJudgment = true
      } else {
        this.staffAlertJudgment = false
      }
    },
    highlightDays: {
      handler: function (val) {
        if (Object.keys(this.highlightDays).length === 0) {
          this.dayAlertJudgment = true
        } else {
          this.dayAlertJudgment = false
        }
      },
      deep: true
    }
  },
  computed: {
    formatedCalendarStart () {
      let ym = this.calendarStart.split('-')
      return ym[0] + '年 ' + ym[1] + '月の勤務可能日を送信する'
    },
    dayObj () {
      let dayObj = {}
      this.workableShiftItems.forEach((item) => {
        if (!dayObj[moment(item.workable_date).format('YYYY-MM-DD')]) {
          dayObj[moment(item.workable_date).format('YYYY-MM-DD')] = []
        }
        dayObj[moment(item.workable_date).format('YYYY-MM-DD')].push(item)
      })

      Object.keys(dayObj).forEach((dayItem) => {
        dayObj[dayItem].sort(function (a, b) {
          if (a.staff_id < b.staff_id) return -1
          if (a.staff_id > b.staff_id) return 1
          return 0
        })
      })
      return dayObj
    },
    newEvents () {
      let obj = {}
      Object.keys(this.dayObj).forEach((day) => {
        let type = this.dayObj[day][0].category

        if (!obj[moment(day).format('YYYY-MM-DD')]) {
          obj[moment(day).format('YYYY-MM-DD')] = []
        }
        obj[moment(day).format('YYYY-MM-DD')].push({
          title: '',
          start: moment(day).toDate(),
          end: null,
          type: type
        })
      })
      return obj
    },
    events () {
      let items = []
      Object.keys(this.dayObj).forEach((day) => {
        items.push({
          title: this.dayObj[day].length + this.countDisplay,
          start: moment(day).toDate(),
          end: null,
          type: 'work'
        })
      })
      return items
    },
    dayList () {
      let list = []
      Object.keys(this.highlightDays).forEach((date) => {
        list.push(date)
      })
      return list
    }
  },
  mounted () {
    this.getDefaultWorktime()
    this.getWorkAbleShift()
    this.getWorktime()
    this.getProject()
  },
  methods: {
    checkIfRead (staffId) {
      let isRead = 0
      this.worktimeItems.forEach((item) => {
        if (item.staff_id === staffId) {
          if (this.tomorrow === moment(item.worktime_date).format('YYYY/MM/DD(ddd)') && item.is_read === 1) {
            isRead = 1
          } else {
            isRead = 0
          }
        }
      })
      if (isRead) {
        return true
      } else {
        return false
      }
    },
    checkIfContacted (staffId) {
      let isContacted = 0
      this.worktimeItems.forEach((item) => {
        if (item.staff_id === staffId) {
          if (this.tomorrow === moment(item.worktime_date).format('YYYY/MM/DD(ddd)') && item.is_contacted === 1) {
            isContacted = 1
          } else {
            isContacted = 0
          }
        }
      })
      if (isContacted) {
        return true
      } else {
        return false
      }
    },
    closeWorkShiftDialog () {
      this.staffByWorktimeItems = []
      this.workShiftDialog = false
    },
    cancelWorkShift () {
      if (confirm(this.editedWorkableShiftItem.name + 'の勤務シフトの確定を解除して、' + this.editedWorkableShiftItem.name + 'へメールでお知らせします。\nよろしいですか？')) {
        let staffId = 0
        let worktimeIdList = []

        staffId = this.staffByWorktimeItems[0].staff_id
        this.staffByWorktimeItems.forEach((item) => {
          worktimeIdList.push(item.id)
        })

        axios.put('/api/manager/worktime/cancel/' + staffId, { 'worktime_id_list': worktimeIdList })
          .then((res) => {
            this.getWorktime()
            this.workShiftDialog = false
            this.staffByWorktimeItems = []
          })
          .catch(() => {
            alert('更新エラー')
          })
      }
    },
    fixWorkShift () {
      if (confirm(this.editedWorkableShiftItem.name + 'の勤務シフトを確定し、' + this.editedWorkableShiftItem.name + 'へメール送信します。\nよろしいですか？')) {
        let staffId = 0
        let worktimeIdList = []

        staffId = this.staffByWorktimeItems[0].staff_id
        this.staffByWorktimeItems.forEach((item) => {
          worktimeIdList.push(item.id)
        })

        axios.put('/api/manager/worktime/contacted/' + staffId, { 'worktime_id_list': worktimeIdList })
          .then((res) => {
            this.getWorktime()
            this.workShiftDialog = false
            this.staffByWorktimeItems = []
          })
          .catch(() => {
            alert('更新エラー')
          })
      }
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((pRes) => {
          this.projectNameObj = {}
          this.projectItems = []
          this.projectItems = pRes.data

          pRes.data.forEach((item) => {
            if (!this.projectNameObj[item.id]) {
              this.projectNameObj[item.id] = item.name
            }
          })
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    deleteItem (item) {
      let confirmText = item.staff_name + ' の\n' +
        item.begin_time + '-' + item.end_time + ' を削除します。'
      if (confirm(confirmText)) {
        axios.delete('/api/manager/worktime/' + item.id)
          .then(() => {
            this.workShiftDialog = false
            this.getWorktime()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    addWorkShift () {
      this.$refs.form.resetValidation()
      this.editedWorktimeItem = {}
      this.editedWorktimeItem.staff_id_list = [this.editedWorkableShiftItem.staff_id]
      this.addWorkShiftDialog = true
    },
    editItem (item) {
      let tmpItem = cloneDeep(item)
      this.editedWorktimeItem = tmpItem
      this.addWorkShiftDialog = true
    },
    getWorktime () {
      axios.get('/api/manager/worktime?ym=' + this.calendarStart)
        .then((res) => {
          this.worktimeItems = res.data
          this.confirmshiftTotal = []
          let obj = {}

          res.data.forEach((item) => {
            let targetDate = moment(item.worktime_date).format('YYYY/MM/DD(ddd)')

            if (targetDate === this.confirmEventDate) {
              if (!obj[item.area]) {
                obj[item.area] = {}
              }
              if (!obj[item.area][item.denomination]) {
                obj[item.area][item.denomination] = {}
              }
              if (!obj[item.area][item.denomination][item.hall_name]) {
                obj[item.area][item.denomination][item.hall_name] = {}
              }
              if (!obj[item.area][item.denomination][item.hall_name][item.staff_id]) {
                obj[item.area][item.denomination][item.hall_name][item.staff_id] = []
              }
              obj[item.area][item.denomination][item.hall_name][item.staff_id].push(item)
            }
          })

          Object.keys(obj).forEach((area) => {
            let denominationItems = []
            Object.keys(obj[area]).forEach((denomination) => {
              let hallNameItems = []
              Object.keys(obj[area][denomination]).forEach((hallName) => {
                let staffItems = []
                Object.keys(obj[area][denomination][hallName]).forEach((staffId) => {
                  obj[area][denomination][hallName][staffId].forEach((staffItem) => {
                    staffItems.push(staffItem)
                  })
                })
                hallNameItems.push({
                  hallName: hallName,
                  staffItems: staffItems
                })
              })
              denominationItems.push({
                denomination: denomination,
                hallNameItems: hallNameItems
              })
            })

            this.confirmshiftTotal.push({
              area: area,
              denomination: denominationItems
            })
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getWorktime]')
        })
    },
    postWorktimeShift () {
      if (this.$refs.form.validate()) {
        if (this.editedWorktimeItem.id) {
          axios.put('/api/manager/worktime/' + this.editedWorktimeItem.id, this.editedWorktimeItem)
            .then((res) => {
              this.addWorkShiftDialog = false
              this.workShiftDialog = false
              this.editedWorkableShiftItem = {}
              this.editedWorktimeItem = {}
              this.getWorktime()
              this.beginTime = '00:00'
              this.endTime = '00:00'
            })
            .catch(() => {
              alert('登録エラー')
            })
        } else {
          this.editedWorktimeItem.date_list = [moment(this.confirmEventDate, 'YYYY/MM/DD(ddd)').format('YYYY-MM-DD')]
          axios.post('/api/manager/worktime', this.editedWorktimeItem)
            .then((res) => {
              this.addWorkShiftDialog = false
              this.workShiftDialog = false
              this.editedWorkableShiftItem = {}
              this.editedWorktimeItem = {}
              this.getWorktime()
              this.beginTime = '00:00'
              this.endTime = '00:00'
            })
            .catch(() => {
              alert('登録エラー')
            })
        }
      }
    },
    getDefaultWorktime () {
      axios.get('/api/manager/default-worktime')
        .then((res) => {
          this.defaultWorktimeItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getDefaultWorktime]')
        })
    },
    eventDate (eventItems) {
      return moment(eventItems[0].workable_date).format('YYYY/MM/DD(ddd)')
    },
    putWorkableShift () {
      let ym = this.calendarStart.split('-')
      let confirmText = '作成された「 ' + ym[0] + '年 ' + ym[1] + '月 」の勤務可能日を管理者へ送信します。\nよろしいですか？'

      if (confirm(confirmText)) {
        axios.put('/api/send-workable-shift', { 'calendar_start': this.calendarStart })
          .then((res) => {
            this.getWorkAbleShift()
          })
          .catch((x) => {
            alert(x.response.data.error)
          })
      }
    },
    showWorktimePlan () {
      this.highlightDays = {}
      this.selectedStaffIds = []
      this.endTime = '00:00'
      this.beginTime = '00:00'
      this.worktimePlanDialog = true
    },
    monthChanged (event) {
      this.getWorkAbleShift()
    },
    editWorkableShift (item) {
      this.staffByWorktimeItems = []
      let tmpItem = cloneDeep(item)
      // tmpItem.date = moment(this.editedWorkableShiftItem.worktime_date).format('YYYY-MM-DD')
      this.editedWorkableShiftItem = tmpItem
      this.editedWorktimeItem.staff_id_list = [tmpItem.staff_id]

      this.worktimeItems.forEach((item) => {
        let targetDate = moment(item.worktime_date).format('YYYY/MM/DD(ddd)')

        if (item.staff_id === tmpItem.staff_id && targetDate === this.confirmEventDate) {
          this.staffByWorktimeItems.push(item)
        }
      })

      this.workShiftDialog = true
    },
    eventClicked (event = '') {
      this.getWorktime()
      this.eventItems2 = []
      this.confirmEventItems = []
      let clickedDayObj = {}
      let items = []

      let tomorrowObj = this.dayObj[moment(event.start).add(1, 'days').format('YYYY-MM-DD')]
      items = (cloneDeep(tomorrowObj))

      if (event) {
        clickedDayObj = this.dayObj[moment(event.start).format('YYYY-MM-DD')]
        items = (cloneDeep(this.dayObj[moment(event.start).format('YYYY-MM-DD')]))
        this.confirmEventDate = moment(event.start).format('YYYY/MM/DD(ddd)')
      } else {
        clickedDayObj = this.dayObj[moment().subtract(4, 'days').format('YYYY-MM-DD')]
      }

      items.forEach((item) => {
        if (item.category !== 'not_possible') {
          this.confirmEventItems.push(item)
        }
      })

      for (let i = 0; i < 6; i++) {
        if (clickedDayObj) {
          this.eventItems2.push(cloneDeep(clickedDayObj))
          if (event) {
            clickedDayObj = this.dayObj[moment(event.start).add(i + 1, 'days').format('YYYY-MM-DD')]
          } else {
            clickedDayObj = this.dayObj[moment().subtract(4, 'days').add(i + 1, 'days').format('YYYY-MM-DD')]
          }
        }
      }
    },
    dayClicked (date) {
      let days = cloneDeep(this.highlightDays)
      if (days[date]) {
        delete days[date]
      } else {
        days[date] = true
      }
      this.highlightDays = cloneDeep(days)
    },
    deleteWorktime (item) {
      let confirmText = item.staff_name + ' の\n' +
        item.begin_time + '-' + item.end_time + ' を削除します。'
      if (confirm(confirmText)) {
        axios.delete('/api/manager/worktime/' + item.id)
          .then(() => {
            this.getWorktime()
            setTimeout(() => {
              let tmpItems = []
              this.eventItems.forEach((eventItem) => {
                if (eventItem.id !== item.id) {
                  tmpItems.push(eventItem)
                }
                this.eventItems = tmpItems
              })
            }, 300)
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    workableSave () {
      let postDataObj = {}
      postDataObj['date_list'] = this.dayList
      postDataObj['begin_time'] = this.beginTime
      postDataObj['end_time'] = this.endTime
      postDataObj['category'] = this.category
      postDataObj['funeral_category'] = this.select
      postDataObj['wake_category'] = this.select2

      axios.post('/api/workable-shift', postDataObj)
        .then((res) => {
          this.worktimePlanDialog = false
          this.getWorkAbleShift()
          this.snackbar = true
          setTimeout(() => {
            this.beginTime = '00:00'
            this.endTime = '00:00'
            this.category = ''
            this.select = ''
            this.select2 = ''
            this.worktimePlanDialogPage = 1
            this.highlightDays = {}
          }, 300)
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    getWorkAbleShift () {
      axios.get('/api/manager/workable-shift?ym=' + this.calendarStart + '&range=three')
        .then((res) => {
          this.eventItems2 = []
          this.confirmEventItems = []
          this.workableShiftItems = []
          this.isCompleted = 0

          if (this.group !== '指定なし') {
            res.data.forEach((item) => {
              if (item.group_name === this.group) {
                this.workableShiftItems.push(item)
              }
            })
          } else {
            this.workableShiftItems = res.data
          }
          if (this.workableShiftItems.length > 0) {
            this.eventClicked()
          }
        })
        .catch(() => {
          // alert('取得エラー')
        })
    },
    toSelect () {
      this.worktimePlanDialogPage = 2
    },
    toConfirm () {
      this.worktimePlanDialogPage = 3
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.scroller {
  width: 100%;
  height: 38vh;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
.daily-worktime-th {
  font-weight: bold;
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
  font-weight: bold;
  padding-top: 1.2em !important
}
.border {
  border-left: 1px solid rgba(0,0,0,0.12);
  border-bottom: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.border2 {
  border-left: 1px solid rgba(0,0,0,0.12);
  border-right: 1px solid rgba(0,0,0,0.12);
  border-bottom: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}

</style>
