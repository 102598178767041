<template>
  <div>
    <MyToolbar />
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="beginDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="beginDate"
              label="日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="beginDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="beginDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="endDateMenu = false"
            ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white" class="py-4">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">日別データ締め処理</v-toolbar-title>
        <v-spacer></v-spacer>
        <span class="subheading">チェックされた日を全て</span>
        <ul style="list-style: none;">
          <li>
            <v-btn color="orange lighten-1" dark class="ma-2" @click="processClosing()">
              締め処理をする
            </v-btn>
          </li>
          <li>
            <v-btn color="blue-grey lighten-3" dark class="ma-2" @click="cancelClosing()">
              締めを解除する
            </v-btn>
          </li>
        </ul>
      </v-toolbar>
      <div style="display: block">
        <v-layout row wrap class="timetable aggregate-header">
          <v-flex xs1 class="pa-2 aggregate-th" style="font-weight: bold; padding-top: 1.6em !important">
            締め
          </v-flex>
          <v-flex xs1 class="pa-2 aggregate-th" style="font-weight: bold; padding-top: 1.6em !important">
            解除
          </v-flex>
          <v-flex xs2 class="pa-2 text-xs-center aggregate-th" style="font-weight: bold; padding-top: 1.6em !important">
            日付
          </v-flex>
          <v-flex xs8>
            <v-layout>
              <v-flex xs3 class="pa-2 aggregate-th" style="padding-top: 1.6em !important">
                打刻漏れ
              </v-flex>
              <v-flex xs3 class="pa-2 aggregate-th" style="padding-top: 0.9em !important">
                未承認<br>休暇申請
              </v-flex>
              <v-flex xs3 class="pa-2 aggregate-th" style="padding-top: 0.9em !important">
                未承認<br>休日出勤申請
              </v-flex>
              <v-flex xs3 class="pa-2 aggregate-th" style="padding-top: 1.6em !important">
                状態
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
        </v-layout>
        <v-alert :value="worktimeRecordItems.length == 0" color="error" icon="warning" outline>
          データがありません
        </v-alert>
      </div>
      <div class="tbody" style="font-size: 15px">
        <v-layout v-for="(item, idx) in automatic" :key="item.date" row wrap class="timetable">
          <v-flex
            xs1
            class="automatic-aggregate-td"
            v-on:mouseover="mouseover(idx)"
            v-on:mouseleave="mouseleave"
            :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
          >
            <v-checkbox
              :disabled="closingIspossibleToDetermine(item)"
              v-model="selected"
              :value="item.date"
              style="padding-left: 2.2em;margin-top: 0.25em !important"
            ></v-checkbox>
          </v-flex>
          <v-flex
            xs1
            class="automatic-aggregate-td"
            v-on:mouseover="mouseover(idx)"
            v-on:mouseleave="mouseleave"
            :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
          >
            <v-checkbox
              :disabled="checkIfClosed(item)"
              v-model="cancelSelected"
              :value="item.date"
              style="padding-left: 2.2em;margin-top: 0.25em !important"
            ></v-checkbox>
          </v-flex>
          <v-flex
            xs2
            class="pa-2 automatic-aggregate-td"
            v-on:mouseover="mouseover(idx)"
            v-on:mouseleave="mouseleave"
            :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
          >
            <span class="subheading">{{ item.date }}</span>
          </v-flex>
          <v-layout xs8>
            <v-flex
              xs3
              class="pa-2 automatic-aggregate-td"
              v-on:mouseover="mouseover(idx)"
              v-on:mouseleave="mouseleave"
              :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
            >
              <span class="subheading">{{ item.has_error }}</span>
            </v-flex>
            <v-flex
              xs3
              :key="`name-${idx}`"
              class="pa-2 automatic-aggregate-td"
              v-on:mouseover="mouseover(idx)"
              v-on:mouseleave="mouseleave"
              :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
            >
              {{ item.unapproved_leave_application }}
            </v-flex>
            <v-flex
              xs3
              :key="`name2-${idx}`"
              class="pa-2 automatic-aggregate-td"
              v-on:mouseover="mouseover(idx)"
              v-on:mouseleave="mouseleave"
              :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
            >
              {{ item.unapproved_holiday_working_application }}
            </v-flex>
            <v-flex
              xs3
              :key="`attendance-${idx}`"
              class="pa-2 automatic-aggregate-td"
              v-on:mouseover="mouseover(idx)"
              v-on:mouseleave="mouseleave"
              :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
            >
              <span v-if="item.closed_id !== 0" style="color: orange">締め済み</span>
              <span v-else>修正受付中</span>
            </v-flex>
          </v-layout>
          <v-flex
            v-if="idx !== processedItems.length - 1"
            xs12
          >
            <v-divider />
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import itemTools from '@/mixins/item_tools.js'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CsvDownload',
  mixins: [itemTools],
  components: {
    MyToolbar
  },
  data: () => ({
    holidayWorkingItems: [],
    projectItems: [],
    selected: [],
    cancelSelected: [],
    automaticAggregateItems: [],
    automatic: [],
    bg: '',
    companyItem: {},
    leaveApplicationItems: [],
    leaveApplicationDataItems: {
      paid_leave: '有給休暇',
      am_paid_leave: '午前有給休暇',
      pm_paid_leave: '午後有給休暇',
      time_paid_leave: '時間有給',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      general_absence: '一般欠勤',
      disease_scratch_absence: '病傷欠勤',
      closing: '休業',
      time_closing: '時間休業',
      public_holiday: '公休日'
    },
    beginDateMenu2: false,
    endDateMenu2: false,
    csvOutputDialog: false,
    csvBeginDate: moment().date(16).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    csvEndDate: moment().add(1, 'months').date(15).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    headers: [
      { text: '氏名', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '出勤', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '退勤', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '休憩', value: 'name', sortable: false, class: 'aggregate-header' },
      { text: '労働時間', value: 'name', sortable: false, class: 'aggregate-header' }
    ],
    worktimeRecordItems: []
  }),
  filters: {
    changeNotationToHourTime: function (minute) {
      if (minute === 'leave-application') {
        return ''
      } else if (minute === '') {
        return ''
      } else if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    }
  },
  watch: {
    beginDate () {
      this.getWorktimeRecord()
      this.getLeaveApplication()
      this.getHolidayWorkingItems()
    },
    endDate () {
      this.getWorktimeRecord()
      this.getLeaveApplication()
      this.getHolidayWorkingItems()
    }
  },
  mounted () {
    this.getProject()
    this.getCompany()
    this.getWorktimeRecord()
    this.getLeaveApplication()
    this.getHolidayWorkingItems()
  },
  methods: {
    getHolidayWorkingItems () {
      axios.get('/api/manager/holiday-work-application?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.holidayWorkingItems = res.data
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.projectItems = []
          this.projectItems = res.data
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    cancelClosing () {
      if (this.cancelSelected.length === 0) {
        if (confirm('日がチェックされていません。')) {
        }
      } else {
        let editedItem = {
          'cancel_date_list': this.cancelSelected
        }
        if (confirm('締めを解除します。')) {
          axios.put('/api/manager/cancel/', editedItem)
            .then(() => {
              this.getCompany()
              this.getWorktimeRecord()
              this.getLeaveApplication()
              this.getHolidayWorkingItems()
              this.cancelSelected = []
            })
            .catch((x) => {
              alert(x.response.data.error)
            })
        }
      }
    },
    processClosing () {
      if (this.selected.length === 0) {
        if (confirm('日がチェックされていません。')) {
        }
      } else {
        let editedItem = {
          'date_list': this.selected
        }
        if (confirm('締め処理を実行します。')) {
          axios.put('/api/manager/closed/', editedItem)
            .then(() => {
              this.getCompany()
              this.getWorktimeRecord()
              this.getLeaveApplication()
              this.getHolidayWorkingItems()
              this.selected = []
            })
            .catch(() => {
              alert('締めエラー')
            })
        }
      }
    },
    closingIspossibleToDetermine (item) {
      if (
        item.has_error === 0 &&
        item.unapproved_leave_application === 0 &&
        item.unapproved_holiday_working_application === 0 &&
        item.closed_id === 0
      ) {
        return false
      } else {
        return true
      }
    },
    checkIfClosed (item) {
      if (item.closed_id !== 0) {
        return false
      } else {
        return true
      }
    },
    processedItems () {
      let pItems = this.processItems(this.worktimeRecordItems, '指定なし', this.companyItem, 'closed', this.projectItems)
      let pItems2 = []

      pItems.forEach((item) => {
        if (item.date >= this.beginDate) {
          pItems2.push(item)
        }
      })

      this.automaticAggregateItems = pItems2
      this.getLeaveApplication()
    },
    mouseleave () {
      this.bg = ''
    },
    mouseover (idx) {
      this.bg = idx
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    add24Hours (leaveTime) {
      return Number(leaveTime.slice(0, 2)) + 24 + leaveTime.slice(2, 5)
    },
    convAtTime (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm')
    },
    convAtDate (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getLeaveApplication () {
      this.leaveApplicationItems = []
      axios.get('/api/manager/leave-application?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          let editedItem = {}
          let lItems = []
          let defaultEditedItem = {
            staff_id: 0,
            name: '',
            application_date: '',
            attendance: '',
            leave: '',
            is_holiday: 0,
            is_approved: 0,
            is_early_withdrawal: 0,
            early_withdrawal_time: '',
            rest_minutes: '',
            work_minute: '',
            record_at: '',
            closed_id: 0,
            legal_overtime_working_minutes: 'leave-application',
            non_statutory_working_minutes: 'leave-application',
            overtime_workday_minutes: 'leave-application',
            overtime_workday_midnight_minutes: 'leave-application',
            overtime_holiday_workday_midnight_minutes: 'leave-application',
            hasError: false,
            errorText: ''
          }
          res.data.forEach((item) => {
            editedItem = cloneDeep(defaultEditedItem)
            editedItem.attendance = this.leaveApplicationDataItems[item.category]
            editedItem.staff_id = item.staff_id
            editedItem.name = item.staff_name
            editedItem.application_date = item.application_date
            editedItem.category = item.category
            editedItem.closed_id = item.closed_id
            editedItem.is_approved = item.is_approved
            lItems.push(cloneDeep(editedItem))
          })
          this.leaveApplicationItems = lItems
          this.automatic = this.processItemsForClosing(this.automaticAggregateItems, this.leaveApplicationItems, this.holidayWorkingItems)
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    getWorktimeRecord () {
      axios.get('/api/manager/worktime-record?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.worktimeRecordItems = res.data
          this.processedItems()
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    }
  }
}
</script>

<style>
.aggregate-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  font-size: 15px;
}
.aggregate-th {
  font-weight: bold;
  text-align: center;
}
.automatic-aggregate-td .v-messages {
  min-height: 0;
}
</style>

<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.tbody::-webkit-scrollbar {
    display:none;
}
.grayCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.whiteCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
