<template>
  <v-layout style="background-color: white" column fill-height>
    <MobileHeader :val=staffName />
    <v-flex xs12>
      <v-card tile class="ma-2">
        <v-alert type="error" :value="showNoAttendanceAlert">出勤が未登録</v-alert>
        <v-list>
          <template v-for="item in processedItems">
            <v-alert
              :key="`alert-${item.id}`"
              type="error"
              :value="item.alert !== ''"
              v-html="item.alert"
            />
            <v-list-tile :key="item.id">
              <v-list-tile-content>
                {{ convAt(item.without_change_record_at) }}
              </v-list-tile-content>
              <v-list-tile-content>
                <v-list-tile-title>{{ convName(item.category) }}</v-list-tile-title>
                <v-list-tile-sub-title
                  v-if="item.project_id !== 0"
                >
                  {{ projectNameObj[item.project_id] }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title
                  v-if="item.body_temperature !== '' && item.category === 'attendance'"
                >{{ item.body_temperature }}℃</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn v-if="item.category !== 'attendance' && item.category !== 'leave'" icon ripple>
                  <v-icon
                    color="red darken-1"
                    @click="deleteConfirm(item)"
                    :disabled='determineClosing(item)'
                >highlight_off</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              v-if="item !== worktimeRecordItems[processedItems.length-1]"
              :key="`divider-${item.id}`"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex xs12 v-if="requestOvertimeItems.length">
      <v-card tile class="ma-2">
        <v-list>
          <template v-for="item in requestOvertimeItems">
            <v-list-tile :key="item.id">
              <v-list-tile-content>
                {{
                  item.request_time_start + ' ～ '
                    + item.request_time_end + '　'
                    + listCalculationWorktime(item)
                }}
              </v-list-tile-content>
              <v-list-tile-content>
                <v-list-tile-title>{{ requestTypeName[item.request_type] }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-content>
                <v-list-tile-title
                  :class="`${requestStatusItems[item.request_status].color}--text font-weight-bold`"
                  style="text-align:right;"
                >
                  {{ requestStatusItems[item.request_status].status }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider
              v-if="item !== requestOvertimeItems[requestOvertimeItems.length-1]"
              :key="`divider-${item.id}`"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-card class="ma-2">
        <v-layout wrap class="text-xs-center">
          <v-flex xs6 v-if="isRecordBodyTemperature !== '1'" class="pa-2 mb-1">
            <v-btn
              outline
              color="light-blue darken-2"
              block
              style="height: 100px; border-width: 3px"
              :disabled="disabledAttendance"
              @click="postWorktimeRecord('attendance')"
            >
              <span class="title">出勤</span>
              <v-icon right dark>access_time</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs6 v-else class="pa-2 mb-1">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  outline
                  color="light-blue darken-2"
                  block
                  style="height: 100px; border-width: 3px"
                  :disabled="disabledAttendance"
                  v-on="on"
                >
                <span class="title">出勤</span>
                <v-icon right dark>access_time</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title
                  class="headline grey lighten-2"
                  primary-title
                >
                  現時点の体温
                </v-card-title>

                <v-card-text>
                  <v-layout>
                    <v-flex xs8>
                      <v-text-field
                        v-model="bodyTemperature"
                        @blur="$v.bodyTemperature.$touch()"
                        :error-messages="bodyTemperatureErrors"
                        placeholder="例：36.5"
                        suffix="℃"
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#6ac67b"
                    dark
                    @click="postBodyTemperature()"
                  >
                    体温を登録し、出勤打刻する
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs6 class="pa-2 mb-1">
            <v-btn
              block
              outline
              color="orange"
              style="height: 100px; border-width: 3px"
              :disabled="disabledButtons"
              @click="postWorktimeRecord('leave')"
            >
              <span class="title">退勤</span>
              <v-icon right dark>directions_run</v-icon>
            </v-btn>
          </v-flex>

          <v-flex xs12>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="show = !show">
                <v-icon
                  large
                  color="primary"
                >{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>

          <v-flex xs6 v-show="show" class="pa-2">
            <v-btn
              block
              large
              outline
              color="indigo"
              :disabled="disabledButtons"
              @click="postWorktimeRecord('rest')"
            >
              休入
              <v-icon right dark>local_cafe</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs6 v-show="show" class="pa-2">
            <v-btn
              block
              large
              outline
              color="indigo"
              :disabled="disabledButtons"
              @click="postWorktimeRecord('restart')"
            >
              休戻
              <v-icon right dark>reply</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs6 v-show="show" class="pa-2">
            <v-btn
              block
              large
              outline
              color="indigo darken-4"
              @click="leavingSpanningDate()"
            >
              日をまたぐ退勤
            </v-btn>
          </v-flex>

          <!--
          <v-flex xs6 v-show="show" class="pa-2">
            <v-btn
              v-if="isSoul"
              block
              large
              outline
              round
              color="green darken-2"
              @click="$router.push('shift-confirm')"
            >
              シフト確認
              <v-icon right dark>event</v-icon>
            </v-btn>
            <v-btn
              v-else
              block
              large
              outline
              round
              color="green darken-2"
              @click="$router.push('worktime')"
            >
              予定確認
              <v-icon right dark>event</v-icon>
            </v-btn>
          </v-flex>
          -->
          <v-flex xs6 v-show="show" class="pa-2">
            <v-btn
              block
              large
              outline
              round
              color="#E57373"
              @click="$router.push('leave-application')"
            >
              休暇申請
              <v-icon right dark>edit</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs6 v-if="isSoul" v-show="show" class="pa-2">
            <v-btn block large outline round
              color="green darken-2"
              @click="$router.push('shift-application')"
            >
              勤務可能日作成
              <v-icon right dark class="ml-0">create</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs6 v-show="show" class="pa-2">
            <v-btn
              block
              large
              outline
              round
              color="#E57373"
              @click="$router.push('request-overtime-work')"
            >
              時間外勤務申請
              <v-icon right dark>edit</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-dialog v-model="confirmDialog" persistent max-width="500px">
      <v-card style="height: 30vh">
        <v-layout>
          <v-flex xs2 class="ml-3" style="margin-top: 6em">
            <v-icon color="success">done_outline</v-icon>
          </v-flex>
          <v-flex xs10  class="mt-5">
            <p style="font-size: 1.8em;">
              <span style="border-bottom: solid 3px #e9eef7;">{{ staffName + 'さん' }}</span>
            </p>
            <p style="font-size: 1.5em" v-if="projectId !== 0">
              {{ projectNameObj[projectId] }}の作業で、
            </p>
            <span
              style="font-size: 1.5em"
              :style="{color: category === 'attendance' ? '#1976D2' : 'orange'}"
            >
              {{ worktimeRecordNames[category] }}
            </span>
            <span style="font-size: 1.2em">の登録が完了しました。</span>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="projectDialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          作業選択
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-layout row wrap align-center>
              <v-flex xs6>
                <v-subheader>作業</v-subheader>
              </v-flex>

              <v-flex xs6>
                <v-select
                  v-model="projectId"
                  :items="projectItems"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  single-line
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            @click="cancel()"
          >
            キャンセル
          </v-btn>
          <v-btn
            flat
            v-if="projectId === 0"
            disabled
          >
            作業を選択してください
          </v-btn>
          <v-btn
            color="success"
            flat
            v-else
            @click="shiftConfirm()"
          >
            出勤登録する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import dayjs from 'dayjs'
import itemTools from '@/mixins/item_tools.js'
import { validationMixin } from 'vuelidate'
import moment from 'moment-timezone'
import MobileHeader from '@/components/MobileHeader'

export default {
  mixins: [validationMixin, itemTools],
  name: 'MobileWorktimeRecord',
  components: {
    MobileHeader
  },
  validations: {
    bodyTemperature: {
      checkNotation () {
        if (this.bodyTemperature !== undefined && this.bodyTemperature !== '') {
          let bodyTemperature = (this.bodyTemperature).match(/^[3][5-9]+.+[0-9]+$/)
          if (!(bodyTemperature)) {
            return false
          }
        }
        return true
      },
      checkNotation2 () {
        if (this.bodyTemperature === undefined || this.bodyTemperature === '') {
          return false
        }
        return true
      }
    }
  },
  data: () => ({
    show: true,
    isLeavingSpanningDate: 0,
    projectId: 0,
    projectDialog: false,
    projectNameObj: {},
    projectItems: [],
    bodyTemperature: '',
    isRecordBodyTemperature: 0,
    dialog: false,
    category: '',
    confirmDialog: false,
    worktimeRecordItems: [],
    requestOvertimeItems: [],
    worktimeRecordNames: {
      attendance: '出勤',
      leave: '退勤',
      outOfOffice: '外出',
      returnToOffice: '外戻',
      rest: '休入',
      restart: '休戻',
      amRest: '午前休入',
      amRestart: '午前休戻',
      pmRest: '午後休入',
      pmRestart: '午後休戻'
    },
    requestTypeName: {
      overtime_work: '残業',
      early_attendant: '早出',
      holiday_work: '休日勤務'
    },
    requestStatusItems: [
      { id: 0, status: '申請中', color: 'orange' },
      { id: 1, status: '承認済み', color: 'cyan' },
      { id: 2, status: '再申請中', color: 'deep-orange' },
      { id: 3, status: '申請拒否', color: 'pink' },
      { id: 4, status: '申請取下げ', color: 'blue-grey' }
    ],
    company: null
  }),
  computed: {
    isSoul () {
      if (this.company === null) {
        return false
      }
      if (this.company.overtime_calc_for_soul === 1) {
        if (this.company.rest_registration_for_soul === 0) {
          return true
        }
      }
      return false
    },
    bodyTemperatureErrors () {
      const errors = []
      if (!this.$v.bodyTemperature.$dirty) return errors
      !this.$v.bodyTemperature.checkNotation && errors.push('形式が正しくありません')
      !this.$v.bodyTemperature.checkNotation2 && errors.push('入力は必須です')
      return errors
    },
    processedItems () {
      let items = []
      let countCategory = {
        attendance: 0,
        leave: 0,
        outOfOffice: 0,
        returnToOffice: 0,
        rest: 0,
        restart: 0
      }
      this.worktimeRecordItems.forEach((item, idx) => {
        item.alert = ''
        if (item.category === 'returnToOffice') {
          if (idx === 0) {
            item.alert = '外出が記録されていません。'
          } else if (countCategory.outOfOffice <= countCategory.returnToOffice) {
            item.alert = '外出が記録されていません。'
          }
        } else if (item.category === 'restart') {
          if (idx === 0) {
            item.alert = '休入が記録されていません。'
          } else if (countCategory.rest <= countCategory.restart) {
            item.alert = '休入が記録されていません。'
          }
        } else if (item.category === 'leave') {
          if (countCategory.rest !== countCategory.restart) {
            item.alert = '休戻が記録されていません。'
          }
          if (countCategory.outOfOffice !== countCategory.returnToOffice) {
            if (item.alert) {
              item.alert += '<br>外戻が記録されていません。'
            } else {
              item.alert = '外戻が記録されていません。'
            }
          }
        }
        items.push(item)

        switch (item.category) {
          case ('attendance'):
            countCategory.attendance++
            break
          case ('leave'):
            countCategory.leave++
            break
          case ('outOfOffice'):
            countCategory.outOfOffice++
            break
          case ('returnToOffice'):
            countCategory.returnToOffice++
            break
          case ('rest'):
            countCategory.rest++
            break
          case ('restart'):
            countCategory.restart++
            break
        }
      })
      return items
    },
    disabledAttendance () {
      if (this.projectNameObj === {}) {
        if (this.worktimeRecordItems.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    disabledButtons () {
      if (this.projectNameObj === {}) {
        if (this.worktimeRecordItems.some((item) => { return item.category === 'leave' })) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showNoAttendanceAlert () {
      if (this.worktimeRecordItems.length > 0) {
        if (!this.worktimeRecordItems.some((item) => { return item.category === 'attendance' })) {
          return true
        }
      }
      return false
    },
    ...mapState(['staffName'])
  },
  mounted () {
    this.isRecordBodyTemperature = localStorage.getItem('isRecordBodyTemperature')
    this.getWorktimeRecord()
    this.getProject()
    this.getCompany()
    this.getRequestOvertime()
  },
  methods: {
    getCompany () {
      axios.get('/api/company_for_user')
        .then((res) => {
          this.company = res.data
        })
        .catch((error) => {
          this.errorDecision(error, 'Error, get company!')
        })
    },
    leavingSpanningDate () {
      if (confirm('日をまたいだ退勤登録をします。\nよろしいですか？')) {
        this.isLeavingSpanningDate = 1
        this.postWorktimeRecord('leave')
      }
    },
    shiftConfirm () {
      axios.get('/api/shift-confirm?category=attendance' + '&project_id=' + this.projectId)
        .then((res) => {
          if (res.data.decision) {
            this.postWorktimeRecord('attendance')
          } else {
            if (confirm(res.data.message + '\nそれでも出勤登録しますか？')) {
              this.postWorktimeRecord('attendance')
            }
          }
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[shiftConfirm]')
        })
    },
    getProject () {
      axios.get('/api/project')
        .then((pRes) => {
          this.projectNameObj = {}
          this.projectItems = []
          this.projectId = 0

          this.projectItems = pRes.data

          pRes.data.forEach((item) => {
            if (!this.projectNameObj[item.id]) {
              this.projectNameObj[item.id] = item.name
            }
          })
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    determineClosing (item) {
      if (item.closed_id !== 0) {
        return true
      } else {
        return false
      }
    },
    postBodyTemperature () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.dialog = false
        this.projectDialog = false
        this.postWorktimeRecord('attendance')
        this.isRecordBodyTemperature = ''
      }
    },
    convAt (datetime) {
      return dayjs(datetime).format('HH:mm:ss')
    },
    convName (name) {
      return this.worktimeRecordNames[name]
    },
    postWorktimeRecord (category) {
      if (category === 'attendance' && this.projectItems.length > 0 && this.projectId === 0) {
        this.projectDialog = true
      } else {
        this.category = category

        let data = {
          category: category,
          body_temperature: this.bodyTemperature,
          project_id: this.projectId,
          is_leaving_spanning_date: this.isLeavingSpanningDate
        }
        new Promise((resolve, reject) => {
          if (this.company.is_activate_gps === 1) {
            navigator.geolocation.getCurrentPosition((position) => {
              data.latitude = position.coords.latitude
              data.longitude = position.coords.longitude
              resolve()
            }, () => {
              alert('位置情報取得エラー')
              reject(new Error('位置情報取得エラー'))
            })
          } else {
            resolve()
          }
        }).then(() => {
          axios.post('/api/user', data)
            .then(() => {
              this.isLeavingSpanningDate = 0
              this.projectDialog = false
              this.confirmDialog = true
              this.getWorktimeRecord()
              setTimeout(() => {
                this.category = ''
                this.confirmDialog = false
                this.getProject()
              }, 1000)
            })
            .catch(() => {
              alert('登録エラー')
            })
        })
        /*
        navigator.geolocation.getCurrentPosition((position) => {
          let data = {
            category: category,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            body_temperature: this.bodyTemperature
          }
          axios.post('/api/user', data)
            .then(() => {
              this.confirmDialog = true
              this.getWorktimeRecord()
              setTimeout(() => {
                this.confirmDialog = false
              }, 1000)
            })
            .catch(() => {
              alert('登録エラー')
            })
        }, () => {
          alert('位置情報取得エラー')
        })
        */
      }
    },
    deleteConfirm (item) {
      if (confirm(this.convName(item.category) + ' ' + this.convAt(item.without_change_record_at) + ' を削除します。')) {
        axios.delete('/api/user/' + item.id)
          .then(() => {
            this.getWorktimeRecord()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    getWorktimeRecord () {
      axios.get('/api/user')
        .then((res) => {
          this.worktimeRecordItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/', 'データエラー[getWorktimeRecord]')
        })
    },
    cancel () {
      this.getProject()
      this.category = ''
      this.projectDialog = false
    },
    getRequestOvertime () {
      axios.get('/api/user/request-overtimework-today')
        .then((res) => {
          this.requestOvertimeItems = res.data
          console.log(this.requestOvertimeItems)
        })
    },
    listCalculationWorktime (item) {
      let dateTo = moment(item.request_date + ' ' + item.request_time_start, 'YYYY-MM-DD H:mm')
      let dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm')
      // 残業終了時間が開始時間より前の場合
      if (dateFrom.isBefore(dateTo)) {
        dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm').add(1, 'd')
        this.isOvernightWork = true
        return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
      } else {
        this.isOvernightWork = false
      }
      return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
    }
  }
}
</script>
