<template>
  <v-combobox
    v-model="comboValue"
    :items="processedItems"
    label="グループ"
    prepend-icon="group"
  ></v-combobox>
</template>

<script>
import axios from 'axios'

export default {
  props: [ 'value' ],
  data: () => ({
    items: [],
    groupObj: { '指定なし': 0 }
  }),
  computed: {
    comboValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$parent.groupId = this.groupObj[newValue]
        this.$emit('update:value', newValue)
      }
    },
    processedItems () {
      let items = [ '指定なし' ]
      this.items.forEach((item) => {
        items.push(item.name)
      })
      return items
    }
  },
  mounted () {
    this.getStaff()
  },
  methods: {
    getStaff () {
      axios.get('/api/manager/group')
        .then((res) => {
          let items = []
          let parentNameObj = {}

          res.data.forEach((item) => {
            if (item.parent_id === 0) {
              if (!parentNameObj[item.id]) {
                parentNameObj[item.id] = {}
                parentNameObj[item.id] = item.name
              }
            }
          })
          res.data.forEach((item) => {
            if (item.parent_id === 0) {
              items.push(item)
            } else {
              item.name = parentNameObj[item.parent_id] + ' ＞ ' + item.name
              items.push(item)
            }
            if (!this.groupObj[item.name]) {
              this.groupObj[item.name] = item.id
            }
          })
          this.items = items
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/manager')
          } else {
            alert('データエラー')
          }
        })
    }
  }
}
</script>
