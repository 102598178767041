<template>
  <v-layout style="background-color: white" column fill-height>
    <MobileHader />
    <v-flex xs12>
      <v-card class="mb-2" style="min-height: 304px; width: 95%; margin: auto">
        <v-card-title
          class="headline"
          primary-title
        >
          時間外勤務申請
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout row wrap>
              <v-flex xs5>
                <v-select
                  v-model="editedItem.request_type"
                  label="種別"
                  :items="typeItems"
                  item-text="v"
                  item-value="k"
                  :rules="[v => !!v || '種別を選択してください']"
                  required
                />
              </v-flex>
              <v-flex xs2></v-flex>
              <v-flex xs5>
                <v-menu
                  v-model="requestDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.request_date"
                      label="勤務日"
                      readonly
                      v-on="on"
                      :rules="[v => !!v || '勤務日を設定してください']"
                      required
                    ></v-text-field>
                  </template>
                    <v-date-picker
                    v-model="editedItem.request_date"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    @input="requestDateMenu = false"
                    ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-dialog
                  ref="overtimeStartDialog"
                  v-model="overtimeStartModal"
                  :return-value.sync="editedItem.request_time_start"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.request_time_start"
                      label="開始時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                      :rules="[v => !!v || '開始時間を設定してください']"
                      required
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="overtimeStartModal"
                    :allowed-minutes="allowedStep"
                    v-model="editedItem.request_time_start"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="overtimeStartModal = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.overtimeStartDialog.save(editedItem.request_time_start)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs2>
                <div
                  class="text-xs-center"
                  style="font-size:1.2rem;padding-top:20px;"
                >
                  ～
                </div>
              </v-flex>
              <v-flex xs3>
                <v-dialog
                  ref="overtimeEndDialog"
                  v-model="overtimeEndModal"
                  :return-value.sync="editedItem.request_time_end"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.request_time_end"
                      label="終了時間"
                      readonly
                      v-on="on"
                      :rules="[v => !!v || '終了時間を設定してください']"
                      required
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="overtimeEndModal"
                    :allowed-minutes="allowedStep"
                    v-model="editedItem.request_time_end"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="overtimeEndModal = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.overtimeEndDialog.save(editedItem.request_time_end)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs3>
                <div
                  class="text-xs-center"
                  style="font-size:1.1rem;padding-top:20px;"
                >
                  {{ calculationWorktime }}
                </div>
              </v-flex>
              <v-flex xs12>
                <div>
                  <span class="red--text">{{ calculationWorktime2 }}</span>
                </div>
              </v-flex>
              <v-flex xs12 class="mt-3">
                <v-textarea
                  v-model="editedItem.request_comment"
                  box
                  label="時間外勤務の理由・備考"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="editStates === 'new'"
            outline
            color="grey"
            flat
            class="ma-a"
            @click="$router.push('/worktime-record')"
          >
            戻る
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editStates === 'edit'"
            outline
            color="red darken-4"
            flat
            class="ma-1"
            @click="deleteRequestOvertimeWork"
            >
            申請取下げ
          </v-btn>
          <v-btn
            outline
            color="grey"
            flat
            class="ma-1"
            @click="clearRequestOvertimeWork"
          >
            {{ clearButtonText }}
          </v-btn>
          <v-btn
            v-if="editStates === 'new'"
            outline
            color="success"
            flat
            class="ma-1"
            @click="postRequestOvertimeWork"
          >
            申請
          </v-btn>
          <v-btn
            v-if="editStates === 'edit'"
            outline
            color="success"
            flat
            class="ma-1"
            @click="putRequestOvertimeWork"
          >
            再申請
          </v-btn>
          <v-snackbar
            v-model="requestSnackbar"
            :timeout="2000"
            color="success"
            align-center
          >
            <div class="text-xs-center" style="width: 100%">{{ requestSnackbarText }}</div>
          </v-snackbar>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 class="pt-2 pb-2">
      <v-card class="text-xs-center">
        <v-layout column>
          <v-flex xs12>
            <v-list style="height: 100%">
              <template v-for="item in requestOvertimeItems">
                <div
                  :key="item.id"
                >
                  <v-list-tile :key="item.id" :id="`${item.request_type }-${item.request_date }`">
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.request_date + '　' +  weekday(item.request_date) }}</v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{ requestTypeName[item.request_type]  + '　' + item.request_time_start + '～' + item.request_time_end + '　' + listCalculationWorktime(item)}}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <template>
                        <v-btn
                          v-if="item.request_status !== 4"
                          outline
                          round
                          :color="requestStatusItems[item.request_status].color"
                          class="approved-chip text-xs-center pl-2 pr-2"
                          @click="changeRequestOvertimeItem(item)"
                        >
                          {{ requestStatusItems[item.request_status].status }}
                          <v-icon right dark>edit</v-icon>
                        </v-btn>
                        <v-btn v-else round outline color="blue-grey" class="approved-chip">承認取り下げ</v-btn>
                      </template>
                    </v-list-tile-action>
                  </v-list-tile>
                  <div
                    v-if="item.approval_comment"
                    class="text-xs-left mx-4"
                  >
                    <div style="font-size:0.9em;color:rgba(0,0,0,0.54);">マネージャーからの可否コメント</div>
                    <v-textarea
                      solo
                      name="input-7-4"
                      :value="item.approval_comment"
                      dense
                      readonly
                      hide-details
                    ></v-textarea>
                  </div>
                  <v-divider v-if="item !== requestOvertimeItems[requestOvertimeItems.length-1]" :key="`divider-${item.id}`"></v-divider>
                </div>
              </template>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import MobileHader from '@/components/MobileHeader'
import itemTools from '@/mixins/item_tools.js'

let defaultEditedItem = {
  id: 0,
  staff_id: 0,
  request_type: '',
  request_date: moment().format('YYYY-MM-DD'),
  request_time_start: '00:00',
  request_time_end: '00:00',
  request_comment: ''
}

export default {
  mixins: [itemTools],
  name: 'MobileRequestOvertimeWork',
  components: {
    MobileHader
  },
  data: () => ({
    snackbar: false,
    requestSnackbar: false,
    yearMenu: false,
    requestOvertimeItems: [],
    typeItems: [
      { k: 'overtime_work', v: '残業' },
      { k: 'early_attendant', v: '早出' },
      { k: 'holiday_work', v: '休日勤務' }
    ],
    requestStatusItems: [
      { id: 0, status: '申請中', color: 'orange' },
      { id: 1, status: '承認済み', color: 'cyan' },
      { id: 2, status: '再申請中', color: 'deep-orange' },
      { id: 3, status: '申請拒否', color: 'pink' },
      { id: 4, status: '申請取下げ', color: 'blue-grey' }
    ],
    requestDateMenu: false,
    overtimeStartModal: false,
    overtimeEndModal: false,
    editedItem: cloneDeep(defaultEditedItem),
    calendarStart: moment().format('YYYY'),
    valid: true,
    requestTypeName: {
      overtime_work: '残業',
      early_attendant: '早出',
      holiday_work: '休日勤務'
    },
    // 編集状態 new:新規 edit:編集
    editStates: 'new',
    requestSnackbarText: '',
    clearButtonText: 'クリア',
    isOvernightWork: false
  }),
  computed: {
    itemsNumber () {
      let n = 0
      this.requestOvertimeItems.forEach((item) => {
        if (item.request_status !== 4) {
          n++
        }
      })
      return n
    },
    afterRequeast () {
      let requestDate = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_start, 'YYYY-MM-DD H:mm')
      let submissionDate = moment(this.editedItem.submission_date + ' ' + this.editedItem.submission_time, 'YYYY-MM-DD H:mm')
      return submissionDate.isSameOrAfter(requestDate)
    },
    calculationWorktime () {
      let dateTo = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_start, 'YYYY-MM-DD H:mm')
      let dateFrom = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_end, 'YYYY-MM-DD H:mm')
      // 残業終了時間が開始時間より前の場合
      if (dateFrom.isBefore(dateTo)) {
        dateFrom = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_end, 'YYYY-MM-DD H:mm').add(1, 'd')
        return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
      }
      return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
    },
    calculationWorktime2 () {
      let dateTo = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_start, 'YYYY-MM-DD H:mm')
      let dateFrom = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_end, 'YYYY-MM-DD H:mm')
      // 残業終了時間が開始時間より前の場合
      if (dateFrom.isBefore(dateTo)) {
        dateFrom = moment(this.editedItem.request_date + ' ' + this.editedItem.request_time_end, 'YYYY-MM-DD H:mm').add(1, 'd')
        return '※勤務予定時間が日をまたいでいます。'
      }
      return ''
    }
  },
  watch: {
    calendarStart () {
      this.getRequestOvertime()
    }
  },
  mounted () {
    this.getRequestOvertime()
  },
  methods: {
    allowedStep: m => m % 15 === 0, // 15分単位
    prevYear () {
      this.calendarStart = moment(this.calendarStart, 'YYYY').subtract(1, 'years').format('YYYY')
    },
    nextYear () {
      this.calendarStart = moment(this.calendarStart, 'YYYY').add(1, 'years').format('YYYY')
    },
    convAtDate (date) {
      return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getRequestOvertime () {
      axios.get('/api/user/request-overtimework-after')
        .then((res) => {
          this.requestOvertimeItems = res.data
        })
        // .catch((error) => {
        //   this.errorDecision(error, '/', 'データエラー[getRequestOvertime]')
        // })
    },
    isDuplicate (editedItem) {
      return this.requestOvertimeItems.some((item) => {
        if (item.request_status !== 4) { // キャンセル以外
          if (item.id !== editedItem.id) {
            if (item.request_type === editedItem.request_type) {
              if (item.request_date === editedItem.request_date) {
                return true
              }
            }
          }
        }
        return false
      })
    },
    validate () {
      if (this.$refs.form.validate()) {
        return true
      }
      return false
    },
    reset () {
      this.$refs.form.resetValidation()
      this.editedItem = cloneDeep(defaultEditedItem)
      this.editStates = 'new'
      this.clearButtonText = 'クリア'
    },
    postRequestOvertimeWork () {
      if (this.isDuplicate(this.editedItem)) {
        alert('同一日に同種の申請があります。')
        return false
      }
      if (this.validate()) {
        axios.post('/api/user/request-overtimework', this.editedItem)
          .then((res) => {
            this.requestSnackbarText = '申請完了'
            this.requestSnackbar = true
            this.getRequestOvertime()
            this.reset()
          })
          .catch(() => {
            alert('登録エラー[postRequestOvertimeWork]')
          })
      }
    },
    clearRequestOvertimeWork () {
      // 編集クリア
      this.reset()
    },
    changeRequestOvertimeItem (item) {
      // 編集
      this.editedItem.id = item.id
      this.editedItem.request_type = item.request_type
      this.editedItem.request_date = moment(item.request_date).format('YYYY-MM-DD')
      this.editedItem.request_time_start = item.request_time_start
      this.editedItem.request_time_end = item.request_time_end
      this.editedItem.request_comment = item.request_comment
      this.editStates = 'edit'
      this.clearButtonText = 'キャンセル'
    },
    putRequestOvertimeWork () {
      if (this.isDuplicate(this.editedItem)) {
        alert('同一日に同種の申請があります。')
        return false
      }
      if (this.validate()) {
        axios.put('/api/user/request-overtimework', this.editedItem)
          .then((res) => {
            this.requestSnackbarText = '再申請完了'
            this.requestSnackbar = true
            this.getRequestOvertime()
            this.reset()
          })
          .catch(() => {
            alert('再申請に失敗しました。')
          })
      }
    },
    deleteRequestOvertimeWork () {
      axios.put('/api/user/request-overtimework/delete', this.editedItem)
        .then((res) => {
          this.requestSnackbarText = '申請取下げ完了'
          this.requestSnackbar = true
          this.getRequestOvertime()
          this.reset()
        })
        .catch(() => {
          alert('申請取下げに失敗しました。')
        })
    },
    listCalculationWorktime (item) {
      let dateTo = moment(item.request_date + ' ' + item.request_time_start, 'YYYY-MM-DD H:mm')
      let dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm')
      // 残業終了時間が開始時間より前の場合
      if (dateFrom.isBefore(dateTo)) {
        dateFrom = moment(item.request_date + ' ' + item.request_time_end, 'YYYY-MM-DD H:mm').add(1, 'd')
        this.isOvernightWork = true
        return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
      } else {
        this.isOvernightWork = false
      }
      return '( ' + dateFrom.diff(dateTo, 'hours', true) + 'h )'
    },
    weekday (date) {
      let wDayList = ['日', '月', '火', '水', '木', '金', '土']
      let wDay = moment(date, 'YYYY-MM-DD').format('d')
      return ' (' + wDayList[wDay] + ')'
    }
  }
}
</script>
