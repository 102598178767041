<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" @click="newEdit">まるめ時間</v-btn>
    </template>
    <v-card>
      <v-dialog v-model="editDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">まるめ時間編集</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs5 style="margin-top: 1em">出勤時刻のまるめ単位</v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="roundAttendanceTime"
                    :items="roundTimeItems"
                    outline
                    suffix="分"
                    height="2em"
                  ></v-select>
                </v-flex>
                <v-flex xs5 style="margin-top: 1em">退勤時刻のまるめ単位</v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="roundLeaveTime"
                    :items="roundTimeItems"
                    outline
                    suffix="分"
                  ></v-select>
                </v-flex>
                <v-flex xs5 style="margin-top: 1em">休憩時刻のまるめ単位</v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="roundRestTime"
                    :items="roundTimeItems"
                    outline
                    suffix="分"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="editDialog = false">Cancel</v-btn>
            <v-btn color="success" flat @click="save">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'

export default {
  data: () => ({
    companyId: 0,
    roundAttendanceTime: 0,
    roundLeaveTime: 0,
    roundRestTime: 0,
    roundTimeItems: [0, 5, 10, 15, 20, 25, 30, 40, 50, 60],
    editedBeginTimeModal: false,
    editedEndTimeModal: false,
    dialog: false,
    editDialog: false,
    editedItem: {}
  }),
  mounted () {
    this.getCompanyItems()
  },
  methods: {
    getCompanyItems () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyId = res.data['id']
          this.roundAttendanceTime = res.data['round_attendance_time']
          this.roundLeaveTime = res.data['round_leave_time']
          this.roundRestTime = res.data['round_rest_time']
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getCompany]')
        })
    },
    save () {
      axios.put('/api/manager/company/',
        { round_attendance_time: this.roundAttendanceTime, round_leave_time: this.roundLeaveTime, round_rest_time: this.roundRestTime })
        .then((res) => {
          this.getCompanyItems()
          this.editDialog = false
        })
        .catch(() => {
          alert('追加登録エラー')
        })
    },
    newEdit () {
      this.editDialog = true
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.editDialog = true
    }
  }
}
</script>

<style>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line) .v-select__selections {
  padding-top: 0;
}
</style>
