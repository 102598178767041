<template>
  <v-layout style="background-color: white" column fill-height>
    <MobileHader />
    <v-flex xs12 class="pt-2 pb-2">
      <v-card class="text-xs-center">
        <v-layout column>
          <v-flex xs12>
            <v-list style="height: 100%">
              <div v-for="item in leaveApplicationItems" :key="item.id">
                <v-list-tile :id="`${item.category}-${item.application_date}`">
                  <v-list-tile-content>
                    <v-list-tile-title v-text="convAtDate(item.application_date)" />
                    <v-list-tile-sub-title>
                      {{ categoryName[item.category] }}
                      {{ item.time_paid_leave !== 0 ? item.time_paid_leave / 10 : ''}}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn
                      v-if="item.is_approved === 0"
                      outline round
                      color="indigo"
                      class="approved-chip text-xs-center pl-2 pr-2"
                      @click="deleteLeaveApplication(item)"
                    >申請中<v-icon right dark>cancel</v-icon></v-btn>
                    <v-btn
                      v-else
                      round outline
                      :disabled="true"
                      color="success"
                      class="approved-chip"
                    >承認済み</v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="item !== leaveApplicationItems[leaveApplicationItems.length-1]"
                  :key="`divider-${item.id}`"
                />
              </div>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>

    <v-flex xs12>
      <v-card class="mb-2" style="min-height: 304px; width: 95%; margin: auto">
        <v-card-title primary-title class="headline">休暇申請</v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap>
              <v-flex xs5>
                <v-select
                  v-model="editedItem.category"
                  label="種別"
                  :items="categoryItems"
                  item-text="v"
                  item-value="k"
                  :rules="[v => !!v || '種別を選択してください']"
                  required
                />
              </v-flex>
              <v-flex xs2 />
              <v-flex xs5>
                <v-menu
                  v-model="applicationDateMenu"
                  lazy offset-y full-width
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.application_date"
                      readonly
                      label="取得希望日"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="editedItem.application_date"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    @input="applicationDateMenu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs7>
                <template v-if="isPaidLeave">
                取得済み: {{ digestedPaidHoliday }} 日 / {{ ownLeaveDays }} 日
                残り{{ this.remainingLeaveDays }} 日
                </template>
              </v-flex>
              <v-flex xs5 v-if="editedItem.category === 'time_paid_leave'">
                <v-select
                  v-model="editedItem.time_paid_leave"
                  label="時間有給"
                  :items="timePaidLeaveItems"
                  item-value="k"
                  :rules="[k => !!k || '時間単位を選択してください']"
                  required
                />
              </v-flex>
              <v-flex xs5 v-if="needsBasisDate()">
                <v-menu
                  v-model="basisDateMenu"
                  lazy offset-y full-width
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.basis_date"
                      readonly
                      :label="basisDateLabel"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="editedItem.basis_date"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    @input="basisDateMenu = false"
                    ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 class="mt-3">
                <v-textarea
                  v-model="editedItem.leave_reason"
                  box
                  label="引き継ぎ・連絡事項"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large outline flat
            color="grey"
            class="ma-2"
            @click="$router.push('/worktime-record')"
          >cancel</v-btn>
          <v-chip
            v-if="editedItem.category !== 'transfer_leave' &&
            editedItem.category !== 'alternative_leave' &&
            editedItem.category &&
            this.remainingLeaveDays < 0" label>
            取得日数が上限に達しています。</v-chip>
          <v-btn
            v-else
            large outline flat
            color="success"
            class="ma-2"
            @click="postLeaveApplication"
          >申請</v-btn>
          <v-snackbar
            v-model="snackbar"
            align-center
            :timeout="2000"
            color="success"
          >
            <div class="text-xs-center" style="width: 100%">登録完了</div>
          </v-snackbar>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import MobileHader from '@/components/MobileHeader'
import itemTools from '@/mixins/item_tools.js'

let defaultEditedItem = {
  id: 0,
  staff_id: 0,
  application_date: moment().format('YYYY-MM-DD'),
  category: null,
  basis_date: moment().format('YYYY-MM-DD'),
  is_approved: 0,
  time_paid_leave: 0,
  leave_reason: ''
}

export default {
  mixins: [itemTools],
  name: 'MobileLeaveApplication',
  components: {
    MobileHader
  },
  data: () => ({
    remainingLeaveDays: 0,
    timePaidLeaveItems: [],
    digestedPaidHoliday: 0,
    ownLeaveDays: 0,
    snackbar: false,
    yearMenu: false,
    leaveApplicationItems: [],
    categoryItems: [
      { k: 'paid_leave', v: '有給休暇' },
      { k: 'transfer_leave', v: '振替休暇' },
      { k: 'alternative_leave', v: '代替休暇' },
      { k: 'am_paid_leave', v: '午前有給休暇' },
      { k: 'pm_paid_leave', v: '午後有給休暇' },
      { k: 'time_paid_leave', v: '有給休暇(時間休)' },
      { k: 'general_absence', v: '一般欠勤' }
    ],
    applicationDateMenu: false,
    editedItem: cloneDeep(defaultEditedItem),
    basisDateMenu: false,
    calendarStart: moment().format('YYYY'),
    valid: true,
    categoryName: {
      paid_leave: '有給休暇',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      am_paid_leave: '午前有給休暇',
      pm_paid_leave: '午後有給休暇',
      time_paid_leave: '有給休暇(時間休)',
      general_absence: '一般欠勤'
    }
  }),
  computed: {
    itemsNumber () {
      let n = 0
      this.leaveApplicationItems.forEach((item) => {
        if (item.is_approved === 1) {
          n++
        }
      })
      return n
    },
    isPaidLeave () {
      if (this.editedItem.category === 'paid_leave') {
        return true
      }
      if (this.editedItem.category === 'am_paid_leave') {
        return true
      }
      if (this.editedItem.category === 'pm_paid_leave') {
        return true
      }
      if (this.editedItem.category === 'time_paid_leave') {
        return true
      }
      return false
    }
  },
  watch: {
    calendarStart () {
      this.getLeaveApplication()
    },
    editedItem: {
      handler: function () {
        this.getLeaveDays(this.editedItem.application_date)
      },
      deep: true
    }
  },
  mounted () {
    this.getLeaveApplication()

    for (let i = 1; i <= 9; i++) {
      this.timePaidLeaveItems.push(i / 10)
    }
  },
  methods: {
    basisDateLabel () {
      if (this.editedItem.category === 'transfer_leave') {
        return '振替の基日'
      }
      return '代替の基日'
    },
    needsBasisDate () {
      if (this.editedItem.category === 'transfer_leave') {
        return true
      }
      if (this.editedItem.category === 'alternative_leave') {
        return true
      }
      return false
    },
    getLeaveDays (date2 = '') {
      let date = moment().format('YYYY-MM-DD')

      if (date2) {
        date = date2
      }

      axios.get('/api/leave-days?date=' + date)
        .then((res) => {
          this.ownLeaveDays = res.data.leave_days
          this.digestedPaidHoliday = res.data.used_days
          this.remainingLeaveDays = ((res.data.leave_days * 10) - (res.data.used_days * 10)) / 10
        })
        .catch(() => {
          alert('有給休暇数取得エラー')
        })
    },
    prevYear () {
      this.calendarStart =
        moment(this.calendarStart, 'YYYY').subtract(1, 'years').format('YYYY')
    },
    nextYear () {
      this.calendarStart = moment(this.calendarStart, 'YYYY').add(1, 'years').format('YYYY')
    },
    convAtDate (date) {
      return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    notIsPastLeaveApplicationItem (applicationDate) {
      if (moment(applicationDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
        return true
      } else {
        return false
      }
    },
    getLeaveApplication () {
      axios.get('/api/leave-application')
        .then((res) => {
          this.leaveApplicationItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/', 'データエラー[getLeaveApplication]')
        })
    },
    validate () {
      if (this.$refs.form.validate()) {
        return true
      }
      return false
    },
    reset () {
      this.$refs.form.reset()
    },
    postLeaveApplication () {
      this.validate()
      let permit = 0

      if (
        this.editedItem.category !== null && this.editedItem.category !== 'time_paid_leave'
      ) {
        permit = 1
      } else {
        if (this.editedItem.category === 'time_paid_leave') {
          if (this.editedItem.time_paid_leave !== 0) {
            permit = 1
          } else {
            permit = 0
          }
        }
      }

      if (permit) {
        axios.post('/api/leave-application', this.editedItem)
          .then((res) => {
            this.snackbar = true
            this.getLeaveApplication()
            this.reset()
            this.editedItem = cloneDeep(defaultEditedItem)
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    deleteLeaveApplication (item) {
      let category = {
        paid_leave: '有給休暇',
        transfer_leave: '振替休暇',
        alternative_leave: '代替休暇',
        am_paid_leave: '午前有給休暇',
        pm_paid_leave: '午後有給休暇',
        time_paid_leave: '有給休暇(時間休)'
      }
      let confirmText = this.convAtDate(item.application_date) + ' ' +
      category[item.category] + ' を削除しますか？'
      if (confirm(confirmText)) {
        axios.delete('/api/leave-application/' + item.id)
          .then(() => {
            this.getLeaveApplication()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>
