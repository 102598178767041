<template>
  <div>
    <MyToolbar :val="toolbarTitle" />
    <v-layout class="mt-4">
      <v-flex xs6 class="mx-2">
        <v-card>
          <v-card-title style="font-weight: bold;background-color: #ECEFF1">
            <v-icon class="mr-1">warning</v-icon>
            アラート</v-card-title>
            <v-layout class="mt-2">
              <v-flex xs4 class="pa-2">
                <v-menu
                  v-model="beginDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="beginDate"
                      label="日付範囲（開始）"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="beginDate"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    @input="beginDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4 class="pa-2">
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDate"
                      label="日付範囲（終了）"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    locale="ja-jp"
                    :day-format="date => new Date(date).getDate()"
                    @input="endDateMenu = false"
                    ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-spacer />
              <v-flex>
                <AlertPreferenceDialog @child-event="parentMethod"/>
              </v-flex>
            </v-layout>
            <v-data-table
              :items="[{ name: '打刻なし', name2: '時間外20h超過' }]"
              hide-headers
              hide-actions
              style="width: 90%; margin: 0 auto;"
              class="elevation-1"
            >
              <template v-slot:items="props">
                <tr>
                  <td style="border-right: 2px solid #ECEFF1; background-color: white;">{{ props.item.name }}</td>
                  <td class="text-xs-right" style="background-color: white">
                    <v-btn v-if="props.item.name === '打刻なし'" color="primary" flat class="mb-2" @click="showAlertDialog = true">{{ processedItems.length }}件</v-btn>
                    <!-- <v-btn v-else color="primary" flat class="mb-2">0件</v-btn> -->
                  </td>
                </tr>
                <!-- <tr>
                  <td style="border-right: 2px solid #ECEFF1; background-color: white;">{{ props.item.name2 }}</td>
                  <td class="text-xs-right" style="background-color: white">
                    <v-btn color="primary" flat class="mb-2" @click="showAlertDialog2 = true">2件</v-btn>
                  </td>
                </tr> -->
              </template>
            </v-data-table>
            <v-layout><v-flex class="mt-3"></v-flex></v-layout>
            <v-dialog
              v-model="showAlertDialog"
              persistent
              max-width="80%"
              scrollable
            >
              <v-card>
                <v-card-title>
                  <span class="title">打刻なしスタッフ一覧</span>
                </v-card-title>
                <v-layout><v-flex class="mt-3"></v-flex></v-layout>
                <v-data-table
                  :headers="headers"
                  :items="processedItems"
                  hide-actions
                  style="width: 90%; margin: 0 auto;"
                  class="elevation-1"

                >
                  <template v-slot:items="props">
                    <td style="border-right: 2px solid #ECEFF1; background-color: white;">{{ props.item.staff_name }}</td>
                    <td class="text-xs-right" style="border-right: 2px solid #ECEFF1;background-color: white">{{ convAtDate(props.item.worktime_date) }}</td>
                    <td class="text-xs-right" style="border-right: 2px solid #ECEFF1;background-color: white">{{ props.item.content }}</td>
                    <td class="text-xs-right" style="background-color: white;">
                      <div style="text-align: center">
                        <v-btn @click="showAbsenceRegister(props.item)">欠勤登録</v-btn>
                        <v-btn @click="modifyAttendance(props.item.worktime_date)">修正</v-btn>
                      </div>
                    </td>
                  </template>
                </v-data-table>
                <v-spacer></v-spacer>
                <v-btn flat color="grey lighten-1" @click="showAlertDialog = false">Cancel</v-btn>
              </v-card>
              <v-dialog
                v-model="showAbsenceDialog"
                persistent
                max-width="40%"
                scrollable
              >
                <v-card>
                  <v-card-title
                    class="headline"
                    primary-title
                  >
                    欠勤登録
                  </v-card-title>

                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                          v-model="editedItem.application_date"
                          label="欠勤登録日"
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-select
                          :error-messages="errorItem.category"
                          v-model="editedItem.category"
                          label="種別"
                          :items="categoryItems"
                          item-text="v"
                          item-value="k"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-textarea
                          v-model="editedItem.leave_reason"
                          maxlength="100"
                          box
                          label="休暇理由（任意）"
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      flat
                      @click="closeAbsenceDialog"
                    >
                      cancel
                    </v-btn>
                    <v-btn
                      color="success"
                      flat
                      @click="save"
                    >
                      ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-dialog>

            <v-dialog
              v-model="showAlertDialog2"
              persistent
              max-width="80%"
              scrollable
            >
              <!-- <v-card>
                <v-card-title>
                  <span class="title">時間外超過スタッフ一覧</span>
                </v-card-title>
                <v-layout><v-flex class="mt-3"></v-flex></v-layout>
                <v-data-table
                  :headers="[
                    { text: 'スタッフ名', sortable: false, class: 'default-alert-header-line' },
                    { text: '時間外', sortable: false, class: 'default-alert-header-line' }
                  ]"
                  :items="[
                    {staff_name: '福島治男', overtime_work: '25h' },
                    {staff_name: '小出祐昭', overtime_work: '26h' }
                  ]"
                  hide-actions
                  style="width: 90%; margin: 0 auto;"
                  class="elevation-1"

                >
                  <template v-slot:items="props">
                    <td style="border-right: 2px solid #ECEFF1; background-color: white;">{{ props.item.staff_name }}</td>
                    <td class="text-xs-right" style="border-right: 2px solid #ECEFF1;background-color: white">{{ props.item.overtime_work }}</td>
                  </template>
                </v-data-table>
                <v-spacer></v-spacer>
                <v-btn flat color="grey lighten-1" @click="showAlertDialog2 = false">Cancel</v-btn>
              </v-card> -->
            </v-dialog>

        </v-card>
      </v-flex>
      <v-flex xs6 class="mx-2">
        <v-card>
          <v-card-title style="font-weight: bold;background-color: #ECEFF1">
            <v-icon class="mr-1">add_alert</v-icon>
            お知らせ</v-card-title>
            お知らせはありません。
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'
// import Encoding from 'encoding-japanese'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import itemTools from '@/mixins/item_tools.js'
// import Papa from 'papaparse'
import cloneDeep from 'lodash/cloneDeep'
import AlertPreferenceDialog from '@/components/AlertPreferenceDialog'

let defaultEditedItem = {
  id: 0,
  staff_id: 0,
  application_date: moment().format('YYYY-MM-DD'),
  category: '',
  basis_date: moment().format('YYYY-MM-DD'),
  is_approved: 1,
  leave_reason: ''
}
let defaultErrorItem = {
  category: []
}

export default {
  mixins: [itemTools],
  components: {
    MyToolbar,
    AlertPreferenceDialog
  },
  data: () => ({
    toolbarTitle: '',
    wdayHolidayItems: [],
    holidayItems: [],
    isActivateJapanageHoliday: 0,
    JapaneseHolidayItems: [],
    is_attendance_none_detection_shift_system_do: 0,
    errorItem: cloneDeep(defaultErrorItem),
    categoryItems: [
      { k: 'general_absence', v: '一般欠勤' },
      { k: 'disease_scratch_absence', v: '病傷欠勤' }
    ],
    showAbsenceDialog: false,
    showAlertDialog2: false,
    editedItem: cloneDeep(defaultEditedItem),
    headers: [
      { text: 'スタッフ', value: 'carbs', sortable: false, class: 'default-alert-header-line' },
      { text: '日時', value: 'protein', sortable: false, class: 'default-alert-header-line' },
      { text: '内容', value: 'iron', sortable: false, class: 'default-alert-header-line' },
      { text: '', value: '', sortable: false }
    ],
    showAlertDialog: false,
    worktimeItems: [],
    leaveApplicationItems: [],
    beginDateMenu2: false,
    endDateMenu2: false,
    csvOutputDialog: false,
    csvBeginDate: moment().date(16).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    csvEndDate: moment().add(1, 'months').date(15).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    worktimeRecordItems: []
  }),
  computed: {
    processedItems () {
      let noRecordDataItems = []
      let today = moment().tz('Asia/Tokyo').format('YYYY-MM-DD')

      if (this.is_attendance_none_detection_shift_system_do === 1) {
        let existData = false

        this.worktimeItems.forEach((wItem) => {
          let worktimeDate = moment(wItem.worktime_date).format('YYYY-MM-DD')
          if (today <= worktimeDate) {
            return
          }
          existData = false
          this.worktimeRecordItems.forEach((wrItem) => {
            let recordAtDate = moment(wrItem.record_at).tz('Asia/Tokyo').format('YYYY-MM-DD')

            if ((worktimeDate === recordAtDate) && (wItem.staff_id === wrItem.staff_id)) {
              existData = true
            }
          })
          if (!existData) {
            let existApplicationDate = false
            this.leaveApplicationItems.forEach((lItem) => {
              let leaveApplicationDate = moment(lItem.application_date).format('YYYY-MM-DD')
              if (worktimeDate === leaveApplicationDate) {
                if (wItem.staff_id === lItem.staff_id && lItem.is_approved === 1) {
                  existApplicationDate = true
                }
              }
            })
            if (!existApplicationDate) {
              wItem['content'] = '打刻なし'
              noRecordDataItems.push(wItem)
            }
          }
        })
      } else {
        let targetDate = this.beginDate
        let endDate
        if (this.endDate < today) {
          endDate = this.endDate
        } else {
          endDate = today
        }

        while (targetDate < endDate) {
          let isNotTargetDate = 0
          let existData = false

          if (this.isActivateJapanageHoliday === 1) {
            this.JapaneseHolidayItems.forEach((item) => {
              if (targetDate === item.date) {
                isNotTargetDate = 1
              }
            })
          }
          this.holidayItems.forEach((item) => {
            if (targetDate === item.date) {
              isNotTargetDate = 1
            }
          })
          this.wdayHolidayItems.forEach((item) => {
            if (Object.keys(item).indexOf('wday') !== -1) {
              if (moment(targetDate, 'YYYY-MM-DD').day() === item.wday) {
                isNotTargetDate = 1
              }
            }
          })
          if (isNotTargetDate === 0) {
            this.staffItems.forEach((sItem) => {
              existData = false
              let obj = {}
              this.worktimeRecordItems.forEach((wItem) => {
                if (targetDate === moment(wItem.record_at).tz('Asia/Tokyo').format('YYYY-MM-DD')) {
                  if (wItem.staff_id === sItem.id) {
                    existData = true
                    return true
                  }
                }
              })
              if (!existData) {
                let existApplicationDate = false
                this.leaveApplicationItems.forEach((lItem) => {
                  let leaveApplicationDate = moment(lItem.application_date).format('YYYY-MM-DD')
                  if (targetDate === leaveApplicationDate) {
                    if (sItem.id === lItem.staff_id && lItem.is_approved === 1) {
                      existApplicationDate = true
                    }
                  }
                })
                if (!existApplicationDate) {
                  obj['content'] = '打刻なし'
                  obj['staff_id'] = sItem.id
                  obj['staff_name'] = sItem.name
                  obj['worktime_date'] = targetDate
                  noRecordDataItems.push(obj)
                }
              }
            })
          }
          targetDate = moment(targetDate).add(1, 'd').format('YYYY-MM-DD')
        }
      }
      return noRecordDataItems
    }
  },
  watch: {
    beginDate () {
      this.getRegisteredWorktime()
      this.getWorktimeRecord()
      this.getLeaveApplication()
    },
    endDate () {
      this.getRegisteredWorktime()
      this.getWorktimeRecord()
      this.getLeaveApplication()
    }
  },
  mounted () {
    this.toolbarTitle = localStorage.getItem('toolbarTitle')
    this.getRegisteredWorktime()
    this.getWorktimeRecord()
    this.getLeaveApplication()
    this.getCompanyHoliday()
    this.getCompanyActivateJapanageHoliday()
    this.getJapaneseHolidays()
    this.getStaff()
  },
  methods: {
    getStaff () {
      this.staffItems = []
      axios.get('/api/manager/staff')
        .then((res) => {
          res.data.forEach((item) => {
            if (item.is_retired === 0) {
              this.staffItems.push(item)
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getStaff]')
        })
    },
    getJapaneseHolidays () {
      axios.get('/api/manager/japanese-holiday')
        .then((res) => {
          this.JapaneseHolidayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getJapaneseHoliday]')
        })
    },
    getCompanyActivateJapanageHoliday () {
      axios.get('/api/manager/company-japanage-holiday')
        .then((res) => {
          this.isActivateJapanageHoliday = res.data[0]
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    getCompanyHoliday () {
      axios.get('/api/manager/company-holiday?type=date')
        .then((res) => {
          this.holidayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
      axios.get('/api/manager/company-holiday?type=wday')
        .then((res) => {
          this.wdayHolidayItems = []
          if (res.data.length !== 0) {
            for (let x = 0; x < 7; x++) {
              let dataExists = false
              res.data.forEach((item) => {
                if (x === item.wday) {
                  this.wdayHolidayItems.push(item)
                  dataExists = true
                }
              })
              if (!dataExists) {
                this.wdayHolidayItems.push({ id: x })
              }
            }
          } else {
            for (let x = 0; x < 7; x++) {
              this.wdayHolidayItems.push({ id: x })
            }
          }
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    parentMethod (val) {
      this.is_attendance_none_detection_shift_system_do = val
    },
    modifyAttendance (date) {
      this.showAlertDialog = false
      let specifiedDate = moment(date).format('YYYY-MM-DD')
      this.$router.push('/manager/worktime-record/' + specifiedDate)
    },
    validation () {
      this.errorItem = cloneDeep(defaultErrorItem)
      let isNoError = true
      if (this.editedItem.category === '') {
        this.errorItem.category.push('種別を選択してください。')
        isNoError = false
      }
      return isNoError
    },
    save () {
      if (this.validation() === false) {
        return
      }
      axios.post('/api/manager/leave-application', this.editedItem)
        .then((res) => {
          this.showAlertDialog = false
          this.editedItem = cloneDeep(defaultEditedItem)
          this.getLeaveApplication()
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    closeAbsenceDialog () {
      this.editedItem = cloneDeep(defaultEditedItem)
      this.showAbsenceDialog = false
    },
    showAbsenceRegister (item) {
      this.showAbsenceDialog = true
      this.editedItem.staff_id = item.staff_id
      let worktimeDate = moment(item.worktime_date).format('YYYY-MM-DD')
      this.editedItem.application_date = worktimeDate
    },
    getLeaveApplication () {
      axios.get('/api/manager/leave-application?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.leaveApplicationItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    convAtDate (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    getWorktimeRecord () {
      axios.get('/api/manager/worktime-record?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.worktimeRecordItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    },
    getRegisteredWorktime () {
      this.worktimeItems = []
      axios.get('/api/manager/registered-worktime?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          res.data.forEach((item) => {
            if (item.is_retired === 0) {
              this.worktimeItems.push(item)
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    }
  }
}
</script>

<style>
.default-alert-header-line {
  border-right: 2px solid #ECEFF1;
}
</style>
<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.grayCursor {
  background-color: #ECEFF1;
}
.whiteCursor {
  background-color: white;
}
</style>
