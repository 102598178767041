<template>
  <div>
    <v-layout row wrap>
      <v-toolbar flat class="py-2">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >
          勤務予定確認
        </v-toolbar-title>
      </v-toolbar>

      <v-flex xs12 class="text-xs-center">
        <v-container fluid grid-list-md>
          <v-date-picker
            v-model="date1"
            :events="arrayEvents"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            :picker-date.sync="pickerDate"
            no-title
            full-width
            color="primary"
            :event-color="eventColoring"
          />
        </v-container>
      </v-flex>

    </v-layout>

    <v-container fluid grid-list-md>
      <v-data-iterator :items="items" hide-actions row wrap>
        <template v-slot:header>
          <v-toolbar class="mb-2" color="#456ab8" dark flat>
          <v-toolbar-title>{{ date1 | dateFormatConv }}</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:no-data>
          <v-alert
            v-if="items.length === 0"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            データがありません
          </v-alert>
        </template>

        <template v-slot:item="props">
          <v-flex v-if="props.item.scheduleType === 'worktime'" xs12 sm6 md4 lg3>
            <v-card class="my-3">
              <v-card-title>
                <h2>{{ props.item.begin_time }}~{{ props.item.end_time }}</h2>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex v-else xs12 sm6 md4 lg3>
            <v-card class="my-3">
              <v-card-title>
                <h2>有給休暇</h2>
              </v-card-title>
            </v-card>
          </v-flex>
        </template>
      </v-data-iterator>
    </v-container>

    <v-btn color="grey lighten-1" @click="$router.push('worktime-record')">戻る</v-btn>

  </div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import itemTools from '@/mixins/item_tools.js'

dayjs.locale('ja')

export default {
  mixins: [itemTools],
  data: () => ({
    arrayEvents: [],
    eventsColor: {},
    date1: dayjs().format('YYYY-MM-DD'),
    pickerDate: null,
    worktimeItems: [],
    items: []
  }),
  mounted () {
    this.getWorkTime()
  },
  watch: {
    pickerDate () {
      this.getWorkTime()
    },
    date1 () {
      this.selectDay()
    }
  },
  filters: {
    dateFormatConv: function (val) {
      return dayjs(val).format('YYYY/MM/DD(ddd)')
    }
  },
  methods: {
    eventColoring (date) {
      if (this.eventsColor[date] === 'worktime') {
        return 'green'
      }
      if (this.eventsColor[date] === 'leave_application') {
        return 'red'
      }
      return 'grey'
    },
    getWorkTime () {
      axios.get('/api/worktime-normal?ym=' + this.pickerDate)
        .then((res) => {
          this.worktimeItems = res.data
          this.arrayEvents = []
          this.eventsColor = {}

          res.data.forEach((item) => {
            let date = dayjs(item.date).format('YYYY-MM-DD')
            this.arrayEvents.push(date)
            this.eventsColor[date] = item.scheduleType
          })

          this.selectDay()
        })
        .catch((error) => {
          this.errorDecision(error, '/', 'データエラー[getWorkTime]')
        })
    },
    selectDay () {
      this.items = this.worktimeItems.filter((item) => {
        return dayjs(item.date).format('YYYY-MM-DD') === this.date1
      })
    }
  }
}
</script>
