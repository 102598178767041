<template>
  <div>
    <v-flex xs12>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="body-2 white--text text-xs-center">イージア</v-toolbar-title>
        <v-spacer />
        <v-toolbar-title v-if="val" class="body-2 white--text text-xs-center">
          {{ val }}
          <span class="caption">さん</span>
          <v-btn v-if="qrValue" flat @click="qrDialog = true">QRコード</v-btn>
          <img src="../../logout.png" @click="logout" style="margin-top: 1em;margin-left: 1em; width: 1.3em">
        </v-toolbar-title>
      </v-toolbar>
      <div style="text-align: center">
        <div class="mb-2 blue-grey--text text--darken-4 display-3">
          {{ timeHM }}<span class="blue-grey--text text--darken-4 title">{{ timeS }}</span>
        </div>
        <div class="blue-grey--text text--darken-4 title">{{ date }}</div>
      </div>
    </v-flex>
    <template v-if="val">
      <v-dialog v-model="qrDialog" width="400">
        <v-card>
          <v-card-text class="text-xs-center">
            <VueQrcode :value="qrValue" :options="{ width: 300, margin: 0 }" tag="img"></VueQrcode>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat @click="qrDialog = false" >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  props: ['val'],
  components: {
    VueQrcode
  },
  data: () => ({
    qrDialog: false,
    qrValue: '',
    date: dayjs().format('YYYY年M月D日'),
    timeHM: dayjs().format('HH:mm'),
    timeS: dayjs().format('ss')
  }),
  mounted () {
    if (localStorage.getItem('qrValue')) {
      this.qrValue = localStorage.getItem('qrValue')
    }
    setInterval(() => {
      this.date = dayjs().format('YYYY年M月D日')
      this.timeHM = dayjs().format('HH:mm')
      this.timeS = dayjs().format('ss')
    }, 1000)
  },
  methods: {
    logout () {
      if (confirm('ログアウトします。\nよろしいですか？')) {
        axios.post('/api/logout')
          .then(() => {
            this.$router.push('/')
          })
          .catch(() => {
            this.$router.push('/')
          })
      }
    }
  }
}
</script>
