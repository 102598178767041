<template>
  <v-menu
    v-model="menu"
    :nudge-width="200"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        class="white--text"
        outline
        v-on="on"
      >
        パターンから選択
      </v-btn>
    </template>
    <v-card>
      <v-radio-group v-model="radioGroup">
        <v-list two-line>
          <template v-for="item in defaultWorktimeItems">
            <v-list-tile :key="item.id" @click="radioGroup = item">
              <v-list-tile-action>
                <v-radio :value="item" />
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.name }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ item.begin_time }} - {{ item.end_time }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider :key="`divider-${item.id}`" />
          </template>
        </v-list>
      </v-radio-group>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: [
    'defaultWorktimeItems',
    'beginTime',
    'endTime'
  ],
  data: () => ({
    menu: false,
    radioGroup: {}
  }),
  watch: {
    radioGroup () {
      this.$emit('update:beginTime', this.radioGroup.begin_time)
      this.$emit('update:endTime', this.radioGroup.end_time)
      this.menu = false
    }
  }
}
</script>
