<template>
  <div>
    <MyToolbar />
    <v-card class="ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title
          style="font-weight: bold;  border-left: solid 5px #456ab8; padding-left: 0.3em"
        >深夜時間帯</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs4 class="mr-5">
              <v-dialog
                ref="beginTimeDialog"
                v-model="editedBeginTimeModal"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="lateNightTimeObj.begin_late_night_time"
                    label="開始時刻"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="editedBeginTimeModal"
                  v-model="lateNightTimeObj.begin_late_night_time"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="editedBeginTimeModal = false">Cancel</v-btn>
                  <v-btn flat color="success" @click="lateNightTimeSave">save</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs4>
              <v-dialog
                ref="endTimeDialog"
                v-model="editedEndTimeModal"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="lateNightTimeObj.end_late_night_time"
                    label="終了時刻"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="editedEndTimeModal"
                  v-model="lateNightTimeObj.end_late_night_time"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="editedEndTimeModal = false">Cancel</v-btn>
                  <v-btn flat color="success" @click="lateNightTimeSave">save</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="ma-2 pb-4">
      <v-toolbar flat color="white">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >法定休日</v-toolbar-title>
      </v-toolbar>
      <div class="text-xs-center">
        <span v-for="item in wdayHolidayItems" :key="item.id">
          <v-chip
            v-if="item.wday || item.wday === 0"
            @click="deleteWday(item.id)"
            outline
            color="accent"
            text-color="accent">{{ weekdayItems[item.wday] }}</v-chip>
          <v-chip
            v-else
            @click="save(item.id)"
            outline>{{ weekdayItems[item.id] }}</v-chip>
        </span>
      </div>
      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="timeout"
      >
        {{ '登録しました' }}
      </v-snackbar>
    </v-card>

    <v-card class="ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >所定休日（公休）</v-toolbar-title>
        <v-spacer />

        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on }">
            <v-btn color="#6ac67b" dark v-on="on">日付で追加</v-btn>
          </template>

          <v-card>
            <v-card-title class="headline" primary-title>公休日設定</v-card-title>

            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-menu
                    v-model="applicationDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.date"
                        label="日付"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.date"
                      locale="ja-jp"
                      @input="applicationDateMenu = false"
                      :day-format="date => new Date(date).getDate()"
                      ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="editedItem.name" label="休日名" required />
                </v-flex>
              </v-layout>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" flat @click="closeDialog">cancel</v-btn>
              <v-btn color="success" flat @click="save('not')">ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="calendarDialog" width="800">
          <template v-slot:activator="{ on }">
            <v-btn color="#6ac67b" dark v-on="on">カレンダーで追加</v-btn>
          </template>

          <v-card>
            <v-card-title class="headline" primary-title>公休日設定</v-card-title>

            <v-card-text>
              <div class="d-flex pb-2" style="align-items: center; font-size: 2em;">
                <div class="text-xs-center">
                  <v-icon @click="calendarPrevMonth">chevron_left</v-icon>
                </div>
                <div class="text-xs-center">{{ calendar.year }}年 {{ calendar.month + 1 }}月</div>
                <div class="text-xs-center">
                  <v-icon @click="calendarNextMonth">chevron_right</v-icon>
                </div>
              </div>
              <div style="height: 640px;">
                <table class="calendar">
                  <thead>
                    <tr>
                      <th v-for="wday in weekdayItems" :key="wday">{{ wday }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(week, weekIdx) in calendarContents" :key="`week-${weekIdx}`">
                      <td v-for="(day, dayIdx) in week" :key="`day-${dayIdx}`">
                        <div :class="day.type" @click="addSelectedDays(day)">
                          <div class="d-flex">
                            <div class="pa-1">
                            <v-icon
                              v-if="calendar.selectedDays.includes(day.date)"
                              color="success"
                            >check_circle</v-icon>
                            </div>
                            <div class="text-xs-right pr-1">
                              {{ day.day ? day.day : '' }}
                            </div>
                          </div>
                          <div v-if="day.type === 'public-holiday'" class="pa-1">
                            {{ day.text }}
                          </div>
                          <div v-if="day.type === 'japanese-holiday'" class="pa-1">
                            {{ day.text }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="py-2">
                <template v-for="date in calendar.selectedDays">
                  <v-chip close :key="date" @input="deleteSelectedDays(date)">{{ date }}</v-chip>
                </template>
              </div>
              <div class="py-2">
                <v-text-field v-model="calendar.editName" label="公休日名称" />
              </div>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" flat @click="calendarDialog = false">cancel</v-btn>
              <v-btn
                :disabled="calendar.selectedDays.length === 0"
                color="success"
                flat
                @click="saveCalendar()"
              >登録</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>

      <div class="text-xs-center">
        <v-chip
          v-if="isActivateJapaneseHoliday === 1"
          @click="putCompanyActivateJapaneseHoliday(0)"
          outline
          color="accent"
          text-color="accent">祝日</v-chip>
        <v-chip v-else @click="putCompanyActivateJapaneseHoliday(1)" outline>祝日</v-chip>

        <span v-for="item in wdayPublicHolidayItems" :key="item.id">
          <v-chip
            v-if="item.wday || item.wday === 0"
            @click="deleteWday(item.id)"
            outline
            color="accent"
            text-color="accent">{{ weekdayItems[item.wday] }}</v-chip>
          <v-chip
            v-else
            @click="save(item.id, 'publicHolidayWday')"
            outline>{{ weekdayItems[item.id] }}</v-chip>
        </span>
      </div>

      <div class="pa-3">
        <v-data-table
          class="mt-3 holiday-table"
          :headers="headers"
          :items="holidayItems"
          hide-actions
          style="margin: auto;"
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="error" icon="warning" outline>
              データがありません
            </v-alert>
          </template>
          <template v-slot:items="props">
            <td class="holiday-td" style="width: 9em;">{{ props.item.date }}</td>
            <td class="holiday-td">{{ props.item.name }}</td>
            <td class="holiday-td" style="width: 100px;">
              <div class="justify-center layout px-0">
                <v-icon small color="primary" class="mr-2" @click="editItem(props.item)">
                  edit
                </v-icon>
                <v-icon small color="accent" @click="deleteItem(props.item)">delete</v-icon>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<style>
.calendar {
  border-collapse: collapse;
  border: 1px solid #999;
  width: 100%;
}

.calendar th {
  font-weight: normal;
}

.calendar  td > div {
  height: 100px;
}

.calendar th,
.calendar td {
  border: 1px solid #999;
  width: 14.28%;
}

.calendar td .none {
  background-color: #E0E0E0;
}

.calendar td .japanese-holiday {
  background-color: #FFAB91;
}

.calendar td .legal-holiday {
  background-color: #FFAB91;
}

.calendar td .public-holiday-wday {
  background-color: #FFAB91;
}

.calendar td .public-holiday {
  background-color: #C8E6C9;
}

.v-chip .v-chip__content {
  cursor: pointer !important;
}

.holiday-table {
  width: 600px;
}

.holiday-table table {
  border: 1px solid rgba(0,0,0,0.12);
}

.holiday-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.holiday-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: left;
}

</style>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'

let defaultEditedItem = {
  id: 0,
  date: moment().format('YYYY-MM-DD'),
  type: 'date',
  name: '',
  wday: 0
}

export default {
  components: {
    MyToolbar
  },
  data: () => ({
    timeout: 2000,
    snackbar: false,
    lateNightTimeObj: {
      begin_late_night_time: '',
      end_late_night_time: ''
    },
    companyItems: [],
    editedBeginTimeModal: false,
    editedEndTimeModal: false,
    isActivateJapaneseHoliday: 0,
    weekdayItems: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
    dialog: false,
    calendarDialog: false,
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    headers: [
      { text: '日付', value: 'name', sortable: false, class: 'holiday-header' },
      { text: '名称', value: 'name', sortable: false, class: 'holiday-header' },
      { text: '', value: 'name', align: 'center', sortable: false, class: 'holiday-header' }
    ],
    holidayItems: [],
    japaneseHolidayItems: [],
    wdayHolidayItems: [],
    wdayPublicHolidayItems: [],
    editedItem: cloneDeep(defaultEditedItem),
    applicationDateMenu: false,
    basisDateMenu: false,
    calendar: {
      year: moment().year(),
      month: moment().month(),
      firstWday: moment().date(1).day(),
      lastDay: moment().add(1, 'months').date(1).subtract(1, 'days').date(),
      selectedDays: [],
      editName: ''
    }
  }),
  computed: {
    calendarContents: function () {
      let trCount = Math.floor((this.calendar.lastDay + this.calendar.firstWday) / 7)
      if ((this.calendar.lastDay + this.calendar.firstWday) % 7) {
        trCount++
      }
      const result = []
      let day = 1
      for (let trCountI = 1; trCountI <= trCount; trCountI++) {
        const week = []
        let wI = 0
        if (trCountI === 1) {
          for (let firstWdayI = 0; firstWdayI < this.calendar.firstWday; firstWdayI++) {
            week.push({ notThisMonth: true, day: 0, type: 'none' })
            wI++
          }
          while (wI < 7) {
            const momentInstance = moment()
              .year(this.calendar.year).month(this.calendar.month).date(day)
            const formatDate = momentInstance.format('YYYY-MM-DD')
            week.push({
              isPreMonth: false,
              day,
              date: formatDate,
              type: this.getDayType(day),
              text: this.getDayText(day)
            })
            wI++
            day++
          }
        } else {
          while (wI < 7) {
            if (day > this.calendar.lastDay) {
              week.push({ notThisMonth: true, day: 0, type: 'none' })
            } else {
              const momentInstance = moment()
                .year(this.calendar.year).month(this.calendar.month).date(day)
              const formatDate = momentInstance.format('YYYY-MM-DD')
              week.push({
                isPreMonth: false,
                day,
                date: formatDate,
                type: this.getDayType(day),
                text: this.getDayText(day)
              })
            }
            wI++
            day++
          }
        }
        result.push(week)
      }
      return result
    },
    legalHolidaySet: function () {
      const result = new Set()
      this.wdayHolidayItems.forEach((item) => {
        if (item.wday !== undefined) {
          result.add(item.wday)
        }
      })
      return result
    },
    japaneseHolidayHash: function () {
      const result = {}
      this.japaneseHolidayItems.forEach((item) => {
        result[item.date] = item.name
      })
      return result
    },
    publicHolidayWdaySet: function () {
      const result = new Set()
      this.wdayPublicHolidayItems.forEach((item) => {
        if (item.wday !== undefined) {
          result.add(item.wday)
        }
      })
      return result
    },
    publicHolidayHash: function () {
      const result = {}
      this.holidayItems.forEach((item) => {
        result[item.date] = item.name
      })
      return result
    }
  },
  mounted () {
    this.getCompanyItems()
    this.getCompanyHoliday()
    this.getJapaneseHoliday()
    this.getCompanyActivateJapaneseHoliday()
  },
  watch: {
  },
  methods: {
    deleteSelectedDays (deleteDate) {
      this.calendar.selectedDays = this.calendar.selectedDays.filter((date) => {
        return deleteDate !== date
      })
    },
    addSelectedDays (dayObj) {
      if (dayObj.type === 'free') {
        if (!this.calendar.selectedDays.includes(dayObj.date)) {
          this.calendar.selectedDays.push(dayObj.date)
          this.calendar.selectedDays = this.calendar.selectedDays.sort()
        } else {
          this.calendar.selectedDays = this.calendar.selectedDays.filter((date) => {
            return date !== dayObj.date
          })
        }
      }
    },
    getDayText (day) {
      const momentInstance = moment()
        .year(this.calendar.year).month(this.calendar.month).date(day)
      const formatDate = momentInstance.format('YYYY-MM-DD')

      let result = ''
      if (this.publicHolidayHash[formatDate]) {
        result = this.publicHolidayHash[formatDate]
      }
      if (this.japaneseHolidayHash[formatDate]) {
        result = this.japaneseHolidayHash[formatDate]
      }
      return result
    },
    getDayType (day) {
      /*
         祝日: japanese-holiday
         法定休日: legal-holiday(this.wdayHolidayItems)
         所定休日/公休日（曜日）: public-holiday-wday
         所定休日/公休日（特定日）: public-holiday
         設定可能日: free
      */
      const momentInstance = moment()
        .year(this.calendar.year).month(this.calendar.month).date(day)
      const wday = momentInstance.day()
      const formatDate = momentInstance.format('YYYY-MM-DD')

      let result = 'free'
      if (this.legalHolidaySet.has(wday)) {
        result = 'legal-holiday'
      }
      if (this.publicHolidayWdaySet.has(wday)) {
        result = 'public-holiday-wday'
      }
      if (this.isActivateJapaneseHoliday) {
        if (this.japaneseHolidayHash[formatDate] !== undefined) {
          result = 'japanese-holiday'
        }
      }
      if (this.publicHolidayHash[formatDate] !== undefined) {
        result = 'public-holiday'
      }
      return result
    },
    calendarNextMonth () {
      const momentInstance = moment()
        .year(this.calendar.year).month(this.calendar.month).add(1, 'months').date(1)
      this.calendar.year = momentInstance.year()
      this.calendar.month = momentInstance.month()
      this.calendar.firstWday = momentInstance.day()
      this.calendar.lastDay = momentInstance.add(1, 'months').date(1).subtract(1, 'days').date()
    },
    calendarPrevMonth () {
      const momentInstance = moment()
        .year(this.calendar.year).month(this.calendar.month).subtract(1, 'months').date(1)
      this.calendar.year = momentInstance.year()
      this.calendar.month = momentInstance.month()
      this.calendar.firstWday = momentInstance.day()
      this.calendar.lastDay = momentInstance.add(1, 'months').date(1).subtract(1, 'days').date()
    },
    saveCalendar () {
      const postData = {
        selected_days: this.calendar.selectedDays,
        name: this.calendar.editName
      }
      axios.post('/api/manager/company-holiday-multiple', postData)
        .then((res) => {
          this.calendarDialog = false
          this.getCompanyHoliday()
          this.calendar.selectedDays = []
          this.calendar.editName = ''
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    getCompanyItems () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItems = res.data
          this.lateNightTimeObj.begin_late_night_time = this.companyItems['begin_late_night_time']
          this.lateNightTimeObj.end_late_night_time = this.companyItems['end_late_night_time']
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getStaff]')
        })
    },
    getCompanyActivateJapaneseHoliday () {
      axios.get('/api/manager/company-japanage-holiday')
        .then((res) => {
          this.isActivateJapaneseHoliday = res.data[0]
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    getJapaneseHoliday () {
      axios.get('/api/manager/japanese-holiday')
        .then((res) => {
          this.japaneseHolidayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getJapaneseHoliday]')
        })
    },
    getCompanyHoliday () {
      axios.get('/api/manager/company-holiday?type=date')
        .then((res) => {
          this.holidayItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
      axios.get('/api/manager/company-holiday?type=wday')
        .then((res) => {
          this.wdayHolidayItems = []
          if (res.data.length !== 0) {
            for (let x = 0; x < 7; x++) {
              let dataExists = false
              res.data.forEach((item) => {
                if (x === item.wday) {
                  this.wdayHolidayItems.push(item)
                  dataExists = true
                }
              })
              if (!dataExists) {
                this.wdayHolidayItems.push({ id: x })
              }
            }
          } else {
            for (let x = 0; x < 7; x++) {
              this.wdayHolidayItems.push({ id: x })
            }
          }
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
      axios.get('/api/manager/company-holiday?type=publicHolidayWday')
        .then((res) => {
          this.wdayPublicHolidayItems = []
          if (res.data.length !== 0) {
            for (let x = 0; x < 7; x++) {
              let dataExists = false
              res.data.forEach((item) => {
                if (x === item.wday) {
                  this.wdayPublicHolidayItems.push(item)
                  dataExists = true
                }
              })
              if (!dataExists) {
                this.wdayPublicHolidayItems.push({ id: x })
              }
            }
          } else {
            for (let x = 0; x < 7; x++) {
              this.wdayPublicHolidayItems.push({ id: x })
            }
          }
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    save (wdayId, type = 'legalHoliday') {
      if (wdayId !== 'not' && type === 'legalHoliday') { // 定休日（法定休日）設定だったら
        this.editedItem.type = 'wday'
        this.editedItem.wday = wdayId
      } else if (wdayId !== 'not' && type === 'publicHolidayWday') {
        this.editedItem.type = 'publicHolidayWday'
        this.editedItem.wday = wdayId
      }
      if (this.editedItem.id === 0) { // 定休日設定の場合は常にpostかdeleteWday()
        axios.post('/api/manager/company-holiday', this.editedItem)
          .then((res) => {
            if (wdayId !== 'not' && res.data.registered === 0) {
              this.snackbar = true
            }
            this.dialog = false
            this.editedItem = cloneDeep(defaultEditedItem)
            this.getCompanyHoliday()
          })
          .catch(() => {
            alert('登録エラー')
          })
      } else {
        axios.put('/api/manager/company-holiday/' + this.editedItem.id, this.editedItem)
          .then((res) => {
            this.dialog = false
            this.editedItem = cloneDeep(defaultEditedItem)
            this.getCompanyHoliday()
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    lateNightTimeSave () {
      axios.put('/api/manager/company-late-night-time/', this.lateNightTimeObj)
        .then((res) => {
          this.editedBeginTimeModal = false
          this.editedEndTimeModal = false
          this.getCompanyItems()
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    putCompanyActivateJapaneseHoliday (isActivateJapaneseHoliday) {
      axios.put('/api/manager/company-japanage-holiday/' + isActivateJapaneseHoliday)
        .then((res) => {
          this.snackbar = true
          this.getCompanyHoliday()
          this.getCompanyActivateJapaneseHoliday()
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    deleteWday (id) {
      axios.delete('/api/manager/company-holiday/' + id)
        .then(() => {
          this.snackbar = true
          this.getCompanyHoliday()
        })
        .catch(() => {
          alert('削除エラー')
        })
    },
    deleteItem (item) {
      if (confirm(item.date + 'の\n' + item.name + 'を削除します。')) {
        axios.delete('/api/manager/company-holiday/' + item.id)
          .then(() => {
            this.getCompanyHoliday()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.editedItem = cloneDeep(defaultEditedItem)
    }
  }
}
</script>
