import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'

export default {
  methods: {
    errorDecision (errorStatus, redirectUri, alertContent) {
      if (errorStatus.response.status === 401) {
        this.$router.push(redirectUri)
      } else {
        alert(alertContent)
        this.$router.push(redirectUri)
      }
    },
    convAtDate (date) {
      return moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD')
    },
    convAtTime (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm')
    },
    calBaseRestTimeWhenAttendance (attendanceTime, companyItem, defaultAmRestMinutes, defaultRestMinutes, defaultPmRestMinutes) {
      if (attendanceTime <= companyItem['begin_am_rest_time']) {
        return 0
      }
      if (attendanceTime > companyItem['begin_am_rest_time'] && attendanceTime < companyItem['end_am_rest_time']) {
        return (moment(attendanceTime, 'HH:mm').diff(moment(companyItem['begin_am_rest_time'], 'HH:mm'), 'minute'))
      }
      if (attendanceTime >= companyItem['end_am_rest_time'] && attendanceTime <= companyItem['begin_rest_time']) {
        return defaultAmRestMinutes
      }
      if (attendanceTime > companyItem['begin_rest_time'] && attendanceTime < companyItem['end_rest_time']) {
        return (moment(attendanceTime, 'HH:mm').diff(moment(companyItem['begin_rest_time'], 'HH:mm'), 'minute'))
      }
      if (attendanceTime >= companyItem['end_rest_time'] && attendanceTime <= companyItem['begin_pm_rest_time']) {
        return defaultAmRestMinutes + defaultRestMinutes
      }
      if (attendanceTime > companyItem['begin_pm_rest_time'] && attendanceTime < companyItem['end_pm_rest_time']) {
        return defaultAmRestMinutes + defaultRestMinutes + (moment(attendanceTime, 'HH:mm').diff(moment(companyItem['begin_pm_rest_time'], 'HH:mm'), 'minute'))
      }
      if (attendanceTime >= companyItem['end_pm_rest_time']) {
        return defaultAmRestMinutes + defaultRestMinutes + defaultPmRestMinutes
      }
    },
    calBaseRestTimeWhenLeave (endTime, leaveTime, companyItem, defaultRestMinutes, defaultPmRestMinutes) {
      if (endTime <= companyItem['begin_am_rest_time'] && endTime !== '' && companyItem['begin_am_rest_time'] !== '') {
        return 'reset'
      } else if (endTime <= companyItem['begin_rest_time'] && endTime !== '') {
        return (defaultRestMinutes + defaultPmRestMinutes)
      } else if (endTime <= companyItem['begin_pm_rest_time'] && endTime !== '' && companyItem['begin_pm_rest_time'] !== '') {
        return defaultPmRestMinutes
      } else if (leaveTime <= companyItem['begin_am_rest_time'] && companyItem['begin_am_rest_time'] !== '') {
        return 'reset'
      } else if (leaveTime > companyItem['begin_pm_rest_time'] && companyItem['begin_pm_rest_time'] !== '') {
        if (leaveTime < companyItem['end_pm_rest_time'] && companyItem['end_pm_rest_time']) {
          return defaultPmRestMinutes
        } else {
          return 'reset'
        }
      } else if (leaveTime > companyItem['begin_rest_time']) {
        if (leaveTime < companyItem['end_rest_time']) {
          return defaultRestMinutes
        } else if (leaveTime <= companyItem['begin_pm_rest_time'] && companyItem['begin_pm_rest_time'] !== '') {
          return defaultPmRestMinutes
        } else if (leaveTime >= companyItem['end_rest_time']) {
          return 'reset'
        }
      } else if (leaveTime <= companyItem['begin_rest_time']) {
        return (defaultRestMinutes + defaultPmRestMinutes)
      } else if (leaveTime > companyItem['begin_am_rest_time'] && companyItem['begin_am_rest_time'] !== '') {
        if (leaveTime < companyItem['end_am_rest_time'] && companyItem['end_am_rest_time']) {
          return (defaultRestMinutes + defaultPmRestMinutes)
        }
      }
    },
    getOutingRecordTiming (recordAt, companyItem) {
      if (
        companyItem['begin_am_rest_time'] >= moment(recordAt).format('HH:mm') &&
        companyItem['begin_am_rest_time'] !== ''
      ) {
        // 午前休憩入りより前
        return 'beforeAmRest'
      } else if (
        companyItem['begin_am_rest_time'] <= moment(recordAt).format('HH:mm') &&
        companyItem['end_am_rest_time'] > moment(recordAt).format('HH:mm') &&
        companyItem['begin_am_rest_time'] !== '' && companyItem['end_am_rest_time'] !== ''
      ) {
        // 午前休憩中
        return 'inAmRest'
      } else if (
        companyItem['end_am_rest_time'] <= moment(recordAt).format('HH:mm') &&
        companyItem['begin_rest_time'] > moment(recordAt).format('HH:mm') &&
        companyItem['end_am_rest_time'] !== '' && companyItem['begin_rest_time'] !== ''
      ) {
        // 午前休憩終わりとお昼休憩入りの間
        return 'whileOfAmRestAndRest'
      } else if (
        companyItem['end_am_rest_time'] === '' &&
        companyItem['begin_rest_time'] > moment(recordAt).format('HH:mm')
      ) {
        // お昼休憩入りより前
        return 'beforeRest'
      } else if (
        companyItem['begin_rest_time'] <= moment(recordAt).format('HH:mm') &&
        companyItem['end_rest_time'] > moment(recordAt).format('HH:mm') &&
        companyItem['begin_rest_time'] !== '' && companyItem['end_rest_time'] !== ''
      ) {
        // お昼休憩中
        return 'inRest'
      } else if (
        companyItem['end_rest_time'] <= moment(recordAt).format('HH:mm') &&
        companyItem['begin_pm_rest_time'] > moment(recordAt).format('HH:mm') &&
        companyItem['end_rest_time'] !== '' && companyItem['begin_pm_rest_time'] !== ''
      ) {
        // お昼休憩終わりと午後休憩入りの間
        return 'whileOfRestAndPmRest'
      } else if (
        companyItem['end_pm_rest_time'] === '' &&
        companyItem['end_rest_time'] <= moment(recordAt).format('HH:mm')
      ) {
        // お昼休憩終わりより後
        return 'afterRest'
      } else if (
        companyItem['begin_pm_rest_time'] <= moment(recordAt).format('HH:mm') &&
        companyItem['end_pm_rest_time'] > moment(recordAt).format('HH:mm') &&
        companyItem['begin_pm_rest_time'] !== '' && companyItem['end_pm_rest_time'] !== ''
      ) {
        // 午後休憩中
        return 'inPmRest'
      } else if (
        companyItem['end_pm_rest_time'] <= moment(recordAt).format('HH:mm') &&
        companyItem['end_pm_rest_time'] !== ''
      ) {
        // 午後休憩終わりより後
        return 'afterPmRest'
      }
    },
    calculateOutingTime (outOfOfficeTiming, returnToOfficeTiming, outOfOfficeRecordAt, returnToOfficeRecordAt, companyItem) {
      // 就業規則の休憩時間以外の外出時間を計算する
      if (
        outOfOfficeTiming === 'beforeAmRest' &&
        returnToOfficeTiming === 'beforeAmRest'
      ) {
        return moment(returnToOfficeRecordAt).diff(moment(outOfOfficeRecordAt), 'minute')
      } else if (
        outOfOfficeTiming === 'beforeAmRest' &&
        returnToOfficeTiming === 'inAmRest'
      ) {
        return moment(companyItem['begin_am_rest_time'], 'HH:mm').diff(moment(outOfOfficeRecordAt), 'minute')
      } else if (
        outOfOfficeTiming === 'inAmRest' &&
        returnToOfficeTiming === 'inAmRest'
      ) {
        return 0
      } else if (
        outOfOfficeTiming === 'inAmRest' &&
        (returnToOfficeTiming === 'beforeRest' || returnToOfficeTiming === 'whileOfAmRestAndRest')
      ) {
        return moment(returnToOfficeRecordAt).diff(moment(companyItem['end_am_rest_time'], 'HH:mm'), 'minute')
      } else if (
        (outOfOfficeTiming === 'beforeRest' || outOfOfficeTiming === 'whileOfAmRestAndRest') &&
        (returnToOfficeTiming === 'beforeRest' || returnToOfficeTiming === 'whileOfAmRestAndRest')
      ) {
        return moment(returnToOfficeRecordAt).diff(moment(outOfOfficeRecordAt), 'minute')
      } else if (
        (outOfOfficeTiming === 'beforeRest' || outOfOfficeTiming === 'whileOfAmRestAndRest') &&
        returnToOfficeTiming === 'inRest'
      ) {
        return moment(companyItem['begin_rest_time'], 'HH:mm').diff(moment(outOfOfficeRecordAt), 'minute')
      } else if (
        outOfOfficeTiming === 'inRest' &&
        returnToOfficeTiming === 'inRest'
      ) {
        return 0
      } else if (
        outOfOfficeTiming === 'inRest' &&
        (returnToOfficeTiming === 'afterRest' || returnToOfficeTiming === 'whileOfRestAndPmRest')
      ) {
        return moment(returnToOfficeRecordAt).diff(moment(companyItem['end_rest_time'], 'HH:mm'), 'minute')
      } else if (
        (outOfOfficeTiming === 'afterRest' || outOfOfficeTiming === 'whileOfRestAndPmRest') &&
        (returnToOfficeTiming === 'afterRest' || returnToOfficeTiming === 'whileOfRestAndPmRest')
      ) {
        return moment(returnToOfficeRecordAt).diff(outOfOfficeRecordAt, 'minute')
      } else if (
        (outOfOfficeTiming === 'afterRest' || outOfOfficeTiming === 'whileOfRestAndPmRest') &&
        returnToOfficeTiming === 'inPmRest'
      ) {
        return moment(companyItem['begin_pm_rest_time'], 'hh:mm').diff(outOfOfficeRecordAt, 'minute')
      } else if (
        outOfOfficeTiming === 'inPmRest' &&
        returnToOfficeTiming === 'inPmRest'
      ) {
        return 0
      } else if (
        outOfOfficeTiming === 'inPmRest' &&
        returnToOfficeTiming === 'afterPmRest'
      ) {
        return moment(returnToOfficeRecordAt).diff(moment(companyItem['end_pm_rest_time'], 'HH:mm'), 'minute')
      } else if (
        outOfOfficeTiming === 'afterPmRest' &&
        (outOfOfficeTiming === 'afterPmRest')
      ) {
        return moment(returnToOfficeRecordAt).diff(outOfOfficeRecordAt, 'minute')
      }
    },
    processItems (items, staff, companyItem = {}, closed = '', projectItems = []) {
      let itemsByDate = {}
      let itemsByDate2 = {}
      let itemsByDate3 = []
      let byStaffWeekWorkingRecordItem = []
      let bystaffDailyWorkingMinutesItem = []
      let defaultAmRestMinutes = 0
      let defaultPmRestMinutes = 0
      let defaultRestMinutes = 0
      let closingWeekday = 0

      if ('id' in companyItem) {
        if (companyItem['starting_weekday_overtime_weekly'] === 0) {
          closingWeekday = 6
        } else if (companyItem['starting_weekday_overtime_weekly'] === 6) {
          closingWeekday = 5
        }

        if (
          companyItem['begin_am_rest_time'] !== '' &&
          companyItem['end_am_rest_time'] !== '' &&
          companyItem['begin_pm_rest_time'] !== '' &&
          companyItem['end_am_rest_time'] !== ''
        ) {
          defaultAmRestMinutes = moment(companyItem['end_am_rest_time'], 'HH:mm').diff(moment(companyItem['begin_am_rest_time'], 'HH:mm'), 'minute')
          defaultPmRestMinutes = moment(companyItem['end_pm_rest_time'], 'HH:mm').diff(moment(companyItem['begin_pm_rest_time'], 'HH:mm'), 'minute')
        }
        if (
          companyItem['begin_rest_time'] !== '' &&
          companyItem['end_rest_time'] !== ''
        ) {
          defaultRestMinutes = moment(companyItem['end_rest_time'], 'HH:mm').diff(moment(companyItem['begin_rest_time'], 'HH:mm'), 'minute')
        }
      }

      items.forEach((item, idx) => {
        if (closed === '') {
          if (this.group !== '指定なし') {
            if (this.group !== '') {
              if (item.group_id !== this.groupId) {
                return
              }
            }
          }

          if (staff && staff !== '指定なし') {
            if (!item.name.includes(staff)) {
              return
            }
          }
        }
        item.record_atJpDate = this.convAtDate(item.record_at)
        if (!itemsByDate[item.record_atJpDate]) {
          itemsByDate[item.record_atJpDate] = {}
        }
        if (!itemsByDate[item.record_atJpDate][item.staff_id]) {
          itemsByDate[item.record_atJpDate][item.staff_id] = []
        }
        itemsByDate[item.record_atJpDate][item.staff_id].push(item)
      })

      Object.keys(itemsByDate).forEach((date) => {
        if (!itemsByDate2[date]) {
          itemsByDate2[date] = {}
        }
        Object.keys(itemsByDate[date]).forEach((staffId) => {
          let defaultTotalRestMinutes = defaultAmRestMinutes + defaultRestMinutes + defaultPmRestMinutes

          if ('id' in companyItem) {
            if (companyItem['is_weekly_40_time_or_more_working_hours_overtime_and_do'] === 1) {
              // スタッフ別に1日の労働時間を記録
              if (!bystaffDailyWorkingMinutesItem[staffId]) {
                bystaffDailyWorkingMinutesItem[staffId] = {
                  worktimeMinutes: 0
                }
              }
            }

            // byStaffWeekWorkingRecordItem[staffId]が存在していて、起算曜日の時は削除
            // もしくは記録された日付から1週間経過していたら削除
            // 記録されている1日の労働時間も0に初期化

            if (byStaffWeekWorkingRecordItem[staffId]) {
              if (moment(date).day() === companyItem['starting_weekday_overtime_weekly']) {
                bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes = 0
                delete byStaffWeekWorkingRecordItem[staffId]
              } else if (date > moment(byStaffWeekWorkingRecordItem[staffId].date).add(7, 'days').format('YYYY-MM-DD')) {
                bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes = 0
                delete byStaffWeekWorkingRecordItem[staffId]
              }
            }

            if (companyItem['is_weekly_40_time_or_more_working_hours_overtime_and_do'] === 1) {
              // 1週間の労働時間の合計を記録していく
              if (!byStaffWeekWorkingRecordItem[staffId] && moment(date).day() !== 6) {
                byStaffWeekWorkingRecordItem[staffId] = {
                  date: date,
                  a_week_total_working_minutes: 0
                }
              }
            }

            // 加算
            if (byStaffWeekWorkingRecordItem[staffId]) {
              byStaffWeekWorkingRecordItem[staffId].a_week_total_working_minutes += bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes
            }
          }

          let workingItemsObj = {
            attendance: null,
            leave: null,
            rest: null,
            amRest: null,
            pmRest: null,
            outOfOffice: null,
            returnToOffice: null,
            outOfOfficeTiming: '',
            returnToOfficeTiming: '',
            restMinutes: 0,
            countOutOfOffice: 0,
            countReturnToOffice: 0,
            countRest: 0,
            countRestart: 0,
            countAmRest: 0,
            countAmRestart: 0,
            countPmRest: 0,
            countPmRestart: 0,
            errorItems: [],
            isLeavingSpanningDate: 0,
            restTimeAtLeaving: 0
          }

          itemsByDate[date][staffId].forEach((item) => {
            if (!itemsByDate2[date][staffId]) {
              itemsByDate2[date][staffId] = {}
            }
            if (item.category === 'attendance') {
              itemsByDate2[date][staffId][item.id] = {
                staff_id: itemsByDate[date][staffId][0].staff_id,
                yayoi_code: itemsByDate[date][staffId][0].yayoi_code || '',
                name: itemsByDate[date][staffId][0].name,
                group_id: itemsByDate[date][staffId][0].group_id,
                is_part: itemsByDate[date][staffId][0].is_part,
                project_id: item.project_id,
                is_late_night_time_shift: itemsByDate[date][staffId][0].is_late_night_time_shift,
                is_holiday: 0,
                is_early_withdrawal: 0,
                is_tardiness: 0,
                tardiness_time: 0,
                early_withdrawal_time: 0,
                attendance: '',
                leave: '',
                rest_minutes: 0,
                outing_minutes: 0,
                work_minute: 0,
                is_leaving_spanning_date: 0,
                overtime_workday_minutes: 0,
                overtime_workday_midnight_minutes: 0,
                overtime_holiday_workday_midnight_minutes: 0,
                early_attendance_time: 0, // 早出の時間を記録
                overtime_time: 0, // 所定労働時間を超えた労働時間
                legal_overtime_working_minutes: 0, // 法定内時間外労働
                non_statutory_working_minutes: 0, // 法定外時間外労働
                is_public_holiday_working: 0,
                is_overtime_working_date: 0,
                hasError: false,
                errorText: '',
                closed_id: 0,
                body_temperature: '',
                is_base_transfer_leave: 0,
                am_paid_leave: 0,
                workingItemsObj: cloneDeep(workingItemsObj)
              }
            }
          })

          let attendanceId = 0
          let outOfOfficeTiming = ''
          let returnToOfficeTiming = ''
          let outOfOffice = ''
          let returnToOffice = ''

          // 対象の日の、対象スタッフの労働時間計算
          itemsByDate[date][staffId].forEach((item) => {
            let staffItem = itemsByDate2[date][item.staff_id]

            if (projectItems.length > 0) {
              attendanceId = item.begin_id
            } else {
              attendanceId = Object.keys(staffItem)[0]
            }

            if (item.category === 'attendance') {
              attendanceId = item.id
              staffItem[attendanceId].is_public_holiday_working = item.is_public_holiday_working
              staffItem[attendanceId].is_overtime_working_date = item.is_overtime_working_date
              staffItem[attendanceId].closed_id = item.closed_id
              staffItem[attendanceId].body_temperature = item.body_temperature
              staffItem[attendanceId].is_tardiness = item.is_tardiness
              staffItem[attendanceId].tardiness_time = item.tardiness_time
              staffItem[attendanceId].attendance = this.convAtTime(item.without_change_record_at)
              staffItem[attendanceId].workingItemsObj.attendance = item.record_at
              staffItem[attendanceId].is_base_transfer_leave = item.is_base_transfer_leave
              staffItem[attendanceId].am_paid_leave = item.am_paid_leave

              if (companyItem['is_working_out_of_regular_working_hours_to_do'] === 1 && 'id' in companyItem) {
                staffItem[attendanceId].overtime_workday_minutes += item.overtime_workday_minutes
              }

              staffItem[attendanceId].early_attendance_time = item.overtime_workday_minutes
              staffItem[attendanceId].overtime_workday_midnight_minutes += item.overtime_workday_midnight_minutes
              staffItem[attendanceId].overtime_holiday_workday_midnight_minutes += item.overtime_holiday_workday_midnight_minutes
              let attendanceTime = moment(item.record_at).format('HH:mm')

              // 深夜時間帯を超えた出勤だったら
              if (companyItem['begin_late_night_time'] <= attendanceTime || (attendanceTime >= '00:00' && attendanceTime <= companyItem['end_late_night_time'])) {
                staffItem[attendanceId].is_late_night_time_shift = 1

                let targetDate = moment(item.record_at).format('YYYY-MM-DD')
                let beginLateNightTime = moment(targetDate, 'YYYY-MM-DD').hour(22).minutes(0).format()
                let attendance = moment(item.record_at).format()

                if (companyItem['begin_late_night_time'] < attendanceTime) {
                  staffItem[attendanceId].overtime_workday_midnight_minutes -= Number(moment(attendance).diff(beginLateNightTime, 'minutes'))
                }
              }

              if ('id' in companyItem) {
                defaultTotalRestMinutes -= this.calBaseRestTimeWhenAttendance(attendanceTime, companyItem, defaultAmRestMinutes, defaultRestMinutes, defaultPmRestMinutes)
              }
            } else if (item.category === 'leave') {
              if (staffItem[attendanceId]) {
                let leaveTime = moment(item.record_at).format('HH:mm')
                let leaveTime2 = ''

                if (companyItem['is_rest_time_leaving_overtime_do_not'] === 1) {
                  // 休憩中に退勤した場合、労働時間に加算しない
                  let leaveTiming = this.getOutingRecordTiming(item.record_at, companyItem)
                  if (leaveTiming === 'inAmRest' || leaveTiming === 'inRest' || leaveTiming === 'inPmRest') {
                    let beginRest = ''
                    if (leaveTiming === 'inAmRest') {
                      beginRest = 'begin_am_rest_time'
                    } else if (leaveTiming === 'inRest') {
                      beginRest = 'begin_rest_time'
                    } else if (leaveTiming === 'inPmRest') {
                      beginRest = 'begin_pm_rest_time'
                    }
                    leaveTime2 = companyItem[beginRest]
                  }
                }

                if (item.is_leaving_spanning_date === 1) {
                  staffItem[attendanceId].workingItemsObj.isLeavingSpanningDate = 1
                  staffItem[attendanceId].is_leaving_spanning_date = 1
                  leaveTime = '23:59'
                }
                staffItem[attendanceId].closed_id = item.closed_id
                staffItem[attendanceId].is_holiday = item.is_holiday
                staffItem[attendanceId].is_early_withdrawal = item.is_early_withdrawal
                staffItem[attendanceId].early_withdrawal_time = item.early_withdrawal_time
                staffItem[attendanceId].leave = this.convAtTime(item.without_change_record_at)

                if (leaveTime2 !== '') {
                  let leave2 = ''
                  let restTimeAtLeaving2 = ''
                  leave2 = moment(item.record_at).hour(leaveTime2.substr(0, 2)).tz('UTC').format()
                  staffItem[attendanceId].workingItemsObj.leave = moment(leave2).minute(leaveTime2.substr(3, 2)).tz('UTC').format()
                  // 就業規則の休憩時間中に退勤した場合、就業規則の休憩開始から退勤までの時間を記録
                  restTimeAtLeaving2 = new Date(item.record_at).getTime() - new Date(staffItem[attendanceId].workingItemsObj.leave).getTime()
                  staffItem[attendanceId].workingItemsObj.restTimeAtLeaving = (restTimeAtLeaving2 / 1000 / 60)
                } else {
                  staffItem[attendanceId].workingItemsObj.leave = item.record_at
                }

                if (companyItem['is_working_out_of_regular_working_hours_to_do'] === 1 && 'id' in companyItem) {
                  if ((staffItem[attendanceId].overtime_workday_minutes - staffItem[attendanceId].tardiness_time) < 0) {
                    staffItem[attendanceId].overtime_workday_minutes += 0
                  } else {
                    staffItem[attendanceId].overtime_workday_minutes += (staffItem[attendanceId].overtime_workday_minutes - staffItem[attendanceId].tardiness_time)
                  }
                }
                staffItem[attendanceId].overtime_workday_midnight_minutes += item.overtime_workday_midnight_minutes
                staffItem[attendanceId].overtime_holiday_workday_midnight_minutes += item.overtime_holiday_workday_midnight_minutes
                staffItem[attendanceId].overtime_workday_minutes += item.overtime_workday_minutes
                staffItem[attendanceId].overtime_time += staffItem[attendanceId].overtime_workday_minutes
                staffItem[attendanceId].is_late_night_time_shift += item.is_late_night_time_shift

                if (staffItem[attendanceId].is_late_night_time_shift === 0) {
                  staffItem[attendanceId].overtime_time += staffItem[attendanceId].overtime_workday_midnight_minutes
                }

                if ('id' in companyItem) {
                  let baseRestTime = this.calBaseRestTimeWhenLeave(item.end_time, leaveTime, companyItem, defaultRestMinutes, defaultPmRestMinutes)
                  if (baseRestTime === 'reset') {
                    baseRestTime = 0
                  }
                  defaultTotalRestMinutes -= baseRestTime
                }
                if (item.is_leaving_spanning_date !== 1) {
                  // 就業規則で決められた休憩時間より短い休憩だったら
                  if (defaultTotalRestMinutes !== 0 && defaultTotalRestMinutes > staffItem[attendanceId].workingItemsObj.restMinutes) {
                    staffItem[attendanceId].overtime_workday_minutes += defaultTotalRestMinutes - staffItem[attendanceId].workingItemsObj.restMinutes
                  }
                  staffItem[attendanceId].workingItemsObj.restMinutes = 0
                }
              } else {
                // 出勤データがないとき用にデータを作成
                itemsByDate2[date][staffId][0] = {
                  staff_id: itemsByDate[date][staffId][0].staff_id,
                  yayoi_code: itemsByDate[date][staffId][0].yayoi_code || '',
                  name: itemsByDate[date][staffId][0].name,
                  group_id: itemsByDate[date][staffId][0].group_id,
                  is_part: itemsByDate[date][staffId][0].is_part,
                  project_id: item.project_id,
                  is_late_night_time_shift: itemsByDate[date][staffId][0].is_late_night_time_shift,
                  is_holiday: 0,
                  is_early_withdrawal: 0,
                  is_tardiness: 0,
                  tardiness_time: 0,
                  early_withdrawal_time: 0,
                  attendance: '',
                  leave: this.convAtTime(item.without_change_record_at),
                  rest_minutes: 0,
                  outing_minutes: 0,
                  work_minute: 0,
                  is_leaving_spanning_date: 0,
                  overtime_workday_minutes: 0,
                  overtime_workday_midnight_minutes: 0,
                  overtime_holiday_workday_midnight_minutes: 0,
                  early_attendance_time: 0, // 早出の時間を記録
                  overtime_time: 0, // 所定労働時間を超えた労働時間
                  legal_overtime_working_minutes: 0, // 法定内時間外労働
                  non_statutory_working_minutes: 0, // 法定外時間外労働
                  is_public_holiday_working: 0,
                  is_overtime_working_date: 0,
                  hasError: true,
                  errorText: '',
                  closed_id: 0,
                  body_temperature: '',
                  am_paid_leave: 0,
                  workingItemsObj: cloneDeep(workingItemsObj)
                }
                itemsByDate2[date][staffId][0].workingItemsObj.leave = this.convAtTime(item.without_change_record_at)
              }
            } else if (item.category === 'amRest' && staffItem[attendanceId]) {
              staffItem[attendanceId].workingItemsObj.amRest = item.record_at
              staffItem[attendanceId].workingItemsObj.countAmRest++
            } else if (item.category === 'amRestart' && staffItem[attendanceId]) {
              if (staffItem[attendanceId].workingItemsObj.amRest) {
                staffItem[attendanceId].rest_minutes += Number(moment(item.record_at).diff(staffItem[attendanceId].workingItemsObj.amRest, 'minutes'))
                staffItem[attendanceId].workingItemsObj.restMinutes += Number(moment(item.record_at).diff(staffItem[attendanceId].workingItemsObj.amRest, 'minutes'))
                staffItem[attendanceId].workingItemsObj.amRest = null
              }
              staffItem[attendanceId].workingItemsObj.countAmRestart++
            } else if (item.category === 'pmRest' && staffItem[attendanceId]) {
              staffItem[attendanceId].workingItemsObj.pmRest = item.record_at
              staffItem[attendanceId].workingItemsObj.countPmRest++
            } else if (item.category === 'pmRestart' && staffItem[attendanceId]) {
              if (staffItem[attendanceId].workingItemsObj.pmRest) {
                staffItem[attendanceId].rest_minutes += Number(moment(item.record_at).diff(staffItem[attendanceId].workingItemsObj.pmRest, 'minutes'))
                staffItem[attendanceId].workingItemsObj.restMinutes += Number(moment(item.record_at).diff(staffItem[attendanceId].workingItemsObj.pmRest, 'minutes'))
                staffItem[attendanceId].workingItemsObj.pmRest = null
              }
              staffItem[attendanceId].workingItemsObj.countPmRestart++
              if (item.is_leaving_spanning_date === 1) {
                if (defaultTotalRestMinutes !== 0 && defaultTotalRestMinutes > staffItem[attendanceId].workingItemsObj.restMinutes) {
                  staffItem[attendanceId].overtime_workday_minutes += defaultTotalRestMinutes - staffItem[attendanceId].workingItemsObj.restMinutes
                }
                staffItem[attendanceId].workingItemsObj.restMinutes = 0
              }
            } else if (item.category === 'rest' && staffItem[attendanceId]) {
              staffItem[attendanceId].workingItemsObj.rest = item.record_at
              staffItem[attendanceId].workingItemsObj.countRest++
            } else if (item.category === 'restart' && staffItem[attendanceId]) {
              if (staffItem[attendanceId].workingItemsObj.rest) {
                staffItem[attendanceId].rest_minutes += Number(moment(item.record_at).diff(staffItem[attendanceId].workingItemsObj.rest, 'minutes'))
                staffItem[attendanceId].workingItemsObj.restMinutes += Number(moment(item.record_at).diff(staffItem[attendanceId].workingItemsObj.rest, 'minutes'))
                staffItem[attendanceId].workingItemsObj.rest = null
              }
              staffItem[attendanceId].workingItemsObj.countRestart++
            } else if (item.category === 'outOfOffice' && staffItem[attendanceId]) {
              staffItem[attendanceId].workingItemsObj.outOfOffice = item.record_at
              outOfOffice = item.record_at
              if ('id' in companyItem) {
                staffItem[attendanceId].workingItemsObj.outOfOfficeTiming = this.getOutingRecordTiming(item.record_at, companyItem)
                outOfOfficeTiming = this.getOutingRecordTiming(item.record_at, companyItem)
              }
              staffItem[attendanceId].workingItemsObj.countOutOfOffice++
            } else if (item.category === 'returnToOffice' && staffItem[attendanceId]) {
              staffItem[attendanceId].workingItemsObj.returnToOffice = item.record_at
              returnToOffice = item.record_at
              if ('id' in companyItem) {
                staffItem[attendanceId].workingItemsObj.returnToOfficeTiming = this.getOutingRecordTiming(item.record_at, companyItem)
                returnToOfficeTiming = this.getOutingRecordTiming(item.record_at, companyItem)
                staffItem[attendanceId].outing_minutes += this.calculateOutingTime(outOfOfficeTiming, returnToOfficeTiming, outOfOffice, returnToOffice, companyItem)
              }
              staffItem[attendanceId].workingItemsObj.countReturnToOffice++
            }
          })// 対象の日の、対象スタッフの労働時間計算終わり

          let workMinute = 0
          let earlyAttendanceTime = 0
          let overtimeTime = 0
          let worktimeRecordId = 0

          // 対象の日の、対象スタッフの集計
          Object.keys(itemsByDate2[date][staffId]).forEach((wId) => {
            worktimeRecordId = wId

            let item = itemsByDate2[date][staffId][wId]
            if (item.workingItemsObj.leave !== null) {
              if (item.workingItemsObj.attendance !== null) {
                let leaveDate = new Date(item.workingItemsObj.leave)
                if (item.workingItemsObj.isLeavingSpanningDate === 1) {
                  leaveDate = new Date(leaveDate.setDate(leaveDate.getDate() + 1)).getTime()// 1日後にする
                } else {
                  leaveDate = new Date(item.workingItemsObj.leave).getTime()
                }
                let attendanceDate = new Date(item.workingItemsObj.attendance).getTime()
                let diffTime = leaveDate - attendanceDate
                let diffMinutes = (diffTime / 1000 / 60)
                item['work_minute'] = diffMinutes - (item.rest_minutes + item.outing_minutes)
                item['work_minute'] += workMinute

                // 休日出勤でない場合の、時間外の加算
                if ((item['is_holiday'] === 0 || item['is_base_transfer_leave'] === 1) && companyItem['overtime_calc_for_soul'] === 0) {
                  if (companyItem['is_working_out_of_regular_working_hours_to_do'] === 0 && 'id' in companyItem) {
                    // 1日の労働時間が8時間を超えていたら、時間外
                    if (item['work_minute'] > 480) {
                      // ここ、昼休憩に「マイナス」がある場合、計算されない？
                      if (companyItem['is_rest_time_working_overtime_to_do'] === 0) {
                        item['overtime_workday_minutes'] = item['work_minute'] - 480
                      } else {
                        item['overtime_workday_minutes'] += item['work_minute'] - 480
                      }
                    } else { // 休憩時間に労働しても、時間外として計算しない
                      if (companyItem['is_rest_time_working_overtime_to_do'] === 0) {
                        item['overtime_workday_minutes'] = 0
                      }
                    }
                    if (item['overtime_workday_midnight_minutes'] !== 0 && workMinute > 480) {
                      if (item['is_late_night_time_shift'] === 0) {
                        item['overtime_workday_minutes'] -= item['overtime_workday_midnight_minutes']
                        item['legal_overtime_working_minutes'] -= item['overtime_workday_midnight_minutes']
                      }
                    }
                    if (item['is_overtime_working_date'] === 1) {
                      item['overtime_workday_minutes'] = item['work_minute']
                      item['non_statutory_working_minutes'] = item['overtime_workday_minutes']

                      if (item['overtime_workday_midnight_minutes'] !== 0 && workMinute > 480) {
                        item['overtime_workday_minutes'] -= item['overtime_workday_midnight_minutes']
                      }
                    }
                  }

                  // 法定内時間外と法定外時間外
                  if (
                    companyItem['is_move_up_weekly_40_time_calculation'] === 1 &&
                    item['is_overtime_working_date'] !== 1 &&
                    item['is_public_holiday_working'] === 1 &&
                    item['is_holiday_working'] !== 1 &&
                    'id' in companyItem
                  ) {
                    if (item['work_minute'] > 480) {
                      item['non_statutory_working_minutes'] = item['work_minute'] - 480
                      item['legal_overtime_working_minutes'] = item['work_minute'] - item['non_statutory_working_minutes']
                    } else {
                      item['non_statutory_working_minutes'] = 0
                      item['legal_overtime_working_minutes'] = item['work_minute']
                    }
                  } else if (item['is_overtime_working_date'] !== 1) {
                    if (item['is_public_holiday_working'] === 1) {
                      item['legal_overtime_working_minutes'] = item['work_minute']
                      if (item['legal_overtime_working_minutes'] > 480) {
                        item['non_statutory_working_minutes'] = item['legal_overtime_working_minutes'] - 480
                        item['legal_overtime_working_minutes'] = 480
                      }
                    } else {
                      let totalOvertimeShift = item['overtime_time'] - item['tardiness_time']
                      totalOvertimeShift += item['early_attendance_time']
                      if (companyItem.is_rest_time_working_overtime_to_do === 1) {
                        totalOvertimeShift -=
                          (item.rest_minutes - defaultTotalRestMinutes) +
                            item.outing_minutes
                      } else if (item.rest_minutes > defaultTotalRestMinutes) {
                        // ここの処理が微妙だが、これがないと、休憩超過が法内時間外に計上される
                        totalOvertimeShift -= item.rest_minutes - defaultTotalRestMinutes
                      }
                      item['legal_overtime_working_minutes'] += totalOvertimeShift
                    }
                    if (item['is_public_holiday_working'] !== 1) {
                      if (item['work_minute'] > 480) {
                        item['non_statutory_working_minutes'] = item['work_minute'] - 480
                        // totalOvertimeShiftは法定外時間外を含んでいるため、法内時間外から法定外時間外を引く必要がある
                        item['legal_overtime_working_minutes'] -= item['work_minute'] - 480
                      } else {
                        item['non_statutory_working_minutes'] = 0
                      }
                    }
                  }
                  // 時給制のスタッフは法内時間外は計算しない
                  if (item['is_part'] === 1) {
                    item['legal_overtime_working_minutes'] = 0
                  }
                  // 午前有給取得があった場合、遅刻扱いにしない
                  if (item['am_paid_leave'] === 1) {
                    item['is_tardiness'] = 0
                    item['tardiness_time'] = 0
                  }
                } else { // 休日出勤の場合は休日深夜時間外の計算だけでよい
                  item['overtime_workday_minutes'] = 0
                  item['legal_overtime_working_minutes'] = 0
                }

                item.rest_minutes += item.outing_minutes
                item.rest_minutes += item.workingItemsObj.restTimeAtLeaving
              }
            }
            if (item.workingItemsObj.attendance === null) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('出勤の登録がありません。')
            }
            if (item.workingItemsObj.leave === null) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('退勤の登録がありません。')
            }
            if (item.workingItemsObj.countOutOfOffice > item.workingItemsObj.countReturnToOffice) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('外戻の登録がありません。')
            } else if (item.workingItemsObj.countOutOfOffice < item.workingItemsObj.countReturnToOffice) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('外出の登録がありません。')
            }
            if (item.workingItemsObj.countRest > item.workingItemsObj.countRestart) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('休戻の登録がありません。')
            } else if (item.workingItemsObj.countRest < item.workingItemsObj.countRestart) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('休入の登録がありません。')
            }
            if (item.workingItemsObj.countAmRest > item.workingItemsObj.countAmRestart) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('午前の休戻の登録がありません。')
            } else if (item.workingItemsObj.countAmRest < item.workingItemsObj.countAmRestart) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('午前の休入の登録がありません。')
            }
            if (item.workingItemsObj.countPmRest > item.workingItemsObj.countPmRestart) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('午後の休戻の登録がありません。')
            } else if (item.workingItemsObj.countPmRest < item.workingItemsObj.countPmRestart) {
              item.hasError = true
              item.workingItemsObj.errorItems.push('午後の休入の登録がありません。')
            }
            item.errorText = item.workingItemsObj.errorItems.join('<br>')

            item['work_minute'] -= workMinute
            workMinute += item['work_minute']
            earlyAttendanceTime += item['early_attendance_time']
            overtimeTime += item['overtime_time']
          })// 対象の日の、対象スタッフの集計

          // これは、休日に「休入」などの間違い打刻用対策
          if (!itemsByDate2[date][staffId]) {
            return
          }
          if (!itemsByDate2[date][staffId][worktimeRecordId]) {
            return
          }

          // 休日出勤でなければ、1日の労働時間を記録
          // 法定休日でかつ振替休日の基日でない出勤は、週40時間に加算しない
          if (
            (
              itemsByDate2[date][staffId][worktimeRecordId]['is_holiday'] === 0 ||
              itemsByDate2[date][staffId][worktimeRecordId]['is_base_transfer_leave'] === 1
            ) && bystaffDailyWorkingMinutesItem[staffId]
          ) {
            // 週40時間に加算するのは、8時間まで
            if (workMinute + earlyAttendanceTime + overtimeTime >= 480) {
              bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes = 480
            } else {
              bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes = workMinute
            }
          } else if (bystaffDailyWorkingMinutesItem[staffId]) {
            bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes = 0
          }

          if (moment(date).day() === closingWeekday && byStaffWeekWorkingRecordItem[staffId]) {
            if (itemsByDate2[date][staffId][worktimeRecordId]['is_holiday'] === 0) {
              // 1週間で40時間以上労働していたら、時間外を加算
              if (byStaffWeekWorkingRecordItem[staffId].a_week_total_working_minutes + workMinute > 2400) {
                itemsByDate2[date][staffId][worktimeRecordId].overtime_workday_minutes +=
                byStaffWeekWorkingRecordItem[staffId].a_week_total_working_minutes + workMinute - 2400

                itemsByDate2[date][staffId][worktimeRecordId].non_statutory_working_minutes +=
                byStaffWeekWorkingRecordItem[staffId].a_week_total_working_minutes + workMinute - 2400

                itemsByDate2[date][staffId][worktimeRecordId].legal_overtime_working_minutes = 0

                // 土曜日に時間外労働があった場合は、重複するので差し引く
                if (workMinute > 480) {
                  itemsByDate2[date][staffId][worktimeRecordId].overtime_workday_minutes -= (workMinute - 480)
                  itemsByDate2[date][staffId][worktimeRecordId].non_statutory_working_minutes -= (workMinute - 480)
                }
              }
            }
          }
          if (moment(date).day() === closingWeekday && bystaffDailyWorkingMinutesItem[staffId]) {
            // 1週間で時間外を計算したので、削除する
            delete byStaffWeekWorkingRecordItem[staffId]
            bystaffDailyWorkingMinutesItem[staffId].worktimeMinutes = 0
          }
        })// 対象の日の、各スタッフのループ終わり
      })// 日付でのループ終わり

      Object.keys(itemsByDate2).forEach((date) => {
        let staffItems = []
        Object.keys(itemsByDate2[date]).forEach((staffId) => {
          Object.keys(itemsByDate2[date][staffId]).forEach((item) => {
            staffItems.push(itemsByDate2[date][staffId][item])
          })
        })
        itemsByDate3.push({
          date: date,
          wDay: moment(date).format('e'),
          staffItems: staffItems
        })
      })
      return itemsByDate3
    },
    automaticprocessItems (items, leaveApplicationItems, isShowRounding, obj, staffItems, companyItem, projectId = 0) {
      let itemsByStaff = {}
      let itemsByStaff2 = {}

      items.forEach((dayObj, idx) => {
        (dayObj.staffItems).forEach((staffItems) => {
          if (!itemsByStaff[staffItems.staff_id]) {
            itemsByStaff[staffItems.staff_id] = []
          }
          itemsByStaff[staffItems.staff_id].push(staffItems)
        })
      })

      leaveApplicationItems.forEach((item) => {
        if (!itemsByStaff[item.staff_id]) {
          itemsByStaff[item.staff_id] = []

          if (!itemsByStaff2[item.staff_id]) {
            itemsByStaff2[item.staff_id] = {
              staff_id: item.staff_id,
              name: item.staff_name,
              yayoi_code: item.yayoi_code,
              group_id: item.group_id,
              is_part: item.is_part,
              is_tardiness: 0,
              tardiness_time: 0,
              is_early_withdrawal: 0,
              early_withdrawal_time: 0,
              rest_minutes: 0,
              work_minute: 0,
              holidayWorkMinutes: 0,
              workTime: 0,
              holidayWorkTime: 0,
              legal_overtime_working_minutes: 0,
              non_statutory_working_minutes: 0,
              overtime_workday_minutes: 0,
              overtime_workday_midnight_minutes: 0,
              overtime_holiday_workday_midnight_minutes: 0,
              leaveApplication: 0,
              class_name: '',
              closed_id: 0
            }
          }
        }

        itemsByStaff[item.staff_id].push(item)
      })

      let count = 0
      Object.keys(itemsByStaff).forEach((staff) => {
        if (!itemsByStaff2[staff]) {
          itemsByStaff2[staff] = {
            staff_id: staff,
            name: itemsByStaff[staff][0].name,
            yayoi_code: itemsByStaff[staff][0].yayoi_code,
            group_id: itemsByStaff[staff][0].group_id,
            is_part: itemsByStaff[staff][0].is_part,
            is_tardiness: 0,
            tardiness_time: 0,
            is_early_withdrawal: 0,
            early_withdrawal_time: 0,
            rest_minutes: 0,
            work_minute: 0,
            holidayWorkMinutes: 0,
            workTime: 0,
            holidayWorkTime: 0,
            legal_overtime_working_minutes: 0,
            non_statutory_working_minutes: 0,
            overtime_workday_minutes: 0,
            overtime_workday_midnight_minutes: 0,
            overtime_holiday_workday_midnight_minutes: 0,
            leaveApplication: 0,
            class_name: '',
            closed_id: 0
          }
          if (count % 2 === 0) {
            itemsByStaff2[staff].class_name = 'whiteCursor'
          } else {
            itemsByStaff2[staff].class_name = 'grayCursor'
          }
          count++
        }

        staffItems.forEach((item) => {
          if (item.id === Number(staff)) {
            itemsByStaff2[staff].yayoi_code = item.yayoi_code
          }
        })

        leaveApplicationItems.forEach((item) => {
          if (item.staff_id === Number(staff) && item.is_approved === 1) {
            if (item.category === 'paid_leave') {
              itemsByStaff2[staff].leaveApplication += 1
            } else if (item.category === 'am_paid_leave' || item.category === 'pm_paid_leave') {
              itemsByStaff2[staff].leaveApplication += 0.5
            } else if (item.category === 'time_paid_leave') {
              itemsByStaff2[staff].leaveApplication += item.time_paid_leave / 10
            }
          }
        })
        let saveAttendanceDate = ''

        itemsByStaff[staff].forEach((staffItems) => {
          if (projectId !== 0 && staffItems.project_id !== projectId) {
            return
          }
          if (!staffItems.workingItemsObj) {
            return
          }

          let targetSaveAttendanceDate = moment(staffItems.workingItemsObj.attendance).tz('Asia/Tokyo').format('YYYY-MM-DD')

          itemsByStaff2[staff].is_tardiness += staffItems.is_tardiness
          itemsByStaff2[staff].tardiness_time += staffItems.tardiness_time
          itemsByStaff2[staff].is_early_withdrawal += staffItems.is_early_withdrawal
          itemsByStaff2[staff].early_withdrawal_time += staffItems.early_withdrawal_time
          itemsByStaff2[staff].rest_minutes += staffItems.rest_minutes
          itemsByStaff2[staff].closed_id = staffItems.closed_id

          if (staffItems.is_holiday === 1 && staffItems.is_base_transfer_leave === 0) {
            itemsByStaff2[staff].holidayWorkMinutes += staffItems.work_minute
            itemsByStaff2[staff].work_minute += staffItems.work_minute

            if (this.$route.path === '/manager/project-aggregate') {
              itemsByStaff2[staff].holidayWorkTime += 1
            } else if (saveAttendanceDate !== targetSaveAttendanceDate) {
              itemsByStaff2[staff].holidayWorkTime += 1
            }
          } else {
            itemsByStaff2[staff].work_minute += staffItems.work_minute

            if (this.$route.path === '/manager/project-aggregate') {
              itemsByStaff2[staff].workTime += 1
            } else if (saveAttendanceDate !== targetSaveAttendanceDate) {
              itemsByStaff2[staff].workTime += 1
            }
          }

          itemsByStaff2[staff].overtime_workday_minutes += staffItems.overtime_workday_minutes
          itemsByStaff2[staff].overtime_workday_midnight_minutes += staffItems.overtime_workday_midnight_minutes
          itemsByStaff2[staff].legal_overtime_working_minutes += staffItems.legal_overtime_working_minutes
          itemsByStaff2[staff].non_statutory_working_minutes += staffItems.non_statutory_working_minutes
          // if (staffItems.is_holiday === 1) {
          //   itemsByStaff2[staff].workHours += staffItems.overtime_holiday_workday_midnight_minutes / 60
          // }
          itemsByStaff2[staff].overtime_holiday_workday_midnight_minutes += staffItems.overtime_holiday_workday_midnight_minutes

          if (companyItem['overtime_calc_for_soul'] === 1 && itemsByStaff2[staff].is_part === 0) {
            let workTime2 = itemsByStaff2[staff].workTime + itemsByStaff2[staff].holidayWorkTime
            if (workTime2 === 28 && itemsByStaff2[staff].work_minute > 160 * 60) {
              itemsByStaff2[staff].overtime_workday_minutes = itemsByStaff2[staff].work_minute - 160 * 60
            } else if (workTime2 === 29 && itemsByStaff2[staff].work_minute > 165 * 60) {
              itemsByStaff2[staff].overtime_workday_minutes = itemsByStaff2[staff].work_minute - 165 * 60
            } else if (workTime2 === 30 && itemsByStaff2[staff].work_minute > 171 * 60) {
              itemsByStaff2[staff].overtime_workday_minutes = itemsByStaff2[staff].work_minute - 171 * 60
            } else if (workTime2 === 31 && itemsByStaff2[staff].work_minute > 177 * 60) {
              itemsByStaff2[staff].overtime_workday_minutes = itemsByStaff2[staff].work_minute - 177 * 60
            }

            // ソールは1時間単位で切り捨て
            itemsByStaff2[staff].overtime_workday_minutes = Math.floor(itemsByStaff2[staff].overtime_workday_minutes / 60) * 60
          }

          saveAttendanceDate = targetSaveAttendanceDate
        })
      })

      if (isShowRounding) {
        for (let i in itemsByStaff2) {
          if (obj['overtime_workday_minutes']) {
            if (itemsByStaff2[i].overtime_workday_minutes % 60 >= 30) {
              itemsByStaff2[i].overtime_workday_minutes += 60 - (itemsByStaff2[i].overtime_workday_minutes % 60)
            } else {
              itemsByStaff2[i].overtime_workday_minutes -= itemsByStaff2[i].overtime_workday_minutes % 60
            }
          }
          if (obj['overtime_workday_midnight_minutes']) {
            if (itemsByStaff2[i].overtime_workday_midnight_minutes % 60 >= 30) {
              itemsByStaff2[i].overtime_workday_midnight_minutes += 60 - (itemsByStaff2[i].overtime_workday_midnight_minutes % 60)
            } else {
              itemsByStaff2[i].overtime_workday_midnight_minutes -= itemsByStaff2[i].overtime_workday_midnight_minutes % 60
            }
          }
          if (obj['legal_overtime_working_minutes']) {
            if (itemsByStaff2[i].legal_overtime_working_minutes % 60 >= 30) {
              itemsByStaff2[i].legal_overtime_working_minutes += 60 - (itemsByStaff2[i].legal_overtime_working_minutes % 60)
            } else {
              itemsByStaff2[i].legal_overtime_working_minutes -= itemsByStaff2[i].legal_overtime_working_minutes % 60
            }
          }
          if (obj['non_statutory_working_minutes']) {
            if (itemsByStaff2[i].non_statutory_working_minutes % 60 >= 30) {
              itemsByStaff2[i].non_statutory_working_minutes += 60 - (itemsByStaff2[i].non_statutory_working_minutes % 60)
            } else {
              itemsByStaff2[i].non_statutory_working_minutes -= itemsByStaff2[i].non_statutory_working_minutes % 60
            }
          }
          if (obj['holidayWorkMinutes']) {
            if (itemsByStaff2[i].holidayWorkMinutes % 60 >= 30) {
              itemsByStaff2[i].holidayWorkMinutes += 60 - (itemsByStaff2[i].holidayWorkMinutes % 60)
            } else {
              itemsByStaff2[i].holidayWorkMinutes -= itemsByStaff2[i].holidayWorkMinutes % 60
            }
          }
          if (obj['overtime_holiday_workday_midnight_minutes']) {
            if (itemsByStaff2[i].overtime_holiday_workday_midnight_minutes % 60 >= 30) {
              itemsByStaff2[i].overtime_holiday_workday_midnight_minutes += 60 - (itemsByStaff2[i].overtime_holiday_workday_midnight_minutes % 60)
            } else {
              itemsByStaff2[i].overtime_holiday_workday_midnight_minutes -= itemsByStaff2[i].overtime_holiday_workday_midnight_minutes % 60
            }
          }
        }
      }
      return itemsByStaff2
    },
    processItemsForClosing (items, leaveApplicationItems, holidayWorkingItems) {
      let dailyItems = {}
      let dailyLeaveApplicationItems = {}

      items.forEach((dayObj) => {
        if (!dailyItems[dayObj.date]) {
          dailyItems[dayObj.date] = {
            date: dayObj.date,
            has_error: 0,
            unapproved_leave_application: 0,
            unapproved_holiday_working_application: 0,
            closed_id: 0
          }
        }
        dayObj.staffItems.forEach((staffItems) => {
          dailyItems[dayObj.date].has_error += staffItems.hasError
          dailyItems[dayObj.date].closed_id = staffItems.closed_id
        })

        if (dailyLeaveApplicationItems[dayObj.date]) {
          dailyItems[dayObj.date].closed_id = dailyLeaveApplicationItems[dayObj.date].closed_id
        }
      })

      leaveApplicationItems.forEach((lItems) => {
        let applicationDate = moment(lItems.application_date).format('YYYY-MM-DD')

        if (!dailyItems[applicationDate]) {
          dailyItems[applicationDate] = {
            date: applicationDate,
            has_error: 0,
            unapproved_leave_application: 0,
            unapproved_holiday_working_application: 0,
            closed_id: 0
          }
        }
        if (lItems.is_approved === 0) {
          dailyItems[applicationDate].unapproved_leave_application += 1
        }
        dailyItems[applicationDate].closed_id = lItems.closed_id
      })

      holidayWorkingItems.forEach((lItems) => {
        let applicationDate = moment(lItems.application_date).format('YYYY-MM-DD')

        if (!dailyItems[applicationDate]) {
          dailyItems[applicationDate] = {
            date: applicationDate,
            has_error: 0,
            unapproved_leave_application: 0,
            unapproved_holiday_working_application: 0,
            closed_id: 0
          }
        }
        if (lItems.is_second_approved === 0) {
          dailyItems[applicationDate].unapproved_holiday_working_application += 1
        }
        dailyItems[applicationDate].closed_id = lItems.closed_id
      })
      return dailyItems
    },
    soulProcessItems (items, projectNameObj, worktimeItems, beginDate, endDate, staff = '') {
      if (!staff || staff === '指定なし') {
        return []
      }

      let itemsByDate = {}
      let itemsByDate2 = {}
      let itemsByDate3 = []
      let staffId = ''
      let staffName = ''
      let groupId = ''
      let isPart = ''
      let projectId = ''

      items.forEach((item, idx) => {
        if (item.name !== staff) {
          return
        }

        item.record_atJpDate = this.convAtDate(item.record_at)
        if (!itemsByDate[item.record_atJpDate]) {
          itemsByDate[item.record_atJpDate] = []
        }
        itemsByDate[item.record_atJpDate].push(item)
      })

      Object.keys(itemsByDate).forEach((date) => {
        let attendanceItems = []
        let leaveItems = []
        let wakeBeginRecordAt = ''
        let wakeEndRecordAt = ''
        let countAttendance = 0
        let countleave = 0

        if (!staffId) {
          staffId = itemsByDate[date][0].staff_id
        }
        if (!staffName) {
          staffName = itemsByDate[date][0].name
        }
        if (!groupId) {
          groupId = itemsByDate[date][0].group_id
        }
        if (!isPart) {
          isPart = itemsByDate[date][0].is_part
        }
        if (!projectId) {
          projectId = itemsByDate[date][0].project_id
        }

        if (!itemsByDate2[date]) {
          itemsByDate2[date] = {
            date: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD(ddd)'),
            staff_id: itemsByDate[date][0].staff_id,
            yayoi_code: itemsByDate[date][0].yayoi_code || '',
            name: itemsByDate[date][0].name,
            group_id: itemsByDate[date][0].group_id,
            is_part: itemsByDate[date][0].is_part,
            project_id: itemsByDate[date][0].project_id,
            begin_time: '',
            begin_record_at: '',
            end_time: '',
            end_record_at: '',
            wake_begin_time: '',
            wake_end_time: '',
            work_time: 0,
            non_work_time: 0,
            non_work_minutes: 0,
            wake_work_time: 0,
            wake_work_minutes: 0,
            working_location: [],
            errorText: ''
          }
        }

        itemsByDate[date].forEach((item) => {
          if (item.category === 'attendance') {
            let attendanceTime = moment(item.record_at).format('HH:mm')
            countAttendance += 1

            if (projectNameObj[item.project_id] === '通夜') {
              itemsByDate2[date].wake_begin_time = attendanceTime
              wakeBeginRecordAt = item.record_at
            } else {
              attendanceItems.push(item.record_at)

              if (itemsByDate2[date].begin_time === '') {
                itemsByDate2[date].begin_time = attendanceTime
                itemsByDate2[date].begin_record_at = item.record_at
              }
            }
          } else if (item.category === 'leave') {
            let leaveTime = moment(item.record_at).format('HH:mm')
            countleave += 1

            if (projectNameObj[item.project_id] === '通夜') {
              itemsByDate2[date].wake_end_time = leaveTime
              wakeEndRecordAt = item.record_at
            } else {
              leaveItems.push(item.record_at)

              if (itemsByDate2[date].end_time === '') {
                itemsByDate2[date].end_time = leaveTime
                itemsByDate2[date].end_record_at = item.record_at
              } else if (itemsByDate2[date].end_time < leaveTime) {
                itemsByDate2[date].end_time = leaveTime
                itemsByDate2[date].end_record_at = item.record_at
              }
            }
          }
        })

        if (countAttendance < countleave) {
          itemsByDate2[date].errorText = '出勤の登録がありません。'
        } else if (countAttendance > countleave) {
          itemsByDate2[date].errorText = '退勤の登録がありません。'
        }

        if (itemsByDate2[date].wake_begin_time && itemsByDate2[date].wake_end_time) {
          let diffTime = new Date(wakeEndRecordAt) - new Date(wakeBeginRecordAt)
          let diffMinutes = (diffTime / 1000 / 60)
          let diffMinutes2 = Math.floor(diffMinutes / 60) + ':' + (Math.floor(diffMinutes) % 60)
          itemsByDate2[date].wake_work_time = diffMinutes2
          itemsByDate2[date].wake_work_minutes = diffMinutes
        }

        if (!itemsByDate2[date].errorText) {
          for (let i = 0; i < attendanceItems.length - 1; i++) {
            let diffTime = new Date(attendanceItems[i + 1]) - new Date(leaveItems[i])
            let diffMinutes = (diffTime / 1000 / 60)
            let diffMinutes2 = 0

            if (diffMinutes !== 0) {
              diffMinutes2 = Math.floor(diffMinutes / 60) + ':' + (Math.floor(diffMinutes) % 60)
            }
            itemsByDate2[date].non_work_time = diffMinutes2
            itemsByDate2[date].non_work_minutes = diffMinutes
          }
        }

        worktimeItems.forEach((item) => {
          if (moment(item.worktime_date).format('YYYY-MM-DD') === date) {
            itemsByDate2[date].working_location.push(item)
          }
        })
      })

      let totalWorkMinutes = 0
      let totalNonWorkMinutes = 0
      let totalWakeWorkMinutes = 0

      Object.keys(itemsByDate2).forEach((date) => {
        if (itemsByDate2[date].begin_record_at && itemsByDate2[date].end_record_at) {
          let diffTime = new Date(itemsByDate2[date].end_record_at) - new Date(itemsByDate2[date].begin_record_at)
          let diffMinutes = (diffTime / 1000 / 60)
          totalWorkMinutes += diffMinutes
        }

        totalNonWorkMinutes += itemsByDate2[date].non_work_minutes
        totalWakeWorkMinutes += itemsByDate2[date].wake_work_minutes
      })

      let beginDate2 = beginDate
      let endDate2 = moment(endDate, 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD')

      while (beginDate2 !== endDate2) {
        let itemsByDate22 = {}
        let isExist = 0
        Object.keys(itemsByDate2).forEach((date) => {
          if (beginDate2 === date) {
            isExist = 1
          }
        })

        if (isExist === 0) {
          let isInsertionComplete = 0

          Object.keys(itemsByDate2).forEach((date) => {
            if (date < beginDate2) {
              itemsByDate22[date] = itemsByDate2[date]
            } else {
              if (isInsertionComplete === 0) {
                itemsByDate22[beginDate2] = {
                  date: moment(beginDate2, 'YYYY-MM-DD').format('YYYY-MM-DD(ddd)'),
                  staff_id: staffId,
                  yayoi_code: '',
                  name: staffName,
                  group_id: groupId,
                  is_part: groupId,
                  project_id: projectId,
                  begin_time: '',
                  begin_record_at: '',
                  end_time: '',
                  end_record_at: '',
                  wake_begin_time: '',
                  wake_end_time: '',
                  work_time: 0,
                  non_work_time: 0,
                  non_work_minutes: 0,
                  wake_work_time: 0,
                  wake_work_minutes: 0,
                  working_location: [],
                  errorText: ''
                }
                itemsByDate22[date] = itemsByDate2[date]
                isInsertionComplete = 1
              } else {
                itemsByDate22[date] = itemsByDate2[date]
              }
            }
          })

          if (isInsertionComplete === 0) {
            itemsByDate22[beginDate2] = {
              date: moment(beginDate2, 'YYYY-MM-DD').format('YYYY-MM-DD(ddd)'),
              staff_id: staffId,
              yayoi_code: '',
              name: staffName,
              group_id: groupId,
              is_part: groupId,
              project_id: projectId,
              begin_time: '',
              begin_record_at: '',
              end_time: '',
              end_record_at: '',
              wake_begin_time: '',
              wake_end_time: '',
              work_time: 0,
              non_work_time: 0,
              non_work_minutes: 0,
              wake_work_time: 0,
              wake_work_minutes: 0,
              working_location: [],
              errorText: ''
            }
          }

          itemsByDate2 = itemsByDate22
        }
        beginDate2 = moment(beginDate2, 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD')
      }

      Object.keys(itemsByDate2).forEach((date) => {
        itemsByDate3.push(itemsByDate2[date])
      })

      if (totalWorkMinutes !== 0) {
        totalWorkMinutes -= totalNonWorkMinutes
        totalWorkMinutes += totalWakeWorkMinutes
      }

      let totalWorkTime = 0
      let totalNonWorkTime = 0
      let totalWakeWorkTime = 0

      if (totalWorkMinutes !== 0) {
        totalWorkTime = Math.floor(totalWorkMinutes / 60) + ':' + (Math.floor(totalWorkMinutes) % 60)
      }
      if (totalNonWorkMinutes !== 0) {
        totalNonWorkTime = Math.floor(totalNonWorkMinutes / 60) + ':' + (Math.floor(totalNonWorkMinutes) % 60)
      }
      if (totalWakeWorkMinutes !== 0) {
        totalWakeWorkTime = Math.floor(totalWakeWorkMinutes / 60) + ':' + (Math.floor(totalWakeWorkMinutes) % 60)
      }

      if (Object.keys(itemsByDate2).length) {
        itemsByDate3.push({
          date: '',
          begin_time: '',
          end_time: totalWorkTime,
          non_work_time: totalNonWorkTime,
          wake_work_time: totalWakeWorkTime,
          working_location: [],
          errorText: ''
        })
      }

      return itemsByDate3
    }
  }
}
