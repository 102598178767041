import moment from 'moment'

export default {
  methods: {
    pdf () {
      setTimeout(() => {
        // ここでフォントを指定
        window.pdfMake.fonts = {
          GenShin: {
            normal: 'GenShinGothic-P-Regular.ttf',
            bold: 'GenShinGothic-P-Regular.ttf',
            italics: 'GenShinGothic-P-Regular.ttf',
            bolditalics: 'GenShinGothic-P-Regular.ttf'
          }
        }

        let TotalTableContent = {
          table: {
            widths: '*',
            body: [
              []
            ]
          }
        }
        let tableHead1 = ['見出し1', '見出し2', '見出し3']

        let headTr1 = []
        for (let v of tableHead1) {
          headTr1.push({
            text: v,
            style: ['tableHeader', 'tableCell']
          })
        }
        TotalTableContent['table']['body'][0] = headTr1
        let tableBody1 = [
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3'],
          ['value1', 'value2', 'value3']
        ]
        for (let a of tableBody1) {
          let tr = []
          for (let v of a) {
            tr.push({
              text: v,
              fillColor: '#fff',
              style: ['tableCell'],
              alignment: 'right'
            })
          }
          TotalTableContent['table']['body'].push(tr)
        }

        window.pdfMake.createPdf({
          pageSize: 'A4',
          content: [
            {
              text: moment().format('YYYY年M月D日'),
              alignment: 'right'
            },
            // {
            //   columns: [
            //     {
            //       fontSize: 19,
            //       text: moment().format('YYYY年M月D日')
            //     },
            //     // TotalTableContent
            //   ],
            //   columnGap: 10
            // },
            TotalTableContent
          ],
          defaultStyle: {
            font: 'GenShin', // ここでデフォルトのスタイル名を指定しています。
            fontSize: 12
          }
        }).download('ファイル名.pdf')
      }, 300)
    }
  }
}
