<template>
  <div>
    <MyToolbar />
      <v-layout>
        <v-flex xs2 class="pa-2">
          <v-select
            :items="retireItems"
            v-model="isRetired"
            item-value="id"
            item-text="name"
            prepend-icon="business"
          ></v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
          <GroupCombo :value.sync="group" :groupId="groupId" />
        </v-flex>
        <v-flex xs2 class="pa-2">
          <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired" />
        </v-flex>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="ma-2" @click="showStaffEntryDialog()">
          登録履歴
        </v-btn>
        <v-btn color="primary" dark class="ma-2" @click="$router.push('/manager/group')">
          グループ設定
        </v-btn>
      </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >スタッフ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip v-if="isOverRegisterableNumber" label>スタッフ数が登録上限に達しています。</v-chip>
        <v-btn v-else color="#6ac67b" dark class="mb-2" @click="newEdit">追加</v-btn>
        <v-dialog v-model="dialog" persistent max-width="900px">
          <v-card>
            <v-card-title>
              <span class="headline">スタッフ編集</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs4 style="margin: auto 0">
                    <v-subheader class="font-weight-bold">
                      <span style="color: red">[必須]&nbsp;&nbsp;</span>スタッフ名</v-subheader>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      v-model="editedItem.name"
                      required
                      @input="$v.editedItem.name.$touch()"
                      @blur="$v.editedItem.name.$touch()"
                      :error-messages="nameErrors"
                    />
                  </v-flex>
                  <v-flex xs4></v-flex>
                  <v-flex xs4 style="margin: auto 0">
                    <v-subheader class="font-weight-bold">
                    <span
                      v-if="editedItem.id === 0 || changePassword"
                      style="color: red"
                    >[必須]&nbsp;&nbsp;</span>
                      パスワード
                    </v-subheader>
                  </v-flex>
                  <v-flex v-if="editedItem.id !== 0" xs4>
                    <v-checkbox v-model="changePassword" label="Passwordを変更する"></v-checkbox>
                    <v-text-field
                      v-if="changePassword"
                      v-model="editedItem.new_password"
                      @input="$v.editedItem.new_password.$touch()"
                      @blur="$v.editedItem.new_password.$touch()"
                      :error-messages="editItemPasswordErrors"
                    ></v-text-field>
                  </v-flex>
                  <v-flex v-else xs4>
                    <v-text-field
                      v-model="editedItem.password"
                      @input="$v.editedItem.password.$touch()"
                      @blur="$v.editedItem.password.$touch()"
                      :error-messages="newEditPasswordErrors"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4></v-flex>
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">メールアドレス</v-subheader>
                  </v-flex>
                  <v-flex xs8>
                    <v-text-field
                      v-model="editedItem.email"
                      @input="$v.editedItem.email.$touch()"
                      @blur="$v.editedItem.email.$touch()"
                      :error-messages="emailErrors"
                    />
                  </v-flex>

                  <!--
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">住所</v-subheader>
                  </v-flex>
                  <v-flex xs8>
                    <v-text-field
                      v-model="editedItem.address"
                      @input="$v.editedItem.address.$touch()"
                      @blur="$v.editedItem.address.$touch()"
                      :error-messages="addressErrors"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">電話番号（ハイフンなし）</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-model="editedItem.tel"
                      @input="$v.editedItem.tel.$touch()"
                      @blur="$v.editedItem.tel.$touch()"
                      :error-messages="telErrors"
                    />
                  </v-flex>
                  <v-flex xs5></v-flex>
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">生年月日</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-menu
                      ref="birthDateMenu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="editedItem.birth_date" readonly v-on="on"></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        locale="ja-jp"
                        :day-format="date => new Date(date).getDate()"
                        v-model="editedItem.birth_date"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="birthDateSave"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs5></v-flex>
                  -->

                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">入社日</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-menu
                      ref="enteringCompanyDateMenu"
                      v-model="enteringCompanyDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.entering_company_date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker2"
                        locale="ja-jp"
                        :day-format="date => new Date(date).getDate()"
                        v-model="editedItem.entering_company_date"
                        min="1950-01-01"
                        @change="enteringCompanyDateSave"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs5></v-flex>
                  <v-flex xs4 v-if="editedItem.is_retired === 1">
                    <v-subheader class="font-weight-bold">退職日</v-subheader>
                  </v-flex>
                  <v-flex xs3 v-if="editedItem.is_retired === 1">
                    <v-text-field v-model="editedItem.retired_date" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs5 v-if="editedItem.is_retired === 1"></v-flex>
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">グループ</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-select
                      v-model="editedItem.group_id"
                      :items="allGroupItems"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </v-flex>
                  <v-flex xs3></v-flex>
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">給与ソフトの従業員ID</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field v-model="editedItem.yayoi_code" />
                  </v-flex>
                  <v-flex xs5></v-flex>

                  <!--
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">時給制</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-switch v-model.number="editedItem.is_part" :true-value="1" :false-value="0" />
                  </v-flex>
                  <v-flex xs5></v-flex>
                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">シフト申請制</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-switch
                      v-model.number="editedItem.is_shift_application"
                      :true-value="1"
                      :false-value="0"
                    />
                  </v-flex>
                  <v-flex xs5></v-flex>
                  -->

                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">非36協定社員</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-switch v-model.number="editedItem.is_not_36" :true-value="1" :false-value="0" />
                  </v-flex>
                  <v-flex xs5></v-flex>

                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">役員</v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-switch v-model.number="editedItem.is_officer" :true-value="1" :false-value="0" />
                  </v-flex>
                  <v-flex xs5></v-flex>

                  <v-flex xs4>
                    <v-subheader class="font-weight-bold">備考</v-subheader>
                  </v-flex>
                  <v-flex xs8>
                    <v-textarea
                      v-model="editedItem.memo"
                      @input="$v.editedItem.memo.$touch()"
                      @blur="$v.editedItem.memo.$touch()"
                      :error-messages="memoErrors"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" flat @click="dialog = false">Cancel</v-btn>
              <v-btn
                v-if="editedItem.is_retired === 0"
                color="success"
                flat
                @click="save"
              >OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
       <v-progress-linear v-if="isWait === 1" :indeterminate="true"></v-progress-linear>
       <v-data-table
        :headers="headers"
        :items="items"
        :rows-per-page-items="[{ text: 'All', value: -1 }, 10]"
      >
        <template v-slot:no-data>
          <v-alert v-if="isWait === 0" :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="staff-td">{{ props.item.name }}</td>
          <td class="staff-td" style="white-space:pre-line;">
            {{ groupNameObj[props.item.group_id] }}
          </td>
          <!--
          <td class="staff-td">{{ props.item.address }}</td>
          <td class="staff-td">{{ props.item.tel }}</td>
          <td class="staff-td">{{ props.item.birth_date }}</td>
          -->
          <td class="staff-td">{{ props.item.email }}</td>
          <td class="staff-td">{{ props.item.entering_company_date }}</td>
          <td class="staff-td">
            <v-icon v-if="props.item.is_officer === 1" small color="primary">check</v-icon>
          </td>
          <td class="staff-td">
            <v-icon v-if="props.item.is_not_36 === 1" small color="primary">check</v-icon>
          </td>
          <td class="staff-td px-0" style="border-right: 1px solid rgba(0,0,0,0.12);">
            <v-btn color="success" flat @click="showQrDialog(props.item)">QRコード</v-btn>
          </td>
          <td class="justify-center layout px-0" :class="props.item.class_name">
            <v-icon small color="primary" class="mx-1" @click="editItem(props.item)">
              edit
            </v-icon>
            <v-btn
              v-if="props.item.is_retired === 0"
              color="accent"
              small
              outline
              style="margin-top: 0.8em; margin-right: 0"
              @click="retire(props.item)"
              >退職</v-btn>
            <v-btn v-else color="success" small flat style="margin-top: 0.8em" @click="rehire(props.item)">
              再雇用
            </v-btn>
            <v-icon
              v-if="props.item.is_retired === 1"
              small
              color="accent"
              class="mx-1"
              @click="deleteItem(props.item)"
            >delete</v-icon>
          </td>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </v-card>

    <v-dialog v-model="qrDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ qrItem.name }}
        </v-card-title>
        <v-card-text>
          <div v-if="checkedAuth" class="text-xs-center">
            <VueQrcode :value="qrItem.uri" :options="{ width: 100 }" tag="img"></VueQrcode>
          </div>
          <v-layout wrap v-else>
            <v-flex xs12>
              <v-text-field v-model="qrItem.password" label="パスワード"></v-text-field>
            </v-flex>
            <v-flex xs12 class="text-xs-right">
              <v-btn color="success" flat @click="checkAuth">QRコードを表示</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" flat @click="qrDialog = false">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="retireDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>退職処理</v-card-title>

        <v-card-text>
          <v-menu
            v-model="retireMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="editedItem.retired_date"
                label="退職日"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editedItem.retired_date" @input="retireMenu = false" />
          </v-menu>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="editedItem.retired_date" color="success" flat @click="retire2">
            退職処理をする
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="staffEntryDialog" width="380">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>登録履歴</v-card-title>

        <v-card-text>
          <v-data-table :headers="staffEntryHeaders" :items="staffEntryItems">
            <template v-slot:items="props">
              <td class="year-month">{{ props.item.yearMonth }}</td>
              <td class="entry-count">{{ props.item.entryCount }} 名</td>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="staffEntryDialog = false"> 閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import itemTools from '@/mixins/item_tools.js'

export default {
  mixins: [validationMixin, itemTools],
  components: {
    MyToolbar,
    VueQrcode,
    StaffCombo,
    GroupCombo
  },
  validations: {
    editedItem: {
      name: { required },
      email: {
        checkMailNotation () {
          if (this.editedItem.email !== undefined && this.editedItem.email !== '') {
            const result =
              (this.editedItem.email).match(/^[0-9a-z_./?\-\\+]+@([0-9a-z-]+\.)+[0-9a-z-]+$/)
            if (!(result)) {
              return false
            }
          }
          return true
        }
      },
      tel: {
        checkTelNotation () {
          if (this.editedItem.tel !== undefined && this.editedItem.tel !== '') {
            let tel = (this.editedItem.tel).match(/^[0-9]{10,11}$/)
            if (!(tel)) {
              return false
            }
          }
          return true
        }
      },
      memo: {
        checkMemoNotation () {
          if (this.editedItem.memo !== undefined && this.editedItem.memo !== '') {
            if (this.editedItem.memo.length > 300) {
              return false
            }
          }
          return true
        }
      },
      address: {
        checkAddressNotation () {
          if (this.editedItem.address !== undefined && this.editedItem.address !== '') {
            if (this.editedItem.address.length > 100) {
              return false
            }
          }
          return true
        }
      },
      password: {
        requiredNewEditPassword () {
          if (this.editedItem.id === 0) {
            if (this.editedItem.password === '') {
              return false
            }
          }
          return true
        },
        minLengthNewEditPassword () {
          if (this.editedItem.id === 0) {
            if (this.editedItem.password.length < 8) {
              return false
            }
          }
          return true
        }
      },
      new_password: {
        requiredEditItemPassword () {
          if (this.editedItem.id !== 0) {
            if (this.changePassword) {
              if (this.editedItem.new_password === '') {
                return false
              }
            }
          }
          return true
        },
        minLengthEditItemPassword () {
          if (this.editedItem.id !== 0) {
            if (this.changePassword) {
              if (this.editedItem.new_password.length < 8) {
                return false
              }
            }
          }
          return true
        }
      }
    }
  },
  data: () => {
    return {
      isRetired: 0,
      retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }],
      isWait: 1,
      staff: '',
      staffItems: [],
      staffEntryItems: [],
      staffEntryDialog: false,
      group: '指定なし',
      groupId: 0,
      groupNameObj: {},
      allGroupItems: [],
      criteriaMonthly: '',
      criteriaDay: '',
      criteriaMonthlyItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      criteriaDayItems: [],
      enteringCompanyDateMenu: false,
      retireMenu: false,
      birthDateMenu: false,
      checkedAuth: false,
      retireDialog: false,
      qrDialog: false,
      qrItem: {
        id: 0,
        name: '',
        password: '',
        uri: ''
      },
      changePassword: false,
      companyItem: {},
      items: [],
      headers: [
        {
          text: 'スタッフ名',
          align: 'center',
          sortable: false,
          value: 'name',
          width: 100,
          class: 'staff-header'
        },
        {
          text: 'グループ',
          align: 'center',
          width: 150,
          sortable: false,
          value: 'group',
          class: 'staff-header'
        },
        /*
        {
          text: '住所',
          align: 'center',
          sortable: false,
          value: 'address',
          width: 250,
          class: 'staff-header'
        },
        {
          text: '電話番号',
          align: 'center',
          sortable: false,
          value: 'tel',
          class: 'staff-header'
        },
        {
          text: '生年月日',
          align: 'center',
          sortable: false,
          value: 'birth-date',
          class: 'staff-header'
        },
        */
        {
          text: 'Email',
          align: 'center',
          sortable: false,
          value: 'email',
          class: 'staff-header'
        },
        {
          text: '入社日',
          align: 'center',
          sortable: false,
          value: 'entering-company-date',
          class: 'staff-header'
        },
        {
          text: '役員',
          align: 'center',
          sortable: false,
          value: 'is-officer',
          class: 'staff-header'
        },
        {
          text: '非36協定社員',
          align: 'center',
          sortable: false,
          value: 'is-not-36',
          class: 'staff-header'
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'name',
          width: '7em',
          class: 'staff-header'
        },
        { text: '', sortable: false, value: 'name', class: 'staff-header' }
      ],
      dialog: false,
      editedItem: {}
    }
  },
  watch: {
    birthDateMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    enteringCompanyDateMenu (val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = 'YEAR'))
    },
    staff () {
      this.getStaff()
      this.getGroup()
      this.makeDaysArray()
      this.getGroup()
    },
    group () {
      this.getStaff()
      this.getGroup()
      this.makeDaysArray()
      this.getGroup()
    },
    isRetired () {
      this.getStaff()
      this.getGroup()
      this.makeDaysArray()
      this.getGroup()
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('スタッフ名は必須です')
      return errors
    },
    emailErrors () {
      if (!this.$v.editedItem.email.$dirty) {
        return []
      }

      if (!this.$v.editedItem.email.checkMailNotation) {
        return ['メールアドレスの形式が正しくありません']
      }

      return []
    },
    telErrors () {
      const errors = []
      if (!this.$v.editedItem.tel.$dirty) return errors
      !this.$v.editedItem.tel.checkTelNotation && errors.push('電話番号の形式が正しくありません')
      return errors
    },
    memoErrors () {
      const errors = []
      if (!this.$v.editedItem.memo.$dirty) return errors
      !this.$v.editedItem.memo.checkMemoNotation && errors.push('備考は300文字以内です')
      return errors
    },
    addressErrors () {
      const errors = []
      if (!this.$v.editedItem.address.$dirty) return errors
      !this.$v.editedItem.address.checkAddressNotation && errors.push('住所は100文字以内です')
      return errors
    },
    newEditPasswordErrors () {
      const errors = []
      if (!this.$v.editedItem.password.$dirty) {
        return errors
      }

      if (!this.$v.editedItem.password.requiredNewEditPassword) {
        errors.push('パスワードは必須です')
      }
      if (!this.$v.editedItem.password.minLengthNewEditPassword) {
        errors.push('パスワードは8文字以上必要です')
      }
      return errors
    },
    editItemPasswordErrors () {
      const errors = []
      if (!this.$v.editedItem.new_password.$dirty) return errors

      if (!this.$v.editedItem.new_password.requiredEditItemPassword) {
        errors.push('パスワードは必須です')
      }
      if (!this.$v.editedItem.new_password.minLengthEditItemPassword) {
        errors.push('パスワードは8文字以上必要です')
      }
      return errors
    },
    isOverRegisterableNumber () {
      return this.items.length >= this.companyItem.registerable_number
    }
  },
  mounted () {
    this.getCompany()
    this.getStaff()
    this.makeDaysArray()
    this.getGroup()
  },
  methods: {
    getGroup () {
      axios.get('/api/manager/group')
        .then((res) => {
          this.groupNameObj = {}
          let items = []
          let parentNameObj = {}

          res.data.forEach((item) => {
            if (!this.groupNameObj[item.id]) {
              this.groupNameObj[item.id] = ''
            }
            if (item.parent_id === 0) {
              if (!parentNameObj[item.id]) {
                parentNameObj[item.id] = {}
                parentNameObj[item.id] = item.name
              }
            }
          })
          res.data.forEach((item) => {
            if (item.parent_id === 0) {
              this.groupNameObj[item.id] = item.name
              items.push(item)
            } else {
              this.groupNameObj[item.id] = parentNameObj[item.parent_id] + '\n' + '＞ ' + item.name
              item.name = parentNameObj[item.parent_id] + ' ＞ ' + item.name
              items.push(item)
            }
          })
          this.allGroupItems = items
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getGroup]')
        })
    },
    makeDaysArray () {
      for (let i = 0; i < 31; i++) {
        this.criteriaDayItems.push(i + 1)
      }
    },
    checkAuth () {
      let postData = {
        id: this.qrItem.id,
        password: this.qrItem.password
      }
      axios.post('/api/login', postData)
        .then((res) => {
          this.qrItem.uri = this.qrItem.id + ':' + this.qrItem.password
          this.checkedAuth = true
        })
        .catch(() => {
          alert('認証エラー')
        })
    },
    showStaffEntryDialog () {
      this.staffEntryItems = []
      this.staffEntryDialog = true
      this.getStaffEntry()
    },
    showQrDialog (item) {
      this.checkedAuth = false
      this.qrItem.name = item.name
      this.qrItem.id = item.id
      this.qrDialog = true
    },
    newEdit () {
      this.criteriaMonthly = ''
      this.criteriaDay = ''
      this.editedItem = {
        id: 0,
        group_id: 0,
        name: '',
        email: '',
        tel: '',
        address: '',
        birth_date: '',
        entering_company_date: '',
        memo: '',
        password: '',
        new_password: '',
        yayoi_code: '',
        is_part: 0,
        is_shift_application: 0,
        is_retired: 0,
        is_rehire: 0,
        is_not_36: 0,
        is_officer: 0,
        retired_date: ''
      }
      this.$v.$reset()
      this.dialog = true
    },
    editItem (item) {
      this.$v.$reset()
      let tmpItem = cloneDeep(item)
      tmpItem.password = ''
      tmpItem.new_password = ''

      this.editedItem = tmpItem
      this.editedItem.is_rehire = 0
      this.dialog = true
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    getStaff () {
      axios.get('/api/manager/staff')
        .then((res) => {
          let items = []

          res.data.forEach((item) => {
            if (this.isRetired === 0) {
              if (item.is_retired === 1) {
                return
              }
            }
            if (this.isRetired === 1) {
              if (item.is_retired === 0) {
                return
              }
            }

            if (this.group !== '指定なし') {
              if (this.group !== '') {
                if (item.group_id !== this.groupId) {
                  return
                }
              }
            }
            if (this.staff !== '指定なし') {
              if (this.staff !== '') {
                if (item.name !== this.staff) {
                  return
                }
              }
            }
            items.push(item)
          })
          this.items = items

          this.$nextTick(() => {
            this.isWait = 0
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaff]')
        })
    },
    getStaffEntry () {
      axios.get('/api/manager/staff-entry')
        .then((res) => {
          const ymObj = {}
          res.data.forEach((item) => {
            const key = `${item.year_month}:${item.staff_entry_id}`
            if (!ymObj[key]) {
              ymObj[key] = 0
            }
            ymObj[key]++
          })
          const insertedKey = {}
          const ymItems = []
          Object.keys(ymObj).sort((a, b) => {
            if (a > b) {
              return -1
            }
            if (b > a) {
              return 1
            }
            return 0
          }).forEach((ym) => {
            function ymToJa (str, p1, p2) {
              return `${p1}年${parseInt(p2)}月`
            }
            const ymJa = ym.replace(/^(\d{4})-(\d{2}).*$/, ymToJa)
            if (insertedKey[ymJa]) {
              return
            }
            insertedKey[ymJa] = true
            ymItems.push({
              yearMonth: ymJa,
              entryCount: ymObj[ym]
            })
          })
          this.staffEntryItems = ymItems
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaffEntry]')
        })
    },
    save () {
      this.$v.$touch()
      if (this.criteriaMonthly !== '' && this.criteriaDay !== '') {
        if (String(this.criteriaMonthly).length === 1) {
          this.criteriaMonthly = '0' + String(this.criteriaMonthly)
        }
        if (String(this.criteriaDay).length === 1) {
          this.criteriaDay = '0' + String(this.criteriaDay)
        }
        this.editedItem.first_day_of_work =
          String(this.criteriaMonthly) + '-' + String(this.criteriaDay)
      }

      if (!this.$v.$invalid) {
        if (this.editedItem.id === 0) {
          axios.post('/api/manager/staff', this.editedItem)
            .then((res) => {
              this.getStaff()
              this.changePassword = false
              this.dialog = false
            })
            .catch((error) => {
              if (error.response.data) {
                if (error.response.data.error) {
                  if (error.response.data.error === 'email duplicate') {
                    alert('このEmailは登録済みです。')
                    return
                  }
                }
              }
              alert('登録エラー')
            })
        } else {
          axios.put('/api/manager/staff/' + this.editedItem.id, this.editedItem)
            .then((res) => {
              this.getStaff()
              this.changePassword = false
              this.dialog = false
            })
            .catch((error) => {
              if (error.response.data) {
                if (error.response.data.error) {
                  if (error.response.data.error === 'email duplicate') {
                    alert('このEmailは他スタッフで登録済みです。')
                    return
                  }
                }
              }
              alert('登録エラー')
            })
        }
      }
    },
    birthDateSave (date) {
      this.$refs.birthDateMenu.save(date)
    },
    enteringCompanyDateSave (date) {
      this.$refs.enteringCompanyDateMenu.save(date)
    },
    retire (item) {
      this.editedItem.is_retired = 1
      this.editedItem.is_rehire = 0
      this.editedItem.id = item.id
      this.retireDialog = true
    },
    retire2 () {
      axios.put('/api/manager/staff/' + this.editedItem.id, this.editedItem)
        .then((res) => {
          this.editedItem.is_retired = 0
          this.editedItem.retired_date = ''
          this.retireDialog = false
          this.getStaff()
        })
        .catch(() => {
          alert('退職更新エラー')
        })
    },
    rehire (item) {
      if (confirm(item.name + ' を再雇用します。')) {
        this.editedItem.is_rehire = 1
        this.editedItem.is_retired = 0

        axios.put('/api/manager/staff/' + item.id, this.editedItem)
          .then((res) => {
            this.editedItem.is_rehire = 0
            this.retireDialog = false
            this.getStaff()
          })
          .catch(() => {
            alert('再雇用更新エラー')
          })
      }
    },
    deleteItem (item) {
      if (confirm(item.name + ' のデータを完全に削除します。')) {
        axios.delete('/api/manager/staff/' + item.id)
          .then(() => {
            this.getStaff()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>

<style>
.staff-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.staff-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
