<template>
  <div>
    <MyToolbar />
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white" class="pt-2">
        <v-layout>
          <v-flex xs2>
            <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding: 0.3em">勤務時間</v-toolbar-title>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs2>
            <v-select
              :items="retireItems"
              v-model="isRetired"
              item-value="id"
              item-text="name"
              prepend-icon="business"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="termStaffId"
              :items="termStaffItems"
              item-value="id"
              item-text="name"
              label="スタッフ"
              prepend-icon="perm_identity"
            />
          </v-flex>
          <v-flex xs6 class="text-xs-right">
            <v-btn color="#6ac67b" dark class="mb-2" @click="showWorktimePlan">予定追加</v-btn>
            <DefaultWorktimeDialog :items="defaultWorktimeItems" @updateDefaultWorktime="getDefaultWorktime" />
            <RoundTimeDialog />
            <DefaultRestTimeDialog />
            <CompanyOrdinaryTimeDialog />
          </v-flex>
        </v-layout>
      </v-toolbar>
      <v-layout>
        <v-spacer />
        <!-- <v-btn color="primary" dark style="margin-right: 2.3em;" @click="$router.push('/manager/daily-worktime')">
          日別勤務シフト
        </v-btn> -->
        <v-btn v-if="companyItem.id === 11 || companyItem.id === 12" color="primary" dark style="margin-right: 2.3em;" @click="$router.push('/manager/daily-worktime')">
          日別勤務シフト
        </v-btn>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs8 class="text-xs-center">
        </v-flex>
        <v-flex xs4>
        </v-flex>
      <v-layout row wrap>
      </v-layout>
        <v-flex xs8 class="pa-1">
          <MyCalendar
            :eventItems="newEvents"
            :calendarStart.sync="calendarStart"
            @event-clicked="eventClicked"
            @month-changed="monthChanged"
          />
        </v-flex>
        <v-flex xs4 class="pa-1">
          <v-card class="pa-2">
            <v-card-text>
              <v-list subheader two-line>
                <v-subheader>{{ eventDate }}</v-subheader>
                <template v-for="item in eventItems">
                  <div v-if="item.application_date" :key="item.id">
                    <v-list-tile :key="item.id">
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item.staff_name }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ leaveCategory(item.category) }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider :key="`divider-${item.id}`" />
                  </div>
                  <div v-else :key="item.id">
                    <v-list-tile :key="item.id">
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item.staff_name }}</v-list-tile-title>
                        <v-list-tile-sub-title v-if="item.project_id !== 0">{{ projectNameObj[item.project_id] }}</v-list-tile-sub-title>
                        <v-list-tile-sub-title>{{ item.begin_time }} - {{ item.end_time }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon ripple>
                          <v-icon
                            color="primary"
                            @click="editWorktime(item)"
                            :disabled="determineClosing(item)"
                          >
                            edit
                          </v-icon>
                        </v-btn>
                      </v-list-tile-action>
                      <v-list-tile-action>
                        <v-btn icon ripple>
                          <v-icon
                            color="accent"
                            @click="deleteWorktime(item)"
                            :disabled="determineClosing(item)"
                          >
                            delete
                          </v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider :key="`divider-${item.id}`" />
                  </div>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
    <v-dialog
      v-model="editDialog"
      persistent
      max-width="400px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">勤務時間編集</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs5>
              <v-subheader>日付</v-subheader>
            </v-flex>
            <v-flex xs7 class="subheading">
              <v-text-field
                readonly
                :value="editedWorktimeItem.date"
                class="pt-0"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs5>
              <v-subheader>スタッフ名</v-subheader>
            </v-flex>
            <v-flex xs7 class="subheading">
              <v-text-field
                readonly
                :value="editedWorktimeItem.staff_name"
                class="pt-0"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs5 v-if="editedWorktimeItem.project_id !== 0">
              <v-subheader>作業</v-subheader>
            </v-flex>
            <v-flex xs7 v-if="editedWorktimeItem.project_id !== 0" class="subheading">
              <v-text-field
                readonly
                :value="projectNameObj[editedWorktimeItem.project_id]"
                class="pt-0"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <DefaultWorktimeMenu
              :defaultWorktimeItems="defaultWorktimeItems"
              :beginTime.sync="editedWorktimeItem.begin_time"
              :endTime.sync="editedWorktimeItem.end_time"
            />
          </v-toolbar>
          <v-layout row wrap>
            <v-flex xs12>
              <v-dialog
                ref="beginTimeDialogForEdit"
                v-model="beginTimeModalForEdit"
                :return-value.sync="editedWorktimeItem.begin_time"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="editedWorktimeItem.begin_time"
                    label="開始時間"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="beginTimeModalForEdit"
                  v-model="editedWorktimeItem.begin_time"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="beginTimeModalForEdit = false">Cancel</v-btn>
                  <v-btn
                    flat
                    color="success"
                    @click="$refs.beginTimeDialogForEdit.save(editedWorktimeItem.begin_time)"
                  >OK</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs12>
              <v-dialog
                ref="endTimeDialogForEdit"
                v-model="endTimeModalForEdit"
                :return-value.sync="editedWorktimeItem.end_time"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="editedWorktimeItem.end_time"
                    label="終了時間"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="endTimeModalForEdit"
                  v-model="editedWorktimeItem.end_time"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="endTimeModalForEdit = false">Cancel</v-btn>
                  <v-btn flat color="success" @click="$refs.endTimeDialogForEdit.save(editedWorktimeItem.end_time)">OK</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" flat @click="editDialog = false">CANCEL</v-btn>
          <v-btn color="success" flat @click="worktimePlanUpdate">登録</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="worktimePlanDialog"
      persistent
      max-width="1000px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">勤務時間編集</span>
        </v-card-title>
        <template v-if="worktimePlanDialogPage === 1">
          <v-card-text style="overflow-y: scroll; height: 90vh;">
            <v-layout row wrap>
              <v-flex xs12>
                <v-toolbar flat color="white">
                  <v-menu
                    v-model="staffSelectMenu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        class="white--text"
                        outline
                        v-on="on"
                      >
                        スタッフ
                        <v-icon right dark>add</v-icon>
                      </v-btn>
                    </template>

                    <v-card style="overflow-y: scroll; height: 60vh;">
                      <v-list>
                        <v-list-tile v-for="item in staffItems" :key="item.id">
                          <v-list-tile-action>
                            <v-checkbox
                              v-model="selectedStaffIds"
                              :label="item.name"
                              :value="item.id"
                            ></v-checkbox>
                          </v-list-tile-action>
                        </v-list-tile>

                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn flat @click="staffSelectMenu = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-chip v-for="name in staffNameList" :key="name">{{ name }}</v-chip>
                </v-toolbar>
                <v-divider />
              </v-flex>
              <v-flex xs12>
                <MyCalendar
                  :eventItems="newEvents"
                  :calendarStart.sync="calendarStart"
                  :highlightDays="highlightDays"
                  @day-clicked="dayClicked"
                  @month-changed="monthChanged"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="worktimePlanDialog = false">Cancel</v-btn>
            <v-alert
              :value="staffAlertJudgment"
              color="error"
              outline
              icon="error_outline"
              class="mr-1"
            >
              スタッフが未選択です
            </v-alert>
            <v-alert
              :value="dayAlertJudgment"
              color="error"
              outline
              icon="error_outline"
            >
              日付が未選択です
            </v-alert>
            <v-btn
              v-if="(staffAlertJudgment !== true) && (dayAlertJudgment !== true) && projectItems.length > 0"
              color="success"
              flat
              @click="projectDialog = true"
            >
              作業を指定
            </v-btn>
            <v-btn
              v-else-if="(staffAlertJudgment !== true) && (dayAlertJudgment !== true)"
              color="success"
              flat
              @click="toSelectTime"
            >
              時間を指定
            </v-btn>
          </v-card-actions>
        </template>
        <template v-if="worktimePlanDialogPage === 2">
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <DefaultWorktimeMenu
              :defaultWorktimeItems="defaultWorktimeItems"
              :beginTime.sync="beginTime"
              :endTime.sync="endTime"
            />
          </v-toolbar>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-dialog
                  ref="beginTimeDialog"
                  v-model="beginTimeModal"
                  :return-value.sync="beginTime"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="beginTime"
                      label="開始時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="beginTimeModal"
                    v-model="beginTime"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="beginTimeModal = false">Cancel</v-btn>
                    <v-btn flat color="success" @click="$refs.beginTimeDialog.save(beginTime)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12>
                <v-dialog
                  ref="endTimeDialog"
                  v-model="endTimeModal"
                  :return-value.sync="endTime"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endTime"
                      label="終了時間"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="endTimeModal"
                    v-model="endTime"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="endTimeModal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.endTimeDialog.save(endTime)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="worktimePlanDialogPage = 1">戻る</v-btn>
            <v-btn color="success" flat @click="toConfirm">確認</v-btn>
          </v-card-actions>
        </template>
        <template v-if="worktimePlanDialogPage === 3">
          <v-card-text>
            <v-card>
              <v-card-title>
                <h3 class="title mb-0">スタッフ</h3>
              </v-card-title>
              <v-card-text class="subheading">
                <v-chip v-for="(name, idx) in staffNameList" :key="idx">{{ name }}</v-chip>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>
                <h3 class="title mb-0">日付</h3>
              </v-card-title>
              <v-card-text class="subheading">
                <v-chip v-for="(day, idx) in dayList" :key="idx">{{ day }}</v-chip>
              </v-card-text>
            </v-card>
            <v-card v-if="projectId !== 0">
              <v-card-title>
                <h3 class="title mb-0">作業</h3>
              </v-card-title>
              <v-card-text class="subheading">
                <v-chip>{{ projectNameObj[projectId] }}</v-chip>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>
                <h3 class="title mb-0">勤務時間</h3>
              </v-card-title>
              <v-card-text class="subheading">
                <v-chip>{{ beginTime }}</v-chip>
                -
                <v-chip>{{ endTime }}</v-chip>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey lighten-1" flat @click="worktimePlanDialogPage = 2">戻る</v-btn>
            <v-btn color="success" flat @click="worktimePlanSave">登録</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="projectDialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          作業選択
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-layout row wrap align-center>
              <v-flex xs6>
                <v-subheader>作業</v-subheader>
              </v-flex>

              <v-flex xs6>
                <v-select
                  v-model="projectId"
                  :items="projectItems"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  single-line
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            @click="projectDialog = false"
          >
            キャンセル
          </v-btn>
          <v-btn
            flat
            v-if="projectId === 0"
            disabled
          >
            作業を選択してください
          </v-btn>
          <v-btn
            color="success"
            flat
            v-else
            @click="toSelectTime"
          >
            時間を指定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import MyToolbar from '@/components/MyToolbar'
import MyCalendar from '@/components/MyCalendar'
import DefaultWorktimeDialog from '@/components/DefaultWorktimeDialog'
import RoundTimeDialog from '@/components/RoundTimeDialog'
import DefaultWorktimeMenu from '@/components/DefaultWorktimeMenu'
import DefaultRestTimeDialog from '@/components/DefaultRestTimeDialog'
import CompanyOrdinaryTimeDialog from '@/components/CompanyOrdinaryTimeDialog'
import '@/assets/vue2-simple-calendar.css'
import moment from 'moment-timezone'
import itemTools from '@/mixins/item_tools.js'

export default {
  mixins: [itemTools],
  name: 'your-component',
  components: {
    MyToolbar,
    MyCalendar,
    DefaultWorktimeDialog,
    RoundTimeDialog,
    DefaultWorktimeMenu,
    DefaultRestTimeDialog,
    CompanyOrdinaryTimeDialog
  },
  data: () => ({
    companyItem: {},
    isRetired: 0,
    retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }, { id: 3, name: '指定なし' }],
    countDisplay: '',
    projectDialog: false,
    projectNameObj: {},
    projectItems: [],
    projectId: 0,
    calendarStart: moment().format('YYYY-MM'),
    leaveCategoryName: {
      paid_leave: '有給休暇',
      am_paid_leave: '午前有給休暇',
      pm_paid_leave: '午後有給休暇',
      time_paid_leave: '時間有給',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      general_absence: '一般欠勤',
      disease_scratch_absence: '病傷欠勤',
      closing: '休業',
      time_closing: '時間休業',
      public_holiday: '公休日'
    },
    leaveApplicationItems: [],
    termStaffId: 0,
    selectedDefaultWorktime: {},
    selectedDefaultWorktime2: {},
    defaultWorktimeSelectMenu: false,
    defaultWorktimeSelectMenu2: false,
    eventItems: [],
    staffSelectMenu: false,
    dayAlertJudgment: true,
    staffAlertJudgment: true,
    beginTimeModal: false,
    beginTimeModalForEdit: false,
    endTimeModal: false,
    endTimeModalForEdit: false,
    selectedStaffIds: [],
    beginTime: '00:00',
    endTime: '00:00',
    editedWorktimeItem: {},
    editDialog: false,
    worktimePlanDialog: false,
    worktimePlanDialogPage: 1,
    staffItems: [],
    worktimeItems: [],
    highlightDays: {},
    defaultWorktimeItems: []
  }),
  watch: {
    termStaffId () {
      this.eventItems = []
    },
    worktimePlanDialog (value) {
      if (value === false) {
        this.staffSelectMenu = false
      }
      this.$emit('month-changed', { start: '2019-12-01', end: '2019-12-31' })
    },
    selectedDefaultWorktime () {
      this.selectedSaveDefaultWorktime()
    },
    selectedStaffIds () {
      if (this.selectedStaffIds.length === 0) {
        this.staffAlertJudgment = true
      } else {
        this.staffAlertJudgment = false
      }
    },
    highlightDays: {
      handler: function (val) {
        if (Object.keys(this.highlightDays).length === 0) {
          this.dayAlertJudgment = true
        } else {
          this.dayAlertJudgment = false
        }
      },
      deep: true
    },
    isRetired () {
      // これあやしい。。
      this.getWorktime()
      this.getLeaveApplication()
      this.getStaff()
    }
  },
  computed: {
    eventDate () {
      if (this.eventItems.length === 0) {
        return ''
      } else {
        if (this.eventItems[0].worktime_date) {
          return moment(this.eventItems[0].worktime_date).format('YYYY-MM-DD')
        } else {
          return moment(this.eventItems[0].application_date).format('YYYY-MM-DD')
        }
      }
    },
    dayObj () {
      let dayObj = {}
      this.worktimeItems.forEach((item) => {
        if (!dayObj[moment(item.worktime_date).format('YYYY-MM-DD')]) {
          dayObj[moment(item.worktime_date).format('YYYY-MM-DD')] = []
        }
        dayObj[moment(item.worktime_date).format('YYYY-MM-DD')].push(item)
      })

      Object.keys(dayObj).forEach((dayItem) => {
        dayObj[dayItem].sort(function (a, b) {
          if (a.begin_time < b.begin_time) return -1
          if (a.begin_time > b.begin_time) return 1
          return 0
        })
      })
      return dayObj
    },
    leaveApplicationDayObj () {
      let dayObj = {}
      this.leaveApplicationItems.forEach((item) => {
        if (!dayObj[moment(item.application_date).format('YYYY-MM-DD')]) {
          if (item.is_approved === 1) {
            dayObj[moment(item.application_date).format('YYYY-MM-DD')] = []
            dayObj[moment(item.application_date).format('YYYY-MM-DD')].push(item)
          }
        }
      })
      return dayObj
    },
    termStaffItems () {
      let items = [
        { id: 0, name: '指定なし' },
        ...this.staffItems
      ]
      return items
    },
    newEvents () {
      let obj = {}
      if (this.termStaffId === 0) {
        Object.keys(this.dayObj).forEach((day) => {
          if (!obj[moment(day).format('YYYY-MM-DD')]) {
            obj[moment(day).format('YYYY-MM-DD')] = []
          }
          let staffList = []
          Object.keys(this.dayObj[day]).forEach((dayItems) => {
            staffList.push(this.dayObj[day][dayItems].staff_name)
          })
          obj[moment(day).format('YYYY-MM-DD')].push({
            title: this.dayObj[day].length + this.countDisplay,
            staffList: staffList,
            start: moment(day).toDate(),
            end: null,
            type: 'work'
          })
        })
        Object.keys(this.leaveApplicationDayObj).forEach((day) => {
          if (!obj[moment(day).format('YYYY-MM-DD')]) {
            obj[moment(day).format('YYYY-MM-DD')] = []
          }
          let staffList = []
          Object.keys(this.leaveApplicationDayObj[day]).forEach((dayItems) => {
            staffList.push(this.leaveApplicationDayObj[day][dayItems].staff_name)
          })
          obj[moment(day).format('YYYY-MM-DD')].push({
            title: '休暇 ' + this.leaveApplicationDayObj[day].length + '名',
            staffList: staffList,
            start: moment(day).toDate(),
            end: null,
            classes: ['worktime-leave-day'],
            type: 'leave'
          })
        })
      } else {
        this.worktimeItems.forEach((item) => {
          if (item.staff_id === this.termStaffId) {
            if (!obj[moment(item.worktime_date).format('YYYY-MM-DD')]) {
              obj[moment(item.worktime_date).format('YYYY-MM-DD')] = []
            }
            obj[moment(item.worktime_date).format('YYYY-MM-DD')].push({
              staffList: [item.staff_name],
              title: item.begin_time + ' - ' + item.end_time,
              start: moment(item.worktime_date).toDate(),
              end: null,
              type: 'work'
            })
          }
        })
        this.leaveApplicationItems.forEach((item) => {
          if (item.staff_id === this.termStaffId) {
            if (item.is_approved === 1) {
              if (!obj[moment(item.application_date).format('YYYY-MM-DD')]) {
                obj[moment(item.application_date).format('YYYY-MM-DD')] = []
              }
              obj[moment(item.application_date).format('YYYY-MM-DD')].push({
                staffList: [item.staff_name],
                title: this.leaveCategoryName[item.category],
                start: moment(item.application_date).toDate(),
                end: null,
                classes: ['worktime-leave-day'],
                type: 'leave'
              })
            }
          }
        })
      }
      return obj
    },
    events () {
      let items = []
      if (this.termStaffId === 0) {
        Object.keys(this.dayObj).forEach((day) => {
          items.push({
            title: this.dayObj[day].length + this.countDisplay,
            start: moment(day).toDate(),
            end: null,
            type: 'work'
          })
        })
        Object.keys(this.leaveApplicationDayObj).forEach((day) => {
          items.push({
            title: '休暇 ' + this.leaveApplicationDayObj[day].length + '名',
            start: moment(day).toDate(),
            end: null,
            classes: ['worktime-leave-day'],
            type: 'leave'
          })
        })
      } else {
        this.worktimeItems.forEach((item) => {
          if (item.staff_id === this.termStaffId) {
            items.push({
              title: item.begin_time + ' - ' + item.end_time,
              start: moment(item.worktime_date).toDate(),
              end: null
            })
          }
        })
        this.leaveApplicationItems.forEach((item) => {
          if (item.staff_id === this.termStaffId) {
            if (item.is_approved === 1) {
              items.push({
                title: this.leaveCategoryName[item.category],
                start: moment(item.application_date).toDate(),
                end: null,
                classes: ['worktime-leave-day']
              })
            }
          }
        })
      }
      return items
    },
    dayList () {
      let list = []
      Object.keys(this.highlightDays).forEach((date) => {
        list.push(date)
      })
      return list
    },
    staffNameList () {
      let list = []
      let staffObj = {}
      this.staffItems.forEach((item) => {
        staffObj[item.id] = item
      })
      this.selectedStaffIds.forEach((staffId) => {
        list.push(staffObj[staffId].name)
      })
      return list
    }
  },
  mounted () {
    this.getCompany()
    this.getDefaultWorktime()
    this.getWorktime()
    this.getStaff()
    this.getLeaveApplication()
    this.getProject()
  },
  methods: {
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((pRes) => {
          this.projectNameObj = {}
          this.projectItems = []
          this.projectId = 0

          this.projectItems = pRes.data

          pRes.data.forEach((item) => {
            if (!this.projectNameObj[item.id]) {
              this.projectNameObj[item.id] = item.name
            }
          })

          if (this.projectItems.length === 0) {
            this.countDisplay = '名'
          } else {
            this.countDisplay = ''
          }
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    determineClosing (item) {
      if (item.closed_id !== 0) {
        return true
      } else {
        return false
      }
    },
    monthChanged (event) {
      this.getWorktime()
      this.getLeaveApplication()
    },
    leaveCategory (category) {
      return this.leaveCategoryName[category]
    },
    getLeaveApplication () {
      this.leaveApplicationItems = []
      let beginDate = this.calendarStart + '-01'
      let endDate = moment(beginDate).endOf('month').format('YYYY-MM-DD')

      axios.get('/api/manager/leave-application?begin_date=' + beginDate + '&end_date=' + endDate)
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.leaveApplicationItems.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.leaveApplicationItems.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.leaveApplicationItems.push(item)
              }
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getLeaveApplication]')
        })
    },
    worktimePlanUpdate () {
      axios.put('/api/manager/worktime/' + this.editedWorktimeItem.id, this.editedWorktimeItem)
        .then((res) => {
          this.editDialog = false
          this.getWorktime()
          setTimeout(() => {
            let tmpItems = []
            this.eventItems.forEach((eventItem) => {
              if (eventItem.id === this.editedWorktimeItem.id) {
                eventItem.begin_time = this.editedWorktimeItem.begin_time
                eventItem.end_time = this.editedWorktimeItem.end_time
              }
              tmpItems.push(eventItem)
              this.eventItems = tmpItems
            })
            this.editedWorktimeItem = {}
          }, 300)
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    editWorktime (item) {
      let tmpItem = cloneDeep(item)
      tmpItem.date = moment(this.editedWorktimeItem.worktime_date).format('YYYY-MM-DD')
      this.editedWorktimeItem = tmpItem
      this.editDialog = true
    },
    selectedSaveDefaultWorktime () {
      this.defaultWorktimeSelectMenu = false
      if (this.selectedDefaultWorktime !== null) {
        this.beginTime = this.selectedDefaultWorktime.begin_time
        this.endTime = this.selectedDefaultWorktime.end_time
      } else {
        this.beginTime = '00:00'
        this.endTime = '00:00'
      }
    },
    eventClicked (event) {
      let clickedDayObj = this.dayObj[moment(event.start).format('YYYY-MM-DD')]
      let clickedLeaveApplicationDayObj = this.leaveApplicationDayObj[moment(event.start).format('YYYY-MM-DD')]

      if (clickedDayObj) {
        this.eventItems = cloneDeep(clickedDayObj)
        if (clickedLeaveApplicationDayObj) {
          let i = 0
          while (clickedLeaveApplicationDayObj.length > i) {
            this.eventItems.push(clickedLeaveApplicationDayObj[i])
            i++
          }
        }
      } else {
        this.eventItems = cloneDeep(clickedLeaveApplicationDayObj)
      }
    },
    dayClicked (date) {
      let days = cloneDeep(this.highlightDays)
      if (days[date]) {
        delete days[date]
      } else {
        days[date] = true
      }
      this.highlightDays = cloneDeep(days)
    },
    deleteWorktime (item) {
      let confirmText = item.staff_name + ' の\n' +
        item.begin_time + '-' + item.end_time + ' を削除します。'
      if (confirm(confirmText)) {
        axios.delete('/api/manager/worktime/' + item.id)
          .then(() => {
            this.getWorktime()
            setTimeout(() => {
              let tmpItems = []
              this.eventItems.forEach((eventItem) => {
                if (eventItem.id !== item.id) {
                  tmpItems.push(eventItem)
                }
                this.eventItems = tmpItems
              })
            }, 300)
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    worktimePlanSave () {
      let postDataObj = {}
      this.selectedDefaultWorktime = {}
      postDataObj['staff_id_list'] = this.selectedStaffIds
      postDataObj['date_list'] = this.dayList
      postDataObj['begin_time'] = this.beginTime
      postDataObj['end_time'] = this.endTime
      postDataObj['project_id'] = this.projectId

      axios.post('/api/manager/worktime', postDataObj)
        .then((res) => {
          this.worktimePlanDialog = false
          this.getWorktime()
          this.getProject()
          setTimeout(() => {
            this.selectedStaffIds = []
            this.beginTime = '00:00'
            this.endTime = '00:00'
            this.worktimePlanDialogPage = 1
            this.highlightDays = {}
          }, 300)
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    showWorktimePlan () {
      this.highlightDays = {}
      this.selectedStaffIds = []
      this.endTime = '00:00'
      this.beginTime = '00:00'
      this.worktimePlanDialog = true
    },
    getWorktime () {
      axios.get('/api/manager/worktime?ym=' + this.calendarStart)
        .then((res) => {
          this.worktimeItems = []
          res.data.forEach((item, idx) => {
            if (this.isRetired === 3) {
              this.worktimeItems.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.worktimeItems.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.worktimeItems.push(item)
              }
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getWorktime]')
        })
    },
    getStaff () {
      this.staffItems = []
      axios.get('/api/manager/staff')
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.staffItems.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.staffItems.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.staffItems.push(item)
              }
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaff]')
        })
    },
    toSelectTime () {
      this.projectDialog = false
      this.worktimePlanDialogPage = 2
    },
    toConfirm () {
      this.worktimePlanDialogPage = 3
    },
    getDefaultWorktime () {
      axios.get('/api/manager/default-worktime')
        .then((res) => {
          this.defaultWorktimeItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getDefaultWorktime]')
        })
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
