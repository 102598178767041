export default {
  openSound () {
    document.getElementById('open-sound').play()
  },
  clickSound () {
    document.getElementById('click-sound').play()
  },
  attendanceSound () {
    document.getElementById('attendance-sound').play()
  },
  leaveSound () {
    document.getElementById('leave-sound').play()
  },
  confirmSound () {
    document.getElementById('confirm-sound').play()
  },
  logoutSound () {
    document.getElementById('logout-sound').play()
  }
}
