<template>
  <v-btn
    :disabled="items.length === 0"
    @click="downloadCSV"
  >CSVダウンロード</v-btn>
</template>

<script>
import Encoding from 'encoding-japanese'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'

export default {
  name: 'AggregateCsv',
  props: ['items'],
  methods: {
    downloadCSV () {
      const csvData = Papa.unparse(this.items)
      const unicodeList = []
      for (let i = 0; i < csvData.length; i += 1) {
        unicodeList.push(csvData.charCodeAt(i))
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode')
      const uInt8List = new Uint8Array(shiftJisCodeList)
      const writeData = new Blob([uInt8List], { type: 'text/csv' })
      saveAs(writeData, 'イージア-一覧データ.csv')
    }
  }
}
</script>
