<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items2" divider=">" class="pb-0"></v-breadcrumbs>
    <v-toolbar-title
      style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
      class="ma-3"
    >グループ設定</v-toolbar-title>
    <v-layout row wrap>
      <v-flex xs5>
        <v-card class="ma-1">
          <v-toolbar
            card
            color="grey lighten-3"
          >
            <v-icon>group</v-icon>
            <v-toolbar-title>グループ名</v-toolbar-title>
          </v-toolbar>
          <v-treeview open-all :items="tItems"></v-treeview>
        </v-card>
      </v-flex>
      <v-flex xs7>
        <v-card class="ma-1" height="500">
          <v-tabs fixed-tabs slider-color="primary">
            <v-tab
              v-for="n in 2"
              :key="n"
            >
              {{ n === 1 ? 'メイングループ' : 'サブグループ'}}
            </v-tab>
            <v-tab-item
              v-for="n in 2"
              :key="n"
            >
              <v-card flat>
                <v-card-text v-if="n === 1">
                  <v-card class="mb-3 ma-2">
                    <v-toolbar flat color="white">
                      <v-toolbar-title class="subheading font-weight-bold" style="border-left: solid 3px #456ab8; padding-left: 0.3em">メイングループ</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn color="#6ac67b" dark class="mb-2" @click="newEdit('main')">追加</v-btn>
                      <v-dialog v-model="dialog" persistent max-width="600px">
                        <v-card>
                          <v-card-title>
                            <span class="headline">メイングループ編集</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container grid-list-md>
                              <v-layout wrap>
                                <v-flex xs5 style="margin: auto 0">
                                  <v-subheader class="font-weight-bold">
                                    <span style="color: red">[必須]&nbsp;&nbsp;</span>
                                    メイングループ名
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-text-field
                                    v-model="editedItem.name"
                                    required
                                    @input="$v.editedItem.name.$touch()"
                                    @blur="$v.editedItem.name.$touch()"
                                    :error-messages="nameErrors"
                                  />
                                </v-flex>
                                <v-flex xs5>
                                  <v-subheader class="font-weight-bold">
                                    Chatwork ルームID
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-text-field
                                    v-model="editedItem.chatwork_room_id"
                                    @input="$v.editedItem.chatworkRoomId.$touch()"
                                    @blur="$v.editedItem.chatworkRoomId.$touch()"
                                    :error-messages="roomIDErrors"
                                  />
                                </v-flex>
                                <v-flex xs5>
                                  <v-subheader class="font-weight-bold">
                                    Chatwork APIトークン
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-text-field
                                    v-model="editedItem.chatwork_token"
                                    @input="$v.editedItem.chatworkToken.$touch()"
                                    @blur="$v.editedItem.chatworkToken.$touch()"
                                    :error-messages="tokenErrors"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey lighten-1" flat @click="dialog = false">Cancel</v-btn>
                            <v-btn color="success" flat @click="save">OK</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      :headers="headers"
                      :items="mainItems"
                    >
                      <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning" outline>
                          データがありません
                        </v-alert>
                      </template>
                      <template v-slot:items="props">
                        <td class="staff-td">{{ props.item.name }}</td>
                        <td class="staff-td justify-center layout px-0" :class="props.item.class_name">
                          <v-icon
                            small
                            color="primary"
                            class="mr-4"
                            @click="editItem(props.item, 'main')"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            small
                            color="accent"
                            @click="deleteItem(props.item)"
                          >
                            delete
                          </v-icon>
                        </td>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                  </v-card>
                </v-card-text>
                <v-card-text v-else>
                  <v-card class="mb-3 ma-2">
                    <v-toolbar flat color="white">
                      <v-toolbar-title class="subheading font-weight-bold" style="border-left: solid 3px #456ab8; padding-left: 0.3em">サブグループ</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn color="#6ac67b" dark class="mb-2" @click="newEdit('sub')">追加</v-btn>
                      <v-dialog v-model="subDialog" persistent max-width="600px">
                        <v-card>
                          <v-card-title>
                            <span class="headline">サブグループ編集</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container grid-list-md>
                              <v-layout wrap>
                                <v-flex xs5 style="margin: auto 0">
                                  <v-subheader class="font-weight-bold">
                                    <span style="color: red">[必須]&nbsp;&nbsp;</span>
                                    サブグループ名
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-text-field
                                    v-model="editedItem.name"
                                    required
                                    @input="$v.editedItem.name.$touch()"
                                    @blur="$v.editedItem.name.$touch()"
                                    :error-messages="nameErrors"
                                  />
                                </v-flex>
                                <v-flex xs5 style="margin: auto 0">
                                  <v-subheader class="font-weight-bold">
                                    <span style="color: red">[必須]&nbsp;&nbsp;</span>
                                    親グループ
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-select
                                    v-model="editedItem.parent_id"
                                    :items="parentItems"
                                    item-text="name"
                                    item-value="id"
                                    @change="$v.editedItem.parent.$touch()"
                                    @blur="$v.editedItem.parent.$touch()"
                                    :error-messages="parentErrors"
                                  ></v-select>
                                </v-flex>
                                <v-flex xs5>
                                  <v-subheader class="font-weight-bold">
                                    Chatwork ルームID
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-text-field
                                    v-model="editedItem.chatwork_room_id"
                                    @input="$v.editedItem.chatworkRoomId.$touch()"
                                    @blur="$v.editedItem.chatworkRoomId.$touch()"
                                    :error-messages="roomIDErrors"
                                  />
                                </v-flex>
                                <v-flex xs5>
                                  <v-subheader class="font-weight-bold">
                                    Chatwork APIトークン
                                  </v-subheader>
                                </v-flex>
                                <v-flex xs7>
                                  <v-text-field
                                    v-model="editedItem.chatwork_token"
                                    @input="$v.editedItem.chatworkToken.$touch()"
                                    @blur="$v.editedItem.chatworkToken.$touch()"
                                    :error-messages="tokenErrors"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey lighten-1" flat @click="subDialog = false">Cancel</v-btn>
                            <v-btn color="success" flat @click="save">OK</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      :headers="subHeaders"
                      :items="subItems"
                    >
                      <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning" outline>
                          データがありません
                        </v-alert>
                      </template>
                      <template v-slot:items="props">
                        <td class="staff-td">{{ props.item.name }}</td>
                        <td class="staff-td">{{ parentNameObj[props.item.parent_id] }}</td>
                        <td class="staff-td justify-center layout px-0" :class="props.item.class_name">
                          <v-icon
                            small
                            color="primary"
                            class="mr-4"
                            @click="editItem(props.item, 'sub')"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            small
                            color="accent"
                            @click="deleteItem(props.item)"
                          >
                            delete
                          </v-icon>
                        </td>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import MyToolbar from '@/components/MyToolbar'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  components: {
    MyToolbar
  },
  validations: {
    editedItem: {
      name: { required },
      chatworkRoomId: {
        checkRoomIdNotation () {
          if (this.editedItem.chatwork_room_id !== undefined && this.editedItem.chatwork_room_id !== '') {
            let roomId = (this.editedItem.chatwork_room_id).match(/^[0-9a-z]+$/)
            if (!(roomId)) {
              return false
            }
          }
          return true
        }
      },
      chatworkToken: {
        checkTokenNotation () {
          if (this.editedItem.chatwork_token !== undefined && this.editedItem.chatwork_token !== '') {
            let token = (this.editedItem.chatwork_token).match(/^[0-9a-z]+$/)
            if (!(token)) {
              return false
            }
          }
          return true
        }
      },
      parent: {
        checkSelect () {
          if (this.editedItem.parent_id === 0 && this.subDialog) {
            return false
          }
          return true
        }
      }
    }
  },
  data: () => {
    return {
      parentNameObj: {},
      parentItems: [],
      tItems: [],
      mainItems: [],
      subItems: [],
      headers: [
        {
          text: 'メイングループ名',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'staff-header'
        },
        { text: '', sortable: false, value: 'name', class: 'staff-header' }
      ],
      subHeaders: [
        {
          text: 'サブグループ名',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'staff-header'
        },
        {
          text: '親グループ名',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'staff-header'
        },
        { text: '', sortable: false, value: 'name', class: 'staff-header' }
      ],
      dialog: false,
      subDialog: false,
      editedItem: {},
      items2: [
        {
          text: 'スタッフ',
          disabled: false,
          href: '/#/manager/staff'
        },
        {
          text: 'グループ設定',
          disabled: true
        }
      ]
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('メイングループ名は必須です')
      return errors
    },
    roomIDErrors () {
      const errors = []
      if (!this.$v.editedItem.chatworkRoomId.$dirty) return errors
      !this.$v.editedItem.chatworkRoomId.checkRoomIdNotation && errors.push('半角英数字で入力してください')
      return errors
    },
    tokenErrors () {
      const errors = []
      if (!this.$v.editedItem.chatworkToken.$dirty) return errors
      !this.$v.editedItem.chatworkToken.checkTokenNotation && errors.push('半角英数字で入力してください')
      return errors
    },
    parentErrors () {
      const errors = []
      if (!this.$v.editedItem.parent.$dirty) return errors
      !this.$v.editedItem.parent.checkSelect && errors.push('親グループは必須です')
      return errors
    }
  },
  mounted () {
    this.getGroup()
  },
  methods: {
    newEdit (group) {
      this.$v.$reset()
      this.editedItem = {
        id: 0,
        parent_id: 0,
        name: '',
        chatwork_room_id: '',
        chatwork_token: ''
      }
      if (group === 'main') {
        this.dialog = true
      } else {
        this.subDialog = true
      }
    },
    editItem (item, group) {
      this.$v.$reset()
      let tmpItem = cloneDeep(item)

      this.editedItem = tmpItem

      if (group === 'main') {
        this.dialog = true
      } else {
        this.subDialog = true
      }
    },
    getGroup () {
      axios.get('/api/manager/group')
        .then((res) => {
          this.mainItems = []
          this.subItems = []
          this.parentItems = []
          this.parentNameObj = {}
          this.tItems = []
          let items = []

          items = res.data
          items.forEach((item) => {
            if (item.parent_id === 0) {
              this.mainItems.push(item)
              this.parentItems.push({
                'id': item.id,
                'name': item.name
              })

              if (!this.parentNameObj[item.id]) {
                this.parentNameObj[item.id] = {}
                this.parentNameObj[item.id] = item.name
              }
              this.tItems.push({
                name: item.name,
                id: item.id,
                children: []
              })
            } else {
              this.subItems.push(item)
            }
          })

          items.forEach((item) => {
            if (item.parent_id !== 0) {
              this.tItems.forEach((tItem) => {
                if (item.parent_id === tItem.id) {
                  tItem.children.push({ id: item.id, name: item.name })
                }
              })
            }
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getGroup]')
        })
    },
    save () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        if (this.editedItem.id === 0) {
          axios.post('/api/manager/group', this.editedItem)
            .then((res) => {
              this.getGroup()
              this.dialog = false
              this.subDialog = false
            })
            .catch(() => {
              alert('登録エラー')
            })
        } else {
          axios.put('/api/manager/group/' + this.editedItem.id, this.editedItem)
            .then((res) => {
              this.getGroup()
              this.dialog = false
              this.subDialog = false
            })
            .catch(() => {
              alert('登録エラー')
            })
        }
      }
    },
    deleteItem (item) {
      if (confirm(item.name + ' を削除します。')) {
        axios.delete('/api/manager/group/' + item.id)
          .then(() => {
            this.getGroup()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>
